import {WebinarsMetaType} from '../types';

export enum SCOPE_VALUES {
  upcoming = 'upcoming',
  current = 'current',
  recorded = 'recorded'
};

export const WEBINARS_META: WebinarsMetaType = {
  scope: SCOPE_VALUES.upcoming,
  page: 1,
  perPage: 4,
  total_pages: 1,
};