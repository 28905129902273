import {AdminUserInviteType} from "types";
import {cloneDeep} from "lodash";
import {mapFromOneInvite} from ".";

export const mapFromInvites = (data: any): AdminUserInviteType[] => {
  const clone = cloneDeep(data);

  const invites = clone.invites.data;
  const hasData = invites && invites.length > 0;
  invites.sort((a:any, b:any) => a.id - b.id)


  return hasData ? invites.map((item: any) => mapFromOneInvite(item.attributes)) : []
}

