import React, {useEffect} from "react"
import {mount, unmount} from "../../actions";
import {observer} from "mobx-react-lite";
import {ListHeader, ListItem} from ".";
import {AdminUserInviteType} from "types";
import {invitesStore} from "../../stores";
import {useParams} from "react-router-dom";

type Props = {
  status: string;
}

export const List = observer((props:Props) => {
  let {status} = props;
  let {id} = useParams();

  useEffect(
    () => {
      mount(status, Number(id));

      return unmount();
    },
    []
  );

  const {invites, rejectReasons} = invitesStore;

  return (
    <div className="superhost__container">
      <ListHeader />
      <div className="superhost__content">
        {
          invites.map((item: AdminUserInviteType, index:number) =>
            <ListItem
              status={status}
              key={'admin-requests-key-' + index}
              courseId={Number(id)}
              invite={item}
              rejectReasonOptions={rejectReasons}
            />
          )}
      </div>
    </div>
  )
})