import React from 'react';
import {observer} from 'mobx-react-lite';
import {PageHeader} from 'components';
import {Page} from '../Page';
import {List} from 'components';
import {Item, ConfirmModal} from './components';
import {mount, unmount, getCourses, getMyCourses} from './actions/gets';
import {getNotifications} from '../Notifications/actions/gets';
import {coursesStore} from './stores';
import {COURSES_OPTIONS, COURSES_OPTIONS_VALUES} from './constants';

export const Dashboard = observer(() => {
  const handleOptionPress = (value: string) => {
    coursesStore.selectedOption = value;

    switch(value) {
      case COURSES_OPTIONS_VALUES.my_courses:
        getMyCourses();
        break;
      case COURSES_OPTIONS_VALUES.all_courses:
        getCourses();
        break;
    }
  };
  React.useEffect(() => {
    mount();
    getNotifications();

    return unmount();
  }, []);

  const {courses, selectedOption} = coursesStore;
  return (
    <Page className="auth_page_wrapper">
      <PageHeader
        title='WELCOME TO MEDCAFE ACADEMY'
        selectedOption={selectedOption}
        options={COURSES_OPTIONS}
        onOptionPress={handleOptionPress}
      />
      <List>
        {courses.map(course => (
          <Item key={`course_${course.id}`} item={course} isMy={selectedOption === COURSES_OPTIONS_VALUES.my_courses} />
        ))}
      </List>
      <ConfirmModal />
    </Page>
  )
});
