import { toast } from "react-toastify";
import { adminWebinarsService } from "services";
import { showError500, validateBE } from "utils";
import { errorsStore } from "stores";
import { addWebinarStore } from "../stores";
import { mapWebinarToFormData, validateWebinar } from "../utils";
import { WebinarErrorKeys } from "../constants";
import { cloneDeep } from "lodash";
import { InputChangeProps } from "@/types";

export const updateWebinar = async (courseId: number, callBack: Function) => {
  const { webinar } = addWebinarStore;
  let _webinar = cloneDeep(webinar);
  if (_webinar?.cpd_quizzes_attributes?.length > 10)
    _webinar.cpd_quizzes_attributes = _webinar.cpd_quizzes_attributes!.filter(
      (item: any) => item.question
    );

  _webinar.zoom_uid = webinar.zoom_uid.split(" ").join("");

  // IGNORE EMPTY QUESTIONS WHEN THEY ARE MORE THAN 10
  const errors = validateWebinar(_webinar);

  if (errors.length > 0) {
    errorsStore.errors = errors;
    return;
  }

  const formData = mapWebinarToFormData(_webinar, courseId);
  const { status, data } = await adminWebinarsService.updateWebinar(
    _webinar.id,
    formData
  );

  if (status === 401 || status === 403 || status === 400) {
    validateBE(data.error_messages, WebinarErrorKeys);
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  toast.success(`Webinar was updated successfully.`);
  callBack();
};
