import React from 'react'
import TopLogo from "../../../assets/img/png/logo-med-academy.png";
import {AuthBlock} from ".";

export const Header = () => {
  return (
    <header className="header">
      <div className="header__container">
        <div>
          <div className="header__title">
            <section className="top-nav">
              <div className="top-nav-logo">
                <img src={TopLogo} alt=""/>
              </div>
            </section>

            <AuthBlock />
          </div>
        </div>
      </div>
    </header>
  )
}