import { localStore } from "stores";
import { ADMIN_ROUTES } from "constants/routes";

export const adminHomeHardRedirect = () => {
  window.location.href = ADMIN_ROUTES.home;
};

export const logoutAdmin = () => {
  localStore.clearToken();
  adminHomeHardRedirect();
};
