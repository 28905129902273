import {paramsToFormData, showError500, validateBE} from "utils";
import {addInviteStore} from "../../stores";
import {adminInviteService} from "services";
import {toast} from "react-toastify";
import {cloneDeep} from "lodash";
import {errorsStore} from "stores";
import {pauseInviteModalAutoClose} from "../../actions/modals";

export const onInviteUser = async () => {
  errorsStore.clear();

  const storeData = cloneDeep(addInviteStore.invite);

  // TODO 1 add validation
  // validate js
  const isValid = true;

  if (!isValid) {
    return false;
  }

  const formData  = paramsToFormData(storeData, 'invite');

  const {status, data} = await adminInviteService.inviteUser(formData)

  if (status === 401 || status === 403 || status === 400) {
    validateBE(data.error_messages, ['user_email', 'user_first_name', 'user_last_name', 'course_id']);
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  toast.success(`User was invited successfully... auto close`);

  pauseInviteModalAutoClose();
}