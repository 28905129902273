import React from 'react'
import {SelectField} from 'components/form';
import {changeField,} from '../../actions/add';
import {observer} from 'mobx-react-lite';
import {getErrors} from "utils";
import {addUserStore} from "../../stores";
import {USER_TITLES} from "constants/userTitles";
import {IsEdit} from "types";

export const Title = observer((props:IsEdit) => {
  if (!props.isEdit) return <div/>

  const {title} = addUserStore.user;
  const getOptions = () => USER_TITLES.map((item:any, index:number) => ({key: 'user-type-key-' + index, text: item.label, value: item.value}))

  return (
    <SelectField
      name='title'
      label='Title'
      value={title}
      setDefaultValue={true}
      options={getOptions()}
      error={getErrors('title')}
      onChange={changeField}
    />
  )
})