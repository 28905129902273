import React from "react"
import {ADMIN_ROUTES} from "constants/routes";
import {generatePath, Link, useParams} from "react-router-dom";
import {openInviteModal} from "../../Users/actions/modals";

export const AddNewUsers = () => {
  let { id } = useParams();

  const getLinkAddUser = () => generatePath(ADMIN_ROUTES.addUser, {id});

  return (
    <div className='user-buttons-users'>
      <Link to={getLinkAddUser()}>
        <button className="add-new-course">Create new user</button>
      </Link>

      {/* <button className="add-new-course" onClick={openInviteModal}>Invite user</button> */}
    </div>
  )
}