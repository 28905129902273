import React from "react"

export const ListHeader = () => {
  return (
    <div className="superhost__header">
      <div className="header-categories-sponsors webinars">
        <div className="name">
          <p>Course Title</p>
        </div>
        <div className="name">
          <p>Created by</p>
        </div>
        <div className="name">
          <p>Date</p>
        </div>
      </div>
    </div>
  )
}