import React from "react"
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {InputLabel} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import classNames from "classnames";
import {SelectFieldType} from "../../types";
import {DisabledPropType} from 'types';

export const SelectField = (props: SelectFieldType & DisabledPropType) => {
  const {name, label, options, value, onChange, multiple, variant, error, disabled} = props;
  const hasErrors = error && error.length > 0;

  const handleChange = (event: SelectChangeEvent): void => {
    onChange({name: event.target.name, value: event.target.value});
  };
  return (
    <div className="group">
      <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
      <Select
        className=''
        name={name}
        onChange={handleChange}
        multiple={multiple}
        error={hasErrors}
        value={value?.toString()}
        variant={variant || 'standard'}
        labelId={`${name}-select-label`}
        disabled={disabled}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
      <div className='form-error'>{hasErrors ? error?.join('<br/>') : ''}</div>
    </div>
  );
};
