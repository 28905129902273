import React from 'react';
import {observer} from 'mobx-react-lite';
import {InputTextField} from 'components/form';
import {getErrors} from "utils";
import {addWebinarStore} from '../../stores';
import {changeField} from '../../actions/add';
import {DisabledPropType} from 'types';

export const GmsStudyDays = observer((props: DisabledPropType) => {
  const {disabled} = props;
  const {webinar} = addWebinarStore;
  return (
    <InputTextField
      name='gms_study_days'
      label='GMS Study Days'
      value={webinar.gms_study_days}
      onChange={changeField}
      error={getErrors('gms_study_days')}
      type='round'
      disabled={disabled}
    />
  );
});
