import { paramsToFormData, showError500, validateBE } from "utils";
import { addUserStore } from "../../stores";
import { adminUsersService } from "services";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import { errorsStore } from "stores";

export const onSubmitAddUser = async (
  isEdit: boolean,
  callBack: Function,
  courseId: number
) => {
  errorsStore.clear();

  const storeData = cloneDeep(addUserStore.user);
  const id = storeData.id;
  const infoText = isEdit ? "edited" : "added";

  // TODO 1 add validation
  // validate js
  const isValid = true;

  if (!isValid) {
    return false;
  }

  const formData: any = isEdit
    ? { user: storeData }
    : paramsToFormData(storeData, "user");

  const { status, data } = isEdit
    ? await adminUsersService.editUser(formData, id)
    : await adminUsersService.addUser(formData, courseId);

  if (status === 401 || status === 403 || status === 400) {
    validateBE(data.error_messages, [
      "email",
      "first_name",
      "last_name",
      "password",
      "county",
      "accreditation",
      "discipline",
      "location",
      "phone",
      "registration",
    ]);
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  toast.success(`User was ${infoText} successfully... auto close`);

  callBack();
};
