import React from "react"
import {observer} from "mobx-react-lite";

import {usersStore} from "../../Users/stores";

export const UsersCount = observer(() => {
  const count = usersStore.count();
  const countText = count === 0 ? '' : `(${count})`;

  return (
    <div className='active'>
      <button className="all-courses active">Users {countText}</button>
    </div>
  )
})