import React, {useEffect} from "react"
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Layout} from "../Layout";
import {WebLeftComponent} from "components";
import {ROUTES} from '../../../constants';
import {currentUserStore} from 'stores';
import {confirmEmail} from './actions/sets';

export const EmailConfirmation = observer(() => {
  const navigate = useNavigate();
  const {search} = window.location;
  useEffect(() => {
    if (search && currentUserStore.currentUser.id) {
      const token = search.replace('?token=', '');
      confirmEmail(token, () => {
        navigate(ROUTES.settings);
      });
    }
  }, [search, currentUserStore.currentUser]);
  return (
    <Layout>
      <WebLeftComponent />
      <div className="signin__block">
        <div className="signin__container">
          <h1>Email Confirmation Processing...</h1>
          <p>An email was sent to <span>{currentUserStore.currentUser?.email}</span>.
            <br /><br />
            Check your email for a link to confirm your account.
            <br /><br />
            If you don’t receive an email, please check your spam or promotions folder.
            <br /><br />
            If you are having any problems confirming your account, please send us an email at
            <a className="signin__container-link" href="mailto:academysupport@medcafe.ie"> academysupport@medcafe.ie</a>.
          </p>
        </div>
      </div>
    </Layout>
  );
});
