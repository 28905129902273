import { cloneDeep } from "lodash";
import { showError500 } from "utils";
import { adminUsersService } from "services";
import { usersStore } from "../../stores";
import { mapFromUsers } from "modules/admin/SuperHosts/utils";
import { mapByPage } from "utils";

export const getUsers = async (_page?: number) => {
  const { meta } = usersStore;
  const page = _page || meta.page;
  const { status, data } = await adminUsersService.getUsers({ ...meta, page });
  const usersResponse = mapFromUsers(data);
  const users = mapByPage(page, usersStore.users, usersResponse);
  const _meta = cloneDeep(meta);
  _meta.total_pages = data.total_pages || 1;

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  usersStore.meta = _meta;
  usersStore.users = users;
};
