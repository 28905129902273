import React from "react";
import FooterLogo from "../../../assets/img/svg/logo.svg";
import { Link } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../constants";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__content">
          <div className="footer__copyrights">
            <img src={FooterLogo} alt="" />
            <p>
              &copy; MedCafe Academy {new Date().getFullYear()}. All rights
              reserved.
            </p>
          </div>
          <ul className="footer-list">
            <a href="https://www.medcafe.ie/terms_of_service">
              <li>Terms of service</li>
            </a>
            <a href="https://www.medcafe.ie/privacy_policy">
              <li>Privacy Policy</li>
            </a>
          </ul>
        </div>
        <div className="footer__info">
          <div>IE24OB00031</div>
          <div>
            The Apis bull logo is registered trademark owned by Novo Nordisk
            A/S.
          </div>
          <div>February 2024</div>
        </div>
      </div>
    </footer>
  );
};
