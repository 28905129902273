import React from "react"
import {observer} from "mobx-react-lite";
import {useNavigate} from 'react-router-dom';
import {confirmModalStore} from "../stores";
import {closeConfirmModal} from "../actions/modals";
import CloseIcon from "../../../../assets/img/png/ic-close.png";
import {ButtonRound} from 'components';
import {ROUTES} from '../../../../constants';

export const ConfirmModal = observer(() => {
  const navigate = useNavigate();
  const {show} = confirmModalStore;
  const handleClose = () => {
    closeConfirmModal();
    navigate(ROUTES.courseMaterials);
  };

  if (!show) {
    return <div />
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-content-close_wrapper">
          <img src={CloseIcon} alt="close modal button" onClick={handleClose} />
        </div>
        <h3>SENT FOR APPROVAL</h3>
        <p className="modal-content_confirm_text">Your file has been submitted for approval.</p>
        <ButtonRound
          onClick={handleClose}
          className='open-academy'
        >
          OK
        </ButtonRound>
      </div>
    </div>
  )
})