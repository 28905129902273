import React from 'react';
import {observer} from 'mobx-react-lite';
import {WEBINAR_TYPES_OPTIONS} from '../../../../../constants';
import {RadioButtonsGroup} from 'components';
import {addWebinarStore} from '../../stores';
import {changeField} from '../../actions/add';
import {getErrors} from "utils";
import {DisabledPropType} from 'types';

export const WebinarType = observer((props: DisabledPropType) => {
  const {disabled} = props;
  const {webinar} = addWebinarStore;
  const options = disabled ? WEBINAR_TYPES_OPTIONS.filter(option => option.value === webinar.webinar_type) : WEBINAR_TYPES_OPTIONS;

  return (
    <RadioButtonsGroup
      label='Webinar Type:'
      name='webinar_type'
      value={webinar.webinar_type}
      options={options}
      onChange={changeField}
      row={false}
      disabled={disabled}
      showQr={disabled}
      qrUrl={webinar.qrcode_url}
      error={getErrors('webinar_type')}
    />
  );
});
