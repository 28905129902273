import {toast} from 'react-toastify';
import {defaultErrorsInfo} from '../../../../../utils';
import {newPasswordStore} from '../../stores';
import {newPasswordServices} from '../../services';

export const submitNewPassword = async (callback: Function) => {
  try {
    newPasswordStore.inProgress = true;
    const {password, passwordConfirmation} = newPasswordStore;
    if (password === passwordConfirmation) {
      const formData = new FormData();
      formData.append('user[password]', password);
      formData.append('user[password_confirmation]', passwordConfirmation);
      const {data} = await newPasswordServices.newPassword(formData);

      newPasswordStore.clear();
    } else {
      toast.error('Passwords entered do not match, please try again.');
    }
    newPasswordStore.inProgress = false;
  } catch (error: any) {
    newPasswordStore.inProgress = false;
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
};
