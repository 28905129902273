import React from "react"
import {ADMIN_ROUTES} from "constants/routes";
import {generatePath, NavLink, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {isActiveClass} from "utils";
import {webinarsStore} from "../../stores";

export const LinkWebinars = observer(() => {
  const count = webinarsStore.count();
  const countText = count === 0 ? '' : `(${count})`;

  let {id} = useParams();

  const getLink = () => generatePath(ADMIN_ROUTES.webinars, {id});

  return (
    <NavLink to={getLink()} className={isActiveClass}>
      <button className="all-courses active">Webinars {countText}</button>
    </NavLink>
  )
})