import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import TopLogo from "../../../assets/img/png/logo-med-academy.png";
import NovoLogo from "../../../assets/img/png/novoBlueLogo.png";
import { HeaderLinks, AuthBlock } from ".";
import { ROUTES } from "../../../constants";
import { currentUserStore } from "stores";

export const Header = observer(() => {
  const { currentUser } = currentUserStore;
  const navigateTo = currentUser.id ? ROUTES.webinars : ROUTES.main;
  return (
    <header className="header">
      <div className="header__container">
        <div>
          <div className="header__title">
            <section className="top-nav">
              {window.location.pathname.match(ROUTES.register) ? (
                <Link className="top-nav-logo" to={navigateTo}>
                  <img src={TopLogo} alt="" />
                </Link>
              ) : (
                <Link className="top-nav-logo" to={navigateTo}>
                  <img src={NovoLogo} alt="" />
                </Link>
              )}
              <input id="menu-toggle" type="checkbox" />
              <label className="menu-button-container" htmlFor="menu-toggle">
                <div className="menu-button"></div>
              </label>
              <HeaderLinks />
            </section>
            <AuthBlock />
          </div>
        </div>
      </div>
    </header>
  );
});
