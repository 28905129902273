import {defaultErrorsInfo} from '../../../../../utils';
import {createAccountStore} from '../../stores';
import {createAccountServices} from '../../services';
import {commonStore, currentUserStore} from 'stores';
import {joinCourse} from '../../../Dashboard/actions/add';

export const submitUser = async () => {
  try {
    createAccountStore.inProgress = true;

    const {user, courseId} = createAccountStore;
    const userKeys = Object.keys(user);
    const userFormData = new FormData();
    userKeys.forEach((key: string) => {
      userFormData.append(`user[${key}]`, user[key]);
    });
    const {data} = await createAccountServices.createAccount(userFormData);

    commonStore.token = data.token;
    currentUserStore.currentUser = data.user.data.attributes;
    createAccountStore.inProgress = false;

    if (courseId)
      joinCourse(courseId)
      .then(() => {
        createAccountStore.clearCourseId();
      });
  } catch (error: any) {
    createAccountStore.inProgress = false;

    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
}