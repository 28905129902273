import React from 'react';
import {observer} from 'mobx-react-lite';
import {InputTextField} from 'components/form';
import {RadioButtonsGroup} from 'components';
import {InputChangeProps} from 'types';
// import {addWebinarStore} from '../../stores';
import {QuizQuestion} from '../../types';
import {changeQuestion} from '../../actions/add';
import {getErrors} from "utils";
import {DisabledPropType} from 'types';

export const QUESTION_ANSWER = [{
  label: 'True',
  value: true,
}, {
  label: 'False',
  value: false,
}];

type Props = {
  index: number;
  item: QuizQuestion;
};

export const QuestionInput = observer((props: Props & DisabledPropType) => {
  const {item, index, disabled} = props;
  const errors = getErrors('cpd_quizzes_attributes') as any;
  const currentQuestionError = errors.findIndex((error: any) => error.id === item.id);
  const options = disabled ? QUESTION_ANSWER.filter(option => option.value === item.correct) : QUESTION_ANSWER;

  const handleChange = (data: InputChangeProps) => {
    changeQuestion({
      ...item,
      ...data,
    });
  };

  return (
    <div className='group date-picker'>
      <InputTextField
        name='question'
        label={`Question ${index}`}
        value={item.question}
        onChange={handleChange}
        type='round'
        error={errors[currentQuestionError]?.question}
        disabled={disabled}
    />
      <RadioButtonsGroup
        label=''
        name='correct'
        value={item.correct}
        options={options}
        onChange={handleChange}
        error={errors[currentQuestionError]?.correct}
        disabled={disabled}
      />
    </div>
  );
});
