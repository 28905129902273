import { logoutAdmin, showError500 } from "utils";
import { sponsorsService } from "../../services";
import { sponsorsStore } from "../../stores";
import { mapFromSponsor } from "../../utils";

export const getSponsors = async () => {
  const { status, data } = await sponsorsService.getSponsors();

  if (status === 401) {
    logoutAdmin();
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  sponsorsStore.sponsors = mapFromSponsor(data);
};
