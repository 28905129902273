import React from "react"
import {observer} from "mobx-react-lite";
import {infoModalStore} from "../stores";
import {closeInfoModal} from "../actions/modals";
import CloseIcon from "assets/img/png/ic-close.png";

export const InfoModal = observer(() => {
  const {show} = infoModalStore;
  const {id, first_name, last_name, email, phone, created_at, last_login} = infoModalStore.user;

  const phoneNumber = phone ? phone : 'No number';
  const lastLogin = last_login ? last_login : 'Never logged in';

  if (!show) {
    return <div />
  }

  return (
    <div className="modal" style={{display: 'block'}}>
      <div className="modal-container-academy">
        <span className="close">×</span>
        <div className="modal-content-create__academy">
          <div className="modal-content-academy__headline">
            <img src={CloseIcon} alt="close modal button" onClick={closeInfoModal} />
          </div>
          <div className="academy-list">
            <h3>{first_name + ' ' + last_name}</h3>
            <div className="academy-specs">
              <p>User ID: <b>{id}</b></p>
            </div>
            <div className="academy-specs">
              <p>User Email: <b>{email}</b></p>
            </div>
            <div className="academy-specs">
              <p>Phone Number: <b>{phoneNumber}</b></p>
            </div>
            <div className="academy-specs">
              <p>Date Registered: <b>{created_at}</b></p>
            </div>
            <div className="academy-specs">
              <p>Last login in: <b>{lastLogin}</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})