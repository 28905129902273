import { adminAuthStore, errorsStore, localStore } from "stores";
import { authService } from "services";
import { cloneDeep } from "lodash";
import {
  adminCoursesHardRedirect,
  adminHomeHardRedirect,
  showError500,
  validateBE,
} from "utils";
import { toast } from "react-toastify";

export const onLogin = async () => {
  errorsStore.clear();
  const { email, password } = adminAuthStore.user;

  // TODO 1 add FE validation
  // validate js
  const isValid = true;

  if (!isValid) {
    return false;
  }

  const params = { email, password };
  const { status, data } = await authService.adminLogin(params);

  if (status === 401 || status === 403 || status === 400) {
    toast.error(data.error_message);
    // validateBE(data.error_messages, ["email", "password"]);
    return;
  }

  if (status === 500) {
    showError500(status, data);
    return;
  }

  const clone = cloneDeep(data);
  const user = clone.user.data.attributes;

  adminAuthStore.user = { ...user };
  adminAuthStore.auth = true;
  localStore.token = clone.token;

  const isAdmin = adminAuthStore.user.role === "admin";

  if (isAdmin) {
    adminCoursesHardRedirect();
  } else {
    adminHomeHardRedirect();
  }
};
