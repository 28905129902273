import React from "react";
import { observer } from "mobx-react-lite";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { addWebinarStore } from "../../stores";
import { changeField } from "../../actions/add";
import { getErrors } from "utils";
import { DisabledPropType } from "types";

type DateInputProps = {
  label: string;
  name: string;
};

export const DateInput = observer(
  (props: DateInputProps & DisabledPropType) => {
    const { label, name, disabled } = props;
    const { webinar } = addWebinarStore;
    const errors = getErrors(name);
    const hasError = errors.length > 0;
    const onChange = (value: string | null) => {
      changeField({ name, value: value || "" });
    };

    return (
      <div className="group date-picker">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label={label}
            value={webinar[name]}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} error={hasError} />}
            disabled={disabled}
          />
        </LocalizationProvider>
        <div className="form-error">
          {hasError ? errors?.join("<br />") : ""}
        </div>
      </div>
    );
  }
);
