import React from "react"
import {AdminUserType} from "types";
import EditIcon from "assets/img/png/edit_icon.png";
import DeleteIcon from "assets/img/png/trashcan.png";
import {openDeleteModal} from "../../actions/modals";
import {openCreateSuperHostModal} from "../../actions/edit";

type Props = {
  user: AdminUserType;
}

export const ListItem = (props:Props) => {
  const {id, email, first_name, last_name, course} = props.user;

  return (
    <div className="content-line">
      <div className="content-desc">
        <div className="course-user">
          <p>{first_name + ' ' + last_name}</p>
        </div>
        <div className="course-mail">
          <p>{email}</p>
        </div>
        <div className="course-name">
          <p>{course.title}</p>
        </div>
      </div>
      <div className="content-options">
          <div className="edit">
            <img src={EditIcon} alt="" onClick={() => openCreateSuperHostModal(props.user) } />
          </div>

        <div className="delete">
          <img src={DeleteIcon} alt="" onClick={() => openDeleteModal(id) } />
        </div>
      </div>
    </div>
  )
}