import React from "react"
import {mount, unmount} from "../../actions/gets";
import {usersStore} from "../../stores";
import {observer} from "mobx-react-lite";
import {ListHeader, ListItem, ListSearch} from ".";
import {AdminUserType} from "types";

export const List = observer(() => {
  React.useEffect(
    () => {
      mount();

      return unmount();
    },
    []
  );

  const {users} = usersStore;

  return (
    <div className="superhost__container">
      <ListSearch />
      <ListHeader />
      <div className="superhost__content">
        {
          users.map((item: AdminUserType, index:number) =>
            <ListItem key={'admin-super-host-key-' + index} user={item} />
          )
        }
      </div>
    </div>
  )
})