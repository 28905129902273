import {NotificationType} from "types";
import {cloneDeep} from "lodash";

export const mapFromNotifications = (data: any): NotificationType[] => {
  const clone = cloneDeep(data);
  const _notifications = clone.notifications.data;
  const hasData = _notifications && _notifications.length > 0;

  return hasData ? _notifications.map((item: any) => ({...item.attributes})) : [];
}
