import React from 'react'
import {ImageType} from "types";
import {toast} from "react-toastify";

export const AvatarField = (props: ImageType) => {
  const {label, name, onChange, maxFileSize, accept} = props;

  const onFileChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;

    if (files && files[0]) {
      const file = files[0];

      if (file.size / 1024 > maxFileSize) {
        toast.error('File is too big. Max file size is ' + maxFileSize + ' Kb');
        return;
      }

      onChange(file);
    }
  };

  return (
    <div className="profile__avatar-wrapper">
      <label className="profile__avatar-label" htmlFor="avatarUpload">
        {label}
        <input
          className="profile__avatar-input"
          id="avatarUpload"
          name={name}
          type='file'
          onChange={onFileChange}
          accept={accept}
          multiple={false}
        />
      </label>
    </div>
  )
}