import React from 'react'
import {InputTextField} from 'components/form';
import {changeField} from '../../actions/add';
import {observer} from 'mobx-react-lite';
import {getErrors} from "utils";
import {addUserStore} from "../../stores";

export const Phone = observer(() => {
  const {phone} = addUserStore.user;

  return (
    <InputTextField
      type='round'
      name='phone'
      label='Phone number'
      placeholder=''
      value={phone}
      error={getErrors('phone')}
      onChange={changeField}
    />
  )
})