import React from "react"
import moment from "moment";
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {WebinarType} from "types";
import {DATE_FORMATS} from '../../../../../constants';
import EditIcon from "assets/img/png/edit_icon.png";
import DeleteIcon from "assets/img/png/trashcan.png";
import {openDeleteModal} from "../../actions/modals";
import {addCheckedId, removeCheckedId} from "../../actions";
import {ADMIN_ROUTES} from "constants/routes";

type Props = {
  webinar: WebinarType;
}

export const ListItem = (props: Props) => {
  let navigate = useNavigate();
  let params = useParams();

  const {
    id,
    webinar_title,
    webinar_type,
    speakers,
    start_date,
    status,
    speakers_names,
  } = props.webinar;

  const onClickCheckBox = (event: any) => {
    const checked = Boolean(event.target.checked);
    checked ? addCheckedId(id) : removeCheckedId(id);
  }

  const redirectEditWebinarPage = () => navigate(generatePath(ADMIN_ROUTES.editWebinar, {id: params.id, webinarId: id}));
  const redirecWebinarPage = () => navigate(generatePath(ADMIN_ROUTES.webinar, {id: params.id, webinarId: id}));

  return (
    <div className="content-line-users">
      <div className="content-desc">
        <label className="container">
          <input type="checkbox" className="content-desc__checkbox" onClick={onClickCheckBox}/>
          <span className="checkmark"></span>
        </label>
        <div className="user-name webinars-static-width cursor-pointer" onClick={redirecWebinarPage}>
          <p>{webinar_title}</p>
        </div>
        <div className="user-name webinars-type">
          <p>{webinar_type}</p>
        </div>
        <div className="user-name webinars-static-width">
          <p>{speakers_names || ''}</p>
        </div>
        <div className="user-name webinars">
          <p>{`${moment(start_date).format(DATE_FORMATS.webinarDate)} | ${moment(start_date).format(DATE_FORMATS.webinarTime)}`}</p>
        </div>
        <div className="user-name webinars">
          <p>{status}</p>
        </div>
      </div>

      <div className="content-options">
        <div className="edit">
          <img src={EditIcon} alt="" onClick={redirectEditWebinarPage} />
        </div>

        <div className="delete">
          <img src={DeleteIcon} alt="" onClick={() => openDeleteModal(id)} />
        </div>
      </div>
    </div>
  )
}