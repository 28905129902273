import agent from "../../../../agent";
import {WebinarsMetaType} from '../types';

const getWebinars = async (meta: WebinarsMetaType) => await agent.Webinars.getWebinars(meta);

const subscribeWebinar = async (webinarId: number) => await agent.Webinars.subscribeWebinar(webinarId);

const registerWebinar = async (formData: FormData) => await agent.Webinars.registerWebinar(formData);

export const webinarsService = {
  getWebinars,
  subscribeWebinar,
  registerWebinar,
}
