export const ROUTES = {
  // Landing Routes
  main: '/',
  home: '/home',

  // Auth Routes
  login: '/login',
  register: '/register',
  forgotPassword: '/forgot-password',
  confirmEmail: '/confirm_email',
  updatePassword: '/update_passwords',

  terms: '/terms',
  privacyPolicy: '/privacy-policy',
  novoNordiskTerms: '/novo-nordisk-terms',

  // App Routes
  dashboard: '/dashboard',
  settings: '/settings',

  // Webinars
  webinars: '/webinars/',
  webinar: '/webinars/:webinarId',
  webinarReview: '/webinars/:webinarId/review',
  goToWebinarReview: (id: String) => `/webinars/${id}/review`,

  // Members
  members: '/members',

  // Course Materials
  courseMaterials: '/course-materials',
  createCourseMaterial: '/course-materials/create',

  // Cpd Locker
  cpdLocker: '/cpd-locker',
};

export const navigateToWebinar = (webinarId: number) => `${ROUTES.webinar.replace(':webinarId', webinarId.toString())}`;

const ADMIN_BASE = '/admin';
const ADMIN_COURSE = `${ADMIN_BASE}/course/:id`;
const ADMIN_REQUESTS = `${ADMIN_COURSE}/requests`;

export const ADMIN_ROUTES = {
  home: `${ADMIN_BASE}`,
  login: `${ADMIN_BASE}/login`,
  terms: `${ADMIN_BASE}/terms`,
  privacy: `${ADMIN_BASE}/privacy`,

  courses: `${ADMIN_BASE}/courses`,
  addCourse: `${ADMIN_BASE}/courses/add`,
  editCourse: `${ADMIN_BASE}/courses/edit`,

  superHosts: `${ADMIN_BASE}/super-hosts`,
  sponsors: `${ADMIN_BASE}/sponsors`,
  partners: `${ADMIN_BASE}/partners`,

  users: `${ADMIN_COURSE}/users`,
  addUser: `${ADMIN_COURSE}/users/add`,
  editUser: `${ADMIN_COURSE}/users/edit/:userId`,

  requestsPending: `${ADMIN_REQUESTS}/pending`,
  requestsApproved: `${ADMIN_REQUESTS}/approved`,
  requestsRejected: `${ADMIN_REQUESTS}/rejected`,

  webinars: `${ADMIN_COURSE}/webinars`,
  addWebinar: `${ADMIN_COURSE}/webinars/add`,
  addUsersWebinar: `${ADMIN_COURSE}/webinars/users/add`,
  editWebinar: `${ADMIN_COURSE}/webinars/edit/:webinarId`,
  webinar: `${ADMIN_COURSE}/webinars/:webinarId`,

  courseMaterials: `${ADMIN_COURSE}/course-materials`,
  courseMaterialsRequests: `${ADMIN_COURSE}/course-materials/requests`,
  courseMaterial: `${ADMIN_COURSE}/course-materials/:courseMaterialId`,

  statistics: `${ADMIN_COURSE}/statictics`
}
