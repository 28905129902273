import { observer } from "mobx-react-lite"
import { ChangeEvent, useEffect } from "react"
import { addCourseMaterialModalStore } from "../stores"
import CloseIcon from 'assets/img/png/ic-close.png'
import { useParams } from "react-router-dom"
import { editCourseMaterial } from "../actions/editCourseMaterial"
import fileIcon from 'assets/img/svg/fileadd.svg'
import { debounce } from "lodash"

type EditCourseMaterialModalPropsType = {
    scope?: string
}

export const EditCourseMaterialModal = observer((props: EditCourseMaterialModalPropsType) => {
    const { id } = useParams()
    const courseMaterial = addCourseMaterialModalStore.courseMaterial
    const open = addCourseMaterialModalStore.openEdit

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, name, files } = event.target
        if(files && files.length) addCourseMaterialModalStore.courseMaterial = {...addCourseMaterialModalStore.courseMaterial, file: files[0]}
        else addCourseMaterialModalStore.courseMaterial = {...addCourseMaterialModalStore.courseMaterial, [name]: value}
    }

    const toggleNewCourseMaterialModal = () => {
        addCourseMaterialModalStore.openEdit = null
    }

    const handleSubmit = () => {
        try {
            if(open) editCourseMaterial(open, function() {}, props?.scope)
        } catch(err) {
            console.log(err)
        }
    }

    const handleClick = (event: any) => {
        event.stopPropagation()
    }

    const handleViewFile = () => {
        if(courseMaterial.file_url) window.open(process.env.REACT_APP_API_URL + courseMaterial.file_url, '_blank')
    }

    const handleDeleteFile = (e: any) => {
        e.stopPropagation()
        debounce(() => {
            addCourseMaterialModalStore.courseMaterial = { ...addCourseMaterialModalStore.courseMaterial, file_url: ''}
            addCourseMaterialModalStore.courseMaterial = { ...addCourseMaterialModalStore.courseMaterial, file: null }
            const el = document.getElementById('inputFile') as HTMLInputElement
            if(el) el.value = ""
        }, 1)()
    }
    
    useEffect(() => {
        if(id) addCourseMaterialModalStore.courseMaterial = {...addCourseMaterialModalStore.courseMaterial, course_id: Number(id)}
    }, [id])

    return (
        <div onClick={toggleNewCourseMaterialModal} id="myModalEdit" className={`modal ${open ? 'open' : ''}`}>
            <div className="modal-container-superhost">
                <span className="close">&times;</span>
                <div onClick={handleClick} className="modal-content-create__sponsors">
                    <div className="modal-content-create__headline">
                        <img onClick={toggleNewCourseMaterialModal} src={CloseIcon} alt="close modal button" />
                    </div>
                    <h3>Edit COURSE MATERIAL</h3>
                    <div className="superhost-create__desc">
                        <div className="superhost-inputs">
                            <div className="superhost-input">
                                <p>Title of Course Material</p>
                                <input onChange={handleChange} name='title' value={courseMaterial.title} type="text" className="superhost" />
                            </div>
                            <div className="superhost-input">
                                <p>Edit file to your publication</p>
                                <label onClick={courseMaterial.file ? handleDeleteFile : handleViewFile} htmlFor={courseMaterial.file_url || courseMaterial.file ? "" : "inputfile"} className="inputfile_custom">
                                    <img src={fileIcon} alt="file" />
                                    <p>{courseMaterial.file ? courseMaterial.file.name : courseMaterial.file_url ? courseMaterial.file_url.split('/').pop() : 'ADD FILE'}</p>
                                    {courseMaterial.file_url || courseMaterial.file ? <div onClick={handleDeleteFile} className='deletefile'>&times;</div> : <></>}
                                </label>
                                <input style={{ display: 'none' }} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.xls,.doc,.docx,.pptx,.ppt" id="inputfile" onClick={(e: any) => {e.target.value = null}} onChange={handleChange} type="file" className="superhost" />
                            </div>
                        </div>
                        <div className="superhost-btns-create">
                            <button className="cancel-btn" onClick={toggleNewCourseMaterialModal}>Cancel</button>
                            <button className="create-btn" onClick={handleSubmit}>update Course Material</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})