import {cloneDeep} from 'lodash';
import {defaultErrorsInfo, mapByPage} from "../../../../../utils";
import {webinarsService} from "../../services";
import {webinarsStore} from "../../stores";
import {WebinarsMetaType} from '../../types';
import {mapFromWebinars} from '../../utils';
import {SCOPE_VALUES} from '../../constants';

export const getWebinars = async () => {
  try {
    const {data} = await webinarsService.getWebinars(webinarsStore.meta as WebinarsMetaType);
    const webinarsResponse = mapFromWebinars(data);
    const webinars = mapByPage(webinarsStore.meta.page, webinarsStore.webinars, webinarsResponse);

    const _meta = cloneDeep(webinarsStore.meta);
    _meta.total_pages = data.total_pages || 1;

    webinarsStore.meta = _meta;
    webinarsStore.webinars = webinars;
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
};

export const initialWebinars = async () => {
  try {
    const {data} = await webinarsService.getWebinars(webinarsStore.meta as WebinarsMetaType);
    const webinarsResponse = mapFromWebinars(data);
    if (webinarsResponse.length > 0) {
      const webinars = mapByPage(webinarsStore.meta.page, webinarsStore.webinars, webinarsResponse);

      const _meta = cloneDeep(webinarsStore.meta);
      _meta.total_pages = data.total_pages || 1;

      webinarsStore.meta = _meta;
      webinarsStore.webinars = webinars;
    } else {
      const _meta = cloneDeep(webinarsStore.meta);
      _meta.scope = SCOPE_VALUES.upcoming;
      webinarsStore.meta = _meta;

      getWebinars();
    }
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
};
