import React from "react";
import {getImageUrl, mapFromSponsors, mapFromSpeakers} from 'utils';

type Props = {
  speakersData: any;
};

export const Speakers = (props: Props) => {
  const {speakersData} = props;
  const speakers = mapFromSpeakers(speakersData);

  return (
    <div className="webinar__speakers-container">
      <span className="webinar__speakers-title">Webinar speakers</span>
      <div className="webinar__speakers-wrapper">
        {speakers.map(speaker => (
          <div key={speaker.id} className="webinar__speaker-container">
            <img className="webinar__speaker-avatar" src={getImageUrl(speaker.avatar_url)} alt={speaker.name} />
            <p>{speaker.name}</p>
            <p>{speaker.presentation_title}</p>
            <span>{`${speaker.speaker_type}・${speaker.location}`}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
