import {AdminUserType} from "types";
import {cloneDeep} from "lodash";
import {mapFromOneUser} from ".";

export const mapFromUsers = (data: any): AdminUserType[] => {
  const clone = cloneDeep(data);

  const users = clone.users.data;
  const hasData = users && users.length > 0;
  users.sort((a:any, b:any) => a.id - b.id)


  return hasData ? users.map((item: any) => mapFromOneUser(item.attributes)) : []
}

