import React from "react";
import {Page} from "../Page";

export const Privacy = () => {
  return (
    <Page>
      <div className="terms">
        <div className="terms-list">
          <h1>Introduction</h1>
          <p>
            MedCafe Technology Solutions Limited, trading as MedCafe ("MedCafe", "we" or "us") fully respects and
            protects your right to privacy in relation to your interactions with this website. We have adopted the
            following statement to safeguard your personal information and to protect its confidentiality.
          </p>
          <p>
            You should read this Privacy Statement carefully before using this website. If you do not read it or if you
            disagree with any aspect of this Privacy Statement, you should not use this website. Your use of this
            website signifies your agreement to be bound by the terms of this Privacy Statement.
          </p>
          <p>
            Please note that this Privacy Statement is subject to MedCafe’s Terms of Service. We recommend that you read
            the Terms of Service. If there is a conflict between the Terms of Service and this Privacy Statement, the
            Terms of Service shall prevail. Except as otherwise provided, terms used herein and defined in the Terms of
            Service shall have the same meaning that is given to them in the Terms of Service.
          </p>
        </div>
        <div className="terms-list">
          <h1>The Collection of Information</h1>
          <p>
            Our primary goal in collecting personal information is to provide you with the best and most useful content
            on our website, and to help improve your online experience.
          </p>
          <p>
            If you are seeking to use the services offered by MedCafe, then you will be asked to register. We use any
            personal information you provide to identify you as a qualified medical practitioner, to process your
            registration and to deliver relevant information about you to registered medical practitioners and other
            healthcare provided regulated by law. We will not give or sell your personal information to any third
            parties for marketing purposes without your consent.
          </p>
          <p>
            Registration will oblige you to provide certain personal information to us. Such information will include,
            without limitation, personal details such as your name, practice address, email address and your Irish
            Medical Council registration number or Nursing and Midwifery Board PIN.
          </p>
          <p>
            We may also use your email address to contact you from time-to-time to update you on matters concerning the
            service we provide. You consent to us contacting you in this regard. You may also from time to time receive
            information from our partners and/or carefully selected third parties. You can unsubscribe from this
            informational service at any stage and still remain a member of MedCafe.
          </p>
          <p>
            You acknowledge and agree that we will be obliged to disclose personal information (i.e. name and county)
            that you have provided to us to professional accrediting bodies (for example the Irish College of General
            Practitioners or similar bodies for other professions) in order to conform to CPD accreditation
            requirements. Some webinars will be sponsored by 3rd parties. These 3rd parties may comprise pharmaceutical
            companies and non-pharmaceutical companies. If the sponsor is a pharmaceutical company, the sponsor will
            receive a report on how many participated in the webinar they sponsored, as well as a breakdown of the
            county the participants are based in. If the sponsor is a non-pharmaceutical company, the sponsor will
            receive a report on how many participated in the webinar they sponsored, the participant names and a
            breakdown of the county the participants are based in. No other personal information will be disclosed to
            webinar sponsors.
          </p>
          <p>
            You also acknowledge and agree that in certain circumstances we may be obliged to disclose personal
            information that you have provided to us to third parties, for example, in order to conform to any
            requirements of law or to comply with any legal process, as well as to protect and defend our rights.
          </p>
          <p>
            Certain information you have provided to us may be made available to other registered users of MedCafe, via
            a profile which discloses certain information about you.
          </p>
        </div>
        <div className="terms-list">
          <h1>The Use of Information by Us</h1>
          <div className="terms-list-container">
            <p>
              Certain kinds of information is collected about users of MedCafe in order to:
            </p>
            <span>
                        1. Help us to monitor and improve the services we offer
                      </span>
            <span>
                        2. Sell advertising space on MedCafe
                      </span>
            <span>
                        3. To protect MedCafe and our users
                      </span>
            <p>
              Visitors should be aware that each time they visit a website or app two general levels of information
              about their visit can be retained. The first level comprises statistical and other analytical information
              collected on an aggregate and non-individual specific basis of all users who visit the site, and the
              second is information that is personal or particular to a specific visitor who knowingly chooses to
              provide that information. It is policy of this app to respect and protect the privacy of our users and
              never wilfully disclose individually identifiable information about our users to any third party without
              first receiving that user’s permission.
            </p>
            <p>
              We may, however, occasionally email you with information or queries about your registration or accounts,
              for instance to request permissions or advise you of changes.
            </p>
          </div>
        </div>
        <div className="terms-list">
          <h1>Stored Personal Information</h1>
          <p>
            As a registered user of MedCafe, whenever you use our services we aim to provide you with access to your
            personal information. If that information is wrong, we strive to give you ways to update it quickly or to
            delete it – unless we have to keep that information for legitimate business or legal purposes. When updating
            your personal information, we may ask you to verify your identity before we can act on your request.
          </p>
          <p>
            If at any time after giving us this information you decide that you no longer wish us to hold or use this
            information, or you wish to change this information, you are free to notify us, and we will remove or
            rectify the information promptly. We do not collect or keep your personal data unless it is necessary for
            the purposes(s) set out above nor do we keep your personal data for longer than is necessary. You have the
            right to request a copy of the information which we hold about you and to request its correction or
            deletion. To obtain a copy of this information, please send an email to support@medcafe.ie.
          </p>
        </div>
        <div className="terms-list">
          <h1>Security</h1>
          <p>
            MedCafe accounts are secured by user-created passwords. Due to the nature of the internet, we cannot
            guarantee the protection of your personal data and therefore we are not responsible for any loss or damage
            resulting to you when our app is used. However, we will take all reasonable steps (including appropriate
            industry and technical protocols and standards) to protect your personal data, and any financial information
            you may from time to time opt to provide to us for the purposes of transacting a service on the MedCafe
            website.
          </p>
          <p>
            In common with other websites and apps that you may visit, you should be aware that each time you visit the
            MedCafe website, two general levels of information about your visit can be retained. The first level
            comprises information which is personal or particular to a specific visitor who knowingly chooses to provide
            that information. Such information is collected through the use of Cookies.
          </p>
          <p>
            The second level of information about which data relating you your visit can be retained comprises
            statistical and/or analytical information collected on an aggregate and non-individual-specific basis. This
            type of non- personal information and data can be collected through the standard operation of internet
            servers and logs as well as Cookies and other technical mechanisms. The data obtained provides us with
            statistical information about all the users who visit the MedCafe website and the pages that they visit.
            This information helps us monitor traffic on the MedCafe website so that we can manage the app’s capacity as
            efficiently as possible.
          </p>
        </div>
        <div className="terms-list">
          <h1>External Links</h1>
          <p>
            MedCafe may contain links to other websites and resources. If you access those links, you will leave our
            website. MedCafe Technology Solutions Limited is not responsible for the content of external internet
            websites. We encourage you to review the privacy statement of any company before submitting your personal
            information. We cannot accept responsibility for external websites or resources, or for any loss or damage
            that may arise from your use of them.
          </p>
        </div>
        <div className="terms-list">
          <h1>Sale of Business</h1>
          <p>
            We reserve the right to transfer personal information (including any personal information you have provided
            to us) to a third party in the event of a sale, merger, liquidation, receivership or transfer of all or
            substantially all of the assets of MedCafe Technology Solutions Limited, a subsidiary, or line of business
            associated with our business. You will be notified in the event of any such transfer and you will be
            afforded an opportunity to opt-out if that is your wish.
          </p>
        </div>
        <div className="terms-list">
          <h1>Amending our Privacy Statement</h1>
          <p>
            MedCafe Technology Solutions Limited reserves the right, at its sole discretion, to amend this Privacy
            Statement at any time. If there are updates to the content of this Privacy Statement, we will post those
            changes and shall update the revision date at the top of this document, so you will always know what
            information we collect online, how we use it, and what choices you have. Whilst we will endeavour to bring
            any material changes to this Privacy Statement to your attention, we encourage you to check our website from
            time to time at www.medcafe.ie for any amendments which may be made.
          </p>
        </div>
        <div className="terms-list">
          <h1>Data Protection</h1>
          <p>
            For the purpose of the Data Protection Act 1998 (the Act), the data controller is MedCafe Technology
            Solutions Limited of Unit 101, 4, Burton Hall Park, Burton Hall Road, Sandyford, Dublin 18.
          </p>
        </div>
        <div className="terms-list">
          <h1>Contacting Us</h1>
          <div className="terms-list-container">
            <p></p>
            <span>
                        If you have any questions about this Privacy Policy or you wish to make a complaint, please feel free to contact us through our support@medcafe.ie email address or write to us at:
                      </span>
            <span>
                        MedCafe Technology Solutions Limited
                      </span>
            <span>
                        Unit 101, 4, Burton Hall Park, Burton Hall Road, Sandyford, Dublin 18.
                      </span>
          </div>
        </div>
        <div className="terms-list">
          <h1>Governing Law and Jurisdiction</h1>
          <p>
            This Privacy Statement is governed by the laws of Ireland. It is a condition precedent to the use of MedCafe
            that any such user submits to the sole and exclusive jurisdiction of the Courts of Ireland and to the
            application of the law in that jurisdiction, including any party accessing such information or facilities
            acting on their own behalf or on behalf of others.
          </p>
        </div>
        <div className="terms-list">
          <h1>Disclaimer</h1>
          <p>
            MEDCAFE TECHNOLOGY SOLUTIONS LIMITED ITS DIRECTORS, EMPLOYEES, SERVANTS AND AGENTS, AFFILIATES OR OTHER
            REPRESENTATIVES AND THEIR RESPECTIVE PARENT AND SUBSIDIARY COMPANIES, SHALL NOT BE LIABLE IN RESPECT OF ANY
            CLAIMS, EMERGENCIES, DEMANDS, CAUSES OF ACTION, DAMAGES, LOSSES, EXPENSES, INCLUDING WITHOUT LIMITATION,
            REASONABLE LEGAL FEES AND COSTS OF PROCEEDINGS ARISING OUT OF OR IN CONNECTION WITH THE USE AND/OR
            DISSEMINATION OF PERSONAL INFORMATION RELATING TO YOU IN ACCORDANCE WITH THIS PRIVACY STATEMENT.
          </p>
        </div>
      </div>
    </Page>
  );
};