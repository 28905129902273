import {makeAutoObservable} from 'mobx'
import {WebinarType} from "types";
import {WEBINARS_META} from '../constants'
import {WebinarsMetaType} from '../types';

export const webinarsStore = makeAutoObservable({
  _webinars: [] as WebinarType[],
  _meta: WEBINARS_META as WebinarsMetaType,
  _inProgress: false,

  set webinars(value: WebinarType[]) {
    this._webinars = value;
  },

  get webinars(): WebinarType[] {
    return this._webinars;
  },

  set meta(meta: WebinarsMetaType) {
    this._meta = meta;
  },

  get meta(): WebinarsMetaType {
    return this._meta;
  },

  set inProgress(value: boolean) {
    this._inProgress = value;
  },

  get inProgress(): boolean {
    return this._inProgress;
  },

  clear(): void {
    this._webinars = [] as WebinarType[];
    this._meta = WEBINARS_META as WebinarsMetaType;
  }
});

