import {showError500} from "utils";
import {adminUsersService} from "services";
import {usersStore} from "../../stores";
import {mapFromUsers} from "../../utils";

export const getSuperHostsByQuery = async (query:string) => {

  const params = {
    query,
    role: 'superhost'
  };

  const {status, data} = await adminUsersService.getUsersByQuery(params);

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  usersStore.users = mapFromUsers(data);
}