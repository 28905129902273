import { generatePath } from "react-router-dom"
import {
    API_ADMIN_COURSE_MATERIALS,
    API_ADMIN_ADD_COURSE_MATERIAL,
    API_ADMIN_COURSE_MATERIAL_DELETE,
    API_ADMIN_COURSE_MATERIAL_ID
} from "constants/api"
import { CourseMaterialsMetaType } from 'modules/admin/CourseMaterials/types'
import { getRequestAuth, postRequestFile, putRequestFile, postRequestAuth } from "utils/requests"

const getCourseMaterials = async (meta: CourseMaterialsMetaType, scope: string | undefined) => await getRequestAuth(API_ADMIN_COURSE_MATERIALS, {...meta, scope});

const getCourseMaterialDetails = async (id: number) => await getRequestAuth(courseMaterialUrl(id));

const addCourseMaterial = async (formData: FormData) => await postRequestFile(API_ADMIN_ADD_COURSE_MATERIAL, formData);

const updateCourseMaterial = async (webinarId: number, formData: FormData) => await putRequestFile(courseMaterialUrl(webinarId), formData);

const deleteCourseMaterial = async (params: object) => await postRequestAuth(API_ADMIN_COURSE_MATERIAL_DELETE, params);


export const adminCourseMaterialsService = {
  getCourseMaterials,
  addCourseMaterial,
  getCourseMaterialDetails,
  updateCourseMaterial,
  deleteCourseMaterial,
}

const courseMaterialUrl = (id: number) => generatePath(API_ADMIN_COURSE_MATERIAL_ID, {id});
