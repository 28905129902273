import {defaultErrorsInfo} from "../../../../../utils";
import {courseService} from "../../services";
import {coursesStore} from "../../stores";
import {mapFromCourses} from '../../utils';

export const getCourses = async () => {
  try {
    const {data} = await courseService.getCourses();

    coursesStore.courses = mapFromCourses(data);
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
}

export const getMyCourses = async () => {
  try {
    const {data} = await courseService.getMyCourses();

    coursesStore.courses = mapFromCourses(data);
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
};
