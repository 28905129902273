import React from "react"
import {AdminSponsorType} from "types";
import EditIcon from "assets/img/png/edit_icon.png";
import DeleteIcon from "assets/img/png/trashcan.png";
import {openDeleteModal} from "../../actions/modals";
import {openEditPartnerModal} from "../../actions/edit";
import {addCheckedId, removeCheckedId} from "../../actions";

type Props = {
  sponsor: AdminSponsorType;
}

export const ListItem = (props: Props) => {
  const {id, title, namespace} = props.sponsor;

  const onClickCheckBox = (event: any) => {
    const checked = Boolean(event.target.checked);
    checked ? addCheckedId(id) : removeCheckedId(id);
  }

  return (
    <div className="content-line-sponsors">
      <div className="content-desc">
        <label className="container">
          <input type="checkbox" className="content-desc__checkbox" onClick={onClickCheckBox}/>
          <span className="checkmark"></span>
        </label>
        <div className="user-name webinars">
          <p>{title}</p>
        </div>
        <div className="user-name webinars m-l-5">
          <p>{namespace}</p>
        </div>
      </div>

      <div className="content-options">
        <div className="edit">
          <img src={EditIcon} alt="" onClick={() => openEditPartnerModal(props.sponsor)}/>
        </div>

        <div className="delete">
          <img src={DeleteIcon} alt="" onClick={() => openDeleteModal(id)}/>
        </div>
      </div>
    </div>
  )
}