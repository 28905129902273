import {
  API_ADMIN_ADD_COURSE_MATERIAL
} from "constants/api";
import {postRequestFile, putRequestFile, deleteRequest} from "utils/requests";

const addCourseMaterial = async (formData: FormData) => await postRequestFile(API_ADMIN_ADD_COURSE_MATERIAL, formData);

const updateCourseMaterial = async (id: Number, formData: FormData) => await putRequestFile(`${API_ADMIN_ADD_COURSE_MATERIAL}/${id}`, formData);

const deleteCourseMaterial = async (id: Number) => await deleteRequest(`${API_ADMIN_ADD_COURSE_MATERIAL}/${id}`);

const approveCourseMaterial = async (id: Number, nullFormData: FormData) => await putRequestFile(`${API_ADMIN_ADD_COURSE_MATERIAL}/${id}?course_material[status]=approved`, nullFormData)

const rejectCourseMaterial = async (id: Number, value: string, nullFormData: FormData) => await putRequestFile(`${API_ADMIN_ADD_COURSE_MATERIAL}/${id}?course_material[status]=${value}`, nullFormData)

export const newCourseMaterialServices = {
  addCourseMaterial,
  updateCourseMaterial,
  deleteCourseMaterial,
  approveCourseMaterial,
  rejectCourseMaterial
}