export const COUNTRIES = [{
  label: 'Afghanistan',
  value: 1,
}, {
  label: 'Albania',
  value: 2,
}, {
  label: 'Algeria',
  value: 3,
}, {
  label: 'Andorra',
  value: 4,
}, {
  label: 'Angola',
  value: 5,
}, {
  label: 'Antigua and Barbuda',
  value: 6,
}, {
  label: 'Argentina',
  value: 7,
}, {
  label: 'Armenia',
  value: 8,
}, {
  label: 'Australia',
  value: 9,
}, {
  label: 'Austria',
  value: 10,
}, {
  label: 'Azerbaijan',
  value: 11,
}, {
  label: 'The Bahamas',
  value: 12,
}, {
  label: 'Bahrain',
  value: 13,
}, {
  label: 'Bangladesh',
  value: 14,
}, {
  label: 'Barbados',
  value: 15,
}, {
  label: 'Belarus',
  value: 16,
}, {
  label: 'Belgium',
  value: 17,
}, {
  label: 'Belize',
  value: 18,
}, {
  label: 'Benin',
  value: 19,
}, {
  label: 'Bhutan',
  value: 20,
}, {
  label: 'Bolivia',
  value: 21,
}, {
  label: 'Bosnia and Herzegovina',
  value: 22,
}, {
  label: 'Botswana',
  value: 23,
}, {
  label: 'Brazil',
  value: 24,
}, {
  label: 'Brunei',
  value: 25,
}, {
  label: 'Bulgaria',
  value: 26,
}, {
  label: 'Burkina Faso',
  value: 27,
}, {
  label: 'Burundi',
  value: 28,
}, {
  label: 'Cabo Verde',
  value: 29,
}, {
  label: 'Cambodia',
  value: 30,
}, {
  label: 'Cameroon',
  value: 31,
},{
  label: 'Canada',
  value: 32,
}, {
  label: 'Central African Republic',
  value: 33,
}, {
  label: 'Chad',
  value: 34,
}, {
  label: 'Chile',
  value: 35,
}, {
  label: 'China',
  value: 36,
}, {
  label: 'Colombia',
  value: 37,
}, {
  label: 'Comoros',
  value: 38,
}, {
  label: 'Congo, Democratic Republic of the',
  value: 39,
}, {
  label: 'Congo, Republic of the',
  value: 40,
}, {
  label: 'Costa Rica',
  value: 41,
}, {
  label: 'Côte d’Ivoire',
  value: 42,
}, {
  label: 'Croatia',
  value: 43,
}, {
  label: 'Cuba',
  value: 44,
}, {
  label: 'Cyprus',
  value: 45,
}, {
  label: 'Czech Republic',
  value: 46,
}, {
  label: 'Denmark',
  value: 47,
}, {
  label: 'Djibouti',
  value: 48,
}, {
  label: 'Dominica',
  value: 49,
}, {
  label: 'Dominican Republic',
  value: 50,
}, {
  label: 'East Timor (Timor-Leste)',
  value: 51,
}, {
  label: 'Ecuador',
  value: 52,
}, {
  label: 'Egypt',
  value: 53,
}, {
  label: 'El Salvador',
  value: 54,
}, {
  label: 'Equatorial Guinea',
  value: 55,
}, {
  label: 'Eritrea',
  value: 56,
}, {
  label: 'Estonia',
  value: 57,
}, {
  label: 'Eswatini',
  value: 58,
}, {
  label: 'Ethiopia',
  value: 59,
}, {
  label: 'Fiji',
  value: 60,
}, {
  label: 'Finland',
  value: 61,
}, {
  label: 'France',
  value: 62,
}, {
  label: 'Gabon',
  value: 63,
}, {
  label: 'The Gambia',
  value: 64,
}, {
  label: 'Georgia',
  value: 65,
}, {
  label: 'Germany',
  value: 66,
}, {
  label: 'Ghana',
  value: 67,
}, {
  label: 'Greece',
  value: 68,
}, {
  label: 'Grenada',
  value: 69,
}, {
  label: 'Guatemala',
  value: 70,
}, {
  label: 'Guinea',
  value: 71,
}, {
  label: 'Guinea-Bissau',
  value: 72,
}, {
  label: 'Guyana',
  value: 73,
}, {
  label: 'Haiti',
  value: 74,
}, {
  label: 'Honduras',
  value: 75,
}, {
  label: 'Hungary',
  value: 76,
}, {
  label: 'Iceland',
  value: 77,
}, {
  label: 'India',
  value: 78,
}, {
  label: 'Indonesia',
  value: 79,
}, {
  label: 'Iran',
  value: 80,
}, {
  label: 'Iraq',
  value: 81,
}, {
  label: 'Israel',
  value: 82,
}, {
  label: 'Italy',
  value: 83,
}, {
  label: 'Jamaica',
  value: 84,
}, {
  label: 'Japan',
  value: 85,
}, {
  label: 'Jordan',
  value: 86,
}, {
  label: 'Kazakhstan',
  value: 87,
}, {
  label: 'Kenya',
  value: 88,
}, {
  label: 'Kiribati',
  value: 89,
}, {
  label: 'Korea, North',
  value: 90,
}, {
  label: 'Korea, South',
  value: 91,
}, {
  label: 'Kosovo',
  value: 92,
},{
  label: 'Kuwait',
  value: 93,
}, {
  label: 'Kyrgyzstan',
  value: 94,
}, {
  label: 'Laos',
  value: 95,
}, {
  label: 'Latvia',
  value: 96,
}, {
  label: 'Lebanon',
  value: 97,
}, {
  label: 'Lesotho',
  value: 98,
}, {
  label: 'Liberia',
  value: 99,
}, {
  label: 'Libya',
  value: 100,
}, {
  label: 'Liechtenstein',
  value: 101,
}, {
  label: 'Lithuania',
  value: 102,
}, {
  label: 'Luxembourg',
  value: 103,
}, {
  label: 'Madagascar',
  value: 104,
}, {
  label: 'Malawi',
  value: 105,
}, {
  label: 'Malaysia',
  value: 106,
}, {
  label: 'Maldives',
  value: 107,
}, {
  label: 'Mali',
  value: 108,
}, {
  label: 'Malta',
  value: 109,
}, {
  label: 'Marshall Islands',
  value: 110,
}, {
  label: 'Mauritania',
  value: 111,
}, {
  label: 'Mauritius',
  value: 112,
}, {
  label: 'Mexico',
  value: 113,
}, {
  label: 'Micronesia, Federated States of',
  value: 114,
}, {
  label: 'Moldova',
  value: 115,
}, {
  label: 'Monaco',
  value: 116,
}, {
  label: 'Mongolia',
  value: 117,
}, {
  label: 'Montenegro',
  value: 118,
}, {
  label: 'Morocco',
  value: 119,
}, {
  label: 'Mozambique',
  value: 120,
}, {
  label: 'Myanmar (Burma)',
  value: 121,
}, {
  label: 'Namibia',
  value: 122,
}, {
  label: 'Nauru',
  value: 123,
}, {
  label: 'Nepal',
  value: 124,
}, {
  label: 'Netherlands',
  value: 125,
}, {
  label: 'New Zealand',
  value: 126,
}, {
  label: 'Nicaragua',
  value: 127,
}, {
  label: 'Niger',
  value: 128,
}, {
  label: 'Nigeria',
  value: 129,
}, {
  label: 'North Macedonia',
  value: 130,
}, {
  label: 'Norway',
  value: 131,
}, {
  label: 'Oman',
  value: 132,
}, {
  label: 'Pakistan',
  value: 133,
}, {
  label: 'Palau',
  value: 134,
}, {
  label: 'Panama',
  value: 135,
}, {
  label: 'Papua New Guinea',
  value: 136,
}, {
  label: 'Paraguay',
  value: 137,
}, {
  label: 'Peru',
  value: 138,
}, {
  label: 'Philippines',
  value: 139,
}, {
  label: 'Poland',
  value: 140,
}, {
  label: 'Portugal',
  value: 141,
}, {
  label: 'Qatar',
  value: 142,
}, {
  label: 'Romania',
  value: 143,
}, {
  label: 'Russia',
  value: 144,
}, {
  label: 'Rwanda',
  value: 145,
}, {
  label: 'Saint Kitts and Nevis',
  value: 146,
}, {
  label: 'Saint Lucia',
  value: 147,
}, {
  label: 'Saint Vincent and the Grenadines',
  value: 148,
}, {
  label: 'Samoa',
  value: 149,
}, {
  label: 'San Marino',
  value: 150,
}, {
  label: 'Sao Tome and Principe',
  value: 151,
}, {
  label: 'Saudi Arabia',
  value: 152,
}, {
  label: 'Senegal',
  value: 153,
}, {
  label: 'Serbia',
  value: 154,
}, {
  label: 'Seychelles',
  value: 155,
}, {
  label: 'Sierra Leone',
  value: 156,
}, {
  label: 'Singapore',
  value: 157,
}, {
  label: 'Slovakia',
  value: 158,
}, {
  label: 'Slovenia',
  value: 159,
}, {
  label: 'Solomon Islands',
  value: 160,
}, {
  label: 'Somalia',
  value: 161,
}, {
  label: 'South Africa',
  value: 162,
}, {
  label: 'Spain',
  value: 163,
}, {
  label: 'Sri Lanka',
  value: 164,
}, {
  label: 'Sudan',
  value: 165,
}, {
  label: 'Sudan, South',
  value: 166,
}, {
  label: 'Suriname',
  value: 167,
}, {
  label: 'Sweden',
  value: 168,
}, {
  label: 'Switzerland',
  value: 169,
}, {
  label: 'Syria',
  value: 170,
}, {
  label: 'Taiwan',
  value: 171,
}, {
  label: 'Tajikistan',
  value: 172,
}, {
  label: 'Tanzania',
  value: 173,
}, {
  label: 'Thailand',
  value: 174,
}, {
  label: 'Togo',
  value: 175,
}, {
  label: 'Tonga',
  value: 176,
}, {
  label: 'Trinidad and Tobago',
  value: 177,
}, {
  label: 'Tunisia',
  value: 178,
}, {
  label: 'Turkey',
  value: 179,
}, {
  label: 'Turkmenistan',
  value: 180,
}, {
  label: 'Tuvalu',
  value: 181,
}, {
  label: 'Uganda',
  value: 182,
}, {
  label: 'Ukraine',
  value: 183,
}, {
  label: 'United Arab Emirates',
  value: 184,
}, {
  label: 'United Kingdom',
  value: 185,
}, {
  label: 'United States',
  value: 186,
}, {
  label: 'Uruguay',
  value: 187,
}, {
  label: 'Uzbekistan',
  value: 188,
}, {
  label: 'Vanuatu',
  value: 189,
}, {
  label: 'Vatican City',
  value: 190,
}, {
  label: 'Venezuela',
  value: 191,
}, {
  label: 'Vietnam',
  value: 192,
}, {
  label: 'Yemen',
  value: 193,
}, {
  label: 'Zambia',
  value: 194,
}, {
  label: 'Zimbabwe',
  value: 195,
}];