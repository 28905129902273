import React from "react"
import {observer} from "mobx-react-lite";
import {AddNewWebinar} from ".";
import {LinkWebinars} from ".";

export const WebinarsNavBar = observer(() => {
  return (
    <div className="courses__header-users">
      <div className="buttons-container">
        <LinkWebinars />
      </div>

      <AddNewWebinar />
    </div>
  )
})