import React from "react"
import {observer} from "mobx-react-lite";
import {AddNewButtons} from ".";
import {LinkRequests, LinkUsers} from "./components";

export type Props = {
  showButton: 'users' | 'requests';
}

export const UsersNavBar = observer((props:Props) => {

  return (
    <div className="courses__header-users">
      <div className="buttons-container">
        <LinkUsers />
        <LinkRequests />
      </div>

      <AddNewButtons showButton={props.showButton} />
    </div>
  )
})