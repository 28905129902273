import {makeAutoObservable} from 'mobx'
import {WebinarType, CpdQuizType} from "types";

export const webinarStore = makeAutoObservable({
  _webinar: {} as WebinarType,
  _cpdQuiz: [] as CpdQuizType[],
  _inProgress: false,

  set webinar(value: WebinarType) {
    this._webinar = value;
  },

  get webinar(): WebinarType {
    return this._webinar;
  },

  set cpdQuiz(value: CpdQuizType[]) {
    this._cpdQuiz = value;
  },

  get cpdQuiz(): CpdQuizType[] {
    return this._cpdQuiz;
  },

  set inProgress(value: boolean) {
    this._inProgress = value;
  },

  get inProgress(): boolean {
    return this._inProgress;
  },

  clear(): void {
    this._webinar = {} as WebinarType;
    this._cpdQuiz = [] as CpdQuizType[];
  }
});

