import {CourseMaterialType} from "types";
import {cloneDeep} from "lodash";

export const mapFromCourseMaterials = (data: any): CourseMaterialType[] => {
  const clone = cloneDeep(data);
  const _courseMaterials = clone.course_materials.data;
  const hasData = _courseMaterials && _courseMaterials.length > 0;

  return hasData ? _courseMaterials.map((item: any) => ({...item.attributes})) : [];
}
