import React from "react";
import {observer} from 'mobx-react-lite';
import {Item} from './';
import {getCourses} from '../../Dashboard/actions/gets';
import {coursesStore} from '../../Dashboard/stores';

export const List = observer(() => {
  const {courses} = coursesStore;

  React.useEffect(() => {
    getCourses();
  }, []);
// academy__container landing
  return (
    <div className="signin__landing">
      {courses.map(course => (
        <Item key={`landing_course_${course.id}`} item={course} />
      ))}
    </div>
  );
});
