import {defaultErrorsInfo} from "../../../../../utils";
import {notificationsService} from "../../services";
import {notificationsStore} from "../../stores";
import {mapFromNotifications} from '../../utils';

export const getNotifications = async () => {
  try {
    const {data} = await notificationsService.getNotifications();
    const notifications = mapFromNotifications(data);

    notificationsStore.notifications = notifications;
  } catch (error: any) {
    const {response} = error;
    // defaultErrorsInfo(response.status, response.data);
  }
}
