import {makeAutoObservable} from 'mobx'

export const loginStore = makeAutoObservable({
  _email: '',
  _password: '',
  _inProgress: false,

  set email(value: string) {
    this._email = value;
  },

  get email(): string {
    return this._email;
  },

  set password(value: string) {
    this._password = value;
  },

  get password(): string {
    return this._password;
  },

  set inProgress(value: boolean) {
    this._inProgress = value;
  },

  get inProgress(): boolean {
    return this._inProgress;
  },

  clear(): void {
    this._email = '';
    this._password = '';
  }
});

