import moment from "moment"
import React, { DetailedHTMLProps, HTMLAttributes, MouseEvent } from "react"
import { StatisticType } from "types"
import { toggleModal } from "../../actions/toggleModal"

type Props = {
  statistic: StatisticType
}

export const ListItem = (props: Props) => {

  const {
    id,
    webinar_title,
    viewed_count,
    downloaded_count,
    completed_count,
    registerd_count,
    attended_count,
    in_person_count,
    start_date
  } = props.statistic

  const handleOpenModal = (statistic_type: string) => {
    toggleModal(id, statistic_type, {title: webinar_title, id, statistic_type})
  }

  // const onClickCheckBox = (event: any) => {
  //   const checked = Boolean(event.target.checked)
  //   checked ? addCheckedId(id) : removeCheckedId(id)
  // }

  const cursorPointer = { cursor: 'pointer' }

  return (
    <div className="content-line-users">
      <div className="content-desc">
        <div className="user-name stats">
          <p>{webinar_title}</p>
        </div>
        <div style={cursorPointer} className="user-name stats link" onClick={() => handleOpenModal('attended')}>
          <p>{attended_count}</p>
        </div>
        <div style={cursorPointer} className="user-name stats link" onClick={() => handleOpenModal('viewed')}>
          <p>{viewed_count}</p>
        </div>
        <div style={cursorPointer} className="user-name stats link" onClick={() => handleOpenModal('completed')}>
          <p>{completed_count}</p>
        </div>
        <div style={cursorPointer} className="user-name stats link" onClick={() => handleOpenModal('in_person')}>
          <p>{in_person_count}</p>
        </div>
        <div style={cursorPointer} className="user-name stats link" onClick={() => handleOpenModal('registered')}>
          <p>{registerd_count}</p>
        </div>
        <div className="user-name stats">
          <p>{moment(start_date).format('DD/MM/YYYY | HH:MM')}</p>
        </div>
      </div>
    </div>
  )
}