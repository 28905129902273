import React from 'react'
import AttachFileIcon from "../../assets/img/png/attach_files.png";
import {toast} from "react-toastify";
import {ImageType, DisabledPropType} from "types";

export const ImageField = (props: ImageType & DisabledPropType) => {
  const { label, name, onChange, maxFileSize, accept, acceptInfo, value, disabled } = props;


  const onFileChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;

    if (files && files[0]) {
      const file = files[0];

      if (file.size / 1024 > maxFileSize) {
        toast.error('File is too big. Max file size is ' + maxFileSize + ' Kb');
        return;
      }

      onChange({ name, value: file });
    }
  };

  const onFileDelete = (e: any) => {
    e.stopPropagation()
    let el = document.getElementById(`file${name}${accept}`) as HTMLInputElement
    if(el) el.value = ''
    onChange({ name, value: null })
  }

  return (
    <div className="account-field">
      {value && <div className='deletefile' onClick={onFileDelete}>×</div>}
      <label className="account-fields__file-input">
        <p>{label}</p>
        <div className="account-fields__file-picker">
          <img src={AttachFileIcon} alt="add logo"/>
          {value ? (
              <p>{value}</p>
              ) : (
              <p>ADD FILE <span>{acceptInfo}</span></p>
            )
          }
          <input
            type="file"
            accept={accept}
            id={`file${name}${accept}`}
            name={name}
            onChange={onFileChange}
            multiple={false}
            disabled={disabled}
          />
        </div>
      </label>
    </div>
  )
}