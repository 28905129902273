import {deleteModalStore} from "../../stores";
import {adminUsersService} from "services";
import {showError500} from "utils";
import {getSuperHosts} from "../gets";
import {toast} from "react-toastify";

export const onDeleteSuperHost = async () => {
  const id = deleteModalStore.superHostId;

  if (id === 0) {
    return;
  }

  const params = {user_ids: [id]}

  const {status, data} = await adminUsersService.deleteUser(params)

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  toast.success('SuperHost was delete successfully.');

  deleteModalStore.clear();
  getSuperHosts();
}