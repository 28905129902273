import React from "react"
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Header} from "../Header";
import {Footer} from "../Footer";
import {ChildrenType} from "../../../types";

export const Layout = (props: ChildrenType) => {
  const {children, hideHeader, className} = props;

  return (
    <div className="web-wrapper-container">
      <div className="wrapper">
        {!hideHeader && (
          <Header/>
        )}
        <main className="page">
          <div className={`page__container ${className || ''}`}>
            {children}
          </div>
        </main>
      </div>
      <Footer/>
      <ToastContainer />
    </div>
  );
}