import React from "react"
import { ADMIN_ROUTES } from "constants/routes"
import { generatePath, NavLink, useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"

export const LinkStatistics = observer(() => {
  let {id} = useParams()

  const getLink = () => generatePath(ADMIN_ROUTES.statistics, {id})

  return <NavLink to={getLink()} className='active'>
    <button className="all-courses">Statistics</button>
  </NavLink>
})