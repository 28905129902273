import {isMobile, isSafari} from 'react-device-detect';
import {defaultErrorsInfo} from "../../../../../utils";
import {cpdLockerServices} from "../../services";
import {cpdLockerStore} from "../../stores";

export const getCertificate = async (webinarId: number) => {
  try {
    cpdLockerStore.inProgress = true;

    const {data} = await cpdLockerServices.getCertificate(webinarId);

    const cert = new Blob([data], {type: 'application/pdf'});
    const certURL = URL.createObjectURL(cert);

    if (isMobile) {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = certURL;
      a.download = certURL;
      a.target = "_blank";
      a.click();
      document.body.removeChild(a);
    } else {
      if (isSafari) {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = certURL;
        a.target = "_blank";
        a.click();
        document.body.removeChild(a);
      } else {
        window.open(certURL, '_blank');
      }
    }

    cpdLockerStore.inProgress = false;
  } catch (error: any) {
    cpdLockerStore.inProgress = false;

    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
}
