import { makeAutoObservable } from "mobx";
import { AdminUserType, ImageChangeProps } from "types";
import { cloneDeep } from "lodash";

export const addUserStore = makeAutoObservable({
  _user: { accreditation: true } as AdminUserType,

  set user(value: AdminUserType) {
    this._user = value;
  },

  get user(): AdminUserType {
    return this._user;
  },

  changeField(data: ImageChangeProps) {
    const clone = cloneDeep(this._user);
    const key = data.name as keyof AdminUserType;

    // @ts-ignore
    clone[key] = data.value;
    this._user = clone;
  },

  isEdit(): boolean {
    return this._user.id !== undefined;
  },

  clear(): void {
    this._user = { accreditation: true } as AdminUserType;
  },
});
