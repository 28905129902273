import React from "react"
import { observer } from "mobx-react-lite"
import { AddNewCourseMaterial } from "./AddNewCourseMaterial"
import { LinkCourseMaterials } from "./LinkCourseMaterials"

export const CourseMaterialsNavBar = observer(() => {
  return (
    <div className="courses__header-users">
      <div className="buttons-container">
        <LinkCourseMaterials />
      </div>

      <AddNewCourseMaterial />
    </div>
  )
})