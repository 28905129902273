export const API_BASE = '/api/v1';
export const API_BASE_ADMIN = '/api/v1/admin';


// Web Auth
export const API_LOGIN = `${API_BASE}/autorization/login`;
export const API_LOGOUT = `${API_BASE}/authorization/logout`;
export const API_FORGOT_PASSWORD = `${API_BASE}/forgot_passwords`;
export const API_UPDATE_PASSWORD = `${API_BASE}/update_passwords`;
export const API_CURRENT_USER = `${API_BASE}/user`;
export const API_REGISTER = `${API_BASE}/users`;
export const API_CONFIRM_EMAIL = `${API_BASE}/confirm_email`;

// Web Courses
export const API_COURSES = `${API_BASE}/courses`;
export const API_MY_COURSES = `${API_BASE}/my_courses`;
export const API_JOIN_COURSE = `${API_BASE}/invites`;

// Web Webinars
export const API_WEBINARS = `${API_BASE}/webinars`;
export const API_SUBSCRIBE_WEBINAR = `${API_BASE}/subscriptions`;
export const API_REGISTER_WEBINAR = `${API_BASE}/register_webinar_requests`;
export const API_SUBMIT_QUIZ = `${API_BASE}/take_cpd_quiz`;

// Web Members
export const API_MEMBERS = `${API_BASE}/users`;

// Web Course Materials
export const API_COURSE_MATERIALS = `${API_BASE}/course_materials`;

// Web Notifications
export const API_NOTIFICATIONS = `${API_BASE}/notifications`;

// Web Cpd Locker
export const API_CPD_LOCKER = `${API_BASE}/cpd_locker`;
export const API_CERTIFICATE = `${API_BASE}/certificates`;


// Admin
export const API_ADMIN_LOGIN = `${API_BASE_ADMIN}/authorization/login`;
export const API_ADMIN_LOGOUT = `${API_BASE_ADMIN}/authorization/logout`;

export const API_ADMIN_COURSES = `${API_BASE_ADMIN}/courses`;
export const API_ADMIN_COURSE_ID = `${API_ADMIN_COURSES}/:id`;

export const API_ADMIN_USERS = `${API_BASE_ADMIN}/users`;
export const API_ADMIN_USER_DELETE = `${API_ADMIN_USERS}/destroy`;
export const API_ADMIN_USER_PROFILE = `${API_ADMIN_USERS}/profile`;
export const API_ADMIN_USER_ID = `${API_ADMIN_USERS}/:id`;
export const API_ADMIN_ADD_USER = `${API_ADMIN_USERS}?course_id=:courseId`;

export const API_ADMIN_SPONSORS = `${API_BASE_ADMIN}/sponsors`;
export const API_ADMIN_SPONSOR_ID = `${API_ADMIN_SPONSORS}/:id`;
export const API_ADMIN_SPONSOR_DELETE = `${API_ADMIN_SPONSORS}/destroy`;

export const API_ADMIN_PARTNERS = `${API_BASE_ADMIN}/partners`;
export const API_ADMIN_PARTNER_ID = `${API_ADMIN_PARTNERS}/:id`;
export const API_ADMIN_PARTNER_DELETE = `${API_ADMIN_PARTNERS}/destroy`;

export const API_ADMIN_INVITES_USER = `${API_BASE_ADMIN}/invites`;
export const API_ADMIN_REJECT_REASONS = `${API_ADMIN_INVITES_USER}/reject_reasons`;
export const API_ADMIN_UPDATE_INVITES = `${API_ADMIN_INVITES_USER}/:inviteId`;

export const API_ADMIN_WEBINARS = `${API_BASE_ADMIN}/webinars`;
export const API_ADMIN_WEBINAR_ID = `${API_ADMIN_WEBINARS}/:id`;
export const API_ADMIN_ADD_WEBINAR = `${API_ADMIN_WEBINARS}`;
export const API_ADMIN_WEBINAR_DELETE = `${API_ADMIN_WEBINARS}/destroy`;

export const API_ADMIN_COURSE_MATERIALS = `${API_BASE_ADMIN}/course_materials`;
export const API_ADMIN_COURSE_MATERIAL_ID = `${API_ADMIN_COURSE_MATERIALS}/:id`;
export const API_ADMIN_ADD_COURSE_MATERIAL = `${API_ADMIN_COURSE_MATERIALS}`;
export const API_ADMIN_COURSE_MATERIAL_DELETE = `${API_ADMIN_COURSE_MATERIALS}/destroy`;

export const API_ADMIN_STATISTICS = `${API_BASE_ADMIN}/statistics`;