import {toast} from "react-toastify";
import {showError500, validateBE} from "utils";
import { newCourseMaterialServices } from "../services";
import { getCourseMaterials } from "./gets";

export const deleteCourseMaterial = async (id: number, callBack: Function, scope?: string) => {

  const {status, data} = await newCourseMaterialServices.deleteCourseMaterial(id)

  if (status === 401 || status === 403 || status === 400) {
    validateBE(data.error_messages, []);
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  await getCourseMaterials(scope)
  toast.success(`Course Material was updated successfully.`);
  callBack();
}