import React from "react"
import {Layout} from "../Layout";
import {ChildrenType} from "../../../types";

export const Page = (props: ChildrenType) => {
  const {children, className} = props;

  return (
    <Layout>
      <div className={`page__wrapper ${className || ''}`}>{children}</div>
    </Layout>
  );
};