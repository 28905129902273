import { ChangeEvent } from "react";
import { observer } from "mobx-react-lite";
import { addWebinarStore } from "../../stores";
import { changeField } from "../../actions/add";
import { FormControlLabel, styled, Switch } from "@mui/material";
import { getErrors } from "utils";

const SwitchStyled = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    background: "#fff",
    border: "2px solid #52AB98",
  },
  "& .MuiSwitch-thumb": {
    background: "#52AB98",
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
  "& .Mui-checked": {
    "& ~ .MuiSwitch-track": {
      background: "#52ab9842 !important",
      opacity: "1 !important",
    },
  },
}));

const CustomSwitch = observer(() => {
  const { webinar } = addWebinarStore;
  const label = webinar.status === "published" ? "Published" : "Unpublished";

  const error = getErrors("status");

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeField({
      name: event.target.name,
      value: event.target.checked ? "published" : "unpublished",
    });
  };

  return (
    <div>
      <FormControlLabel
        control={
          <SwitchStyled
            name="status"
            checked={webinar.status === "published"}
            onChange={handleStatusChange}
          />
        }
        label={label}
      />
      {error && <div className="form-error">{error}</div>}
    </div>
  );
});

export { CustomSwitch };
