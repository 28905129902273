import {
  API_ADMIN_ADD_USER,
  API_ADMIN_USER_DELETE,
  API_ADMIN_USER_ID,
  API_ADMIN_USER_PROFILE,
  API_ADMIN_USERS,
} from "constants/api";
import {
  getRequestAuth,
  postRequestAuth,
  postRequestFile,
  putRequestAuth,
} from "utils/requests";
import { generatePath } from "react-router-dom";

const getSuperHosts = async (params: object) =>
  await getRequestAuth(API_ADMIN_USERS, params);

const getUsers = async (params: object) =>
  await getRequestAuth(API_ADMIN_USERS, params);

const getUserDetails = async (id: number) => await getRequestAuth(userUrl(id));

const getUsersByQuery = async (params: object) =>
  await getRequestAuth(API_ADMIN_USERS, params);

const addSuperHost = async (params: object) =>
  await postRequestAuth(API_ADMIN_USERS, params);

const addUser = async (params: FormData, courseId: number) =>
  await postRequestFile(`${API_ADMIN_USERS}?course_id=${courseId}`, params);

const deleteUser = async (params: object) =>
  await postRequestAuth(API_ADMIN_USER_DELETE, params);

const getUserProfile = async () => await getRequestAuth(API_ADMIN_USER_PROFILE);

const editUser = async (params: object, id: number) =>
  await putRequestAuth(userUrl(id), params);

export const adminUsersService = {
  getSuperHosts,
  getUsers,
  getUsersByQuery,
  addSuperHost,
  addUser,
  deleteUser,
  getUserProfile,
  editUser,
  getUserDetails,
};

const userUrl = (id: number) => generatePath(API_ADMIN_USER_ID, { id });
