import React from "react"
import {List} from "./components/List";
import {WebinarsNavBar} from "./components/WebinarsNavBar";
import {PageSideBar} from "modules/admin/PageSideBar";
import {DeleteModal} from './components/DeleteModal';

export const Webinars = () => {
  return (
    <PageSideBar className='display-block'>
      <WebinarsNavBar />
      <List />
      <DeleteModal />
    </PageSideBar>
  )
}