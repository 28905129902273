import axios from 'axios'
import {generatePath} from 'react-router-dom'
import {addUploadFileHeader} from '.';

export const putRequestFile = async (url:string, formData:FormData) => {
    return await axios
        .create({baseURL: process.env.REACT_APP_API_URL})
        .put(generatePath(url), formData, addUploadFileHeader())
        .then(response => response)
        .catch(error => error.response)
};
