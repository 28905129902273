import validate from "validate.js";
import moment from "moment";
import { RefType } from "types";
import { QuizQuestion, Speaker } from "../types";

const validateZoom = (value: any, options: any, key: any, attributes: any) => {
  if (!value) return options.message;
};

const validateVimeo = (value: any, options: any, key: any, attributes: any) => {
  if (attributes.webinar_type === "recorded" && !value) return options.message;
};

const validateIcgpRef = (
  value: any,
  options: any,
  key: any,
  attributes: any
) => {
  if (attributes.ref_type === RefType.icgp && !value) return options.message;
};

const validateIpnaRef = (
  value: any,
  options: any,
  key: any,
  attributes: any
) => {
  if (
    attributes.ref_type === RefType.ipna &&
    !value &&
    attributes.cpd_status !== "applied_for"
  )
    return options.message;
};

const validateRcpiRef = (
  value: any,
  options: any,
  key: any,
  attributes: any
) => {
  if (
    attributes.ref_type === RefType.rcpi &&
    !value &&
    attributes.cpd_status !== "applied_for"
  )
    return options.message;
};

const validateRefType = (
  value: any,
  options: any,
  key: any,
  attributes: any
) => {
  if (!value && attributes.cpd_status !== "applied_for") return "is required";
};

const validateCPDPoints = (
  value: any,
  options: any,
  key: any,
  attributes: any
) => {
  if (!value && attributes.cpd_status !== "applied_for") return "is required";
};

const validateGmsStudyDays = (
  value: any,
  options: any,
  key: any,
  attributes: any
) => {
  if (
    attributes.ref_type === RefType.icgp &&
    attributes.cpd_status === "granted" &&
    !value
  )
    return options.message;
};

const validateIntPoints = (
  value: any,
  options: any,
  key: any,
  attributes: any
) => {
  if (
    (attributes.cpd_points === "internal" ||
      attributes.cpd_points === "both") &&
    !value &&
    attributes.cpd_status !== "applied_for"
  ) {
    return options.message;
  }
};

const validateExtPoints = (
  value: any,
  options: any,
  key: any,
  attributes: any
) => {
  if (
    (attributes.cpd_points === "external" ||
      attributes.cpd_points === "both") &&
    !value
  )
    return options.message;
};

const validateQuiz = (value: any, options: any, key: any, attributes: any) => {
  const notValidQuestions = (value || []).filter(
    (quiz: QuizQuestion) => quiz.correct === "" || !quiz.question
  );

  if (notValidQuestions.length > 0) {
    const errors = notValidQuestions.map((quiz: QuizQuestion) => ({
      id: quiz.id,
      correct: quiz.correct || quiz.correct === false ? "" : ["is required"],
      question: !quiz.question ? ["is required"] : "",
    }));
    return errors;
  }
};

const validateSpeaker = (
  value: any,
  options: any,
  key: any,
  attributes: any
) => {
  const notValidSpeakers = (value || []).filter(
    (speaker: Speaker) =>
      !speaker.name ||
      !speaker.speaker_type ||
      !speaker.location ||
      !speaker.presentation_title
  );

  if (notValidSpeakers.length > 0) {
    const errors = notValidSpeakers.map((speaker: Speaker) => ({
      id: speaker.id,
      name: !speaker.name ? ["is required"] : "",
      speaker_type: !speaker.speaker_type ? ["is required"] : "",
      location: !speaker.location ? ["is required"] : "",
    }));
    // return errors;
  }
};

const validateStartDate = (
  value: any,
  options: any,
  key: any,
  attributes: any
) => {
  if (!attributes.id && value) {
    const isBefore = moment(value).isBefore(moment());
    if (isBefore) return "can't be past";
  }
};

const validateEndDate = (
  value: any,
  options: any,
  key: any,
  attributes: any
) => {
  if (attributes.start_date && value) {
    const isBefore = moment(value).isBefore(attributes.start_date);
    return isBefore ? "cant be before start date" : "";
  } else if (attributes.start_date && !value) {
    return "is required";
  }
};

const validateCPD = (value: any, options: any, key: any, attributes: any) => {
  if (attributes.cpd_status === "granted" && !value) return "is required";
};

export const initCustomValidate = () => {
  validate.extend(validate.validators.datetime, {
    // The value is guaranteed not to be null or undefined but otherwise it
    // could be anything.
    parse: function (value: any) {
      return +moment.utc(value);
    },
    // Input is a unix timestamp
    format: function (value: any, options: any) {
      var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
      return moment.utc(value).format(format);
    },
  });

  validate.validators.validateZoom = validateZoom;
  validate.validators.validateVimeo = validateVimeo;
  validate.validators.validateIcgpRef = validateIcgpRef;
  validate.validators.validateIpnaRef = validateIpnaRef;
  validate.validators.validateRcpiRef = validateRcpiRef;
  validate.validators.validateGmsStudyDays = validateGmsStudyDays;
  validate.validators.validateIntPoints = validateIntPoints;
  validate.validators.validateExtPoints = validateExtPoints;
  validate.validators.validateQuiz = validateQuiz;
  validate.validators.validateSpeaker = validateSpeaker;
  validate.validators.validateStartDate = validateStartDate;
  validate.validators.validateEndDate = validateEndDate;
  validate.validators.validateCPD = validateCPD;
  validate.validators.validateCPDPoints = validateCPDPoints;
  validate.validators.validateRefType = validateRefType;
};
