import React from "react"
import {Layout} from "../Layout";
import {LeftComponent} from "components/LeftComponent";
import {LoginForm} from "./components/LoginForm";

export const Login = () => {
  return (
    <Layout>
      <LeftComponent/>
      <LoginForm />
    </Layout>
  );
};