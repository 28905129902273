import React from "react"

export const ListHeader = () => {
  return (
    <div className="superhost__header">
      <div className="header-categories-sponsors">
        <div className="name">
          <p>Title</p>
        </div>
        <div className="name">
          <p>Webinar Type</p>
        </div>
        <div className="name">
          <p>Speakers</p>
        </div>
        <div className="name">
          <p>Start date/time</p>
        </div>
        <div className="name">
          <p>Status</p>
        </div>
      </div>
    </div>
  )
}