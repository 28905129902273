import agent from "../../../../agent";

const getCourses = async () => await agent.Courses.allCourses();

const getMyCourses = async () => await agent.Courses.myCourses();

const joinCourse = async (courseId: number) => await agent.Courses.joinCourse(courseId);

export const courseService = {
  getCourses,
  getMyCourses,
  joinCourse,
}
