import React from 'react'
import {observer} from 'mobx-react-lite';
import {useNavigate} from "react-router-dom";
import {ROUTES} from '../../../../constants'
import {ButtonRound, FormInput} from "components";
import {setConfirmPassword, setPassword, submitNewPassword, setToken} from '../actions/sets';
import {newPasswordStore} from '../stores';
import {InputChangeProps} from 'types';

export const NewPasswordForm = observer(() => {
  const navigate = useNavigate();
  const {password, passwordConfirmation, inProgress, token} = newPasswordStore;
  const {search} = window.location;
  const handleInputChange = (data: InputChangeProps) => {
    if (data.name === 'password') {
      setPassword((data.value || '').toString());
    } else {
      setConfirmPassword((data.value || '').toString());
    }
  };
  const handleSubmitForm = () => {
    submitNewPassword(() => {
      navigate(ROUTES.login);
    });
  };

  React.useEffect(() => {
    if (search && !token) {
      const token = search.replace('?token=', '');
      setToken(token);
      // confirmEmail(token, () => {
      //   navigate(ROUTES.settings);
      // });
    }
  }, [search]);

  return (
    <div className="signin__block">
      <div className="signin__container">
        <h1>NEW PASSWORD</h1>
        <div className="signin__content">
          <div className="containers">
            <form>
              <FormInput
                name="password"
                label="Password"
                value={password}
                onChange={handleInputChange}
                type='password'
              />
              <FormInput
                name="password_confirmation"
                label="Confirm Password"
                value={passwordConfirmation}
                onChange={handleInputChange}
                type='password'
              />
            </form>
          </div>

          <ButtonRound onClick={handleSubmitForm} disabled={inProgress} className="auth_rounded_button">Change Password</ButtonRound>
        </div>
      </div>
    </div>
  );
});