import {defaultErrorsInfo} from '../../../../../utils';
import {forgotPasswordStore} from '../../stores';
import {forgotPasswordServices} from '../../services';
import {toast} from 'react-toastify';

export const submitForgotPassword = async (callback: Function) => {
  try {
    forgotPasswordStore.inProgress = true;
    const {email} = forgotPasswordStore;
    const {data} = await forgotPasswordServices.forgotPassword(email);
    forgotPasswordStore.inProgress = false;

    if (data.message) {
      toast.success(data.message);
      callback();
    }
  } catch (error: any) {
    forgotPasswordStore.inProgress = false;

    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
};
