import React from "react"
import {Page} from "../Page";
import {TopNavBar} from "../TopNavBar";
import {List} from "./components/List";
import {AddModal, DeleteModal} from "./components";

export const SuperHosts = () => {
  return (
    <Page>
      <TopNavBar showButton='superhost' />
      <List />
      <AddModal />
      <DeleteModal />
    </Page>
  )
}