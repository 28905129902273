import {makeAutoObservable} from 'mobx'

export const createCourseMaterialStore = makeAutoObservable({
  _title: '',
  _file: null,

  set title(value: string) {
    this._title = value;
  },

  get title(): string {
    return this._title;
  },

  set file(value: any) {
    this._file = value;
  },

  get file(): any {
    return this._file;
  },

  clear(): void {
    this._title = '';
    this._file = null;
  }
});

