import {cloneDeep, concat} from 'lodash';

export const mapByPage = (page: number, data: any, responseData: any): any[] => {
    if (page > 1) {
      const _data = cloneDeep(data);
      const allCourseMaterials = concat(_data, responseData);
      return allCourseMaterials;
    } else {
      return responseData;
    }
}
