import {paramsToFormData, showError500, validateBE} from "utils";
import {addCourseStore} from "../../stores";
import {courseService} from "../../services";
import {toast} from "react-toastify";
import {cloneDeep} from "lodash";
import {errorsStore} from "stores";

export const onSubmitEditCourse = async (callBack: Function) => {
  errorsStore.clear();

  const storeData = cloneDeep(addCourseStore.course);
  const courseId = storeData.id;

  // TODO 1 add validation
  // validate js
  const isValid = true;

  if (!isValid) {
    return false;
  }

  const formData = paramsToFormData(storeData, 'course');

  const {status, data} = await courseService.editCourse(formData, courseId)

  if (status === 401 || status === 403 || status === 400) {
    validateBE(data.error_messages, ['title', 'color_theme']);
    return;
  }

  if (status === 500) {
    showError500(status, data);
    return;
  }

  toast.success(`Course was edited successfully... redirect`);
  callBack();
}