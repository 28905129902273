import React from 'react'
import {Route, Navigate} from 'react-router-dom';
import {ROUTES} from '../constants';
import {Landing} from "../modules/web/Landing";
import {Login} from "../modules/web/Login";
import {Terms} from "../modules/web/Terms/Terms";
import {Privacy} from "../modules/web/Privacy";
import {CreateAccount} from '../modules/web/CreateAccount';
import {ForgotPassword} from '../modules/web/ForgotPassword';
import {Dashboard} from '../modules/web/Dashboard';
import {Settings} from '../modules/web/Settings';
import {EmailConfirmation} from '../modules/web/EmailConfirmation';
import {Webinars} from '../modules/web/Webinars';
import {Members} from '../modules/web/Members';
import {CourseMaterials} from '../modules/web/CourseMaterials';
import {CreateCourseMaterial} from '../modules/web/CreateCourseMaterials';
import {CpdLocker} from '../modules/web/CpdLocker';
import {NewPassword} from '../modules/web/NewPassword';
import {Webinar} from '../modules/web/Webinar';
import {NovoNordiskTerms} from '../modules/web/NovoNordiskTerms';
import {PrivateRoute, PublicRoute} from 'components/router';
import { WebinarReview } from '../modules/web/WebinarReview';

export const WebRoutes = () => ([
  <Route key='web-routes-landing' path={ROUTES.main} element={<PublicRoute><Landing /></PublicRoute>} />,
  <Route key='web-routes-login' path={ROUTES.login} element={<PublicRoute><Login /></PublicRoute>} />,
  <Route key='web-routes-create-account' path={ROUTES.register} element={<PublicRoute><CreateAccount /></PublicRoute>} />,
  <Route key='web-routes-email-confirmation' path={ROUTES.confirmEmail} element={<EmailConfirmation />} />,
  <Route key='web-routes-forgot-password' path={ROUTES.forgotPassword} element={<PublicRoute><ForgotPassword /></PublicRoute>} />,
  <Route key='web-routes-new-password' path={ROUTES.updatePassword} element={<PublicRoute><NewPassword /></PublicRoute>} />,
  <Route key='web-routes-terms' path={ROUTES.terms} element={<Terms />} />,
  <Route key='web-routes-privacy' path={ROUTES.privacyPolicy} element={<Privacy />} />,
  <Route key='web-routes-dashboard' path={ROUTES.novoNordiskTerms} element={<PrivateRoute><NovoNordiskTerms /></PrivateRoute>} />,
  <Route key='web-routes-dashboard' path={ROUTES.dashboard} element={<PrivateRoute><Dashboard /></PrivateRoute>} />,
  <Route key='web-routes-settings' path={ROUTES.settings} element={<PrivateRoute><Settings /></PrivateRoute>} />,
  <Route key='web-routes-webinars' path={ROUTES.webinars} element={<PrivateRoute><Webinars /></PrivateRoute>} />,
  <Route key='web-routes-webinar' path={ROUTES.webinar} element={<PrivateRoute><Webinar /></PrivateRoute>} />,
  <Route key='web-routes-webinar-review' path={ROUTES.webinarReview} element={<PrivateRoute><WebinarReview /></PrivateRoute>} />,
  <Route key='web-routes-members' path={ROUTES.members} element={<PrivateRoute><Members /></PrivateRoute>} />,
  <Route key='web-routes-course-materials' path={ROUTES.courseMaterials} element={<PrivateRoute><CourseMaterials /></PrivateRoute>} />,
  <Route key='web-routes-course-materials-create' path={ROUTES.createCourseMaterial} element={<PrivateRoute><CreateCourseMaterial /></PrivateRoute>} />,
  <Route key='web-routes-cpd-locker' path={ROUTES.cpdLocker} element={<PrivateRoute><CpdLocker /></PrivateRoute>} />,
  <Route key='web-routes-profile-email' path={'/profile'} element={<><Navigate to={ROUTES.settings} replace={true} /></>} />
]);
