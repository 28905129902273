export const CPD_ACCREDITATION_OPTIONS = [{
  label: 'ICGP',
  value: 'icgp',
}, {
  label: 'RCPI',
  value: 'rcpi',
}, {
  label: 'FSEM',
  value: 'fsem',
}, {
  label: 'IPNA',
  value: 'ipna',
}];
