import {localStore} from "../../stores";

export const addUploadFileHeader = () => {
    const token: string = localStore.token;
    return {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        }
    }
}