import {makeAutoObservable} from 'mobx'
import {AdminUserInviteType, OptionType} from "types";
import {STATUS_PENDING} from "constants/inviteStatuses";

export const invitesStore = makeAutoObservable({
  _invites: [] as AdminUserInviteType[],
  _rejectReasons: [] as OptionType[],
  _courseId: 0,
  _inviteStatus: STATUS_PENDING,

  set invites(value: AdminUserInviteType[]) {
    this._invites = value;
  },

  get invites(): AdminUserInviteType[] {
    return this._invites
  },

  getInviteById(id: number) : AdminUserInviteType{
    return this._invites.filter(item => item.id === id)[0];
  },

  set rejectReasons(value: OptionType[]) {
    this._rejectReasons = value;
  },

  get rejectReasons(): OptionType[] {
    return this._rejectReasons;
  },

  set courseId(value: number) {
    this._courseId = value;
  },

  get courseId(): number {
    return this._courseId;
  },

  set inviteStatus(value: string) {
    this._inviteStatus = value;
  },

  get inviteStatus(): string {
    return this._inviteStatus;
  },

  count(): number {
    return this._invites.length;
  },

  clear(): void {
    this._invites = [] as AdminUserInviteType[];
    this._rejectReasons = [] as OptionType[];
    this._courseId = 0;
    this._inviteStatus = STATUS_PENDING;
  }
});

