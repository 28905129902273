import {AxiosError} from 'axios';
import {defaultErrorsInfo, paramsToFormData} from "../../../../../utils";
import {createCourseMaterialStore} from "../../stores";
import {createCourseMaterialService} from "../../services";
import {openConfirmModal} from '../modals'

export const onSubmitCreateCourseMaterial = async () => {
  try {
    const {title, file} = createCourseMaterialStore;
    if(!file) return defaultErrorsInfo(101, 'Please add file to your publication')
    // const params = {title, file};
    const formData = new FormData();
    formData.append('course_material[title]', title);
    formData.append('course_material[file]', file);

    await createCourseMaterialService.createCourseMaterial(formData)

    createCourseMaterialStore.file = null
    createCourseMaterialStore.title = ''

    openConfirmModal();
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
}