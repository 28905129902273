import React from "react"
import {Layout} from "../Layout";
import {WebLeftComponent} from "components";
import {LoginForm} from "./components/LoginForm";

export const Login = () => {
  return (
    <Layout>
      <WebLeftComponent />
      <LoginForm />
    </Layout>
  );
};