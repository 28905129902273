import {AdminUserInviteType} from "types";
import {cloneDeep} from "lodash";
import {mapFromOneUser} from "modules/admin/SuperHosts/utils";
import {mapFromOneCourse} from "modules/admin/Courses/utils";

export const mapFromOneInvite = (data: any): AdminUserInviteType => {
  const clone = cloneDeep(data);

  const {
    id, reject_reason, requested_by, status, user, course
  } = clone;

  return {
    id,
    reject_reason,
    requested_by,
    status,
    user: mapFromOneUser(user),
    course: mapFromOneCourse(course),
  }
}

