import {toast} from "react-toastify";
import {showError500, validateBE} from "utils";
import {errorsStore} from "stores";
import { newCourseMaterialServices } from "../services";

export const approveCourseMaterial = async (id:Number, callBack: Function) => {
  const errors: any[] = [];

  if (errors.length > 0) {
    errorsStore.errors = errors;
    return;
  }

  const formData = new FormData()

  const {status, data} = await newCourseMaterialServices.approveCourseMaterial(id, formData)

  if (status === 401 || status === 403 || status === 400) {
    validateBE(data.error_messages, []);
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  toast.success(`Approved`);
  callBack();
}