import validate from "validate.js";
import { ErrorType, WebinarType } from "types";
import { WebinarConstraints } from "../constants";

export const validateWebinar = (webinar: WebinarType): ErrorType[] => {
  let errorKeys: string[] = [];
  let validateErrors = {} as any;
  let errors: ErrorType[] = [];

  validateErrors = validate(webinar, WebinarConstraints);
  errorKeys = Object.keys(validateErrors || {});
  errors = errorKeys.map((key: string) => ({
    name: key,
    value: validateErrors[key],
  }));

  return errors;
};
