import React from "react"
import {ADMIN_ROUTES} from "constants/routes";
import {generatePath, NavLink, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {isActiveClass} from "utils";

export const WebinarInfo = observer(() => {
  const {id, webinarId} = useParams();
  const getLink = () => generatePath(ADMIN_ROUTES.webinar, {id, webinarId});

  return (
    <NavLink to={getLink()} className={isActiveClass}>
      <button className="all-courses active">Webinar info</button>
    </NavLink>
  )
})