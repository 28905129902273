import React from "react"
import { PageSideBar } from "modules/admin/PageSideBar"
import { List } from './components/List'
import { CourseMaterialsNavBar } from './components/CourseMaterialsNavBar'
import { AddNewCourseMaterialModal } from "./components/AddNewCourseMaterialModal"
import { EditCourseMaterialModal } from "./components/EditCourseMaterialModal"
import { DeleteCourseMaterialModal } from "./components/DeleteCourseMaterialModal"

export const CourseMaterials = () => {
  return (
    <PageSideBar className='display-block'>
      <AddNewCourseMaterialModal />
      <EditCourseMaterialModal />
      <DeleteCourseMaterialModal />
      <CourseMaterialsNavBar />
      <List scope='' />
    </PageSideBar>
  )
}