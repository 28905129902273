import { cloneDeep } from "lodash";
import { addWebinarStore } from "../../stores";

export const addSpeaker = () => {
  const _webinar = cloneDeep(addWebinarStore.webinar);

  if (Array.isArray(_webinar.speakers_attributes))
    _webinar.speakers_attributes.push({
      id: _webinar.speakers_attributes.length + 1,
      name: "",
      location: "",
      speaker_type: "",
      avatar: "",
    });
  else
    _webinar.speakers_attributes = [
      {
        id: 1,
        name: "",
        location: "",
        speaker_type: "",
        avatar: "",
      },
    ];
  addWebinarStore.webinar = _webinar;
};
