import React from "react"
import {List} from "./components/List";
import {NavBar} from "./components/NavBar";
import {UsersNavBar} from "modules/admin/UsersNavBar";
import {PageSideBar} from "modules/admin/PageSideBar";
import {STATUS_PENDING} from "constants/inviteStatuses";
import {ChangeStatusModal} from "./components";

export const RequestsPending = () => {
  return (
    <PageSideBar className='display-block'>
      <UsersNavBar showButton='requests' />
      <NavBar status={STATUS_PENDING} />
      <List status={STATUS_PENDING} />
      <ChangeStatusModal />
    </PageSideBar>
  )
}