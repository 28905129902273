import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {observer} from 'mobx-react-lite';
import {
  SelectField,
  FormInput,
  ButtonRound,
  RadioButtonsGroup,
  FormCheckbox,
  AvatarField,
} from 'components';
import {InputChangeProps} from 'types';
import {
  ROUTES,
  USER_TYPES,
  USER_TITLES,
  ACCREDITATION_TYPES,
  COUNTIES,
  COUNTRIES,
  ACCOUNT_LABELS,
} from '../../../../constants';
import {mount, unmount} from '../actions/gets'
import {changeUserField, updateUser} from '../actions/sets'
import {editUserStore} from '../stores';
import defaultAvatar from 'assets/img/png/user_avatar.png';
import {getFieldIMC, getImageUrl, getLabelIMC} from 'utils';
import {getCountryValue, getCountyValue, getFields} from '../utils';

export const SettingsForm = observer(() => {
  const [avatar, setAvatar] = useState('');
  const navigate = useNavigate();
  const {user, updatingUser} = editUserStore;

  const onFieldChange = (data: InputChangeProps) => {
    changeUserField(data);
  };
  const handleSubmitForm = () => {
    updateUser(() => {
      navigate(ROUTES.webinars);
    });
  };
  const changeAvatar = (file: any) => {
    const avatarUrl = URL.createObjectURL(file);

    setAvatar(avatarUrl);
    changeUserField({name: 'avatar_file', value: file});
  };
  const isAccredited = (): boolean => {
    const isBoolean = typeof user.accreditation === 'boolean';
    return isBoolean ? !!user.accreditation : user.accreditation === 'true';
  };

  const avatarUrl = getImageUrl(user.avatar_url);
  const fields = getFields(user.user_type, user.accreditation);

  React.useEffect(() => {
    mount();

    return () => unmount();
  }, []);

  return user.id ? (
    <div className="signin__block page__container-profile">
      <div className="signin__container page__content-profile">
        <div className="page__title">
          <div className="page__title-profile">
            <h1>Your Profile</h1>
            <img className="settings_avatar-image" src={avatar || avatarUrl || defaultAvatar} alt="" />
            <AvatarField
              accept='.jpg, .jpeg, .png'
              onChange={changeAvatar}
              name='avatar_file'
              label="Change the Photo"
              maxFileSize={1024}
              acceptInfo=''
            />
            <p>PNG or JPEG</p>
          </div>
        </div>
        <div className="signin__content profile-content">
          <div className="containers">
            <form className='profile'>
              <SelectField
                label={ACCOUNT_LABELS['title']}
                name='title'
                value={user.title || ''}
                options={USER_TITLES}
                onChange={onFieldChange}
              />
              <div className="row">
                <FormInput
                  name='first_name'
                  label={ACCOUNT_LABELS['first_name']}
                  value={user.first_name}
                  onChange={onFieldChange}
                />
                <FormInput
                  name='last_name'
                  label={ACCOUNT_LABELS['last_name']}
                  value={user.last_name}
                  onChange={onFieldChange}
                />
              </div>
              <FormInput
                name='email'
                label={ACCOUNT_LABELS['email']}
                value={user.email || ''}
                onChange={onFieldChange}
                disabled
              />
              <SelectField
                label={ACCOUNT_LABELS['user_type']}
                name='user_type'
                value={user.user_type}
                options={USER_TYPES}
                onChange={onFieldChange}
              />
              <FormInput
                name='discipline'
                label={ACCOUNT_LABELS['discipline']}
                value={user.discipline || ''}
                onChange={onFieldChange}
              />
              <FormInput
                name={getFieldIMC({accreditation: user.accreditation, user_type: user.user_type})}
                label={getLabelIMC({accreditation: user.accreditation, user_type: user.user_type})}
                value={user[getFieldIMC({accreditation: user.accreditation, user_type: user.user_type})] || ''}
                onChange={onFieldChange}
              />
              {isAccredited() && fields.map(field => (
                <FormInput
                  key={`setting_${field}`}
                  name={field}
                  label={ACCOUNT_LABELS[field]}
                  value={user[field] || ''}
                  onChange={onFieldChange}
                />
              ))}
              <RadioButtonsGroup
                name='accreditation'
                label={ACCOUNT_LABELS['accreditation']}
                value={user.accreditation}
                onChange={onFieldChange}
                options={ACCREDITATION_TYPES}
              />
              {!isAccredited() && (
                <SelectField
                  label={ACCOUNT_LABELS['country']}
                  name='country'
                  value={user.country || Number(user.country) === 0 ? getCountryValue(user.country) : ''}
                  options={COUNTRIES}
                  onChange={onFieldChange}
                />
              )}
              {isAccredited() && (
                <SelectField
                  label={ACCOUNT_LABELS['county']}
                  name='county'
                  value={user.county || Number(user.county) === 0 ? getCountyValue(user.county) : ''}
                  options={COUNTIES}
                  onChange={onFieldChange}
                />
              )}
              <FormInput
                name='location'
                label={ACCOUNT_LABELS['location']}
                value={user.location || ''}
                onChange={onFieldChange}
                type='tel'
              />
              <FormInput
                name='phone'
                label={ACCOUNT_LABELS['phone']}
                value={user.phone || ''}
                onChange={onFieldChange}
                type='tel'
              />
              <FormCheckbox
                name="is_allow_email_notifications"
                label="Send webinar reminders via email"
                value={!!user.is_allow_email_notifications}
                onChange={onFieldChange}
              />
            </form>
          </div>
          <ButtonRound
            onClick={handleSubmitForm}
            disabled={updatingUser}
            className="auth_rounded_button">
              Update
          </ButtonRound>
        </div>
      </div>
    </div>
  ) : null;
});
