import React from "react"
import moment from "moment"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { CourseMaterialType } from "types"
import { DATE_FORMATS } from '../../../../../constants'
import EditIcon from "assets/img/png/edit_icon.png"
import DeleteIcon from "assets/img/png/trashcan.png"
// import { openDeleteModal } from "../../actions/modals"
// import { addCheckedId, removeCheckedId } from "../../actions"
import { ADMIN_ROUTES } from "constants/routes"
import { approveCourseMaterial } from "../../actions/approveCourseMaterial"
import { rejectCourseMaterial } from "../../actions/rejectCourseMaterial"
import { addCourseMaterialModalStore } from "../../stores"

type Props = {
  courseMaterial: CourseMaterialType
  scope: string | undefined
}

export const ListItem = (props: Props) => {
  let navigate = useNavigate()
  let params = useParams()

  const {
    id,
    title,
    file,
    created_at,
    created_by,
    file_url
  } = props.courseMaterial

  // const onClickCheckBox = (event: any) => {
  //   const checked = Boolean(event.target.checked)
  //   checked ? addCheckedId(id) : removeCheckedId(id)
  // }

  const handleOpenDeleteModal = () => {addCourseMaterialModalStore.openDelete = id}
  const handleOpenEditModal = () => {addCourseMaterialModalStore.openEdit = id; addCourseMaterialModalStore.courseMaterial = {...props.courseMaterial, course_id: null}}
  const redirectCourseMaterialPage = () => navigate(generatePath(ADMIN_ROUTES.courseMaterial, {id: params.id, courseMaterialId: id}))
  const handleApprove = () => {
    approveCourseMaterial(id, () => {})
  }
  const handleReject = () => {
    rejectCourseMaterial(id, 'rejected', () => {})
  }
  const handleViewFile = () => {
    if(file_url) window.open(process.env.REACT_APP_API_URL + file_url, '_blank')
  }

  return (
    <div className="content-line-users">
      <div className="content-desc">
        <div className="user-name webinars">
          <p>{title}</p>
        </div>
        <div className="user-name webinars" onClick={redirectCourseMaterialPage}>
          <p>{created_by}</p>
        </div>
        <div className="user-name webinars">
          <p>{moment(created_at).format(DATE_FORMATS.webinarDate)}</p>
        </div>
      </div>
       {props.scope ? props.scope === 'pending' ? <div className="user-status">
          <button className='approve-btn' onClick={handleApprove}>APPROVE</button>
          <button className='reject-btn' onClick={handleReject}>REJECT</button>
        </div>
       : <></> :<div className="content-options">
          <div className="edit" onClick={handleOpenEditModal}>
            <img src={EditIcon} alt="" />
          </div>
          <div className="delete" onClick={handleOpenDeleteModal}>
            <img src={DeleteIcon} alt="" />
          </div>
          <button onClick={handleViewFile} disabled={!!!file_url} className="view-btn">{file_url ? 'View File' : 'No File'}</button>
        </div>}
    </div>
  )
}