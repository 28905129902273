import React from "react"
import {observer} from "mobx-react-lite";
import CloseIcon from "assets/img/png/ic-close.png";
import {addModalStore, addSponsorStore} from "../stores";
import {ImageField, InputTextField} from "components/form";
import {changeField, mount, onSubmitAddSponsor, unmount} from "../actions/add";
import {closeAddNewSponsorModal} from "../actions/modals";
import {onSubmitEditSponsor} from "../actions/edit";
import {getErrors} from "utils";

export const AddModal = observer(() => {
  const {show} = addModalStore;
  const {id, title, namespace} = addSponsorStore.sponsor;

  const isEdit = id !== undefined
  const headerText = isEdit ? 'EDIT SPONSOR' : 'ADD NEW SPONSOR';
  const buttonText = isEdit ? 'Save Changes' : 'Create sponsor';

  React.useEffect(
    () => {
      mount();

      return unmount();
    },
    []
  );

  const onSubmit = () => isEdit ? onSubmitEditSponsor() : onSubmitAddSponsor();

  if (!show) {
    return <div/>
  }

  return (
    <div className="modal" style={{display: 'block'}}>
      <div className="modal-container-superhost">
        <span className="close">×</span>
        <div className="modal-content-create__sponsors">
          <div className="modal-content-create__headline">
            <img src={CloseIcon} alt="close modal button" onClick={closeAddNewSponsorModal}/>
          </div>
          <h3>{headerText}</h3>
          <div className="superhost-create__desc">
            <div className="superhost-inputs">

              <InputTextField
                type='modal'
                name='title'
                label='Title'
                placeholder=''
                value={title}
                error={getErrors('title')}
                onChange={changeField}
              />

              <InputTextField
                type='modal'
                name='namespace'
                label='Namespace'
                placeholder=''
                value={namespace}
                error={getErrors('namespace')}
                onChange={changeField}
              />

              <ImageField
                maxFileSize={1024}
                accept='.jpg, .jpeg, .png'
                acceptInfo='(JPG/JPEG/PNG)'
                label='Company Logo'
                name='logo'
                onChange={changeField}
              />
            </div>

            <div className="superhost-btns-create">
              <button className="cancel-btn-modal" onClick={closeAddNewSponsorModal}>Cancel</button>
              <button className="create-btn" onClick={onSubmit}>{buttonText}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})