import {adminUsersService} from "services";
import {showError500} from "utils";
import {cloneDeep} from "lodash";
import {adminAuthStore} from "stores";

export const getUserProfile = async () => {
  const {status, data} = await adminUsersService.getUserProfile();

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  const clone = cloneDeep(data);
  const user = clone.user.data && clone.user.data.attributes;

  adminAuthStore.user = user ? {...user} : {}
  adminAuthStore.auth = Boolean(user);
}