import React, { useEffect, useMemo } from "react";
import DeleteIcon from "assets/img/svg/delete_black_24dp.svg";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
  Accreditation,
  Avatar,
  Country,
  Discipline,
  Email,
  EmailConfirmedCB,
  FirstName,
  Imc,
  LastName,
  Location,
  Password,
  Phone,
  Title,
  UserType,
  County,
} from ".";

import { observer } from "mobx-react-lite";
import { ADMIN_ROUTES } from "constants/routes";
import { openDeleteModal } from "../../actions/modals";
import { addUserStore } from "../../stores";
import { onSubmitAddUser } from "../../actions/add";

export const AddUserForm = observer(() => {
  const { id, first_name, last_name, accreditation } = addUserStore.user;
  const isEdit = addUserStore.isEdit();

  let navigate = useNavigate();
  let params = useParams();

  const isEditDisplay = isEdit ? "block" : "none";
  const userName = isEdit ? first_name + " " + last_name : "Create User";

  const onSubmit = () => onSubmitAddUser(isEdit, pause, Number(params.id));

  const pause = () => setTimeout(redirectCoursesPage, 3000);
  const redirectCoursesPage = () =>
    navigate(generatePath(ADMIN_ROUTES.users, { id: params.id }));

  const onDelete = () => {
    openDeleteModal(id);
    pause();
  };

  useEffect(() => {
    return () => addUserStore.clear();
  }, []);

  const isAccredited = useMemo((): boolean => {
    const isBoolean = typeof accreditation === "boolean";
    return isBoolean ? !!accreditation : accreditation === "true";
  }, [accreditation]);

  return (
    <div>
      <div className="courses__header-users">
        <div className="header-desc">
          <span>USERS / </span>
          <span style={{ textTransform: "uppercase" }}>{userName}</span>
        </div>

        <button onClick={onDelete} style={{ display: isEditDisplay }}>
          <img src={DeleteIcon} alt="" />
        </button>
      </div>

      <Avatar />

      <div className="user-container">
        <div className="personal-info">
          <div className="user-block">
            <span>PERSONAL INFORMATION</span>

            <div className="user-fields">
              <Title isEdit={isEdit} />
              <FirstName />
              <LastName />
              {isEdit && <Phone />}
            </div>
          </div>
        </div>
        <div className="additional-info">
          <div className="user-block">
            <span>INFORMATION</span>
            <form className="user-fields">
              <UserType />
              {isEdit && <Discipline />}
              <Imc />
              <Accreditation />
              {!isAccredited ? (
                <Country isEdit={isEdit} />
              ) : (
                <County isEdit={isEdit} />
              )}
              <Location isEdit={isEdit} />
            </form>
          </div>
        </div>
        <div className="account-info">
          <div className="user-block">
            <span>ACCOUNT</span>
            <div className="user-fields">
              <Email />
              <Password />
              <EmailConfirmedCB />
            </div>
          </div>
        </div>
      </div>
      <div className="course-btns">
        <button className="add-new-course" onClick={redirectCoursesPage}>
          Cancel
        </button>
        <button className="add-new-course" onClick={onSubmit}>
          Save
        </button>
      </div>
    </div>
  );
});
