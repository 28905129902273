import {WebinarType} from 'types';
import {CpdStatus, CpdPoints} from '../constants';

const getPoints = (webinar: WebinarType): string => {
  switch(webinar.cpd_points) {
    case CpdPoints.internal:
      return `${webinar.int_cpd_points} Int`;
    case CpdPoints.external:
      return `${webinar.ext_cpd_points} Ext`;
    case CpdPoints.both:
      return `${webinar.int_cpd_points} Int, ${webinar.ext_cpd_points} Ext`;
    default:
      return '';
  }
};

export const getCpdPoints = (webinar: WebinarType): string => {
  switch(webinar.cpd_status) {
    case CpdStatus.not_applicable:
      return 'Not applicable in the cpd locker';
    case CpdStatus.applied_for:
      return 'Applied for';
    case CpdStatus.granted:
      return getPoints(webinar);
    default:
      return '';
  }
};
