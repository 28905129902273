import momentTimezon from 'moment-timezone';
import {defaultErrorsInfo} from '../../../../../utils';
import {editUserStore} from '../../stores';
import {editUserServices} from '../../services';
import {commonStore, currentUserStore} from 'stores';

export const updateUser = async (callback: Function) => {
  try {
    editUserStore.updatingUser = true;

    const {user} = editUserStore;
    const userKeys = Object.keys(user);
    const userFormData = new FormData();
    const timeZone = momentTimezon.tz.guess();

    let _userKeys = []

    for(let i in userKeys) {
      if(user[userKeys[i]]) {
        _userKeys.push(userKeys[i])
      }
    }

    console.log(_userKeys)

    userKeys.forEach((key: string) => {
      if (key === 'country' && !!user[key]) {
        console.log(key === 'country' && !!user[key])
      }
      if (key === 'avatar_file')
        userFormData.append(`user[avatar]`, user[key]);
      else if (key === 'time_zone')
        userFormData.append(`user[${key}]`, timeZone);
      else if (key === 'country' && !!user[key])
        userFormData.append(`user[${key}]`, user[key]);
      else if (key !== 'password' && key !== 'country')
        userFormData.append(`user[${key}]`, user[key] || '');
    });
    
    const {data} = await editUserServices.updateUser(userFormData, user.id);

    commonStore.token = data.token;
    currentUserStore.currentUser = data.user.data.attributes;
    editUserStore.updatingUser = false;
    callback();
  } catch (error: any) {
    editUserStore.updatingUser = false;

    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
}