import React, {useEffect} from 'react';
import {BrowserRouter} from "react-router-dom";
import {Routes as AppRoutes} from "./routes";
import {currentUserStore} from 'stores';
import {Notifications} from './modules/web/Notifications';

import 'assets/scss/main.scss';

const App: React.FC = () => {
  useEffect(() => {
    currentUserStore.mountUser();
  }, []);

  return (
    <BrowserRouter>
      <AppRoutes />
      <Notifications />
    </BrowserRouter>
  );
}

export default App;