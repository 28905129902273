import {makeAutoObservable} from 'mobx'
import {InputChangeProps, UserType} from "types"
import {cloneDeep} from "lodash";

export const adminAuthStore = makeAutoObservable({
  _auth: false,
  _user: {} as UserType,

  set user(value: UserType) {
    this._user = value;
  },

  get user(): UserType {
    return this._user
  },

  set auth(value: boolean) {
    this._auth = value;
  },

  get auth(): boolean {
    return this._auth
  },

  changeField(data: InputChangeProps) {
    const user = cloneDeep(this._user);
    user[data.name] = data.value;
    this._user = user;
  },

  clear() {
    this._user = {} as UserType;
  },

});

