import {makeAutoObservable} from 'mobx';

export const forgotPasswordStore = makeAutoObservable({
  _email: '',
  _inProgress: false,

  set email(value: string) {
    this._email = value;
  },

  get email(): string {
    return this._email;
  },

  set inProgress(value: boolean) {
    this._inProgress = value;
  },

  get inProgress(): boolean {
    return this._inProgress;
  },

  clear() {
    this._email = '';
  },
});