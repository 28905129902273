import React from "react"

export const ListHeader = () => {
  return (
    <div className="superhost__header">
      <div className="header-categories">
        <div className="name">
          <p>Name</p>
        </div>
        <div className="email">
          <p>Email</p>
        </div>
        <div className="course">
          <p>Course</p>
        </div>
      </div>
    </div>
  )
}