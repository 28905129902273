import {toast} from 'react-toastify';
import {defaultErrorsInfo} from "../../../../../utils";
import {courseService} from "../../services";
import {mapFromInvites} from '../../utils';
import {closeConfirmModal} from '../modals';

export const joinCourse = async (courseId: number) => {
  try {
    closeConfirmModal();
    const {data} = await courseService.joinCourse(courseId);

    const invite = mapFromInvites(data);
    toast.success('Request successfully sent.');
    console.log(invite);
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
};
