import {showError500} from "utils";
import {adminInviteService} from "services";
import {invitesStore} from "../../stores";
import {mapFromRejectReasons} from "../../utils";

export const getRejectReasons = async () => {
  const {status, data} = await adminInviteService.getRejectReasons();

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  invitesStore.rejectReasons = mapFromRejectReasons(data);
}