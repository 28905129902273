import React, { useState } from "react"
import { PageSideBar } from "modules/admin/PageSideBar"
import { List } from './components/List'
import { CourseMaterialsNavBar } from './components/CourseMaterialsNavBar'
import { AddNewCourseMaterialModal } from "./components/AddNewCourseMaterialModal"
import { CourseMaterialRequestsBtn } from "./components/CourseMaterialsNavBar/CourseMaterialRequestsBtn"
import { EditCourseMaterialModal } from "./components/EditCourseMaterialModal"
import { DeleteCourseMaterialModal } from "./components/DeleteCourseMaterialModal"

export const CourseMaterialsRequests = () => {
  const [scope, setScope] = useState('pending')

  const handleSetScope = (e: any) => {
    const { id } = e.target
    setScope(id)
  }

  return (
    <PageSideBar className='display-block'>
      <AddNewCourseMaterialModal scope={scope} />
      <EditCourseMaterialModal scope={scope} />
      <DeleteCourseMaterialModal scope={scope} />
      <CourseMaterialsNavBar />
      <CourseMaterialRequestsBtn setScope={handleSetScope} scope={scope} />
      <List scope={scope} />
    </PageSideBar>
  )
}