import React from "react"
import {observer} from "mobx-react-lite";
import {debounce, cloneDeep} from 'lodash';
import {webinarsStore} from "../../stores";
import {openDeleteSelectedModal} from "../../actions/modals";
import {getWebinars} from '../../actions/gets';
import {setMeta} from "../../actions/sets";

export const ListSearch = observer(() => {
  const {meta} = webinarsStore;
  const showDeleteButton = webinarsStore.checkedIds.length > 0;
  const display = showDeleteButton ? 'block' : 'none';

  const handleGetWebinars = (query: string) => {
    const _meta = cloneDeep(meta);
    _meta.page = 1;
    _meta.query = query;
    setMeta(_meta);
    getWebinars();
  };
  const onSearch = debounce((query: string) => handleGetWebinars(query), 500);

  return (
    <div className="search__container">
      <form className="chat">
        <button type="submit">Search</button>
        <input type="search" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} />
      </form>
      <div className="search__content">
        <p style={{display: display}} onClick={openDeleteSelectedModal}>Delete</p>
      </div>
    </div>
  )
})