export const USER_TITLES = [
  {value: 'dr', label: 'Dr'},
  {value: 'mr', label: 'Mr'},
  {value: 'ms', label: 'Ms'},
  {value: 'mrs', label: 'Mrs'},
  {value: 'prof', label: 'Prof'},
];

type USER_TITLES_SHOW = {
  [key: string]: string;
};

export const USER_TITLES_SHOW: USER_TITLES_SHOW = {
  dr: 'Dr',
  mr: 'Mr',
  ms: 'Ms',
  mrs: 'Mrs',
  prof: 'Prof',
};
