import { WebinarType } from "types";
import { cloneDeep } from "lodash";

const getFileName = (url: string, replaceString: string): string => {
  return url.replace(replaceString, "");
};

export const mapFromWebinarDetails = (data: any): WebinarType => {
  const clone = cloneDeep(data);
  const _webinar = clone.webinar.data.attributes;
  _webinar.cpd_quizzes_attributes = _webinar.cpd_quizzes.data.map(
    (quiz: any) => quiz.attributes
  );
  _webinar.speakers_attributes = _webinar.speakers.data.map((speaker: any) => ({
    ...speaker.attributes,
    avatar: speaker.attributes.avatar_url
      ? {
          name: getFileName(
            speaker.attributes.avatar_url,
            `/uploads/speaker/avatar/${speaker.attributes.id}/`
          ),
        }
      : null,
  }));
  _webinar.partner_ids = _webinar.partners.data.map((partner: any) => ({
    id: partner.attributes.id,
    title: partner.attributes.title,
  }));
  _webinar.sponsor_ids = _webinar.sponsors.data.map((sponsor: any) => ({
    id: sponsor.attributes.id,
    title: sponsor.attributes.title,
  }));
  _webinar.agenda = _webinar.agenda_url ? { name: _webinar.agenda_url } : null;
  _webinar.evaluation_feedback_summary =
    _webinar.evaluation_feedback_summary_url
      ? { name: _webinar.evaluation_feedback_summary_url }
      : null;

  _webinar.assigned_users = _webinar.assigned_users.data
    ? _webinar.assigned_users.data.map(({ attributes }: any) => ({
        ...attributes,
      }))
    : [];

  return _webinar;
};
