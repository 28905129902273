import React from 'react';
import {observer} from 'mobx-react-lite';
import {useNavigate, useParams} from 'react-router-dom';
import {Page} from '../Page';
import {ButtonRound, PageHeader} from 'components';
import {mount, unmount} from './actions/gets';
import {openQuizModal} from './actions/sets';
import {getCertificate} from '../CpdLocker/actions/gets';
import {webinarStore} from './stores';
import {Speakers} from './components';
import {getCpdPoints} from 'utils';
import {QuizModal} from './components';
import certIcon from 'assets/img/png/ic-drive-pdf-24-px.png';
import { ROUTES } from '../../../constants';

export const Webinar = observer(() => {
  const params = useParams();
  const navigate = useNavigate()
  const {webinar, inProgress} = webinarStore;
  const handleButtonClick = (): void => {
    if (!inProgress) {
      getCertificate(webinar.id);
    }
  };

  const handleSuccess = () => {
    const webinarId = params.webinarId || '';// window.location.pathname.replace('/webinars/', '');
    mount(webinarId);
  };

  const handleGoToReview = () => {
    const webinarId = params.webinarId || ''
    if(!webinarId) return
    navigate(ROUTES.goToWebinarReview(webinarId))
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const webinarId = params.webinarId || '';// window.location.pathname.replace('/webinars/', '');

    mount(webinarId);

    return unmount();
  }, []);

  return inProgress ? null : (
    <Page className="auth_page_wrapper">
      <PageHeader
        title={webinar.webinar_title}
      />
      <div className='container-webinar'>
        <div className='container-player'>
          <iframe src={`https://player.vimeo.com/video/${(webinar.vimeo_url || '').replace('/', '?h=')}`} width="920" height="480" frameBorder="0" allowFullScreen></iframe>
        </div>
        <div className='webinar__info-container'>
          {/* <h1 className='webinar-title'>{webinar.webinar_title}</h1> */}
          <div className='webinar__speakers-feedback-container'>
            {webinar.speakers && (
              <Speakers speakersData={webinar.speakers} />
            )}
            <div className='webinar__speakers-feedback-devider' />
            {/* <div className='webinar__feedback-container'>
              <h2 className='webinar__feedback-title'>Leave Feedback</h2>
              <p className='webinar__feedback-subtitle'>We will be grateful if you leave feedback on this webinar.</p>
              <ButtonRound className='webinar__feedback-button' onClick={() => {}}>
                Leave feedback
              </ButtonRound>
            </div> */}
          </div>
          <div className="webinar__info-feedback">
            <p>LEAVE FEEDBACK</p>
            <span>We will be grateful if you leave feedback on this webinar.</span>
            <ButtonRound onClick={handleGoToReview}>Leave feedback</ButtonRound>
          </div>
          <div className='webinar__info-bottom-content'>
            {webinar.is_allow_to_get_sertificate ? (
              <div className="download-certificate" onClick={handleButtonClick}>
                <img src={certIcon} alt="" />
                <p>Download certificate</p>
              </div>
            ) : (webinar.cpd_quiz === 'available' && !webinar.is_quiz_completed) && (
              <ButtonRound className='webinar__quiz-button' onClick={openQuizModal}>
                Take cpd quiz
              </ButtonRound>
            )}
            <div className='webinar__info-bottom-rigth-content'>
              <span className='webinar__info-bottom-cpd-points'>CPD Points:</span>
              <span className='webinar__info-bottom-cpd-points'>{getCpdPoints(webinar)}</span>
            </div>
          </div>
        </div>
        <QuizModal submitCallback={handleSuccess} />
      </div>
    </Page>
  )
});
