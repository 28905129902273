import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageSideBar } from "modules/admin/PageSideBar";
import { AddWebinarForm } from "./components/Add";
import { AssignedUsersModal } from "./components/AssignedUsersModal";
import { unmount } from "./actions/add";
import { createWebinar } from "./actions";
import { initCustomValidate } from "./utils";
import { addWebinarStore } from "./stores";
import { WebinarType } from "@/types";
import { errorsStore } from "stores";

export const AddWebinar = () => {
  const navigate = useNavigate();
  const params = useParams();
  const pause = () => setTimeout(redirectWebinarsPage, 3000);
  const redirectWebinarsPage = () => {
    unmount();
    navigate(-1);
  };
  const goBack = () => {
    redirectWebinarsPage();
  };
  const handleSaveWebinar = () => {
    createWebinar(Number(params.id), pause);
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    initCustomValidate();
    addWebinarStore._webinar = {
      start_date: new Date(),
      end_date: new Date(),
      status: "unpublished",
    } as WebinarType;

    return () => {
      addWebinarStore.clear();
      errorsStore.clear();
    };
  }, [addWebinarStore, errorsStore]);

  return (
    <PageSideBar className="add-webinar display-block">
      <h1>Create Webinar</h1>
      <AddWebinarForm />
      <div className="course-btns">
        <button className="add-new-course" onClick={goBack}>
          Cancel
        </button>
        <button className="add-new-course" onClick={handleSaveWebinar}>
          Save
        </button>
      </div>
      <AssignedUsersModal />
    </PageSideBar>
  );
};
