import {makeAutoObservable} from 'mobx'
import {MemberType} from "types";
import {MembersMetaType} from '../types';
import {MEMBERS_META} from '../constants';

export const membersStore = makeAutoObservable({
  _members: [] as MemberType[],
  _meta: MEMBERS_META as MembersMetaType,

  set members(value: MemberType[]) {
    this._members = value;
  },

  get members(): MemberType[] {
    return this._members;
  },

  set meta(meta: MembersMetaType) {
    this._meta = meta;
  },

  get meta(): MembersMetaType {
    return this._meta;
  },

  clear(): void {
    this._members = [] as MemberType[];
  }
});

