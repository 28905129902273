import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {WebinarType} from 'types';
import {getImageUrl, mapFromSponsors, mapFromSpeakers, getCpdPoints} from 'utils';
import {ButtonRound} from 'components';
import {DATE_FORMATS, navigateToWebinar} from '../../../../constants';
import {subscribeWebinar, registerWebinar} from '../actions/sets';
import {SCOPE_VALUES} from '../constants';
import clockIcon from 'assets/img/svg/clock.svg';
import calendarIcon from 'assets/img/svg/calendar.svg';
import leftArrow from 'assets/img/svg/arrow_left.svg'
import rightArrow from 'assets/img/svg/arrow_right.svg'
// import {setCourse} from '../actions/sets';

type Item = {
  item: WebinarType;
  key: string;
  selectetScope: string;
  inProgress: boolean;
};

export const Item = (props: Item) => {
  const navigate = useNavigate();
  const {item, selectetScope, inProgress} = props;
  const scroll = useRef(null as any)

  const getButtonTitle = (): string => {
    switch(selectetScope) {
      case SCOPE_VALUES.current:
        return 'Join Webinar';
      case SCOPE_VALUES.upcoming:
        return item.is_register_interes ? 'Registered' : 'Register interest';
      case SCOPE_VALUES.recorded:
        return 'Open Webinar';
      default:
        return '';
    }
  };
  const getName = (name: string): string => {
    let _name = ''
    let splitted = name.split(' ')
    if(splitted.length > 0) for(let i in splitted) {
      _name = `${_name}${splitted[i][0]}`
    }
    else _name = name[0]
    return _name.slice(_name.length - 2, _name.length + 1)?.toUpperCase()
  }
  const bgColor = useMemo((): string => {
    return `#${Math.floor(Math.random()*16777215).toString(16)}`
  }, [])
  const handleButtonClick = (): void => {
    switch(selectetScope) {
      case SCOPE_VALUES.current:
        subscribeWebinar(item.id);
        break;
      case SCOPE_VALUES.upcoming:
        registerWebinar(item.id);
        break;
      case SCOPE_VALUES.recorded:
        navigate(navigateToWebinar(item.id));
        break;
      default:
        break;
    }
  };
  const isDisabled = (): boolean => {
    switch(selectetScope) {
      case SCOPE_VALUES.current:
      case SCOPE_VALUES.recorded:
        return false
      case SCOPE_VALUES.upcoming:
        return item.is_register_interes;
      default:
        return false;
    }
  };
  const speakers = mapFromSpeakers(item.speakers);
  const sponsors = mapFromSponsors(item.sponsors);
  const partners = mapFromSponsors(item.partners);

  const [scrollLeft, setScrollLeft] = useState(0 as number)
  const scrollBack = () => {
    const el = document.getElementsByClassName('client-content')[0] as HTMLElement
    setScrollLeft((prev: number) => prev - el.offsetWidth - 15)
  }
  const scrollForward = () => {
    const el = document.getElementsByClassName('client-content')[0] as HTMLElement
    setScrollLeft((prev: number) => prev + el.offsetWidth + 15)
  }

  useEffect(() => {
    if(scroll.current) {
      scroll.current.scrollLeft = scrollLeft
    }
  }, [scrollLeft])
  const showForward = useMemo(() => {
    return scroll.current ? ((scroll.current.scrollWidth > scroll.current.offsetWidth) && (scrollLeft < (scroll.current.scrollWidth - scroll.current.offsetWidth))) : speakers.length > 3
  }, [scrollLeft, scroll, speakers])

  return (
    <div className="academy__block-current">
      <div className="academy__content-current">
        <div className="academy-title">
          <div className="academy-calendar">
            <img src={calendarIcon} alt="" />
            <p>{moment(item.start_date).format(DATE_FORMATS.webinarDate)}</p>
            <img src={clockIcon} alt="" />
            <p>{moment(item.start_date).format(DATE_FORMATS.webinarTime)}</p>
          </div>
          <p>{`CPD Points: ${getCpdPoints(item)}`}</p>
        </div>
        <div className="academy-desc">
          <span>{item.webinar_title}</span>
        </div>
        <ButtonRound
          className="register"
          disabled={inProgress || isDisabled()}
          onClick={handleButtonClick}>
          {getButtonTitle()}
        </ButtonRound>
        <div className="split-line" />
        <div className="client-container-wrapper">
          {scrollLeft > 0 && <div className='back-scroll' onClick={scrollBack}><img src={leftArrow} alt='<' /></div>}
          <div className="client-container" ref={scroll}>
          {speakers.map(speaker => (
            <div key={speaker.id} className="client-content">
              {speaker.avatar_url ? <img src={getImageUrl(speaker.avatar_url)} alt={speaker.name} />
              : <div className='img_avatar' style={{ background: bgColor }}>{getName(speaker.name)}</div>}
              <p>{speaker.name}</p>
              {speaker.presentation_title && <p>{speaker.presentation_title}</p>}
              <span>{`${speaker.speaker_type}・${speaker.location}`}</span>
            </div>
          ))}
          </div>
          {showForward && <div className='forward-scroll' onClick={scrollForward}><img src={rightArrow} alt='>' /></div>}
          </div>
        <div className="split-line" />
        <div className="sponsors-container">
          {sponsors.length > 0 && (
            <div className="sponsors-content">
              <p>Organised by:</p>
              <div className='sponsors-wrapper'>
                {sponsors.map(sponsor => (
                  <img key={sponsor.id} src={getImageUrl(sponsor.logo_url)} alt={sponsor.title} />
                ))}
              </div>
            </div>
          )}
          {partners.length > 0 && (
            <div className="sponsors-content">
              <p>In partnership with:</p>
              <div className='sponsors-wrapper'>
                {partners.map(partner => (
                  <img key={partner.id} src={getImageUrl(partner.logo_url)} alt={partner.title} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
