import React from "react";
import { Page } from "../Page";

export const NovoNordiskTerms = () => {
  return (
    <Page>
      <div className="terms novo-terms">
        <div className="terms-list">
          <h1>Novo Nordisk Terms and Conditions</h1>
          <p>
            If you would like further information or support in registering for
            this programme, please email:{" "}
            <a href="mailto:academysupport@medcafe.ie" target="_blank">
              academysupport@medcafe.ie
            </a>
          </p>
          <p>
            <strong>
              The Obesity Primary Care Academy is a medical educational
              programme organised and funded by Novo Nordisk Limited.
            </strong>
          </p>
          <p>
            Novo Nordisk & MedCafe must process the Personal Data you provide at
            registration.
          </p>
          <p>
            Please read the Novo Nordisk{" "}
            <a
              href="https://www.novonordisk.ie/content/dam/nncorp/ie/en/pdfs/data-processing-attendance-organised-or-sponsored-meeting.pdf"
              target="_blank"
            >
              NOTICE OF PERSONAL DATA PROCESSING
            </a>
            , to understand how your data is processed
          </p>
          <p>
            Please read the MedCafe Privacy Policy here:{" "}
            <a href="https://www.medcafe.ie/privacy_policy" target="_blank">
              https://www.medcafe.ie/privacy_policy
            </a>
          </p>
          <p>
            By registering for this programme, you confirm that you have read
            the Novo Nordisk Notice of Data Processing Policy & the MedCafe
            Privacy Policy and consent to the processing of your Personal Data
            for the purpose of organising this programme.
          </p>
          <p>
            External speakers and chairpersons will receive financial
            remuneration from Novo Nordisk Limited.
          </p>
          <p>
            Arrangements for this programme comply with the provisions of the
            Irish Pharmaceutical Healthcare Association Code of Practice for the
            Pharmaceutical Industry and the Novo Nordisk Business Ethics Code of
            Conduct.
          </p>
          <p>IE24OB00131;</p>
          <p>February 2024</p>
          <p>
            The Apis bull logo is a registered trademark owned by Novo Nordisk
            A/S
          </p>
          <p>
            Novo Nordisk Limited, First Floor, Block A, The Crescent Building,
            Northwood Business Park, Santry, Dublin 9, Ireland
          </p>
          <p>
            Phone: <a href="tel:+353 1 862 9700">+353 1 862 9700</a>
          </p>
          <p>Fax: +353 1 862 9725</p>
          <p>
            Mail:{" "}
            <a href="infoireland@novonordisk.com" target="_blank">
              infoireland@novonordisk.com
            </a>
          </p>
          <p>
            <a href="www.novonordisk.ie" target="_blank">
              www.novonordisk.ie
            </a>
          </p>
          <p>
            <a
              href="https://www.novonordisk.ie/content/dam/nncorp/ie/en/pdfs/data-processing-attendance-organised-or-sponsored-meeting.pdf"
              target="_blank"
            >
              Notice of Personal Data Processing
            </a>
          </p>
          <p>
            <a
              href="https://www.novonordisk.ie/contact-us.html"
              target="_blank"
            >
              Contact Us
            </a>
          </p>
          <p>© {new Date().getFullYear()} Novo Nordisk</p>
        </div>
      </div>
    </Page>
  );
};
