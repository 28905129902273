import { API_ADMIN_STATISTICS } from "constants/api"
import { StatisticsMetaType } from 'modules/admin/Statistics/types'
import { getRequestAuth } from "utils/requests"

const getStatistics = async (meta: StatisticsMetaType) => await getRequestAuth(API_ADMIN_STATISTICS, meta);

const getDetailStats = async (id: number, statistic_type: string) => await getRequestAuth(`${API_ADMIN_STATISTICS}/${id}?statistic_type=${statistic_type}`);

const getDetailStatsExport = async (id: number, statistic_type: string) => await getRequestAuth(`${API_ADMIN_STATISTICS}/export/${id}?statistic_type=${statistic_type}`);

export const adminStatisticsService = {
  getStatistics,
  getDetailStats,
  getDetailStatsExport
}