import React from "react"
import {observer} from "mobx-react-lite";
import {infoModalStore} from "../../Courses/stores";
import {closeInfoModal} from "../actions/modals";
import CloseIcon from "../../../../assets/img/png/ic-close.png";

export const InfoModal = observer(() => {
  const {show} = infoModalStore;
  const {title, color_theme, users, superhost, created_at} = infoModalStore.course;
  const noSuperHost = 'Superhost not created';

  const getFullName = () => superhost && superhost.first_name && superhost.last_name ? superhost.first_name + ' ' + superhost.last_name: noSuperHost;
  const getEmail = () => superhost && superhost.email? superhost.email : noSuperHost;

  if (!show) {
    return <div />
  }

  return (
    <div className="modal" style={{display: 'block'}}>
      <div className="modal-container-academy">
        <span className="close">×</span>
        <div className="modal-content-create__academy">
          <div className="modal-content-academy__headline">
            <img src={CloseIcon} alt="close modal button" onClick={closeInfoModal} />
          </div>
          <div className="academy-list">
            <h3>{title}</h3>
            <div className="academy-specs">
              <p>Amount of users: <b>{users}</b></p>
            </div>
            <div className="academy-specs">
              <p>Superhost name: <b>{getFullName()}</b></p>
            </div>
            <div className="academy-specs">
              <p>Superhost email: <b>{getEmail()}</b></p>
            </div>
            <div className="academy-specs">
              <p>Color theme: <b>{color_theme}</b></p>
            </div>
            <div className="academy-specs">
              <p>Date of Registration: <b>{created_at}</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})