import { showError500, validateBE } from "utils";
import { addSuperHostStore } from "../../stores";
import { adminUsersService } from "services";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import { errorsStore } from "stores";
import { pauseModalAutoClose } from "../";

export const onSubmitEditSuperHost = async () => {
  errorsStore.clear();
  const formData = cloneDeep(addSuperHostStore.superHost);
  const id = formData.id;

  // TODO 1 add validation
  // validate js
  const isValid = true;

  if (!isValid) {
    return false;
  }

  const params = { user: formData };
  const { status, data } = await adminUsersService.editUser(params, id!);

  if (status === 401 || status === 403 || status === 400) {
    validateBE(data.error_messages, [
      "company_name",
      "email",
      "first_name",
      "last_name",
      "password",
      "course_id",
    ]);
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  toast.success(`SuperHost was edited successfully... auto close`);

  pauseModalAutoClose();
};
