import {localStore} from "../../stores";

export const addAuthTokens = (params?:object) => {
    const token: string = localStore.token;

    if (params) {
        return {headers: {'Authorization': `Bearer ${token}`}, params: {...params}}
    }

    return {headers: {'Authorization': `Bearer ${token}`}}
}