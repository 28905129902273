import React from "react"
import {observer} from "mobx-react-lite";
import {confirmModalStore} from "../stores";
import {closeConfirmModal} from "../actions/modals";
import {joinCourse} from '../actions/add';
import CloseIcon from "../../../../assets/img/png/ic-close.png";
import {ButtonRound} from 'components';

export const ConfirmModal = observer(() => {
  const {show, courseId} = confirmModalStore;

  if (!show) {
    return <div />
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-content-close_wrapper">
          <img src={CloseIcon} alt="close modal button" onClick={closeConfirmModal} />
        </div>
        <h3>SEND REQUEST</h3>
        <p className="modal-content_confirm_text">If you want to join this Academy, send an application and our manager will consider your application.</p>
        <ButtonRound
          onClick={() => joinCourse(courseId)}
          className='open-academy'
        >
          Send Request
        </ButtonRound>
      </div>
    </div>
  )
})