import { makeAutoObservable } from "mobx";
import { WebinarType } from "types";

export const addWebinarStore = makeAutoObservable({
  _webinar: {} as WebinarType,

  set webinar(value: WebinarType) {
    this._webinar = value;
  },

  get webinar(): WebinarType {
    return this._webinar;
  },

  clear(): void {
    this._webinar = {} as WebinarType;
  },
});
