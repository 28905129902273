import {makeAutoObservable} from 'mobx'

export const changeStatusModalStore = makeAutoObservable({
  _show: false,
  _inviteId: 0,
  rejectReason: -1,

  set show(value: boolean) {
    this._show = value;
  },

  get show(): boolean {
    return this._show;
  },

  set inviteId(value: number) {
    this._inviteId = value;
  },

  get inviteId(): number {
    return this._inviteId;
  },

  set status(value: number) {
    this.rejectReason = value;
  },

  get status(): number {
    return this.rejectReason;
  },

  clear():void {
    this._show = false;
    this._inviteId = 0;
    this.rejectReason = -1;
  },

});

