import {MemberType} from "types";
import {cloneDeep} from "lodash";

export const mapFromMembers = (data: any): MemberType[] => {
  const clone = cloneDeep(data);
  const _members = clone.users.data;
  const hasData = _members && _members.length > 0;

  return hasData ? _members.map((item: any) => ({...item.attributes})) : [];
}
