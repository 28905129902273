import React, {ChangeEvent} from "react"
import {Link} from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {ROUTES} from '../../constants';
import {CheckboxType} from "../../types";

export const FormCheckbox = (props: CheckboxType) => {
  const {name, label, value, onChange} = props;
  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    onChange({name: event.target.name, value: checked});
  };
  return (
    <div className="group">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              onChange={handleCheckbox}
              checked={value}
            />
          }
          label={<Link to={ROUTES.terms}>{label}</Link>}
        />
      </FormGroup>
    </div>
  );
};
