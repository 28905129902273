import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { getUsers } from "../Users/actions/gets";
import { PageSideBar } from "modules/admin/PageSideBar";

import { AddWebinarForm } from "./components/Add";
import { AssignedUsersModal } from "./components/AssignedUsersModal";

import { getWebinarDetails } from "./actions/gets";
import { unmount } from "./actions/add";
import { updateWebinar } from "./actions";

import { initCustomValidate } from "./utils";
import { addWebinarStore, assignedUsersModalStore } from "./stores";
import { setAssignedUsers } from "./actions/sets";
import { usersStore } from "../Users/stores";
import { setMeta } from "../Users/actions/sets";
import { cloneDeep } from "lodash";

export const EditWebinar = observer(() => {
  const { webinar } = addWebinarStore;
  const { users, meta } = usersStore;
  const { assignedUsers } = assignedUsersModalStore;
  const params = useParams();
  const navigate = useNavigate();
  const pause = () => setTimeout(redirectWebinarsPage, 3000);
  const redirectWebinarsPage = () => {
    unmount();
    navigate(-1);
  };
  const goBack = () => {
    redirectWebinarsPage();
  };
  const handleUpdateWebinar = () => {
    updateWebinar(Number(params.id), pause);
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    console.log(webinar.id, Number(params.webinarId));
    if (webinar.id !== Number(params.webinarId)) {
      getWebinarDetails(Number(params.webinarId));
      initCustomValidate();
    }
  }, [params.webinarId, webinar.id]);

  return (
    <PageSideBar className="add-webinar display-block">
      <h1>Edit Webinar</h1>
      {webinar.id ? <AddWebinarForm /> : null}
      <div className="course-btns">
        <button className="add-new-course" onClick={goBack}>
          Cancel
        </button>
        <button className="add-new-course" onClick={handleUpdateWebinar}>
          Update
        </button>
      </div>
      <AssignedUsersModal />
    </PageSideBar>
  );
});
