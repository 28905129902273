import React from "react"
import {Page} from "../Page";
import {Add} from "./components/Add";

export const AddCourse = () => {
  return (
    <Page>
      <Add />
    </Page>
  )
}