import React, {useEffect} from "react"
import {useParams} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {PageSideBar} from "modules/admin/PageSideBar";

import {WebinarNavBar} from "./components/WebinarNavBar";
import {DeleteModal} from './components/DeleteModal';
import {DetailsForm} from './components/Details';
import {AssignedUsersModal} from './components/AssignedUsersModal';

import {getWebinarDetails} from './actions/gets';
import {unmount} from './actions/add';
import {addWebinarStore} from './stores';

export const Webinar = observer(() => {
  const params = useParams();
  const {webinar} = addWebinarStore;

  useEffect(() => {
    getWebinarDetails(Number(params.webinarId));
  }, [params.webinarId]);

  useEffect(() => {
    return () => unmount();
  }, []);

  return (
    <PageSideBar className='add-webinar display-block'>
      <WebinarNavBar />
      {webinar.id ? (
        <DetailsForm />
      ) : null}
      <DeleteModal />
      <AssignedUsersModal />
    </PageSideBar>
  )
});
