import { observer } from "mobx-react-lite"
import { ChangeEvent, FormEvent, useEffect } from "react"
import { addCourseMaterialModalStore } from "../stores"
import CloseIcon from 'assets/img/png/ic-close.png'
import { useParams } from "react-router-dom"
import { deleteCourseMaterial } from "../actions/deleteCourseMaterial"

type DeleteCourseMaterialModalType = {
    scope?: string
}

export const DeleteCourseMaterialModal = observer((props: DeleteCourseMaterialModalType) => {
    const { id } = useParams()
    const courseMaterial = addCourseMaterialModalStore.courseMaterial
    const open = addCourseMaterialModalStore.openDelete

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, name, files } = event.target
        if(files && files.length) addCourseMaterialModalStore.courseMaterial = {...addCourseMaterialModalStore.courseMaterial, file: files[0]}
        else addCourseMaterialModalStore.courseMaterial = {...addCourseMaterialModalStore.courseMaterial, [name]: value}
    }

    const toggleNewCourseMaterialModal = () => {
        addCourseMaterialModalStore.openDelete = null
    }

    const handleSubmit = () => {
        try {
            if(open) deleteCourseMaterial(open, toggleNewCourseMaterialModal, props?.scope)
        } catch(err) {
            console.log(err)
        }
    }

    const handleClick = (event: any) => {
        event.stopPropagation()
    }
    
    useEffect(() => {
        if(id) addCourseMaterialModalStore.courseMaterial = {...addCourseMaterialModalStore.courseMaterial, course_id: Number(id)}
    }, [id])

    return (
        <div onClick={toggleNewCourseMaterialModal} id="deleteModal" className={`modal ${open ? 'open' : ''}`}>
            <div className="modal-container">
                <div onClick={handleClick} className="modal-content-superhost">
                    <span className="close">&times;</span>
                    <h3>DELETE COURSE</h3>
                    <div className="superhost-desc">
                        <p>Are you sure you want to delete this course?</p>
                        <div className="superhost-btns">
                            <button className="cancel-btn" onClick={toggleNewCourseMaterialModal}>Cancel</button>
                            <button className="delete-superhost-btn" onClick={handleSubmit}>Delete Course</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})