import {cloneDeep} from "lodash";

export const paramsToFormData = (d: object, name:string): FormData => {
  const params = cloneDeep(d) as any;
  const formData = new FormData();

  Object.keys(params).forEach(key => formData.append(`${name}[${key}]`, params[key]))

  return formData;
}
