import agent from "../../../../agent";
import {CpdLockerMetaType} from '../types';

const getCpdLocker = async (meta: CpdLockerMetaType) => await agent.CpdLocker.getCpdLocker(meta);

const getCertificate = async (webinarId: number) => await agent.CpdLocker.getCertificate(webinarId);

export const cpdLockerServices = {
  getCpdLocker,
  getCertificate,
}
