import {observer} from 'mobx-react-lite';
import {Link, useNavigate} from 'react-router-dom';
import {ButtonRound, FormInput} from 'components';
import {ROUTES, navigateToWebinar} from '../../../../constants';
import {loginStore} from '../../Login/stores';
import {InputChangeProps} from 'types';
import {setEmail, setPassword, submitLogin} from '../../Login/actions/sets';
import {commonStore} from 'stores';

export const SignInForm = observer(() => {
  const navigate = useNavigate();
  const {email, password, inProgress} = loginStore;
  const handleInputChange = (data: InputChangeProps) => {
    const {name, value} = data;
    if (name === 'email')
      setEmail((value || '').toString());
    else
      setPassword((value || '').toString());
  };
  const onSuccess = () => {
    if (commonStore.webinarId) {
      navigate(navigateToWebinar(Number(commonStore.webinarId)));
      commonStore.clearWebinarId();
    }
  };
  const handleSubmit = () => {
    submitLogin(onSuccess);
  };
  return (
    <div className="top__signin">
      <div className="signin__landing">
        <h2>Sign in</h2>
        <div className="signin-inputs">
          <FormInput
            className='landing_signin-input'
            type="email"
            name='email'
            placeholder='Email address'
            label=''
            value={email}
            onChange={handleInputChange}
          />
          <FormInput
            className='landing_signin-input'
            type="password"
            name='password'
            placeholder='Password'
            label=''
            value={password}
            onChange={handleInputChange}
          />
        </div>
        <Link to={ROUTES.forgotPassword} className="signin">Forgot Password ?</Link>
        <div className="signup__container">
          <ButtonRound disabled={inProgress} className="auth_rounded_button" onClick={handleSubmit}>Sign in</ButtonRound>
          <p>If you wish to join, please email <a href="mailto:academysupport@medcafe.ie"><b>academysupport@medcafe.ie</b></a></p>
        </div>
      </div>
    </div>
  );
});
