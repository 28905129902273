import React from 'react'
import {SelectField} from 'components/form';
import {changeField,} from '../../actions/add';
import {observer} from 'mobx-react-lite';
import {getErrors} from "utils";
import {addUserStore} from "../../stores";
import {IsEdit} from "@/types";
import { COUNTIES } from '../../../../../constants';

export const County = observer((props:IsEdit) => {
  if (!props.isEdit) return <div/>

  const {county} = addUserStore.user;

  const getOptions = () => COUNTIES.map((item:any, index:number) => ({key: 'county-type-key-' + index, text: item.label, value: item.value}))

  return (
    <SelectField
      name='county'
      label='County'
      value={county}
      setDefaultValue={true}
      options={getOptions()}
      error={getErrors('county')}
      onChange={changeField}
    />
  )
})