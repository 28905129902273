import {makeAutoObservable} from 'mobx'

export const deleteModalStore = makeAutoObservable({
  _superHostId: 0,
  _show: false,

  set superHostId(value: number) {
    this._superHostId = value;
  },

  get superHostId(): number {
    return this._superHostId;
  },

  set show(value: boolean) {
    this._show = value;
  },

  get show(): boolean {
    return this._show;
  },

  clear(): void {
    this._superHostId = 0;
    this._show = false;
  },

});

