import {API_ADMIN_COURSE_ID, API_ADMIN_COURSES} from "constants/api";
import {deleteRequest, getRequestAuth, postRequestFile, putRequestFile} from "utils/requests";
import {generatePath} from "react-router-dom";

const addCourse = async (params:FormData) => await postRequestFile(API_ADMIN_COURSES, params);

const editCourse = async (params:FormData, id:number) => await putRequestFile(courseUrl(id), params);

const getCourse = async (id:number) => await getRequestAuth(courseUrl(id));

const getCourses = async () => await getRequestAuth(API_ADMIN_COURSES);

const deleteCourse = async (id:number) => await deleteRequest(courseUrl(id));

export const courseService = {
    addCourse,
    editCourse,
    getCourses,
    deleteCourse,
    getCourse,
}

const courseUrl = (id:number) => generatePath(API_ADMIN_COURSE_ID, {id});