import {makeAutoObservable} from 'mobx'
import {AdminSponsorType} from "types";

export const partnersStore = makeAutoObservable({
  _partners: [] as AdminSponsorType[],
  _checkedIds: [] as number[],

  set partners(value: AdminSponsorType[]) {
    this._partners = value;
  },

  get partners(): AdminSponsorType[] {
    return this._partners
  },

  get checkedIds(): number[] {
    return this._checkedIds;
  },

  addCheckedId(value: number) {
    const copy = [...this._checkedIds];
    const index = copy.findIndex(id => id === value)

    index !== -1 ? copy[index] = value : copy.push(value);

    this._checkedIds = copy;
  },

  removeCheckedId(value: number) {
    this._checkedIds = [...this._checkedIds].filter(id => id !== value)
  },

  count(): number {
    return this._partners.length;
  },

  clear(): void {
    this._partners = [] as AdminSponsorType[]
    this._checkedIds = [] as number[]
  }
});

