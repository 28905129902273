import { getUsers } from ".";
import { usersStore } from "../../stores";
import { setMeta } from "../sets";

export const mount = (id: string) => {
  setMeta({
    ...usersStore.meta,
    course_id: id,
  });
  getUsers(1);
};
