import React from 'react'
import {observer} from 'mobx-react-lite';
import {Link, useNavigate} from "react-router-dom";
import {ROUTES} from '../../../../constants'
import {ButtonRound, FormInput} from "components";
import {forgotPasswordStore} from '../stores';
import {InputChangeProps} from 'types';
import {setEmail, submitForgotPassword} from '../actions/sets';

export const ForgotPasswordForm = observer(() => {
  const navigate = useNavigate();
  const {email, inProgress} = forgotPasswordStore;
  const handleInputChange = (data: InputChangeProps) => {
    setEmail((data.value || '').toString());
  };
  const handleSubmitForm = () => {
    submitForgotPassword(() => {
      setTimeout(() => navigate(ROUTES.main), 5000);
    });
  };

  return (
    <div className="signin__block">
      <div className="signin__container">
        <h1>PASSWORD RESET</h1>
        <p className='signin__subtitle'>Enter your email address that you used to register. We will send you an email with a link to reset your password</p>
        <div className="signin__content">
          <div className="containers">
            <form>
              <FormInput
                name="email"
                label="Email address"
                value={email}
                onChange={handleInputChange}
                type='email'
              />
            </form>
          </div>

          <ButtonRound onClick={handleSubmitForm} disabled={inProgress || !email} className="auth_rounded_button">
            Submit
          </ButtonRound>
          <p>If you wish to join, please email <a href="mailto:academysupport@medcafe.ie"><b>academysupport@medcafe.ie</b></a></p>
        </div>
      </div>
    </div>
  );
});