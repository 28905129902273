import React from 'react'
import {SelectField} from 'components/form';
import {changeField,} from '../../actions/add';
import {observer} from 'mobx-react-lite';
import {getErrors} from "utils";
import {addUserStore} from "../../stores";
import {USER_TYPES} from "constants/userTypes";

export const UserType = observer(() => {
  const {user_type} = addUserStore.user;

  const getOptions = () => USER_TYPES.map((item:any, index:number) => ({key: 'user-type-key-' + index, text: item.label, value: item.value}))

  return (
    <SelectField
      name='user_type'
      label='User Type'
      value={user_type}
      setDefaultValue={true}
      options={getOptions()}
      error={getErrors('user_type')}
      onChange={changeField}
    />
  )
})