import {cloneDeep} from 'lodash';
import {addWebinarStore} from '../../stores';
import {InputChangeProps} from 'types';
import {errorsStore} from 'stores';

export const changeField = (data: InputChangeProps) => {
  errorsStore.setError(data.name, []);
  const _webinar = cloneDeep(addWebinarStore.webinar);

  _webinar[data.name] = data.value;
  addWebinarStore.webinar = _webinar;
};
