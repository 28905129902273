import React from 'react'
import {observer} from 'mobx-react-lite';
import {addUserStore} from "../../stores";
import {RadioButtonsGroup} from "components";
import {ACCOUNT_LABELS, ACCREDITATION_TYPES} from "constants/";
import {changeField} from "../../actions/add";
import {getErrors} from "utils";

export const Accreditation = observer(() => {
  const {accreditation} = addUserStore.user;
  console.log('ACCREDITATION', accreditation)
  return (
    <div className='container-accordion'>
      <RadioButtonsGroup
        name='accreditation'
        label={ACCOUNT_LABELS['accreditation']}
        value={String(accreditation) || ''}
        onChange={changeField}
        options={ACCREDITATION_TYPES}
        error={getErrors('accreditation')}
      />
    </div>
  )
})