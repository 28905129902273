export const USER_TYPES = [
  { value: 'gp', label: 'GP' },
  { value: 'gp_trainee', label: 'GP Trainee' },
  { value: 'practice_nurse', label: 'General Practice Nurse' },
  { value: 'consultant', label: 'Consultant' },
  { value: 'hospital_doctor', label: 'NCHD (Hospital Doctor)' },
  { value: 'nurse_specialist', label: 'Nurse Specialist/ANP' },
  { value: 'allied_healthcare_professional', label: 'Allied Healthcare Professional' },
];

export enum USER_TYPE_ENUM {
  gp = 'gp',
  gp_trainee = 'gp_trainee',
  practice_nurse = 'practice_nurse',
  consultant = 'consultant',
  hospital_doctor = 'hospital_doctor',
  nurse_specialist = 'nurse_specialist',
  allied_healthcare_professional = 'allied_healthcare_professional',
};

type USER_TYPE_SHOW = {
  [key: string]: string;
};

export const USER_TYPES_SHOW: USER_TYPE_SHOW = {
  gp: 'GP',
  gp_trainee: 'GP Trainee',
  practice_nurse: 'General Practice Nurse',
  consultant: 'Consultant',
  hospital_doctor: 'NCHD (Hospital Doctor)',
  nurse_specialist: 'Nurse Specialist/ANP',
  allied_healthcare_professional: 'Allied Healthcare Professional',
};
