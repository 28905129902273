import React from 'react';
import {observer} from 'mobx-react-lite';
import {InputTextField} from 'components/form';
import {addWebinarStore} from '../../stores';
import {changeField} from '../../actions/add';
import {getErrors} from "utils";
import {DisabledPropType} from 'types';

export const VimeoField = observer((props: DisabledPropType) => {
  const {disabled} = props;
  const {webinar} = addWebinarStore;
  return (
    <InputTextField
      name='vimeo_url'
      label='Vimeo UID'
      value={webinar.vimeo_url}
      onChange={changeField}
      type='round'
      error={getErrors('vimeo_url')}
      disabled={disabled}
    />
  );
});
