import {makeAutoObservable} from 'mobx'
import {NotificationType} from "types";

export const notificationsStore = makeAutoObservable({
  _notifications: [] as NotificationType[],
  _showNotifications: false,

  set notifications(value: NotificationType[]) {
    this._notifications = value;
  },

  get notifications(): NotificationType[] {
    return this._notifications;
  },

  set showNotifications(value: boolean) {
    this._showNotifications = value;
  },

  get showNotifications(): boolean {
    return this._showNotifications;
  },

  clear(): void {
    this._notifications = [] as NotificationType[];
  }
});

