import {makeAutoObservable} from 'mobx'

export const localStore = makeAutoObservable({
  _token: '',

  set token(token: string) {
    localStorage.setItem('_token', token);
  },

  get token(): string {
    return localStorage.getItem('_token') as string;
  },

  clearToken(): void {
    localStorage.removeItem('_token');
  },
});
