import {makeAutoObservable} from 'mobx'

export const newPasswordStore = makeAutoObservable({
  _password: '',
  _passwordConfirmation: '',
  _token: window.localStorage.getItem('@medcafe_portal_pass_token') || '',
  _inProgress: false,

  set password(value: string) {
    this._password = value;
  },

  get password(): string {
    return this._password;
  },

  set passwordConfirmation(value: string) {
    this._passwordConfirmation = value;
  },

  get passwordConfirmation(): string {
    return this._passwordConfirmation;
  },

  set token(value: string) {
    window.localStorage.setItem('@medcafe_portal_pass_token', value);
    this._token = value;
  },

  get token(): string {
    return this._token;
  },

  set inProgress(value: boolean) {
    this._inProgress = value;
  },

  get inProgress(): boolean {
    return this._inProgress;
  },

  clear(): void {
    this._password = '';
    this._passwordConfirmation = '';
    this._token = '';
    window.localStorage.removeItem('@medcafe_portal_pass_token');
  }
});

