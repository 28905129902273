import {makeAutoObservable} from 'mobx'
import {CourseType, OptionType} from "types";

export const coursesStore = makeAutoObservable({
  _courses: [] as CourseType[],

  set courses(value: CourseType[]) {
    this._courses = value;
  },

  get courses(): CourseType[] {
    return this._courses
  },

  getCourseById(id:number): CourseType {
    const courses = this._courses.filter(item => item.id === id);

    return courses && courses.length > 0 ? courses[0]: {} as CourseType;
  },

  count(): number {
    return this._courses.length;
  },

  options(): OptionType[] {
    return this._courses.map((item:CourseType, index) => ({text: item.title, value: item.id, key: 'options-key-'+item.id + index}))
  },

  clear(): void {
    this._courses = [] as CourseType[]
  }
});

