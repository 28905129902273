import React from 'react';
import {Navigate} from 'react-router-dom';
import {observer} from "mobx-react-lite";
import {ROUTES} from '../../constants';
import {commonStore} from 'stores'
import {RouteProps} from 'types';

export const PublicRoute = observer((props: RouteProps) => {
  const {children} = props;

  if (commonStore.token)
    return <Navigate to={ROUTES.webinars} />

  return children;
});
