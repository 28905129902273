import {infoModalStore} from "../../stores";
import {courseService} from "../../services";
import {showError500} from "../../../../../utils";
import {mapFromOneCourse} from "../../utils";

export const openInfoModal = async (id:number) => {
  const {status, data} = await courseService.getCourse(id)

  if (status !== 200) {
    showError500(status, data);

    return;
  }

  infoModalStore.course = mapFromOneCourse(data.course.data.attributes)
  infoModalStore.show = true;
}