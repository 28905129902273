import { UsersMetaType } from "../types";

export const USERS_META: UsersMetaType = {
  page: 1,
  per_page: 25,
  total_pages: 1,
  role: "user",
  query: "",
  course_id: "",
};
