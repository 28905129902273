import React from "react"
import {ADMIN_ROUTES} from "../../../../constants";
import {Link} from "react-router-dom";

export const AddNewCourse = () => {
  return (
    <Link to={ADMIN_ROUTES.addCourse}>
      <button className="add-new-course">Add New Course</button>
    </Link>
  )
}