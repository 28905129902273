import React from 'react'
import DeleteIcon from 'assets/img/svg/delete_black_24dp.svg';
import {ImageField, InputTextField} from 'components/form';
import {useNavigate} from 'react-router-dom';

import {onSubmitAddCourse, changeField} from '../../actions/add';
import {onSubmitEditCourse} from '../../actions/edit';
import {observer} from 'mobx-react-lite';
import {addCourseStore} from '../../stores';
import {ADMIN_ROUTES} from 'constants/routes';
import {openDeleteModal} from "../../actions/modals";
import {DeleteModal} from "../";
import {getErrors} from "utils";

export const Add = observer(() => {
  const {title, color_theme, id} = addCourseStore.course;

  const isEdit = id !== undefined;

  let navigate = useNavigate();

  const pageTitle = isEdit ? 'Edit Course' : 'Add New Course';
  const buttonText = isEdit ? 'Save Changes' : 'Add New Course';
  const isEditDisplay = isEdit ? 'block' : 'none';

  const onClick = () => {
    if (isEdit) {
      onSubmitEditCourse(pause);
    } else {
      onSubmitAddCourse(pause);
    }
  }

  const redirectCoursesPage = () => {
    navigate(ADMIN_ROUTES.courses);
  }

  const pause = () => {
    setTimeout(redirectCoursesPage, 3000)
  }

  const onDelete = () => {
    openDeleteModal(id);
    pause();
  }

  return (
    <div>

      <div className='courses__header'>
        <div className='buttons-container'>
          <p>{pageTitle}</p>
        </div>

        <img src={DeleteIcon} alt='' style={{display: isEditDisplay}} onClick={onDelete}/>
      </div>

      <div className='courses-content'>
        <div className='account-block'>
          <span>ACCOUNT</span>
          <div className='account-fields'>

            <InputTextField
              type='round'
              name='title'
              label='Title'
              placeholder='Obesity Academy'
              value={title}
              error={getErrors('title')}
              onChange={changeField}
            />

            <InputTextField
              type='round'
              name='color_theme'
              label='Color theme'
              placeholder='Dark Green'
              value={color_theme}
              error={getErrors('color_theme')}
              onChange={changeField}
            />

          </div>
        </div>

        <div className='logo-block'>
          <span>LOGO</span>
          <div className='account-fields-logo'>

            <ImageField
              maxFileSize={1024}
              accept='.jpg, .jpeg, .png'
              acceptInfo='(JPG/JPEG/PNG)'
              label='COMPANY LOGO'
              name='company_logo'
              onChange={changeField}
            />

            <ImageField
              maxFileSize={1024}
              accept='.jpg, .jpeg, .png'
              acceptInfo='(JPG/JPEG/PNG)'
              label='COURSE LOGO'
              name='course_logo'
              onChange={changeField}
            />

            <ImageField
              maxFileSize={1024}
              accept='.jpg, .jpeg, .png'
              acceptInfo='(JPG/JPEG/PNG)'
              label='TERTIARY LOGO'
              name='tertiary_logo'
              onChange={changeField}
            />

          </div>
        </div>
      </div>

      <div className='course-btns'>
        <button className='cancel-btn' onClick={redirectCoursesPage}>Cancel</button>
        <button className='add-new-course' onClick={onClick}>{buttonText}</button>
      </div>

      <DeleteModal />
    </div>
  )
})