import React from "react"
import {observer} from "mobx-react-lite";
import {AddToWebinar, UsersCount} from "./components";

export const AddToWebinarButtons = observer(() => {
  return (
    <div className="courses__header-users">
      <div className="buttons-container">
        <UsersCount />
      </div>
      <AddToWebinar />
    </div>
  );
})