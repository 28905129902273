import React from "react"
import {Layout} from "../Layout";
import {WebLeftComponent} from "components";
import {CreateAccountForm} from "./components";

export const CreateAccount = () => {
  return (
    <Layout>
      <WebLeftComponent />
      <CreateAccountForm />
    </Layout>
  );
};
