import axios from 'axios'
import {generatePath} from 'react-router-dom'
import {addAuthTokens} from '.';

export const deleteRequest = async (url:string) => {
    return await axios
        .create({baseURL: process.env.REACT_APP_API_URL})
        .delete(generatePath(url), addAuthTokens())
        .then(response => response)
        .catch(error => error.response)
};
