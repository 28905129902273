import React from 'react';
import {observer} from 'mobx-react-lite';
import {InputTextField} from 'components/form';
import {addWebinarStore} from '../../stores';
import {changeField} from '../../actions/add';
import {getErrors} from "utils";
import {DisabledPropType} from 'types';

export const ExtCpdPoints = observer((props: DisabledPropType) => {
  const {disabled} = props;
  const {webinar} = addWebinarStore;
  return (
    <InputTextField
      name='ext_cpd_points'
      label='Number of external points'
      value={webinar.ext_cpd_points}
      onChange={changeField}
      error={getErrors('ext_cpd_points')}
      type='round'
      disabled={disabled}
    />
  );
});
