import {API_ADMIN_SPONSOR_DELETE, API_ADMIN_SPONSOR_ID, API_ADMIN_SPONSORS, API_ADMIN_USERS} from "constants/api";
import {getRequestAuth, postRequestAuth, postRequestFile, putRequestFile} from "utils/requests";
import {generatePath} from "react-router-dom";

const getSponsors = async () => await getRequestAuth(API_ADMIN_SPONSORS);

const getSponsorsByQuery = async (params:object) => await getRequestAuth(API_ADMIN_SPONSORS, params);

const addSponsor = async (params: FormData) => await postRequestFile(API_ADMIN_SPONSORS, params);

const editSponsor = async (params:FormData, id:number) => await putRequestFile(courseUrl(id), params);

const deleteSponsors = async (params: object) => await postRequestAuth(API_ADMIN_SPONSOR_DELETE, params);

export const sponsorsService = {
  getSponsors,
  getSponsorsByQuery,
  addSponsor,
  editSponsor,
  deleteSponsors,
}

const courseUrl = (id:number) => generatePath(API_ADMIN_SPONSOR_ID, {id});