import React from "react"
import {List} from "./components/UsersList";
import {AddToWebinarButtons} from "modules/admin/UsersNavBar";
import {PageSideBar} from "modules/admin/PageSideBar";

export const AddWebinarUsers = () => {
  return (
    <PageSideBar className='display-block'>
      <AddToWebinarButtons />
      <List />
    </PageSideBar>
  )
}