import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { commonStore } from "stores";
import { currentUserStore } from "stores";
import { openNotifications } from "../Notifications/actions/sets";
import { ROUTES } from "../../../constants";
import notificationsIcon from "assets/img/svg/notifications_black_24dp.svg";
// import messagesIcon from 'assets/img/svg/header_messgae.svg';

export const AuthBlock = observer(() => {
  const { currentUser } = currentUserStore;
  const { id, first_name, last_name } = currentUser || {};
  const handleLogout = () => {
    commonStore.logout();
  };
  const handleNotifications = () => {
    openNotifications();
  };

  return id ? (
    <div className="header__login">
      {/* <div className="header__notifications">
        <button id='myBtn' onClick={handleNotifications}>
          <img src={notificationsIcon} alt="" />
        </button>
        <img src={messagesIcon} alt="" />
      </div> */}
      <div className="user__login">
        <Link
          to={ROUTES.settings}
          className="user_name"
        >{`${first_name} ${last_name}`}</Link>
        <button className="logout" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  ) : null;
});
