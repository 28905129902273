import {showError500} from "utils";
import {adminInviteService} from "services";
import {invitesStore} from "../../stores";
import {mapFromInvites} from "../../utils";

export const getInvites = async () => {
  const {courseId, inviteStatus} = invitesStore;
  const params = {role: 'user', page: 1, per_page: 25, status: inviteStatus, course_id: courseId};

  const {status, data} = await adminInviteService.getInvites(params);

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  invitesStore.invites = mapFromInvites(data);
}