import { logoutAdmin, showError500 } from "utils";
import { sponsorsService } from "../../services";
import { partnersStore } from "../../stores";
import { mapFromPartners } from "../../utils";

export const getPartners = async () => {
  const { status, data } = await sponsorsService.getPartners();

  if (status === 401) {
    logoutAdmin();
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  partnersStore.partners = mapFromPartners(data);
};
