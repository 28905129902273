import {showError500} from "utils";
import {adminUsersService} from "services";
import {addUserStore} from "../../stores";
import {mapFromOneUser} from "modules/admin/SuperHosts/utils";

export const getUserDetails = async (userId:number) => {
  const {status, data} = await adminUsersService.getUserDetails(userId);

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  addUserStore.user = mapFromOneUser(data.user.data.attributes);
}