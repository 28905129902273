import {makeAutoObservable} from 'mobx'
import {AdminSponsorType} from "@/types";

export const sponsorsStore = makeAutoObservable({
  _sponsors: [] as AdminSponsorType[],
  _checkedIds: [] as number[],

  set sponsors(value: AdminSponsorType[]) {
    this._sponsors = value;
  },

  get sponsors(): AdminSponsorType[] {
    return this._sponsors
  },

  get checkedIds(): number[] {
    return this._checkedIds;
  },

  addCheckedId(value: number) {
    const copy = [...this._checkedIds];
    const index = copy.findIndex(id => id === value)

    index !== -1 ? copy[index] = value : copy.push(value);

    this._checkedIds = copy;
  },

  removeCheckedId(value: number) {
    this._checkedIds = [...this._checkedIds].filter(id => id !== value)
  },

  count(): number {
    return this._sponsors.length;
  },

  clear(): void {
    this._sponsors = [] as AdminSponsorType[]
    this._checkedIds = [] as number[]
  }
});

