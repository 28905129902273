import { cloneDeep } from "lodash";
import { logoutAdmin, showError500 } from "utils";
import { adminCourseMaterialsService } from "services";
import { courseMaterialsStore } from "../../stores";
import { mapByPage } from "utils";

export const getCourseMaterials = async (scope: string | undefined) => {
  const { meta } = courseMaterialsStore;
  const { status, data } = await adminCourseMaterialsService.getCourseMaterials(
    meta,
    scope
  );
  //   const webinarsResponse = mapFromWebinars(data)

  if (status === 401) {
    logoutAdmin();
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  const _courseMaterials = mapByPage(
    meta.page,
    courseMaterialsStore.courseMaterials,
    data.course_materials.data.map((item: any) => item.attributes)
  );
  const _meta = cloneDeep(meta);
  _meta.total_pages = data.total_pages || 1;

  courseMaterialsStore.meta = _meta;
  courseMaterialsStore.courseMaterials = _courseMaterials;
};
