import React from "react"
import {observer} from "mobx-react-lite";
import {debounce} from "lodash";
import {getUsersByQuery} from "../../../Users/actions/gets";

export const ListSearch = observer(() => {

  const onSearch = debounce((query: string) => getUsersByQuery(query), 3000);

  return (
    <div className="search__container">
      <form className="chat">
        <button type="submit">Search</button>
        <input type="search" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} />
      </form>
      <div className="search__content">
      </div>
    </div>
  )
})