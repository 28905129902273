import {getMyCourses, getCourses} from ".";
import {coursesStore} from '../../stores';
import {COURSES_OPTIONS_VALUES} from '../../constants';

export const mount = () => {
  switch(coursesStore.selectedOption) {
    case COURSES_OPTIONS_VALUES.my_courses:
      getMyCourses();
      break;
    case COURSES_OPTIONS_VALUES.all_courses:
      getCourses();
      break;
    default:
      break;
  }
}