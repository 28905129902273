import React from "react";
import { observer } from "mobx-react-lite";
import { InputTextField } from "components/form";
import { ImageField } from "components/form";
import { Avatar } from "./Avatar";
import { InputChangeProps } from "types";
// import {addWebinarStore} from '../../stores';
import { Speaker } from "../../types";
import { changeSpeaker } from "../../actions/add";
import { getErrors } from "utils";
import { DisabledPropType } from "types";
import { removeSpeaker } from "../../actions/add/removeSpeaker";
import DeleteIcon from "assets/img/png/trashcan.png";
import { errorsStore } from "stores";

const { REACT_APP_API_URL } = process.env;

type Props = {
  index: number;
  item: Speaker;
};

export const SpeakerInput = observer((props: Props & DisabledPropType) => {
  const { item, disabled, index } = props;
  const error = getErrors("speakers.name") as any;
  // const currentSpeakerError = errors.findIndex(
  //   (error: any) => error.id === item.id
  // );

  const handleChange = (data: InputChangeProps) => {
    changeSpeaker({
      ...data,
      id: item.id,
    });
  };

  const handleRemove = () => removeSpeaker(item.id);

  return (
    <div className="group date-picker">
      <div className="speaker-title">
        <span>Speaker №{index + 1}</span>
        {!disabled && (
          <img
            style={{ cursor: "pointer" }}
            src={DeleteIcon}
            alt="trash-icon"
            onClick={handleRemove}
          />
        )}
      </div>
      {disabled && (
        <Avatar
          avatarUrl={item.avatar_url}
          letters={`${item.name.charAt(0)}${item.name.charAt(1)}`}
        />
      )}
      <InputTextField
        name="name"
        label={`Speaker Name`}
        value={item.name}
        onChange={handleChange}
        type="round"
        disabled={disabled}
        error={Boolean(error) && !item.name && error}
      />
      <InputTextField
        name="speaker_type"
        label={`Speaker Type`}
        value={item.speaker_type}
        onChange={handleChange}
        type="round"
        disabled={disabled}
        // error={errors[currentSpeakerError]?.speaker_type}
      />
      <InputTextField
        name="location"
        label={`Speaker Location`}
        value={item.location}
        onChange={handleChange}
        type="round"
        disabled={disabled}
        // error={errors[currentSpeakerError]?.location}
      />
      {/* <InputTextField
        name='presentation_title'
        label={`Speaker Presentation Title`}
        value={item.presentation_title}
        onChange={handleChange}
        type='round'
        disabled={disabled}
        error={errors[currentSpeakerError]?.presentation_title}
      /> */}
      {!disabled && (
        <ImageField
          maxFileSize={1024}
          accept=".jpg, .jpeg, .png"
          acceptInfo="(JPG/JPEG/PNG)"
          label="Speaker Image"
          name="avatar"
          onChange={handleChange}
          value={item.avatar?.name}
          disabled={disabled}
        />
      )}
    </div>
  );
});
