import React from 'react'
import {Route} from 'react-router-dom'
import {ADMIN_ROUTES} from 'constants/routes';
import {Home} from "modules/admin/Home";
import {Login} from "modules/admin/Login";
import {Terms} from "modules/admin/Terms/Terms";
import {Privacy} from "modules/admin/Privacy";
import {Courses, AddCourse, EditCourse} from "modules/admin/Courses";
import {SuperHosts} from "modules/admin/SuperHosts";
import {Sponsors} from "modules/admin/Sponsors";
import {Partners} from "modules/admin/Partners";
import {Users, AddUser, EditUser} from "modules/admin/Users";
import {RequestsApproved, RequestsPending, RequestsRejected} from "modules/admin/Requests";
import {Webinars, AddWebinar, AddWebinarUsers, EditWebinar, Webinar} from 'modules/admin/Webinars';
import { CourseMaterials, CourseMaterial, CourseMaterialsRequests } from 'modules/admin/CourseMaterials'
import { Statistics } from 'modules/admin/Statistics'

export const AdminRoutes = () => ([
    <Route key='admin-routes-home' path={ADMIN_ROUTES.home} element={<Home />}/>,
    <Route key='admin-routes-login' path={ADMIN_ROUTES.login} element={<Login />}/>,
    <Route key='admin-routes-terms' path={ADMIN_ROUTES.terms} element={<Terms />}/>,
    <Route key='admin-routes-privacy' path={ADMIN_ROUTES.privacy} element={<Privacy />}/>,

    <Route key='admin-routes-courses' path={ADMIN_ROUTES.courses} element={<Courses />}/>,
    <Route key='admin-routes-add-course' path={ADMIN_ROUTES.addCourse} element={<AddCourse />}/>,
    <Route key='admin-routes-edit-course' path={ADMIN_ROUTES.editCourse} element={<EditCourse />}/>,

    <Route key='admin-routes-super-hosts' path={ADMIN_ROUTES.superHosts} element={<SuperHosts />}/>,
    <Route key='admin-routes-sponsors' path={ADMIN_ROUTES.sponsors} element={<Sponsors />}/>,
    <Route key='admin-routes-partners' path={ADMIN_ROUTES.partners} element={<Partners />}/>,

    <Route key='admin-routes-courses-user' path={ADMIN_ROUTES.users} element={<Users />}/>,
    <Route key='admin-routes-courses-user-add' path={ADMIN_ROUTES.addUser} element={<AddUser />}/>,
    <Route key='admin-routes-courses-user-edit' path={ADMIN_ROUTES.editUser} element={<EditUser />}/>,

    <Route key='admin-routes-requests-pending' path={ADMIN_ROUTES.requestsPending} element={<RequestsPending />}/>,
    <Route key='admin-routes-requests-approved' path={ADMIN_ROUTES.requestsApproved} element={<RequestsApproved />}/>,
    <Route key='admin-routes-requests-rejected' path={ADMIN_ROUTES.requestsRejected} element={<RequestsRejected />}/>,

    <Route key='admin-routes-webinars' path={ADMIN_ROUTES.webinars} element={<Webinars />} />,
    <Route key='admin-routes-add-webinar' path={ADMIN_ROUTES.addWebinar} element={<AddWebinar />} />,
    <Route key='admin-routes-edit-webinar' path={ADMIN_ROUTES.editWebinar} element={<EditWebinar />} />,
    <Route key='admin-routes-add-users-webinar' path={ADMIN_ROUTES.addUsersWebinar} element={<AddWebinarUsers />} />,
    <Route key='admin-routes-webinar' path={ADMIN_ROUTES.webinar} element={<Webinar />} />,

    <Route key='admin-routes-course-materials' path={ADMIN_ROUTES.courseMaterials} element={<CourseMaterials />} />,
    <Route key='admin-routes-course-material' path={ADMIN_ROUTES.courseMaterial} element={<CourseMaterial />} />,
    <Route key='admin-routes-course-material' path={ADMIN_ROUTES.courseMaterialsRequests} element={<CourseMaterialsRequests />} />,
    
    <Route key='admin-routes-course-material' path={ADMIN_ROUTES.statistics} element={<Statistics />} />,
])