import {toast} from 'react-toastify';
import {cloneDeep} from 'lodash';
import {defaultErrorsInfo} from "../../../../../utils";
import {webinarsService} from '../../services';
import {webinarsStore} from '../../stores';
import {WebinarType} from 'types';

export const registerWebinar = async (webinarId: number) => {
  try {
    webinarsStore.inProgress = true;

    const _webinars = cloneDeep(webinarsStore.webinars);
    const formData = new FormData();
    formData.append('register_webinar_requests[webinar_id]', webinarId.toString());

    const {data} = await webinarsService.registerWebinar(formData);

    const webinarIndex = _webinars.map((webinar: WebinarType) => webinar.id).indexOf(webinarId);
    _webinars[webinarIndex] = {
      ..._webinars[webinarIndex],
      is_register_interes: true,
    };

    webinarsStore.inProgress = false;
    webinarsStore.webinars = _webinars;

    if (data.message)
      toast.success(data.message);

  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
    webinarsStore.inProgress = false;
  }
};
