import {CpdQuizType} from "types";
import {cloneDeep} from "lodash";

export const mapFromQuiz = (data: any): CpdQuizType[] => {
  const clone = cloneDeep(data);
  const _cpdQuizzes = clone.cpd_quizzes.data;
  const hasData = _cpdQuizzes && _cpdQuizzes.length > 0;

  return hasData ? _cpdQuizzes.map((item: any) => ({...item.attributes})) : [];
}
