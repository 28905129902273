import {generatePath} from "react-router-dom";
import {
  API_ADMIN_ADD_WEBINAR,
  API_ADMIN_WEBINAR_ID,
  API_ADMIN_WEBINARS,
  API_ADMIN_WEBINAR_DELETE,
} from "constants/api";
import {WebinarsMetaType} from 'modules/admin/Webinars/types';
import {getRequestAuth, postRequestFile, putRequestFile, postRequestAuth} from "utils/requests";

const getWebinars = async (meta: WebinarsMetaType) => await getRequestAuth(API_ADMIN_WEBINARS, meta);

const getWebinarDetails = async (id: number) => await getRequestAuth(webinarUrl(id));

const addWebinar = async (formData: FormData) => await postRequestFile(API_ADMIN_ADD_WEBINAR, formData);

const updateWebinar = async (webinarId: number, formData: FormData) => await putRequestFile(webinarUrl(webinarId), formData);

const deleteWebinar = async (params: object) => await postRequestAuth(API_ADMIN_WEBINAR_DELETE, params);


export const adminWebinarsService = {
  getWebinars,
  addWebinar,
  getWebinarDetails,
  updateWebinar,
  deleteWebinar,
}

const webinarUrl = (id: number) => generatePath(API_ADMIN_WEBINAR_ID, {id});
