import React from "react"
import { observer } from "mobx-react-lite"
import { LinkStatistics } from "."


export const StatisticsNavBar = observer(() => {
  return (
    <div className="courses__header-users">
      <div className="buttons-container">
        <LinkStatistics />
      </div>
    </div>
  )
})