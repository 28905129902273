import {getWebinars} from ".";
import {webinarsStore} from '../../stores';
import {setMeta} from '../sets';

export const mount = (id: number) => {
  setMeta({
    ...webinarsStore.meta,
    course_id: id,
  });
  getWebinars();
}