import React from "react"
import {Page} from "../Page";
import {TopNavBar} from "../TopNavBar";
import {List, DeleteModal, InfoModal} from "./components";

export const Courses = () => {
  return (
    <Page>
      <TopNavBar showButton='course' />
      <List />
      <DeleteModal />
      <InfoModal />
    </Page>
  )
}