import {CourseType, AdminUserType} from "types";
import {cloneDeep} from "lodash";
import {geDateFormat} from "utils";
import {mapFromOneUser} from "modules/admin/SuperHosts/utils";

export const mapFromOneCourse = (data: any): CourseType => {
  const course = cloneDeep(data);

  if (!course) {
    return {} as CourseType;
  }

  const {id, title, color_theme, created_at, users, is_member} = course;

  return {
    id,
    title,
    color_theme,
    logo_url: course.logo_url ? course.logo_url : '',
    company_logo_url: course.company_logo ? course.company_logo : course.company_logo_url,
    tertiary_logo_url: course.tertiary_logo ? course.tertiary_logo: course.tertiary_logo_url,
    superhost: course.superhost ? mapFromOneUser(course.superhost) : {} as AdminUserType,
    created_at: geDateFormat(created_at),
    users,
    is_member,
  };
}