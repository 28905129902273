import {makeAutoObservable} from 'mobx'
import {ErrorType} from "@/types/ErrorType";

export const errorsStore = makeAutoObservable({
  _errors: [] as ErrorType[],

  set errors(value: ErrorType[]) {
    this._errors = value;
  },

  get errors(): ErrorType[] {
    return this._errors;
  },

  setError(name: string, value: string[]) {
    let clone = [...this._errors];

    const index = clone.findIndex(item => item.name === name);

    if (index !== -1) {
      clone[index] = {name, value};
    } else {
      clone.push({name, value});
    }

    this._errors = clone;
  },

  getError(name: string): string[] {
    const index = this._errors.findIndex(item => item.name === name);

    if (index !== -1) {
      return this._errors[index].value;
    }

    return [];
  },

  clear() {
    this._errors = [] as ErrorType[];
  },

});

