import { showError500 } from "utils";
import { adminWebinarsService } from "services";
import { addWebinarStore } from "../../stores";
import { mapFromWebinarDetails } from "../../utils";

export const getWebinarDetails = async (webinarId: number) => {
  const { status, data } = await adminWebinarsService.getWebinarDetails(
    webinarId
  );

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  addWebinarStore.webinar = mapFromWebinarDetails(data);
};
