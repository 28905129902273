import React from "react"
import {observer} from "mobx-react-lite";
import CloseIcon from "assets/img/png/ic-close.png";
import {changeStatusModalStore, invitesStore} from "../stores";
import {cloneDeep} from "lodash";
import {closeChangeStatusModal, setRejectReason} from "../actions/modals";
import {rejectInvite} from "../actions/edit";

export const ChangeStatusModal = observer(() => {

  const {show, inviteId, rejectReason} = changeStatusModalStore;
  const invite = cloneDeep(invitesStore.getInviteById(inviteId));

  if (!show || !invite) {
    return <div/>
  }
  const {first_name, last_name, email} = invite.user;
  const {superhost, title} = invite.course;
  const {rejectReasons} = invitesStore;

  const onChangeStatusModal = () => {
    if (rejectReason !== -1) {
      rejectInvite();
    }

    closeChangeStatusModal()
  }

  return (
    <div className="modal" style={{display: 'block'}}>

      <div className="modal-container-academy">
        <span className="close">×</span>
        <div className="modal-content-create__academy">
          <div className="modal-content-academy__headline">
            <img src={CloseIcon} alt="close modal button" onClick={closeChangeStatusModal}/>
          </div>
          <div className="academy-list">
            <h4>CHANGE STATUS</h4>
            <div className="academy-specs">
              <p>Name: <b>{first_name + ' ' + last_name}</b></p>
            </div>
            <div className="academy-specs">
              <p>Superhost name: <b>{superhost.first_name + ' ' + superhost.last_name}</b></p>
            </div>
            <div className="academy-specs">
              <p>Email: <b>{email}</b></p>
            </div>
            <div className="academy-specs">
              <p>Course Title: <b>{title}</b></p>
            </div>
            <div className="modal-reject">
              <div className="reject">
                <select className="reject-select" onChange={(e) => setRejectReason(Number(e.target.value))}>
                  <option value={-1}>DECLINE</option>
                  {
                    rejectReasons.map((item) => <option key={item.key} value={item.value}>{item.text}</option>)
                  }
                </select>
                <span className="focus"></span>
              </div>
            </div>
            <div className="request-btns">
              <button className="cancel-btn" onClick={closeChangeStatusModal}>Cancel</button>
              <button className="save-btn" onClick={onChangeStatusModal}>Save</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
})