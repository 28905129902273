import React from "react"
import {mount, unmount} from "../../actions/gets";
import {sponsorsStore} from "../../stores";
import {observer} from "mobx-react-lite";
import {ListHeader, ListItem, ListSearch} from ".";
import {AdminSponsorType} from "@/types";

export const List = observer(() => {
  React.useEffect(
    () => {
      mount();

      return unmount();
    },
    []
  );

  const {sponsors} = sponsorsStore;

  return (
    <div className="superhost__container">
      <ListSearch />
      <ListHeader />
      <div className="superhost__content">
        {
          sponsors.map((item: AdminSponsorType, index) => <ListItem key={'admin-sponsors-key-' + index} sponsor={item}/>)
        }
      </div>
    </div>
  )
})