import {cloneDeep} from 'lodash';
import {defaultErrorsInfo, mapByPage} from "../../../../../utils";
import {membersService} from "../../services";
import {membersStore} from "../../stores";
import {mapFromMembers} from '../../utils';

export const getMembers = async () => {
  try {
    const {data} = await membersService.getMembers(membersStore.meta);
    const membersResponse = mapFromMembers(data);
    const members = mapByPage(membersStore.meta.page, membersStore.members, membersResponse);

    const _meta = cloneDeep(membersStore.meta);
    _meta.total_pages = data.total_pages || 1;

    membersStore.meta = _meta;
    membersStore.members = members;
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
}
