import React from "react"
import {NavLink} from "react-router-dom";

type Props = {
  isActive: boolean;
  to: string;
  text: string;
}

export const NavBarLink = (props: Props) => {
  const {isActive, to, text} = props;
  const activeClassName = isActive ? 'active' : '';

  return (
    <NavLink to={to}>
      <button className={`request-btn ${activeClassName}`}>{text}</button>
    </NavLink>
  )
}