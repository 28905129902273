import { makeAutoObservable } from 'mobx'
import { CourseMaterialType } from "types"
import { COURSE_MATERIALS_META } from '../constants'
import { CourseMaterialsMetaType } from '../types'

export const courseMaterialsStore = makeAutoObservable({
  _courseMaterials: [] as CourseMaterialType[],
  _meta: COURSE_MATERIALS_META as CourseMaterialsMetaType,

  set courseMaterials(value: CourseMaterialType[]) {
    this._courseMaterials = value
  },

  get courseMaterials(): CourseMaterialType[] {
    return this._courseMaterials
  },

  set meta(value: CourseMaterialsMetaType) {
    this._meta = value
  },

  get meta(): CourseMaterialsMetaType {
    return this._meta
  },

  getCourseMaterialById(id: number) : CourseMaterialType{
    return this._courseMaterials.filter(item => item.id === id)[0]
  },

  count(): number {
    return this._courseMaterials.length
  },

  clear(): void {
    this._courseMaterials = [] as CourseMaterialType[]
    this._meta = COURSE_MATERIALS_META as CourseMaterialsMetaType
  }
})

