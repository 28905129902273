import React from "react"
import {NavBarLink} from ".";
import {generatePath, useParams} from "react-router-dom";
import {ADMIN_ROUTES} from "constants/routes";
import {STATUS_APPROVED, STATUS_PENDING, STATUS_REJECTED} from "constants/inviteStatuses";

export type Props = {
  status: string;
}

export const NavBar = (props: Props) => {
  const {status} = props;
  let {id} = useParams();

  const links = [
    {
      to: generatePath(ADMIN_ROUTES.requestsPending, {id}),
      isActive: status === STATUS_PENDING,
      text: 'Pending',
    },
    {
      to: generatePath(ADMIN_ROUTES.requestsApproved, {id}),
      isActive: status === STATUS_APPROVED,
      text: 'Approved',
    },
    {
      to: generatePath(ADMIN_ROUTES.requestsRejected, {id}),
      isActive: status === STATUS_REJECTED,
      text: 'Declined',
    },
  ]

  return (
    <div className="courses__header-users">
      <div className="request-buttons">
        {
          links.map((item, index) =>
            <NavBarLink key={'key-requests-bar' + index}
                        isActive={item.isActive}
                        to={item.to}
                        text={item.text}/>
          )
        }
      </div>
    </div>
  )
}