import {toast} from "react-toastify";
import {showError500, validateBE} from "utils";
import {errorsStore} from "stores";
import {addCourseMaterialModalStore} from '../stores';
import { newCourseMaterialServices } from "../services";
import { getCourseMaterials } from "./gets";

export const createCourseMaterial = async (callBack: Function, scope?: string) => {
  const courseMaterial = addCourseMaterialModalStore.courseMaterial;
  const errors: any[] = [];

  if (errors.length > 0) {
    errorsStore.errors = errors;
    return;
  }

  const formData = new FormData()
  formData.append('course_material[title]', courseMaterial.title)
  formData.append('course_material[file]', courseMaterial.file)
  formData.append('course_material[course_id]', String(courseMaterial.course_id))

  const {status, data} = await newCourseMaterialServices.addCourseMaterial(formData)

  if (status === 401 || status === 403 || status === 400) {
    validateBE(data.error_messages, []);
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  getCourseMaterials(scope)
  addCourseMaterialModalStore.clear()
  toast.success(`Course Material was created successfully.`);
  callBack();
}