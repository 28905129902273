import React from "react"
import {observer} from "mobx-react-lite";
import {EditWebinar} from ".";
import {WebinarInfo, NewsletterUsers} from ".";

export const WebinarNavBar = observer(() => {
  return (
    <div className="courses__header-users webinar-details-header">
      <div className="buttons-container">
        <WebinarInfo />
        {/* <NewsletterUsers /> */}
      </div>

      <EditWebinar />
    </div>
  )
})