import React from 'react'
import {InputType, DisabledPropType} from "types";

export const InputTextField = (props: InputType & DisabledPropType) => {
  const {label, name, placeholder, value, error, type, onChange, disabled} = props;

  const classNames:any = {
    line: {wrapper: 'input', input: 'user'},
    round: {wrapper: 'input', input: 'account'},
    modal: {wrapper: 'superhost-input', input: 'account'},
  };

  const hasErrors = error && error.length > 0;
  const inputClassName = hasErrors ? classNames[type].input + ' error-input' : classNames[type].input;

  return (
    <div className={classNames[type].wrapper}>
      <p>{label}</p>
      <input name={name}
             type="text"
             disabled={disabled}
             className={inputClassName}
             placeholder={placeholder? placeholder : ''}
             onChange={(event) => onChange({name, value: event.currentTarget.value})}
             value={value ? value : ''}
      />
      <div className='form-error'>{hasErrors ? error?.join('<br/>') : ''}</div>
    </div>
  )
}