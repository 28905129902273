import {addCourseStore, deleteModalStore} from "../../stores";
import {courseService} from "../../services";
import {showError500} from "../../../../../utils";
import {getCourses} from "../gets";
import {toast} from "react-toastify";

export const onDeleteCourse = async () => {
  const id = deleteModalStore.courseId

  if (id === 0) {
    return;
  }

  const {status, data} = await courseService.deleteCourse(id)

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  toast.success('Course was delete successfully... redirect');
  deleteModalStore.clear();
  addCourseStore.clear();

  getCourses();
}