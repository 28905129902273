import React from "react"

export const ListHeader = () => {
  return (
    <div className="superhost__header">
      <div className="header-categories-sponsors">
        <div className="name stats">
          <p>Webinar Title</p>
        </div>
        <div className="name stats">
            <p>№ Of Participants (live)</p>
        </div>
        <div className="name stats">
            <p>№ Of Views (recorded)</p>
        </div>
        <div className="name stats">
            <p>№ Of Users Completing Quiz</p>
        </div>
        <div className="name stats">
            <p>№ Of In-Person Attedees</p>
        </div>
        <div className="name stats">
            <p>№ Of Registered</p>
        </div>
        <div className="name stats">
            <p>Start date/time</p>
        </div>
      </div>
    </div>
  )
}