import React from "react"
import {AdminUserInviteType, OptionType} from "types";
import {approveInvite} from "../../actions/gets";
import {getRejectReasons} from "../../actions/edit";
import {STATUS_PENDING} from "constants/inviteStatuses";
import DeleteIcon from "assets/img/png/trashcan.png";
import {openChangeStatusModal} from "../../actions/modals";

type Props = {
  invite: AdminUserInviteType;
  courseId: number;
  rejectReasonOptions: OptionType[];
  status: string;
}

export const ListItem = (props: Props) => {
  const {courseId, rejectReasonOptions, status} = props;
  const {first_name, last_name, email} = props.invite.user;
  const inviteId = props.invite.id;
  const {title} = props.invite.course;

  const onChangeSelect = (value:number) => {
    if (value !== -1 ) {
      getRejectReasons(inviteId, value)
    }
  }

  return (
    <div className="content-line-users">
      <div className="content-desc">
        <div className="user-name webinars">
          <p>{first_name + ' ' + last_name}</p>
        </div>
        <div className="user-name requests">
          <p>{email}</p>
        </div>
        <div className="user-name requests">
          <p>{title}</p>
        </div>
      </div>

      {
        status === STATUS_PENDING
          ?
          <div className="user-status">
            <button className="approve-btn" onClick={() => approveInvite(courseId, inviteId)}>APPROVE</button>

            <div className="reject">
              <select className="reject-select" onChange={(e) => onChangeSelect(Number(e.target.value))}>
                <option value={-1}>DECLINE</option>
                {
                  rejectReasonOptions.map((item) => <option key={item.key} value={item.value}>{item.text}</option>)
                }
              </select>
              <span className="focus"></span>
            </div>
          </div>

          :

          <div className="content-options">
            <div id="myBtn" className="edit" onClick={() => openChangeStatusModal(status, courseId, inviteId)}>
              <h5>CHANGE STATUS</h5>
            </div>
            <div className="delete">
              <img src={DeleteIcon} alt="" />
            </div>
          </div>
      }

    </div>
  )
}