import React from "react";
import { AdminUserType } from "types";
import { addCheckedId, removeCheckedId } from "../../actions";
import { webinarsStore, assignedUsersModalStore } from "../../stores";
import { setAssignedUsers } from "../../actions/sets";

type Props = {
  user: AdminUserType;
};

export const ListItem = (props: Props) => {
  const { checkedIds } = webinarsStore;
  const { assignedUsers } = assignedUsersModalStore;
  const {
    id,
    first_name,
    last_name,
    email,
    country,
    created_at,
    last_login,
    accreditation,
  } = props.user;

  const onClickCheckBox = (event: any) => {
    const checked = Boolean(event.target.checked);
    if (checked) {
      addCheckedId(id);
      setAssignedUsers([...assignedUsers, props.user]);
    } else {
      removeCheckedId(id);
      setAssignedUsers(assignedUsers.filter(({ id }) => props.user.id !== id));
    }
  };

  return (
    <div className="content-line-users">
      <div className="content-desc">
        <label className="container">
          <input
            type="checkbox"
            className="content-desc__checkbox"
            onClick={onClickCheckBox}
            defaultChecked={checkedIds.includes(id)}
          />
          <span className="checkmark"></span>
        </label>
        <div className="user-name webinars cursor-pointer">
          <p>{first_name + " " + last_name}</p>
        </div>
        <div className="user-name webinars">
          <p>{email}</p>
        </div>
        <div className="user-name webinars">
          <p>{accreditation ? "Ireland" : country}</p>
        </div>
        <div className="user-name webinars">
          <p>{created_at}</p>
        </div>
        <div className="user-name webinars">
          <p>{last_login}</p>
        </div>
      </div>
    </div>
  );
};
