import { cloneDeep } from "lodash";
import {
  webinarsStore,
  addWebinarStore,
  assignedUsersModalStore,
} from "../../stores";
import { usersStore } from "../../../Users/stores";

export const setAssignedUsers = (prop: Function | any[]) => {
  let checks = [] as Array<number>;
  const { checkedIds } = webinarsStore;
  if (Array.isArray(prop)) checks = prop.map(({ id }) => id);
  else checks = checkedIds;
  const _webinar = cloneDeep(addWebinarStore.webinar);

  _webinar.assigned_ids = checks;
  if (typeof prop === "function") addWebinarStore.webinar = _webinar;
  assignedUsersModalStore.assignedUsers = prop as any;
  if (typeof prop === "function") prop();
  if (typeof prop === "function") webinarsStore.clearCheckedIds();
};
