import React, { useEffect, useMemo, useRef, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {WebinarType} from 'types';
import {ButtonRound} from 'components';
import {DATE_FORMATS, navigateToWebinar} from '../../../../constants';
import {SCOPE_VALUES} from '../constants';
import {getCertificate} from '../actions/gets';
import {openQuizModal, setQuiz} from '../../Webinar/actions/sets';
import {getImageUrl, mapFromSpeakers, mapFromSponsors, getCpdPoints} from 'utils';
import clockIcon from 'assets/img/svg/clock.svg';
import calendarIcon from 'assets/img/svg/calendar.svg';
import certIcon from 'assets/img/png/ic-drive-pdf-24-px.png';
import leftArrow from 'assets/img/svg/arrow_left.svg'
import rightArrow from 'assets/img/svg/arrow_right.svg'
// import {setCourse} from '../actions/sets';

type Item = {
  item: WebinarType;
  key: string;
  selectetScope: string;
  inProgress: boolean;
  disabled: boolean;
  hideQuizButton: boolean;
};

export const Item = (props: Item) => {
  const {item, inProgress, disabled, hideQuizButton} = props;
  const navigate = useNavigate();

  const navigateToWebinarDetails = (): void => {
    if (!disabled)
      navigate(navigateToWebinar(item.id));
  };
  const handleButtonClick = (): void => {
    if (!inProgress) {
      getCertificate(item.id);
    }
  };
  const handleQuiz = () => {
    setQuiz(item);
    openQuizModal();
  };
  const speakers = mapFromSpeakers(item.speakers);
  const sponsors = mapFromSponsors(item.sponsors);
  const partners = mapFromSponsors(item.partners);
  const scroll = useRef(null as any)

  const [scrollLeft, setScrollLeft] = useState(0 as number)
  const scrollBack = () => {
    const el = document.getElementsByClassName('client-content')[0] as HTMLElement
    setScrollLeft((prev: number) => prev - el.offsetWidth - 15)
  }
  const scrollForward = () => {
    const el = document.getElementsByClassName('client-content')[0] as HTMLElement
    setScrollLeft((prev: number) => prev + el.offsetWidth + 15)
  }

  useEffect(() => {
    if(scroll.current) {
      scroll.current.scrollLeft = scrollLeft
    }
  }, [scrollLeft])
  const showForward = useMemo(() => {
    return scroll.current ? ((scroll.current.scrollWidth > scroll.current.offsetWidth) && (scrollLeft < (scroll.current.scrollWidth - scroll.current.offsetWidth))) : speakers.length > 3
  }, [scrollLeft, scroll, speakers])
  return (
    <div className="academy__block-current">
      <div className="academy__content-current">
        <div className="academy-title">
          <div className="academy-calendar">
            <img src={calendarIcon} alt="" />
            <p>{moment(item.start_date).format(DATE_FORMATS.webinarDate)}</p>
            <img src={clockIcon} alt="" />
            <p>{moment(item.start_date).format(DATE_FORMATS.webinarTime)}</p>
          </div>
          <p>{`CPD Points: ${getCpdPoints(item)}`}</p>
        </div>
        <div className="academy-desc" onClick={navigateToWebinarDetails}>
          <span>{item.webinar_title}</span>
        </div>
        {item.is_allow_to_get_sertificate ? (
          <div className="download-certificate" onClick={handleButtonClick}>
            <img src={certIcon} alt="" />
            <p>Download certificate</p>
          </div>
        ) : (item.cpd_quiz === 'available' && !item.is_quiz_completed && !hideQuizButton) && (
          <ButtonRound className='webinar__quiz-button' onClick={handleQuiz}>
            Take cpd quiz
          </ButtonRound>
        )}
        <div className="split-line" />
        <div className="client-container-wrapper">
          {scrollLeft > 0 && <div className='back-scroll' onClick={scrollBack}><img src={leftArrow} alt='<' /></div>}
          <div className="client-container" ref={scroll}>
            {speakers.map(speaker => (
              <div key={speaker.id} className="client-content">
                <img src={getImageUrl(speaker.avatar_url)} alt={speaker.name} />
                <p>{speaker.name}</p>
                <p>{speaker.presentation_title}</p>
                <span>{`${speaker.speaker_type}・${speaker.location}`}</span>
              </div>
            ))}
          </div>
          {showForward && <div className='forward-scroll' onClick={scrollForward}><img src={rightArrow} alt='>' /></div>}
        </div>
        <div className="split-line" />
        <div className="sponsors-container">
          <div className="sponsors-content">
            <p>Sponsored by:</p>
            <div className='sponsors-wrapper'>
              {sponsors.map(sponsor => (
                <img key={sponsor.id} src={getImageUrl(sponsor.logo_url)} alt={sponsor.title} />
              ))}
            </div>
          </div>
          <div className="sponsors-content">
            <p>In partnership with:</p>
            <div className='sponsors-wrapper'>
              {partners.map(partner => (
                <img key={partner.id} src={getImageUrl(partner.logo_url)} alt={partner.title} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
