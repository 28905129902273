import { cloneDeep } from "lodash";
import { logoutAdmin, showError500 } from "utils";
import { adminStatisticsService } from "services";
import { statisticsStore } from "../../stores";
import { mapByPage } from "utils";

export const getStatistics = async () => {
  const { meta } = statisticsStore;
  const { status, data } = await adminStatisticsService.getStatistics(meta);
  //   const webinarsResponse = mapFromWebinars(data)

  if (status === 401) {
    logoutAdmin();
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  const _arr = mapByPage(
    meta.page,
    statisticsStore.arr,
    data.statistics.data.map((item: any) => item.attributes)
  );
  const _meta = cloneDeep(meta);
  _meta.total_pages = data.total_pages || 1;

  statisticsStore.meta = _meta;
  statisticsStore.arr = _arr;
};
