import { getCourseMaterials } from "."
import { courseMaterialsStore } from '../../stores'
import { setMeta } from '../sets'

export const mount = (id: number, scope: string) => {
  setMeta({
    ...courseMaterialsStore.meta,
    course_id: id,
  })
  getCourseMaterials(scope)
}