import React from 'react'
import {InputTextField} from 'components/form';
import {observer} from 'mobx-react-lite';
import {getErrors} from "utils";
import {addUserStore} from "../../stores";
import {changeField} from "../../actions/add";
import {IsEdit} from "@/types";

export const Location = observer((props:IsEdit) => {
  if (!props.isEdit) return <div/>

  const {location} = addUserStore.user;

  return (
    <InputTextField
      type='round'
      name='location'
      label='Location'
      placeholder=''
      value={location}
      error={getErrors('location')}
      onChange={changeField}
    />
  )
})