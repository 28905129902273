import {makeAutoObservable} from 'mobx'
import {AdminSponsorType, ImageChangeProps} from "types";
import {cloneDeep} from "lodash";

export const addSponsorStore = makeAutoObservable({
  _sponsor: {} as AdminSponsorType,

  set sponsor(value: AdminSponsorType) {
    this._sponsor = value;
  },

  get sponsor(): AdminSponsorType {
    return this._sponsor;
  },

  changeField(data: ImageChangeProps) {
    const clone = cloneDeep(this._sponsor);
    const key = data.name as keyof AdminSponsorType;

    // @ts-ignore
    clone[key] = data.value;
    this._sponsor = clone;
  },

  clear(): void {
    this._sponsor = {} as AdminSponsorType;
  },

});

