import React from 'react';
import {useNavigate} from 'react-router-dom';
import {CourseType} from 'types';
import {getImageUrl} from 'utils';
import {ButtonRound} from 'components';
import {ROUTES} from '../../../../constants';
import {setCourse} from '../actions/sets';
import {openConfirmModal} from '../actions/modals';

type Item = {
  item: CourseType;
  isMy: boolean;
  key: string;
};

export const Item = (props: Item) => {
  const navigate = useNavigate();
  const {item, isMy} = props;
  const handleItemClick = (item: CourseType) => {
    if (isMy || item.is_member) {
      setCourse(item);
      navigate(ROUTES.webinars);
    } else {
      openConfirmModal(item.id);
    }
  };
  return (
    <div className="academy__block">
      <div className="academy__content">
        <img src={getImageUrl(item.logo_url)} alt="" />
        <p>{item.title}</p>
        <ButtonRound className='open-academy' onClick={() => handleItemClick(item)}>
          {isMy || item.is_member ? 'Open Course' : 'Join Course'}
        </ButtonRound>
      </div>
    </div>
  );
};
