export const getFieldIMC = ({
    accreditation,
    user_type
} : any): string => {
    let field = ''
    if(typeof accreditation === 'string' && accreditation === 'false') accreditation = false
    if(typeof accreditation === 'string' && accreditation === 'true') accreditation = true
    switch(accreditation) {
        case true:
            switch(user_type) {
                case 'gp': case 'gp_trainee': case 'consultant': case 'hospital_doctor':
                    field = 'imc'
                    break
                case 'nurse_specialist': case 'practice_nurse':
                    field = 'pin_number'
                    break
                case 'allied_healthcare_professional':
                    field = 'registration'
                    break
                default:
                    break
            }
            break
        case false:
            field = 'registration'
            break
        default:
            break
    }
    return field
}