import React from "react"
import {ADMIN_ROUTES} from "constants/index";
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {coursesStore} from "../../Courses/stores";
import {isActiveClass} from "utils";

export const LinkCourses = observer(() => {
  const count = coursesStore.count();
  const countText = count === 0 ? '' : `(${count})`;

  return (
    <NavLink to={ADMIN_ROUTES.courses} className={isActiveClass}>
      <button className="all-courses active">All Courses {countText}</button>
    </NavLink>
  )
})