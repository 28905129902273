import {cloneDeep, findIndex} from 'lodash';
import {InputChangeProps} from 'types';
import {QuizQuestion} from '../../types';
import {addWebinarStore} from '../../stores';

export const changeQuestion = (data: QuizQuestion & InputChangeProps) => {
  const _webinar = cloneDeep(addWebinarStore.webinar);
  const _questionIndex = findIndex(_webinar.cpd_quizzes_attributes, (question: QuizQuestion) => question.id === data.id);
  
  _webinar.cpd_quizzes_attributes[_questionIndex] = {
    ..._webinar.cpd_quizzes_attributes[_questionIndex],
    [data.name]: data.value,
  };
  addWebinarStore.webinar = _webinar;
};
