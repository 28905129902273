import {cloneDeep, findIndex} from 'lodash';
import {InputChangeProps} from 'types';
import {Speaker} from '../../types';
import {addWebinarStore} from '../../stores';

export const changeSpeaker = (data: InputChangeProps & {id: string | number}) => {
  const _webinar = cloneDeep(addWebinarStore.webinar);
  const _speakerIndex = findIndex(_webinar.speakers_attributes, (speaker: Speaker) => speaker.id === data.id);

  _webinar.speakers_attributes[_speakerIndex] = {
    ..._webinar.speakers_attributes[_speakerIndex],
    [data.name]: data.value,
  };
  addWebinarStore.webinar = _webinar;
};
