import {localStore} from "stores";
import {authService} from "services";
import {adminHomeHardRedirect, showError500} from "utils";

export const onLogout = async () => {
  const {status, data} = await authService.adminLogout()

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  localStore.clearToken();
  adminHomeHardRedirect();
}