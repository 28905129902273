import {cloneDeep} from 'lodash';
import {defaultErrorsInfo, mapByPage} from "../../../../../utils";
import {cpdLockerServices} from "../../services";
import {cpdLockerStore} from "../../stores";
import {CpdLockerMetaType} from '../../types';
import {mapFromCpdLocker} from '../../utils';

export const getCpdLocker = async () => {
  try {
    const {data} = await cpdLockerServices.getCpdLocker(cpdLockerStore.meta as CpdLockerMetaType);
    const cpdLockerResponse = mapFromCpdLocker(data);
    const cpdLocker = mapByPage(cpdLockerStore.meta.page, cpdLockerStore.cpdLocker, cpdLockerResponse);

    const _meta = cloneDeep(cpdLockerStore.meta);
    _meta.total_pages = data.total_pages || 1;

    cpdLockerStore.meta = _meta;
    cpdLockerStore.cpdLocker = cpdLocker as any;
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
}
