import { makeAutoObservable } from 'mobx'
import { newCourseMaterialType } from "../types"
import { newCourseMaterial } from '../constants'

export const addCourseMaterialModalStore = makeAutoObservable({
  _courseMaterial: newCourseMaterial as newCourseMaterialType,
  _open: false as Boolean,
  _openDelete: null as null | number,
  _openEdit: null as null | number,

  set courseMaterial(value: newCourseMaterialType) {
    this._courseMaterial = value
  },

  get courseMaterial(): newCourseMaterialType {
    return this._courseMaterial
  },

  set openDelete(value: null | number) {
    this._openDelete = value
  },

  get openDelete(): null | number {
    return this._openDelete
  },
  
  set openEdit(value: null | number) {
    this._openEdit = value
  },

  get openEdit(): null | number {
    return this._openEdit
  },

  set open(value: Boolean) {
    this._open = value
  },

  get open(): Boolean {
    return this._open
  },

  toggle(): void {
    this._open = !this._open
  },

  clear(): void {
    this._courseMaterial = newCourseMaterial as newCourseMaterialType
    this._open = false as Boolean
    this._openDelete = null as null | number
    this._openEdit = null as null | number
  }
})

