import { cloneDeep } from "lodash";
import { logoutAdmin, showError500 } from "utils";
import { adminWebinarsService } from "services";
import { webinarsStore } from "../../stores";
import { mapFromWebinars } from "modules/web/Webinars/utils";
import { mapByPage } from "utils";

export const getWebinars = async () => {
  const { meta } = webinarsStore;
  const { status, data } = await adminWebinarsService.getWebinars(meta);

  if (status === 401) {
    logoutAdmin();
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  const webinarsResponse = mapFromWebinars(data);
  const _webinars = mapByPage(
    meta.page,
    webinarsStore.webinars,
    webinarsResponse
  );
  const _meta = cloneDeep(meta);
  _meta.total_pages = data.total_pages || 1;

  webinarsStore.meta = _meta;
  webinarsStore.webinars = _webinars;
};
