import React from 'react';
import {observer} from 'mobx-react-lite';
import {TimePicker} from '@mui/x-date-pickers';
import {TextField} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {addWebinarStore} from '../../stores';
import {changeField} from '../../actions/add';
import {getErrors} from "utils";
import {DisabledPropType} from 'types';

type TimeInputProps = {
  label: string;
  name: string;
};

export const TimeInput = observer((props: TimeInputProps & DisabledPropType) => {
  const {label, name, disabled} = props;
  const {webinar} = addWebinarStore;
  const errors = getErrors(name);
  const hasError = errors.length > 0;
  const onChange = (value: string | null) => {
    changeField({name, value: value || ''});
  };

  return (
    <div className='group'>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
          label={label}
          value={webinar[name] || new Date()}
          onChange={onChange}
          disabled={disabled}
          renderInput={(params) => <TextField {...params} error={hasError} />}
        />
      </LocalizationProvider>
      <div className='form-error'>{''}</div>
    </div>
  );
});
