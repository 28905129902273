import axios, {AxiosError, AxiosResponse, ResponseType} from 'axios';
import {commonStore} from './stores/commonStore';
import {
  API_LOGIN,
  API_CURRENT_USER,
  API_REGISTER,
  API_CONFIRM_EMAIL,
  API_FORGOT_PASSWORD,
  API_COURSES,
  API_MY_COURSES,
  API_JOIN_COURSE,
  API_WEBINARS,
  API_SUBSCRIBE_WEBINAR,
  API_REGISTER_WEBINAR,
  API_MEMBERS,
  API_COURSE_MATERIALS,
  API_NOTIFICATIONS,
  API_UPDATE_PASSWORD,
  API_CPD_LOCKER,
  API_CERTIFICATE,
  API_SUBMIT_QUIZ,
} from './constants';
import {WebinarsMetaType} from './modules/web/Webinars/types';
import {MembersMetaType} from './modules/web/Members/types';
import {CourseMaterialsMetaType} from './modules/web/CourseMaterials/types';
import {CpdLockerMetaType} from './modules/web/CpdLocker/types';
import {newPasswordStore} from './modules/web/NewPassword/stores';

const {REACT_APP_API_URL} = process.env;
const instance = axios.create({baseURL: REACT_APP_API_URL});

const handleErrors = (error: AxiosError) => {
  if (error && error.response && error.response.status === 401 && commonStore.token) {
    commonStore.logout();
  }
  throw error;
};

const responseData = (res: AxiosResponse) => res;

const getHeaders = (contentType?: string) => {
  const headers = {
    'Content-Type': contentType || 'application/json',
  }
  if (commonStore.token) {
    return {
      ...headers,
      'Authorization': `Bearer ${commonStore.token}`,
    };
  }
  return headers;
};

const getCourseId = () => `&course_id=${commonStore.selectedCurseId}`;
const pagination = (page: string | number, perPage: string | number) => `page=${page}&per_page=${perPage}`;
const query = (query: string | undefined) => query ? `&query=${query}` : '';
const getPasswordToken = () => `${newPasswordStore.token}`;

const requests = {
  del: (url: string) =>
    instance
      .delete(url, {headers: getHeaders()})
      .then(responseData)
      .catch(handleErrors),
  get: (url: string, responseType?: ResponseType) =>
    instance
      .get(url, {headers: getHeaders(), responseType})
      .then(responseData)
      .catch(handleErrors),
  put: (url: string, body: any, contentType?: string) =>
    instance
      .put(url, body, {headers: getHeaders(contentType)})
      .then(responseData)
      .catch(handleErrors),
  post: (url: string, body: any, contentType?: string) =>
    instance
      .post(url, body, {headers: getHeaders(contentType)})
      .then(responseData)
      .catch(handleErrors),
};

const Auth = {
  current: () =>
    requests.get(API_CURRENT_USER),
  login: (email: string, password: string) =>
    requests.post(API_LOGIN, JSON.stringify({email, password})),
  register: (formData: FormData) =>
    requests.post(API_REGISTER, formData, 'multipart/form-data'),
  confirmEmail: (token: string, id: string | number) =>
    requests.put(`${API_CONFIRM_EMAIL}/${id}`, JSON.stringify({token})),
  updateUser: (user: FormData, id: number | string) =>
    requests.put(`${API_REGISTER}/${id}`, user, 'multipart/form-data'),
  forgotPassword: (email: string) =>
    requests.post(API_FORGOT_PASSWORD, JSON.stringify({email})),
  updatePassword: (formData: FormData) =>
    requests.put(`${API_UPDATE_PASSWORD}/${getPasswordToken()}`, formData, 'multipart/form-data'),
};

const Courses = {
  allCourses: () =>
    requests.get(API_COURSES),
  myCourses: () =>
    requests.get(API_MY_COURSES),
  joinCourse: (courseId: number) =>
    requests.post(API_JOIN_COURSE, JSON.stringify({invite: {course_id: courseId}})),
};

const Webinars = {
  getWebinars: (meta: WebinarsMetaType) =>
    requests.get(`${API_WEBINARS}?scope=${meta.scope}${getCourseId()}&${pagination(meta.page, meta.perPage)}`),
  subscribeWebinar: (webinarId: number) =>
    requests.post(API_SUBSCRIBE_WEBINAR, JSON.stringify({webinar_id: webinarId})),
  registerWebinar: (formData: FormData) =>
    requests.post(API_REGISTER_WEBINAR, formData, 'multipart/form-data'),
  getWebinar: (webinarId: string) =>
    requests.get(`${API_WEBINARS}/${webinarId}`),
  submitAnswers: (webinarId: number, answers: any) =>
    requests.put(`${API_SUBMIT_QUIZ}/${webinarId}`, JSON.stringify({answers})),
};

const WebinarReview = {
  postReview: (webinarId: number, review: any[]) =>
    requests.post(`api/v1/user_feedback?webinar_id=${webinarId}`, JSON.stringify({user_feedback: review}))
};

const Members = {
  getMembers: (meta: MembersMetaType) =>
    requests.get(`${API_MEMBERS}?${pagination(meta.page, meta.perPage)}${getCourseId()}`)
};

const CourseMaterials = {
  getCourseMaterials: (meta: CourseMaterialsMetaType) =>
    requests.get(`${API_COURSE_MATERIALS}?${pagination(meta.page, meta.perPage)}${query(meta.query)}${getCourseId()}&scope=approved`),
  createCourseMaterial: (formData: FormData) =>
    requests.post(`${API_COURSE_MATERIALS}?${getCourseId()}`, formData, 'multipart/form-data'),
};

const Notifications = {
  getNotifications: () =>
    requests.get(API_NOTIFICATIONS),
};

const CpdLocker = {
  getCpdLocker: (meta: CpdLockerMetaType) =>
    requests.get(`${API_CPD_LOCKER}?scope=${meta.scope}${getCourseId()}&${pagination(meta.page, meta.perPage)}`),
  getCertificate: (webinarId: number) =>
    requests.get(`${API_CERTIFICATE}/${webinarId}`, 'blob'),
};

const agent = {
  Auth,
  Courses,
  Webinars,
  Members,
  CourseMaterials,
  Notifications,
  CpdLocker,
  WebinarReview
};

export default agent;