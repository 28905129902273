import React from "react";
import { observer } from "mobx-react-lite";
import { addWebinarStore, assignedUsersModalStore } from "../stores";
import { toggleModal } from "../actions/modals";
import CloseIcon from "assets/img/png/ic-close.png";

export const AssignedUsersModal = observer(() => {
  const { show, assignedUsers } = assignedUsersModalStore;
  const { webinar } = addWebinarStore;

  if (!show) {
    return <div />;
  }

  const users = webinar.assigned_users ?? [];

  return (
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-container-academy">
        <span className="close">×</span>
        <div className="modal-content-create__academy modal-content_assigned-container">
          <div className="assigned-list">
            <div className="assigned-header">
              <div className="assigned-number">
                <p>№</p>
              </div>
              <p>User Name</p>
              <p>User Email</p>
              <div className="modal-content-academy__headline">
                <img
                  src={CloseIcon}
                  alt="close modal button"
                  onClick={toggleModal}
                />
              </div>
            </div>
            <div>
              {users.map((user: any, index: number) => {
                const { id, first_name, last_name, email } = user;

                return (
                  <div key={id} className="assigned-user">
                    <div className="assigned-number">
                      <p>{index + 1}</p>
                    </div>
                    <p>{`${first_name} ${last_name}`}</p>
                    <p>{email}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
