import {makeAutoObservable} from 'mobx'
import {AdminUserType} from "types";

export const infoModalStore = makeAutoObservable({
  _user: {} as AdminUserType,
  _show: false,

  set user(value: AdminUserType) {
    this._user = value;
  },

  get user(): AdminUserType {
    return this._user;
  },

  set show(value: boolean) {
    this._show = value;
  },

  get show(): boolean {
    return this._show;
  },

  clear():void {
    this._user = {} as AdminUserType
    this._show = false;
  },

});

