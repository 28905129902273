import React, { useEffect } from "react";
import { mount, unmount, getUsers } from "../../../Users/actions/gets";
import { observer } from "mobx-react-lite";
import { cloneDeep } from "lodash";
import { ListHeader, ListItem, ListSearch } from ".";
import { ButtonRound } from "components";
import { AdminUserType } from "types";
import { usersStore } from "../../../Users/stores";
import { setMeta } from "../../../Users/actions/sets";
import { addWebinarStore, assignedUsersModalStore } from "../../stores";
import { useNavigate, useParams } from "react-router-dom";
import { setAssignedUsers } from "../../actions/sets";

export const List = observer(() => {
  const { id } = useParams();
  const { webinar } = addWebinarStore;
  const { users, meta } = usersStore;
  const { assignedUsers } = assignedUsersModalStore;
  const navigate = useNavigate();
  const loadMore = () => {
    const _meta = cloneDeep(meta);
    _meta.page = meta.page + 1;
    setMeta(_meta);
    getUsers();
  };

  React.useEffect(() => {
    mount(id!);

    return unmount();
  }, []);

  useEffect(() => {
    if (webinar && webinar.assigned_ids && webinar.assigned_ids.length) {
      setAssignedUsers(webinar.assigned_users);
    }
  }, [webinar]);

  return (
    <div className="superhost__container">
      <ListSearch />
      <ListHeader />
      <div className="superhost__content">
        {users.map((item: AdminUserType, index: number) => (
          <ListItem key={"admin-users-key-" + index} user={item} />
        ))}
      </div>
      {meta.total_pages > meta.page ? (
        <div className="container-button">
          <ButtonRound className="load-more" onClick={loadMore}>
            Load more
          </ButtonRound>
        </div>
      ) : null}
    </div>
  );
});
