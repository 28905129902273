import React from "react";
import {observer} from 'mobx-react-lite';
import moment from "moment";
import {DATE_FORMATS} from '../../../constants';
import {notificationsStore} from './stores';
import {closeNotifications} from './actions/sets';

export const Notifications = observer(() => {
  const {showNotifications, notifications} = notificationsStore;
  const handleCloseModal = () => {
    closeNotifications();
  };
  return showNotifications ? (
    <div id="myModal" className="notifications-modal" onClick={handleCloseModal}>
      <div className="modal-content">
        <span className="close" onClick={handleCloseModal}>&times;</span>
        <h3>Notifications</h3>
        <div className="notifications-desc">
          {notifications.map(notification => (
            <div key={notification.id} className="desc-item">
              <p><b>{notification.title}</b>{` ${notification.body}`}</p>
              <br />
              <p>{`${moment(notification.created_at).format(DATE_FORMATS.webinarDate)} ${moment(notification.created_at).format(DATE_FORMATS.webinarTime)}`}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null
});