import {makeAutoObservable} from 'mobx'

export const addModalStore = makeAutoObservable({
  _show: false,

  set show(value: boolean) {
    this._show = value;
  },

  get show(): boolean {
    return this._show;
  },

  clear():void {
    this._show = false;
  },

});

