import {Labels} from 'types';

export const ACCOUNT_LABELS: Labels = {
  title: 'Title',
  first_name: 'First name',
  last_name: 'Last name',
  email: 'Email',
  confirm_email: 'Confirm email',
  password: 'Password',
  user_type: 'I am a',
  imc: 'IMC Number',
  registration: 'Accreditation Number',
  profession: 'Profession',
  ipna_member: 'IGPNEA member',
  branch: 'Branch',
  country: 'Country',
  county: 'County',
  accreditation: 'Accreditation',
  pin_number: 'NMBI PIN Number',
  discipline: 'Discipline',
  phone: 'Phone number',
  location: 'Location - Hospital',
}
