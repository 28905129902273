import React, {useEffect} from 'react'
import {observer} from "mobx-react-lite";
import {adminAuthStore, localStore} from "../../../stores";
import {ADMIN_ROUTES} from "../../../constants";
import {adminLoginHardRedirect} from "../../../utils";
import {getUserProfile, onLogout} from "./actions";

export const AuthBlock = observer(() => {
  const {first_name, last_name} = adminAuthStore.user;
  const {auth} = adminAuthStore;
  const {token} = localStore;

  useEffect(() => {
      if (token) {
        getUserProfile();
      }

    }, []
  );

  React.useEffect(
    () => {
      const isLoginPage = window.location.pathname === ADMIN_ROUTES.login

      if (!token && !isLoginPage) {
        adminLoginHardRedirect()
      } else {
        getUserProfile();
      }
    },
    []
  );

  if (!auth) {
    return <div className="user__login"/>
  }

  return (
    <div className="user__login">
      <p>{first_name + ' ' + last_name}</p>
      <button className="logout" onClick={onLogout}>Logout</button>
    </div>
  )
})