import React from 'react'
import {observer} from 'mobx-react-lite';
import {addUserStore} from "../../stores";
import DefaultAvatar from "assets/img/png/user_avatar.png";
import {ImageField} from "components/form";
import {changeField, addAvatarPreview} from "../../actions/add";
import {toast} from "react-toastify";

export const Avatar = observer(() => {
  const {id, avatar, avatar_url, first_name, last_name} = addUserStore.user;

  const maxFileSize = 1024;
  const accept = '.jpg, .jpeg, .png'
  const name = 'avatar'
  const src = avatar_url ? avatar_url : DefaultAvatar;
  const userName = first_name + ' ' + last_name;
  const text = avatar ? 'Change Photo' : 'Upload Photo';

  const onFileChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;

    if (files && files[0]) {
      const file = files[0];

      if (file.size / 1024 > maxFileSize) {
        toast.error('File is too big. Max file size is ' + maxFileSize + ' Kb');
        return;
      }

      changeField({name, value: file});
      addAvatarPreview({name: 'avatar_url', value: file});
    }
  };

  return (
    <div className="user-desc">
      <div className="user-logo">
        <img className='round-image' src={src} width={80} height={80} alt="" />
      </div>
      <div className="user-info admin-user">
        <h2>{userName}</h2>

        <div className="user-options">
          <label className="file-upload-label">
            <input type="file"
                   id="file-upload"
                   accept={accept}
                   name={name}
                   onChange={onFileChange}
                   multiple={false} />
              {text}
          </label>

          {avatar ? <span>Delete Photo</span> : false}
        </div>
      </div>
    </div>
  )
})