import React from "react"
import {observer} from "mobx-react-lite";
import {invitesStore} from "../../stores";
import {STATUS_PENDING} from "constants/inviteStatuses";

export const ListHeader = observer(() => {
  const showTitle = invitesStore.inviteStatus === STATUS_PENDING;

  return (
    <div className="users__header">
      <div className="header-categories-sponsors webinars">
        <div className="name">
          <p>User Name</p>
        </div>
        <div className="name">
          <p>User Email</p>
        </div>
        <div className="name">
          <p>Course Title</p>
        </div>
      </div>
      <div className="header-status">
        <div className="status">
          <p>{showTitle ? 'Status' : ''}</p>
        </div>
      </div>

    </div>
  )
})