import React from 'react';
import {Page} from '../Page';
import {SettingsForm} from './components/SettingsForm';

export const Settings = () => {
  return (
    <Page>
      <SettingsForm />
    </Page>
  )
};
