import {paramsToFormData, showError500, validateBE} from "utils";
import {addSponsorStore} from "../../stores";
import {sponsorsService} from "../../services";
import {toast} from "react-toastify";
import {cloneDeep} from "lodash";
import {errorsStore} from "stores";
import {pauseModalAutoClose} from "../";

export const onSubmitEditSponsor = async () => {
  errorsStore.clear();
  const storeData = cloneDeep(addSponsorStore.sponsor);
  const id = storeData.id;

  // TODO 1 add validation
  // validate js
  const isValid = true;

  if (!isValid) {
    return false;
  }

  const formData = paramsToFormData(storeData, 'sponsor');
  const {status, data} = await sponsorsService.editSponsor(formData, id)

  if (status === 401 || status === 403 || status === 400) {
    validateBE(data.error_messages, ['title', 'namespace']);
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  toast.success(`Sponsor was edited successfully... auto close`);

  pauseModalAutoClose();
}