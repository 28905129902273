import React from 'react'
import {SelectField} from 'components/form';
import {changeField,} from '../../actions/add';
import {observer} from 'mobx-react-lite';
import {getErrors} from "utils";
import {addUserStore} from "../../stores";
import {COUNTRIES} from "constants/countries";
import {IsEdit} from "@/types";

export const Country = observer((props:IsEdit) => {
  if (!props.isEdit) return <div/>

  const {country} = addUserStore.user;

  const getOptions = () => COUNTRIES.map((item:any, index:number) => ({key: 'country-type-key-' + index, text: item.label, value: item.value}))

  return (
    <SelectField
      name='country'
      label='Country'
      value={country}
      setDefaultValue={true}
      options={getOptions()}
      error={getErrors('country')}
      onChange={changeField}
    />
  )
})