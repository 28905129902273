import React from "react"
import {Page} from "../Page";

export const Terms = () => {
  return (
    <Page>
      <div className="terms">
        <div className="terms-list">
          <h1>Terms of Service</h1>
          <p>
            1.1 The www.medcafe.academy.ie website is a resource for regulated Medical Professionals practicing in
            Ireland. The service enables registered healthcare professionals with a valid IMC number, Nursing and
            Midwifery Board PIN (or equivalent registration number) to participate in webinars.
          </p>
          <p>
            The domain https://www.medcafe.academy.ie/, the facilities, services and information available thereon (our
            "Services"), are owned and operated by MedCafe Academy Technology Services Ltd, trading as MedCafe Academy
            ("MedCafe"Academy), a company registered in Ireland, number 628757, having its registered office at Unit
            101, 4, Burton Hall Park, Burton Hall Road, Sandyford, Dublin 18.
          </p>
          <p>
            1.2 Your usage of our Service is governed by the continuous application of these Terms of Service. For the
            purpose of these Terms of Service "we", "our" and "us" refers to MedCafe Academy Technology Solutions Ltd,
            trading as MedCafe Academy, and "you" refers to you, a registered user of our Service and/or any facilities,
            services or information available thereon.</p>
          <p>
            1.3 For the purpose of these Terms of Service, "Medical Practitioner" means any individual who is registered
            with, and regulated by, the Irish Medical Council, the Nursing and Midwifery Board of Ireland or who is
            registered with and regulated by an equivalent competent body in another Member State of the European Union.
          </p>
        </div>
        <div className="terms-list">
          <h1>2. IMPORTANT NOTICE</h1>
          <p>
            2.1 WE ARE NOT INVOLVED IN THE PROVISION OF ANY HEALTHCARE OR MEDICAL ADVICE OR DIAGNOSIS. ALL INFORMATION
            PROVIDED VIA OUR SERVICE IS INTENDED FOR INFORMATIONAL PURPOSES ONLY.
          </p>
          <p>
            2.2 WE DO NOT SCREEN OR VALIDATE ANY CONTENT POSTED BY ANY MEDICAL OR HEALTHCARE PROFESSIONALS, NOR DO WE
            ENDORSE ANY PARTICULAR MEDICAL OR HEALTHCARE PROFESSIONAL.
          </p>
          <p>
            2.3 IF YOU USE OUR SERVICE TO SELECT A MEDICAL OR HEALTHCARE PROFESSIONAL TO PROVIDE MEDICAL SERVICES TO
            YOUR PATIENTS, YOU DO SO AT YOUR OWN RISK.</p>
          <p>
            2.4 IF YOU SHARE ANY PATIENT RELATED CASE INFORMATION WITH A WEBINAR PRESENTER OR OTHER HEALTHCARE
            PROFESSIONALS PARTICIPATING IN THE WEBINAR, IT IS YOUR RESPONSIBILITY TO ENSURE THAT THE IDENTITY OF YOUR
            PATIENT IS PROTECTED AT ALL TIMES.
          </p>
        </div>
        <div className="terms-list">
          <h1>3. ACCEPTING THE TERMS OF SERVICE</h1>
          <p>
            3.1 In order to use our Service you must firstly agree to these Terms of Service. You may not use our
            Service if you do not accept these Terms of Service.
          </p>
          <p>
            3.2 You can accept these Terms of Service by actually using our Service. You understand and agree that we
            will treat your use of our Service as acceptance of these Terms of Service from that point onwards.
          </p>
          <p>
            3.3 You may not use our Service and may not accept these Terms of Service if you are not a registered user.
            To be eligible to register as a user of our Service you must be:
            1. A Medical Practitioner, be registered with the Irish Medical Council or the Nursing and Midwifery Board
            of Ireland, and be subject to the possible exercise of ongoing supervisory and/or regulatory control by the
            Irish Medical Council (IMC), the Nursing and Midwifery Board of Ireland or by an equivalent competent body
            in another European Union Member State, or
            2. Any other third party or service-provider approved from time to time by us.
          </p>
        </div>
        <div className="terms-list">
          <h1>4. CHANGES TO THESE TERMS OF SERVICE</h1>
          <p>
            4.1 We reserve the right to modify or terminate any services offered from time to time, for any reason and
            without notice, and without liability to you, to any of our clients or to any other third party. We reserve
            the right to change the content, presentation, performance, user facilities and/or availability of any part
            of these Services including these Terms of Service at our sole discretion from time to time.
          </p>
          <p>
            4.2 We will endeavour to bring any material changes to these Terms of Service to your attention. If we do
            amend these Terms of Service, the amended terms will be effective when posted on our Service. You, the user,
            are responsible for regularly reviewing these Terms of Service and any additional terms posted on our
            Service. By continuing to access or use the Services after any changes become effective, you agree to be
            bound by the revised Terms of Service. If you do not agree to the new terms, please stop using the Services.
          </p>
        </div>
        <div className="terms-list">
          <h1>5. USE OF THE SERVICE</h1>
          <p>
            5.1 MedCafe Technology Solutions Ltd requires you to open an account in order to access our Service. You
            must complete the registration process and all the information supplied must be complete and accurate. You
            will be asked to choose a password, and the responsibility for maintaining the privacy of this account and
            the password to access it is entirely yours. No compensation will be made to you for the set-up or operation
            of your account.
          </p>
          <p>
            5.2 You will be asked to provide your name, email address, Irish Medical Council Registration Number ("IMC
            Number") or Nursing and Midwifery Board of Ireland PIN Number and a password. By participating in the
            registration, you acknowledge that you are a qualified medical professional, registered with the Irish
            Medical Council in Ireland, the Nursing and Midwifery Board of Ireland, or equivalent in another European
            Union Member State, and are therefore confirm your eligibility in accordance with paragraph 3.3 above to use
            our Service.
          </p>
          <p>
            5.3 Whether you register as a Medical Practitioner or a healthcare professional or service provider or other
            third party consented to by us, you agree not to use the account or password of another user at any time or
            to disclose your password to any third party.
          </p>
          <p>
            5.4 You agree to notify MedCafe Technology Solutions Ltd immediately if you suspect any unauthorised use of
            your account or access to your password or any other breach of the security of your account or to the
            security of our Service.
          </p>
          <p>
            5.5 You are responsible for maintaining the confidentiality of your password and account, and you are solely
            and fully responsible for all activities that occur under your password or account. You are prohibited from
            selling, trading or otherwise transferring your MedCafe account to another party. MedCafe Technology
            Solutions Ltd cannot and will not be liable for any loss or damage arising from your failure to comply with
            this obligation.
          </p>
          <p>
            5.6 Once set up, your account will continue indefinitely, regardless of usage levels, unless terminated
            either by you or by us. You can terminate your account by contacting MedCafe Technology Solutions Ltd in
            writing at support@medcafe.ie.
          </p>
          <p>
            5.7 MedCafe Technology Solutions Ltd reserve the right to suspend or terminate your account without prior
            notice at our discretion, in accordance with paragraph 11 hereunder, where we suspect that you have
            fraudulently or otherwise obtained access to our Service by breaching these Terms of Service, or any
            paragraph or sub- paragraph thereof.
          </p>
        </div>
        <div className="terms-list">
          <h1>6. SERVICES WE PROVIDE</h1>
          <p>
            6.1 Our Service enables registered users to discuss issues of a clinical and non clinical nature with
            trusted peers. MedCafe is designed for professional discussion between Medical Practitioners.
          </p>
          <p>
            6.2 We do not make any warranty, either express or implied, as to the veracity or accuracy of any of the
            information available on our Service. You expressly agree that any such use of any or all of the information
            that you obtain from our Service, is done at entirely your own risk and that you will not seek to be
            indemnified by us for any loss, howsoever caused, to you or to your employees, agents or patients, arising
            out of the usage made of any information that you, the user, obtain from our Service.
          </p>
        </div>
        <div className="terms-list">
          <h1>7. SERVICES WE DO NOT PROVIDE</h1>
          <p>
            7.1 Our Service is not a medical advisory service and we do not endorse, recommend, or approve any Medical
            Practitioners or healthcare professionals referred to by name in our Service.
          </p>
          <p>
            7.2 In no way do we guarantee or endorse the competence, authenticity, quality, safety or legality of any
            Medical Practitioner, healthcare professional or any medical services provided or purported to be provided
            by any such Medical Practitioners or healthcare professionals, nor do we guarantee the accuracy of any
            information the Services may provide to you about any such Medical Practitioner, healthcare professional or
            any such medical service.
          </p>
        </div>
        <div className="terms-list">
          <h1>8. CONTENT POLICY</h1>
          <p>
            8.1 We have no obligation to screen content or information in advance, nor are we responsible for screening
            or monitoring material posted by you or any other user.
          </p>
          <p>
            8.2 We cannot and have not checked the accuracy of all information available on our Service.
          </p>
          <p>
            8.3 You are solely responsible for the content and information you provide to us to be published on the
            Services, either through our online data entry forms or other means by which you may opt to provide
            information to us. We reserve the right to edit or remove your content if we suspect it is untrue or that it
            may incur liability for us.
          </p>
        </div>
        <div className="terms-list">
          <h1>9. INTERACTIVE CONTENT</h1>
          <p>
            9.1 MedCafe cannot endorse or stand by the accuracy, truthfulness or reliability of any material inputted by
            users. Statements made by users of MedCafe only reflect the views of that user, and are not representative
            of the views of the MedCafe or any of its employees, agents or advertisers.
          </p>
          <p>
            9.2 You are solely responsible for your conduct and any material that you submit, post, and/or display on
            MedCafe. You agree not to post material contrary to this Content Policy or our Terms of Service.
          </p>
          <p>
            9.3 As with any web interaction, we suggest that you use caution and good judgment. If there is a dispute
            between you and any third party (including any advertiser), we are under no obligation to become involved.
          </p>
          <p>
            9.4 We have no obligation to verify the identity of any users when they are connected to the site or to
            monitor material provided by them.
          </p>
          <p>
            9.5 You represent and warrant that: (i) you own the material posted by you or otherwise have the right to
            grant the licence set forth in this section, and (ii) the posting of the material does not violate the
            privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.
          </p>
        </div>
        <div className="terms-list">
          <h1>10. RULES REGARDING MATERIAL POSTED AS INTERACTIVE CONTENT</h1>
          <p>
            10.1 MedCafe is not responsible for and does not endorse the contents of user posts. You are entirely
            responsible for material which you post.
          </p>
          <p>
            10.2 Please be aware before you submit a question or a comment that you will not be offered an opportunity
            to edit it, and we may not be able to remove it post-publication.
          </p>
          <div className="terms-list-container">
            <p>
              10.3 You agree to abide by the following obligations in respect of any material you submit to MedCafe for
              posting on our Service:
            </p>
            <span>
                          1. Do NOT make comments which are racist, sexist, homophobic, offensive on the grounds of religion, nationality or demographic group;
                        </span>
            <span>
                          2. Do NOT make any comments which violate the criminal law;
                        </span>
            <span>
                          3. Do NOT make any comments which promote or encourage illegal activity;
                        </span>
            <span>
                          4. Do NOT make any comments which may prejudice ongoing civil or criminal proceedings, violate the sub judice rule or may otherwise amount to a contempt of court;
                        </span>
            <span>
                          5. Do NOT make any comment which falsely claims to be sponsored or endorsed by MedCafe;
                        </span>
            <span>
                          6. Do NOT make any comments which are defamatory, invasive of privacy, abusive, harassing, threatening, profane, violent, sexually explicit, or which otherwise violates the rights of others, or which could be perceived as such;
                        </span>
            <span>
                          7. Do NOT post personal information or make derogatory remarks about commenters or any other individuals. Any such material may be removed without notification;
                        </span>
            <span>
                          8. Do NOT use comments to promote commercial products or services without our explicit permission. However, we welcome the use of links to relevant blogs and sites;
                        </span>
            <span>
                          9. Do NOT make any comments which infringe the copyright, patent, trademark, trade secret, database right, right of privacy or publicity or other proprietary right of any party;
                        </span>
            <span>
                          10. Do NOT make any comments which include any spam, unauthorised advertising or any other form of commercial publicity;
                        </span>
            <span>
                          11. Do NOT make any comments which are irrelevant, repetitive or imposes an unreasonable or disproportionately large load the site servers, bandwidth or infrastructure;
                        </span>
            <span>
                          12. Do NOT make any comments which contain any virus, Trojan horse, worm or similar harmful material;
                        </span>
            <span>
                          13. Do NOT seek redress from MedCafe if you suffer loss or damage, or if you are harmed, defamed or offended by material, information, products or services which you accessed or obtained through or which were posted on MedCafe or any related service;
                        </span>
            <span>
                          14. Do NOT discuss religious topics;
                        </span>
            <span>
                          15. Do obtain patient consent before posting any material on this site that includes identifiable or potentially identifiable case material;
                        </span>
            <span>
                          16. Do comply with all applicable laws and regulations, including those related to privacy, personal data, international communications, or the export of technical data;
                        </span>
            <span>
                          17. Do act in a professional manner;
                        </span>
            <span>
                          18. Recognising the global nature of the Internet, you agree to comply with all applicable laws which may apply to you, including but not limited to those regarding acceptable content and online conduct.
                        </span>
            <span>
                          19. You agree to comply with all applicable laws regarding the transmission of technical data exported from the country in which you reside.
                        </span>
          </div>
          <p>
            10.4 Recognising the global nature of the Internet, you agree to comply with all applicable laws which may
            apply to you, including but not limited to those regarding acceptable content and online conduct.
          </p>
          <p>
            10.5 You agree to comply with all applicable laws regarding the transmission of technical data exported from
            the country in which you reside.
          </p>
        </div>
        <div className="terms-list">
          <h1>13. AGE AND RESPONSIBILITY</h1>
          <p>
            13.1 Our Service may only be used by individuals who are of the requisite legal capacity to be bound by
            these Terms of Service. Each time you access our Service you are representing to MedCafe Technology
            Solutions Ltd that you are at least 18 years of age.
          </p>
        </div>
        <div className="terms-list">
          <h1>14. PRIVACY AND DATA PROTECTION</h1>
          <p>
            14.1 In accordance with the MedCafe Privacy Policy, MedCafe Technology Solutions Ltd respects your privacy.
          </p>
          <p>
            14.2 We endeavour at all times to protect your privacy and the privacy of the other users who access the
            Service and use its facilities in accordance with obligations laid down in Irish law. For full details of
            the manner in which we use the information provided by you, the type of information we collect and the
            manner in which cookies are used on our Service, and for further details concerning the circumstances upon
            which we disclose information, please read the Privacy Statement which is hereby incorporated into, and
            forms part of, these Terms of Service.
          </p>
          <p>
            14.3 You may from time-to time receive information from our partners and/or carefully selected third
            parties. You can opt out of this informational service at any stage and still remain a registered member of
            our Service.
          </p>
          <p>
            14.4 Our Service is hosted in the European Union.
          </p>
          <p>
            14.5 MedCafe Technology Solutions Ltd holds data in accordance with the provisions of the Data Protection
            Act 1998, and will adhere to any other relevant future legislation in this area. Under this legislation, you
            the user can obtain a copy of all information which MedCafe Technology Solutions Ltd has on its database in
            relation to use. Members wishing to obtain such information should contact us in writing at the following
            email address: support@medcafe.ie.
          </p>
          <p>
            14.6 Our Service contains links to other external Services. We are not responsible for the privacy practices
            or the content of such external Services, and we do not make any warranties in this regard, either express
            or implied.
          </p>
          <p>
            14.7 Information sent over the internet cannot be guaranteed to be completely secure as it is subject to
            possible interception, or loss, or possible alteration. You understand and agree to assume the security risk
            for any and all information you provide using the Service, including but not limited to information relating
            to any financial transaction that may be conducted between you and us in the course of our business or any
            electronic patient referral initiated by you. Please notify us immediately of any compromise or unauthorised
            use of your account.
          </p>
          <p>
            14.8 We are not responsible for any information sent over the internet and we will not be liable to you or
            any other party for any loss or damage, howsoever caused, or any other loss whatsoever, incurred in
            connection with any information sent by you to us, or to another Medical Practitioner or healthcare
            professional, or any information sent by us, by a Medical Practitioner or healthcare professional or by any
            third party to you, either through the medium of our Service, or otherwise over the internet.
          </p>
        </div>
        <div className="terms-list">
          <h1>15. SUSPENSION/TERMINATION</h1>
          <p>
            15.1 Whilst we will make reasonable attempts to inform you, provided that we deem it to be in our mutual
            interest to do so, we may at any time, without notice to you, suspend or terminate your access to our
            Service, or any service forming part of our Service, wholly or partially, for any reason including, but
            without limitation, where you have provided false or misleading information to us, or you are in breach of
            our Terms of Service, or if we cannot verify or authenticate any information submitted by you, or purported
            to have been submitted by you or on your behalf, to our Service.
          </p>
          <p>
            15.2 We shall not be liable to you or any third party for any suspension or termination of access to our
            Service, which includes any resulting damage that may allegedly arise to you, your employees or your agents,
            howsoever caused.o
          </p>
        </div>
        <div className="terms-list">
          <h1>16. USE OF OUR SERVICE</h1>
          <div className="terms-list-container">
            <p>
              16.1 You agree not to use this Service or any of the facilities and/or services provided by us for any
              purpose that is fraudulent, unlawful or prohibited by these Terms of Service, or otherwise in accordance
              with law. In particular, you agree NOT to use MedCafe to:
            </p>
            <span>
                        1. post illegal material;
                      </span>
            <span>
                        2. defame, abuse, harass, stalk, threaten or otherwise violate the rights (such as rights of privacy and publicity) of others;
                      </span>
            <span>
                        3. identify or speculate as to the identity of any anonymous or pseudonymous user;
                      </span>
            <span>
                        4. solicit personal information from anyone under 18;
                      </span>
            <span>
                        5. solicit passwords or personally identifying information for commercial or unlawful purposes;
                      </span>
            <span>
                        6. collect or store other users' personal data for purposes other than establishing contact that is reasonably expected to be welcomed by such other users;
                      </span>
            <span>
                        7. attempt to gain unauthorised access to the MedCafe administrative areas or interface, to user accounts, computer systems or networks connected to the MedCafe site, through password mining or any other means;
                      </span>
            <span>
                        8. post irrelevant material, repeatedly post the same or similar material or otherwise impose an unreasonable or disproportionately large load on the MedCafe servers or infrastructure;
                      </span>
            <span>
                        9. post any unsolicited or unauthorised advertising, promotional content, 'junk mail', 'spam', 'chain letters', 'pyramid schemes', or any other form of commercial publicity;
                      </span>
            <span>
                        10. post any abusive, harmful, vulgar, obscene, sexually explicit, indecent, profane, inappropriate, or racially, ethnically or otherwise objectionable material;
                      </span>
            <span>
                        11. post material that contains violence, or offensive subject matter or contains a link to adult services or is sexually explicit;
                      </span>
            <span>
                        12. post material that promotes or encourages illegal activity;
                      </span>
            <span>
                        13. post material that infringes any patent, trademark, trade secret, copyright, rights of privacy or publicity, or other proprietary right of any party;
                      </span>
            <span>
                        14. post material that falsely expresses or implies that such material is sponsored or endorsed by Us;
                      </span>
            <span>
                        15. post material that contains software viruses or harmful programmes including but not limited to, Trojan horses, worms, time bombs, cancel-bots, computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
                      </span>
            <span>
                        16. modify, adapt, translate, or reverse engineer any portion of MedCafe, or use any robot, spider, site search/retrieval application, or other device to retrieve or index any portion of MedCafe, except as expressly authorised in writing by MedCafe;
                      </span>
            <span>
                        17. reformat or frame any portion of the web pages that are part of MedCafe.
                      </span>
          </div>
          <p>
            16.2 The information contained on our Service may contain technical inaccuracies and typographical errors.
            We will endeavour to remedy such errors where they are brought to our attention, but to maximum extent
            permitted by law, we shall not accept any responsibility or any liability for our failure to do so.
          </p>
          <p>
            16.3 The information on our Service may be updated from time to time but we do not accept any responsibility
            for keeping the information in these pages up-to-date nor any liability for our failure to do so.
          </p>
          <p>
            16.4 We reserve the right, at our sole discretion, to pursue all of our legal remedies upon breach by you of
            these Terms of Service, including but not limited to removal of your postings and content from our Service,
            and restricting or otherwise suspending your ability to access to our Service and to use any or all of its
            constituent services.
          </p>
        </div>
        <div className="terms-list">
          <h1>17. COPYRIGHT NOTICE AND LIMITED LICENCE</h1>
          <p>
            17.1 The information, content, graphics, text, sounds, images, buttons, trademarks, service marks, get-up,
            business names, domain names, rights in good will, know-how, designs and rights in designs, trade names and
            logos (whether registered or unregistered) contained on our Service are protected by copyright, trade mark,
            database right, sui generis right and other intellectual property laws and are also protected under national
            laws and international treaties. MedCafe Technology Solutions Ltd and/or its licensors (as the case may be)
            retain all right, title, interest and intellectual property rights in and to the materials. All proprietary
            content on our Service is the copyright of MedCafe Technology Solutions Ltd.
          </p>
          <p>
            17.2 Any other use of the information on our Service, including any form of copying or reproduction,
            modification, distribution, uploading, re-publication, extraction, re-utilisation, incorporation or
            integration with other materials or works or re-delivery using framing technology, without our prior
            permission is strictly prohibited and is a violation of the proprietary rights of MedCafe Technology
            Solutions Ltd. Other than as expressly provided herein, nothing in these Terms of Service should be
            construed as conferring, by implication or otherwise, any licence or right under any copyright, patent,
            trade mark, database right, sui generis right or other intellectual property or proprietary interest of
            MedCafe Technology Solutions Ltd, its licensors or any third party.
          </p>
          <p>
            17.3 You agree to grant MedCafe Technology Solutions Ltd a non-exclusive, royalty free, world-wide,
            transferable perpetual licence with the right to sub-licence, reproduce, distribute, transmit, create
            derivative works of, or publicly display any or all content or information (including without limitation,
            any or all ideas contained therein for new or improved services) when you submit information or any other
            content to our Service either during the registration process or to us, by whatever means, thereafter.
          </p>
        </div>
        <div className="terms-list">
          <h1>18. DISCLAIMERS</h1>
          <p>
            18.1 THE SERVICE IS AVAILABLE TO ALL USERS "AS IS" AND, TO THE GREATEST EXTENT PERMITTED BY LAW, THE SERVICE
            IS MADE AVAILABLE WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
          </p>
          <p>
            18.2 MEDCAFE TECHNOLOGY SOLUTIONS LTD MAKES NO REPRESENTATIONS, WARRANTIES OR UNDERTAKINGS ABOUT THE SERVICE
            OR INFORMATION AVAILABLE ON THE SERVICE, INCLUDING WITHOUT LIMITATION, THEIR MERCHANTABILITY, QUALITY OR
            FITNESS FOR A PARTICULAR PURPOSE.
          </p>
          <p>
            18.3 ALL INFORMATION PROVIDED ON OUR SERVICE IS INTENDED AS A GUIDE ONLY AND SHOULD NOT BE CONSTRUED AS A
            SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE AND NEITHER MEDCAFE TECHNOLOGY SOLUTIONS LTD, NOR ITS OFFICERS,
            DIRECTORS EMPLOYEES OR AGENTS MAKE ANY REPRESENTATIONS, WARRANTIES OR UNDERTAKINGS AS TO THE ACCURACY OF ANY
            INFORMATION PROVIDED ON OUR SERVICE.
          </p>
          <p>
            18.4 MEDCAFE TECHNOLOGY SOLUTIONS LTD MAKES NO REPRESENTATIONS, WARRANTIES OR UNDERTAKINGS THAT OUR SERVICE,
            OR THE SERVER(S) UPON WHICH IT IS HOSTED, WILL BE FREE FROM DEFECTS, INCLUDING, BUT NOT LIMITED TO VIRUSES
            OR OTHER HARMFUL ELEMENTS. TO THE MAXIMUM EXTENT PERMITTED BY LAW, GP FORUM SOLUTIONSN LTD ACCEPTS NO
            LIABILITY FOR ANY INFECTION BY COMPUTER VIRUS, BUG, TAMPERING, UNAUTHORISED ACCESS, INTERVENTION, ALTERATION
            OR USE, FRAUD, THEFT, TECHNICAL FAILURE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY, OR ANY
            EVENT OR OCCURRENCE BEYOND THE CONTROL OF MEDCAFE TECHNOLOGY SOLUTIONS LTD, WHICH CORRUPTS OR AFFECTS THE
            ADMINISTRATION, SECURITY, FAIRNESS AND THE INTEGRITY OR PROPER CONDUCT OF ANY ASPECT OF OUR SERVICE.
          </p>
          <p>
            18.5 ALL USE BY YOU OF OUR SERVICE IS AT YOUR OWN RISK. YOU ASSUME COMPLETE RESPONSIBILITY FOR, AND FOR ALL
            RISK OF LOSS RESULTING FROM YOUR DOWNLOADING ,OR USING OF, OR REFERRING TO, OR RELYING ON, THE FACILITIES,
            SERVICE(S) OR INFORMATION PROVIDED ON OUR SERVICE, OR ANY OTHER INFORMATION OBTAINED BY YOU THROUGH YOUR USE
            OF OUR SERVICE. YOU AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, MEDCAFE TECHNOLOGY SOLUTIONS LTD AND
            ALL CONNECTED PROVIDERS OF TELECOMMUNICATIONS AND NETWORK SERVICES AND INFRASTRUCTURE TO MEDCAFE TECHNOLOGY
            SOLUTIONS LTD WILL NOT BE LIABLE FOR DAMAGES ARISING OUT OF YOUR USE OR YOUR INABILITY TO USE THE SERVICES,
            AND YOU HEREBY WAIVE ANY AND ALL CLAIMS WITH RESPECT THERETO, WHETHER BASED ON CONTRACT, TORT OR OTHER
            GROUNDS.
          </p>
          <p>
            18.6 NO ORAL ADVICE OR WRITTEN INFORMATION, GIVEN BY MEDCAFE TECHNOLOGY SOLUTIONS LTD, ITS OFFICERS, AGENTS,
            DIRECTORS, EMPLOYEES OR AGENTS, OR THE LIKE, SHALL CREATE A WARRANTY, NOR SHALL YOU RELY ON ANY SUCH
            INFORMATION OR ADVICE.
          </p>
          <p>
            18.7 MAKE SURE TO INDEPENDENTLY VERIFY ANY INFORMATION AND CONTENT ON MEDCAFE BEFORE RELYING ON IT. ANY
            INFORMATION ON MEDCAFE DOES NOT CONSTITUTE ANY FORM OF ADVICE OR RECOMMENDATION BY MEDCAFE TECHNOLOGY
            SOLUTIONS LTD AND IT IS NOT INTENDED TO BE RELIED UPON IN MAKING ANY DECISION.
          </p>
          <p>
            18.8 TO THE FULLEST EXTENT PERMITTED BY LAW, MEDCAFE TECHNOLOGY SOLUTIONS LTD ASSUMES NO RESPONSIBILITY, NOR
            DO WE GRANT ANY WARRANTIES, EXPRESS OR IMPLIED, RELATING TO THE OPERATION, SAFETY, CONDITION OR SERVICE OF
            AND MEDICAL PRACTITIONER OR HEALTHCARE PROFESSIONAL OR MEDICAL SERVICE, OR ANY OTHER PERSON ASSOCIATED WITH
            SAME THAT IS USED BY, FOR, OR ON BEHALF OF, YOU. MEDCAFE TECHNOLOGY SOLUTIONS LTD IS NOT LIABLE FOR THE
            ACTS, ERRORS, OMISSIONS, REPRESENTATIONS, WARRANTIES, BREACHES OR NEGLIGENCE OF ANY MEDICAL PRACTITIONER OR
            ANY HEALTHCARE PROFESSIONAL OR ANY OTHER PERSONS ASSOCIATED WITH SAME, WHOSE PERSONAL INFORMATION OR CONTACT
            DETAILS YOU OBTAINED FROM OUR SERVICE, OR FOR ANY LOSS, DAMAGES OR EXPENSES RESULTING THEREFROM.
          </p>
          <p>
            18.9 MEDCAFE TECHNOLOGY SOLUTIONS LTD CANNOT GUARANTEE, AND DOES NOT PROMISE, ANY SPECIFIC RESULTS FROM THE
            USE BY YOU OF OUR SERVICE.
          </p>
          <p>
            18.10 PART OF OUR SERVICE MAY CONTAIN THIRD-PARTY ADVERTISING. ADVERTISERS ARE RESPONSIBLE FOR ENSURING THAT
            MATERIAL SUBMITTED FOR INCLUSION ON OUR SERVICE COMPLIES WITH ALL RELEVANT ENGLISH, EUROPEAN AND
            INTERNATIONAL LAWS AND ALL RELEVANT ENGLISH, EUROPEAN AND INTERNATIONAL INDUSTRY CODES OF PRACTICE AND
            INDUSTRY OR VOLUNTARY CODES OF CONDUCT, WHERE APPROPRIATE. MEDCAFE TECHNOLOGY SOLUTIONS LTD TAKES NO
            RESPONSIBILITY FOR THIRD PARTY ADVERTISEMENTS WHICH ARE HOSTED ON OUR SERVICE, NOR DO WE TAKE ANY
            RESPONSIBILITY FOR THE GOODS OR SERVICES OFFERED BY SUCH THIRD PARTY ADVERTISERS. MEDCAFE TECHNOLOGY
            SOLUTIONS LTD WILL NOT BE RESPONSIBLE FOR ANY ERROR OR INACCURACY IN ADVERTISING MATERIALS, NOR WILL IT BE
            LIABLE FOR ANY BREACH OF ENGLISH, EUROPEAN OR OTHER INTERNATIONAL LAWS, NOR ANY ENGLISH, EUROPEAN OR OTHER
            INTERNATIONAL INDUSTRY CODES OF PRACTICE OR VOLUNTARY CODE OF CONDUCT BY THIRD PARTY ADVERTISERS IN RELATION
            TO THE CONTENT OF ANY OF THEIR ADVERTISEMENTS WHICH MAY FROM TIME TO TIME APPEAR ON OUR SERVICE.
          </p>
        </div>
        <div className="terms-list">
          <h1>19. LIMITATION OF LIABILITY</h1>
          <p>
            19.1 TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER MEDCAFE TECHNOLOGY SOLUTIONS LTD NOR ANY OF
            ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES WILL BE LIABLE FOR ANY LOSS OR DAMAGE
            ARISING OUT OF, OR IN CONNECTION WITH, YOUR USE OF ANY FACILITIES OR SERVICES WHICH WE OFFER, OR ANY
            TRANSACTIONS ENTERED INTO, THROUGH OR FROM OUR SERVICES, INCLUDING FOR THE AVOIDANCE OF DOUBT, YOUR
            TRANSACTIONS WITH ANY MEDICAL PROFESSIONAL OR HEALTHCARE PROFESSIONAL FACILITATED THROUGH OUR SERVICE
            INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT OR CONSEQUENTIAL LOSS OR DAMAGES, LOSS OF DATA, LOSS OF
            INCOME, PROFIT OR OPPORTUNITY, LOSS OF, OR DAMAGE TO, PROPERTY AND CLAIMS OF THIRD PARTIES, EVEN IF MEDCAFE
            TECHNOLOGY SOLUTIONS LTD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES, OR SUCH LOSS OR
            DAMAGES WERE REASONABLY FORESEEABLE.
          </p>
          <p>
            19.2 IN NO EVENT SHALL MEDCAFE TECHNOLOGY SOLUTIONS LTD NOR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES,
            AGENTS OR OTHER REPRESENTATIVES BE LIABLE FOR ANY DAMAGES WHATSOEVER RESULTING FROM THE STATEMENTS OR
            CONDUCT OF ANY MEDICAL PRACTITIONER OR HEALTHCARE PROFESSIONAL OR THIRD PARTY OR THE INTERRUPTION,
            SUSPENSION OR TERMINATION OF THE SERVICE, WHETHER SUCH INTERRUPTION, SUSPENSION OR TERMINATION WAS JUSTIFIED
            OR NOT, NEGLIGENT OR INTENTIONAL, INADVERTENT OR ADVERTENT.
          </p>
          <p>
            19.3 WITHOUT LIMITATION TO THE FOREGOING, UNDER NO CIRCUMSTANCES SHALL MEDCAFE TECHNOLOGY SOLUTIONS LTD NOR
            ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES BE HELD LIABLE FOR ANY DELAY OR
            FAILURE IN PERFORMANCE OF OUR SERVICE OR THE FACILITIES OR SERVICES THAT WE OFFER ON OUR SERVICE, RESULTING
            DIRECTLY OR INDIRECTLY FROM ACTS OF NATURE, FORCES OR CAUSES BEYOND THEIR REASONABLE CONTROL, INCLUDING,
            WITHOUT LIMITATION, INTERNET FAILURE, COMPUTER EQUIPMENT FAILURES, TELECOMMUNICATION FAILURES, OTHER
            EQUIPMENT FAILURES, ELECTRICAL POWER FAILURES, STRIKES, LAY-WAY DISPUTES, RIOTS, INTERACTIONS, CIVIL
            DISTURBANCES, SHORTAGES OF LABOUR OR MATERIALS, FIRES, FLOATS, STORMS, EXPLOSIONS, ACTS OF GOD, WAR,
            GOVERNMENTAL ACTIONS, ORDERS OF DOMESTIC OR FOREIGN COURTS OR TRIBUNALS OR THE NON-PERFORMANCE OF A THIRD
            PARTY.
          </p>
          <p>
            19.4 MEDCAFE TECHNOLOGY SOLUTIONS LTD DOES NOT EXCLUDE LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY ITS
            NEGLIGENCE OR THAT OF ITS EMPLOYEES OR AUTHORISED REPRESENTATIVES OR FOR FRAUD.
          </p>
        </div>
        <div className="terms-list">
          <h1>20. INDEMNITY</h1>
          <p>
            20.1 You agree to defend, indemnify and keep indemnified and hold MedCafe Technology Solutions Ltd and (as
            applicable) its officers, directors, employees, agents or other representatives, harmless against any and
            all claims, proceedings, actions, costs (including legal costs), charges, expenses, damages, liability,
            losses and demands made by, or liabilities to, any third party, resulting from any activities conducted
            under your account and/or your email/username and/or password, resulting in the use or misuse of our
            Service, including but not limited to posting content on our Service, entering into transactions with
            Medical Practitioners and / or Healthcare Professionals, infringing any third party intellectual property or
            other rights, or otherwise arising out of your breach or any breach of these Terms of Service.
          </p>
        </div>
        <div className="terms-list">
          <h1>21. LINKS TO THIRD PARTY SERVICES</h1>
          <p>
            21.1 You agree to defend, indemnify and keep indemnified and hold MedCafe Technology Solutions Ltd and (as
            applicable) its officers, directors, employees, agents or other representatives, harmless against any and
            all claims, proceedings, actions, costs (including legal costs), charges, expenses, damages, liability,
            losses and demands made by, or liabilities to, any third party, resulting from any activities conducted
            under your account and/or your email/username and/or password, resulting in the use or misuse of our
            Service, including but not limited to posting content on our Service, entering into transactions with
            Medical Practitioners and / or Healthcare Professionals, infringing any third party intellectual property or
            other rights, or otherwise arising out of your breach or any breach of these Terms of Service.
          </p>
          <p>
            21.2 MedCafe Technology Solutions Ltd is not responsible or liable for the accuracy of any information,
            data, opinions or statements made on third party services, or the security of any link or communication with
            those services. MedCafe Technology Solutions Ltd reserves the right to terminate a link to a third party
            services at any time.
          </p>
          <p>
            21.3 The fact that MedCafe Technology Solutions Ltd provides a link to a third party service does not mean
            that MedCafe Technology Solutions Ltd endorses, authorises or sponsors that services, nor does it mean that
            MedCafe Technology Solutions Ltd is affiliated with any third party services, owners or sponsors. MedCafe
            Technology Solutions Ltd provides these links merely as a convenience for those who use our Service.
          </p>
        </div>
        <div className="terms-list">
          <h1>22. AVAILABILITY</h1>
          <p>
            22.1 Although MedCafe Technology Solutions Ltd endeavours to ensure that our Service is available at all
            times, there may be occasions when access to our Service may be interrupted, e.g. to allow maintenance,
            upgrades and emergency repairs to take place, or due to the failure of telecommunications links and
            equipment that are beyond our control. You agree that MedCafe Technology Solutions Ltd shall not be liable
            to you for any loss incurred by you resulting from the modification, suspension or discontinuance of our
            Service.
          </p>
          <p>
            22.2 You accept that you have sole responsibility for adequate protection and back up of any content and
            data you submit to the Services and for undertaking reasonable and appropriate precautions to scan for
            computer viruses or other destructive items.
          </p>
        </div>
        <div className="terms-list">
          <h1>23. JURISDICTION AND GOVERNING LAW</h1>
          <p>
            23.1 The Service is controlled and operated by MedCafe Technology Solutions Ltd and its service provider(s)
            from Ireland. MedCafe does not make any representation that the facilities, services and information
            contained thereon, including but not limited to the availability of advertising material relating to third
            party products or services, offered through our Service is appropriate or suitable for use in any country
            other than Ireland, or that such information complies with any legal or regulatory requirements in any
            jurisdiction other than Ireland. In accessing our Service, you do so at your own risk and on your own
            initiative, and are responsible for compliance with local laws, to the extent that any local laws are
            applicable.
          </p>
          <p>
            24.2 If it is prohibited to make our Service, facilities, services or information offered through our
            Service, including but not limited to the availability of advertising material relating to third party
            products and or services, or any part of them available to your in your country of access, (whether by
            reason of nationality, residence, law or otherwise), these Services, the facilities, and/or information
            and/or advertising material relating to third party products and or services offered through our Service, or
            any part of them, are hereby regarded at not directed at you.
          </p>
          <p>
            24.3 These Terms of Service shall be governed by and construed in accordance with the laws of Ireland, and
            you hereby agree for the benefit of MedCafe Technology Solutions Ltd, and without prejudice to the right of
            MedCafe Technology Solutions Ltd to take proceedings in relation to these Terms of Service before any court
            of competent jurisdiction, that the courts of Ireland shall have exclusive jurisdiction to hear and
            determine any actions or proceedings that may arise out of, or in connection with, these Terms of Service.
            You also hereby agree that for such purposes you irrevocably submit to the jurisdiction of the courts of
            Ireland.
          </p>
          <p>
            24.4 The language of any dispute resolution procedure or any proceedings under these Terms of Service will
            be English.
          </p>
        </div>
        <div className="terms-list">
          <h1>24. MISCELLANEOUS</h1>
          <p>
            24.1 Any waiver of any provision of these Terms of Service must be in writing and signed by an authorised
            officer of MedCafe Technology Solutions Ltd to be valid. Any waiver of any provision hereunder shall not
            operate as a waiver of any other provision, or a continuing waiver of any provision in the future.
          </p>
          <p>
            24.2 Each of the provisions of these Terms of Service is separate and severable and enforceable accordingly,
            and if at any time any provision is judged by any court of competent jurisdiction to be void or
            unenforceable the validity, legality and enforceability of the remaining provisions shall not in any way be
            affected or impaired.
          </p>
          <p>
            24.3 Nothing in these Terms of Service shall constitute, or be deemed to constitute, a partnership between
            you and MedCafe Technology Solutions Ltd or its agents, nor shall either party be deemed to be the agent of
            the other party.
          </p>
          <p>
            24.4 These Terms of Service represent the entire understanding and agreement between you and MedCafe
            Technology Solutions Ltd relating to your use of our Service, its facilities services and information
            available thereon and supersede any and all prior statements, understandings and agreements.
          </p>
        </div>
        <div className="terms-list">
          <h1>25. CONTACTING US</h1>
          <p>
            In order to contact us, please email us at support@medcafe.ie
          </p>
        </div>
      </div>
    </Page>
  );
};