import {API_ADMIN_INVITES_USER, API_ADMIN_REJECT_REASONS, API_ADMIN_UPDATE_INVITES} from "constants/api";
import {getRequestAuth, postRequestFile, putRequestFile} from "utils/requests";
import {generatePath} from "react-router-dom";

const inviteUser = async (params: FormData) => await postRequestFile(API_ADMIN_INVITES_USER, params);
const getInvites = async (params: object) => await getRequestAuth(API_ADMIN_INVITES_USER, params);
const getRejectReasons = async () => await getRequestAuth(API_ADMIN_REJECT_REASONS);
const updateInvite = async (params: FormData, inviteId:number) => await putRequestFile(generatePath(API_ADMIN_UPDATE_INVITES, {inviteId}), params);

export const adminInviteService = {
  inviteUser,
  getInvites,
  getRejectReasons,
  updateInvite,
}