import React from "react"
import {mount, unmount} from "../../actions/gets";
import {partnersStore} from "../../stores";
import {observer} from "mobx-react-lite";
import {ListHeader, ListItem, ListSearch} from ".";
import {AdminSponsorType} from "types";

export const List = observer(() => {
  React.useEffect(
    () => {
      mount();

      return unmount();
    },
    []
  );

  const {partners} = partnersStore;

  return (
    <div className="superhost__container">
      <ListSearch />
      <ListHeader />
      <div className="superhost__content">
        {
          partners.map((item: AdminSponsorType, index:number) => <ListItem key={'admin-partners-key-' + index} sponsor={item}/>)
        }
      </div>
    </div>
  )
})