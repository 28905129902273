import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "components";
import { Page } from "../Page";
import { mount, unmount, getCourseMaterials } from "./actions/gets";
import { setMeta } from "./actions/sets";
import { courseMaterialsStore } from "./stores";
import { cloneDeep } from "lodash";
import { List, ButtonRound, SearchInput } from "components";
import { Item } from "./components";
import { ROUTES } from "../../../constants";

export const CourseMaterials = observer(() => {
  const navigate = useNavigate();
  const { meta, courseMaterials } = courseMaterialsStore;
  const loadMore = () => {
    const _meta = cloneDeep(meta);
    _meta.page = meta.page + 1;
    setMeta(_meta);
    getCourseMaterials();
  };
  const handleSearch = (query: string) => {
    const _meta = cloneDeep(meta);
    _meta.query = query;
    setMeta(_meta);
    getCourseMaterials();
  };
  const navigateToCreate = () => {
    navigate(ROUTES.createCourseMaterial);
  };
  React.useEffect(() => {
    mount();

    return unmount();
  }, []);

  return (
    <Page className="auth_page_wrapper">
      <PageHeader
        title="COURSE PROGRAMME"
        // options={[{ value: "", label: "Upload the attached PDF" }]}
        // onOptionPress={navigateToCreate}
      />
      <SearchInput value={meta.query || ""} onChange={handleSearch} />
      <List className="academy__container-members">
        {courseMaterials.map((courseMaterial) => (
          <Item key={`member_${courseMaterial.id}`} item={courseMaterial} />
        ))}
      </List>
      {meta.total_pages > meta.page ? (
        <div className="container-button">
          <ButtonRound className="load-more" onClick={loadMore}>
            Load more
          </ButtonRound>
        </div>
      ) : null}
    </Page>
  );
});
