import React, {useEffect} from "react";
import {observer} from 'mobx-react-lite';
import addFileIcon from 'assets/img/png/plus.png';
import calendarFileIcon from 'assets/img/svg/file.svg';
import closeFileIcon from 'assets/img/png/ic-close.png';
import {setFile, setTitle, onSubmitCreateCourseMaterial} from '../actions/sets';
import {createCourseMaterialStore} from '../stores';
import {ButtonRound} from 'components';

export const CreateCourseMaterialForm = observer(() => {
  const {title, file} = createCourseMaterialStore;

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const {length} = event.dataTransfer.files;
    if (length > 0) {
      const newFile = event.dataTransfer.files[0];
      setFile(newFile);
    }
  }
  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    const file = files ? files[0] : null;

    setFile(file);
  };

  return (
    <div className="academy__container-forum">
      <div className="academy__block-forum">
        <form className='course' action="text">
          <input
            className="documentation"
            type="text"
            placeholder="Title of Course Material"
            value={title}
            onChange={onTitleChange}
          />
        </form>
        {!!file && (
          <div className="added-file-block-filled">
            <div className="file-container">
              <img src={calendarFileIcon} alt="" />
              <div className="file-desc">
                <span>{file.name}</span>
                <p>{`${(file.size / 1000000).toFixed(2)} Mb`}</p>
              </div>
            </div>
            <div className="file-close" onClick={() => setFile(null)}>
              <img src={closeFileIcon} alt="" />
            </div>
          </div>
        )}
        <div className="drop-zone" onDrop={onDrop} onDragOver={onDrop}>
          <label className="add-file-block" htmlFor="course_material_file">
            <img src={addFileIcon} alt="" />
            <span>Add file to your Publication</span>
            <p>or drag and drop</p>
            <input type='file' style={{display: 'none'}} id="course_material_file" onChange={onFileChange} />
          </label>
        </div>
        <ButtonRound className="new-publication" onClick={onSubmitCreateCourseMaterial} disabled={!title && !file}>
          Submit Course Material for Approval
        </ButtonRound>
      </div>
    </div>
  );
});
