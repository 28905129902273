import React from "react"
import { toggleNewCourseMaterialModal } from "../../actions/modals"

export const AddNewCourseMaterial = () => {

  return (
    <div className='user-buttons-users'>
      <button onClick={toggleNewCourseMaterialModal} className="add-new-course">Add Course Material</button>
    </div>
  )
}