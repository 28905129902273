import {ChangeEvent} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import classNames from 'classnames';
import {RadioFormTypes, DisabledPropType} from 'types';

const {REACT_APP_API_URL} = process.env;

export const RadioButtonsGroup = (props: RadioFormTypes & DisabledPropType) => {
  const {label, options, name, onChange, value, row = true, error, disabled, showQr, qrUrl} = props;
  const hasErrors = error && error.length > 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({name: event.target.name, value: event.target.value});
  };
  return (
    <div className="group">
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <RadioGroup
          row={row}
          name={name}
          value={value}
          onChange={handleChange}
          className={classNames('', {error: hasErrors})}
        >
          {options.map(option => (
            <FormControlLabel
              disabled={disabled}
              key={option.label}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {showQr && (
        <img src={`${REACT_APP_API_URL}${qrUrl}`} className='webinar_details-qr' alt='QR' />
      )}
      <div className='form-error'>{hasErrors ? error?.join('<br/>') : ''}</div>
    </div>
  );
}
