import React from 'react'

export const WebLeftComponent = () => (
  <div className="image__block">
    <div className="image__desc">
      {/* <h1>Delivering</h1>
      <h1>Medical Education</h1>
      <p>· ONLINE</p>
      <p>· IN-PERSON</p>
      <p>· ON DEMAND</p> */}
    </div>
  </div>
)