import React from 'react'

export const LeftComponent = () => (
  <div className="image__block">
    <div className="image__desc">
      <h1>Delivering Medical Education</h1>
      <div className="split-line"></div>
      <p>ONLINE · IN-PERSON · ON DEMAND</p>
    </div>
  </div>
)