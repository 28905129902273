import {cloneDeep} from 'lodash';
import {addWebinarStore} from '../../stores';

export const addQuestion = () => {
  const _webinar = cloneDeep(addWebinarStore.webinar);

  if (Array.isArray(_webinar.cpd_quizzes_attributes))
    _webinar.cpd_quizzes_attributes.push({
      id: _webinar.cpd_quizzes_attributes.length + 1,
      question: '',
      correct: '',
    });
  else
    _webinar.cpd_quizzes_attributes = [{
      id: 1,
      question: '',
      correct: '',
    }];
  addWebinarStore.webinar = _webinar;
};
