import React from "react"
import {mount, unmount} from "../actions/gets";
import {coursesStore} from "../stores";
import {observer} from "mobx-react-lite";
import {CourseType} from "@/types";
import {Course} from "../components";

export const List = observer(() => {  React.useEffect(
  () => {
    mount();

    return unmount();
  },
  []
);


  const {courses} = coursesStore;

  return (
    <div>
      {
        courses.map((item: CourseType, index) => <Course key={'admin-course-key-' + index} course={item}/>)
      }
    </div>
  )
})