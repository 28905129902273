import { statisticsStore } from "../stores"
import { DetailStatMetaType } from "../types"
import { getDetailStats } from "./gets"

export const toggleModal = async (id?: number, statistic_type?: string, meta?: DetailStatMetaType) => {
    if(!statisticsStore.open && id && statistic_type) {
        await getDetailStats(id, statistic_type)
        if(meta) statisticsStore.detailsMeta = meta
    }
    else {
        statisticsStore.detailsArr = []
        statisticsStore.detailsMeta = null
    }
    statisticsStore.toggleModal()
}