import React from "react"
import {ADMIN_ROUTES} from "constants/routes";
import {generatePath, Link, useParams} from "react-router-dom";

import {openDeleteModal} from "../../actions/modals";

export const EditWebinar = () => {
  const {id, webinarId} = useParams();

  const getLinkEditWebinar = () => generatePath(ADMIN_ROUTES.editWebinar, {id, webinarId});

  return (
    <div className='user-buttons-users'>
      <Link to={getLinkEditWebinar()}>
        <button className="add-new-course">Edit webinar</button>
      </Link>

      <button className="delete-webinar" onClick={() => openDeleteModal(Number(webinarId))}>Delete webinar</button>
    </div>
  )
}