import React from "react";
import {useNavigate} from 'react-router-dom';
import {CourseType} from 'types';
import {getImageUrl} from 'utils';
import {ButtonRound} from 'components';
import {setCourseId} from '../../CreateAccount/actions/sets';
import {ROUTES} from '../../../../constants';
import novoLogoBlue from 'assets/img/png/novoBlueLogo.png';

type Props = {
  item: CourseType,
};

export const Item = (props: Props) => {
  const navigate = useNavigate();
  const {item} = props;
  const handleRegisterInterest = () => {
    setCourseId(item.id);
    navigate(ROUTES.register);
  };

  return (
    <div className="academy__block landing">
      <div className="academy__content">
        <div className="academy__content-logos">
          <img src={getImageUrl(item.logo_url)} alt="" />
          <img src={novoLogoBlue} className='academy__content-novo' alt="" />
        </div>
        <p>{item.title}</p>
        <ButtonRound className='open-academy' onClick={handleRegisterInterest}>
          Register Interest
        </ButtonRound>
      </div>
    </div>
  );
};