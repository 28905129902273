import React from 'react';
import {observer} from 'mobx-react-lite';

const {REACT_APP_API_URL} = process.env;

type Props = {
  avatarUrl?: string;
  letters: string;
};

export const Avatar = observer((props: Props) => {
  const {avatarUrl, letters} = props;

  return (
    <div className='group date-picker'>
      {avatarUrl ? (
        <img src={`${REACT_APP_API_URL}${avatarUrl}`} className='webinar_details-speaker-avatar' alt='Avatar' />
      ) : (
        <span className='webinar_details-speaker-noavatar'>{letters}</span>
      )}
    </div>
  );
});
