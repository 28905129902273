import React, {ChangeEvent} from 'react'
import classNames from 'classnames';
import {FormInputType} from "../../types";

export const FormInput = (props: FormInputType) => {
  const {label, name, placeholder, value, onChange, type, className, disabled, error} = props;
  const hasErrors = error && error.length > 0;
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange({name: event.target.name, value: event.target.value});
  };

  return (
    <div className="group">
      <input
        name={name}
        type={type || "text"}
        placeholder={placeholder}
        onChange={handleChange}
        value={value ? value : ''}
        className={classNames(`form_input ${className || ''}`, {error: hasErrors})}
        disabled={disabled}
      />
        <span className="highlight"></span>
        <span className="bar"></span>
        <label className='form_input_label'>{label}</label>
        <div className='form-error'>{hasErrors ? error?.join('<br/>') : ''}</div>
    </div>
  );
}