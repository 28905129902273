import {makeAutoObservable} from 'mobx'
import {CourseType} from "types";
import {COURSES_OPTIONS_VALUES} from '../constants';

export const coursesStore = makeAutoObservable({
  _courses: [] as CourseType[],
  _course: {} as CourseType,
  _selectedOption: COURSES_OPTIONS_VALUES.my_courses as string,

  set courses(value: CourseType[]) {
    this._courses = value;
  },

  get courses(): CourseType[] {
    return this._courses;
  },

  set selectedOption(value: string) {
    this._selectedOption = value;
  },

  get selectedOption(): string {
    return this._selectedOption;
  },

  set course(course: CourseType) {
    this._course = course;
  },

  get course(): CourseType {
    return this._course;
  },

  clear(): void {
    this._courses = [] as CourseType[];
  }
});

