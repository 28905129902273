import React from "react"
import {Layout} from "../Layout";
import {ChildrenType} from "types";
import {SideBar} from "modules/admin/SideBar";

export const PageSideBar = (props:ChildrenType) => {
  const {children, className} = props;

  return (
    <Layout className={className}>
      <div className="page__container-users">
        <SideBar />
        <div className="page__content-courses">{children}</div>
      </div>
    </Layout>
  );
};