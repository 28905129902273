import {addCourseStore} from "../../stores";
import {CourseFormType} from "types";
import {cloneDeep} from "lodash";

export const setEditCourseData = (value:CourseFormType) => {
  const course = cloneDeep(value) as CourseFormType;

  addCourseStore.course = {
    id: course.id,
    title: course.title,
    color_theme: course.color_theme,
  };
}