import React from 'react';
import {observer} from 'mobx-react-lite';
import {PageHeader} from 'components';
import {Page} from '../Page';
import {mount, unmount, getMembers} from './actions/gets';
import {setMeta} from './actions/sets';
import {membersStore} from './stores';
import {cloneDeep} from 'lodash'
import {List, ButtonRound} from 'components';
import {Item} from './components';

export const Members = observer(() => {
  const {meta, members} = membersStore;
  const loadMore = () => {
    const _meta = cloneDeep(meta);
    _meta.page = meta.page + 1;
    setMeta(_meta);
    getMembers();
  };
  React.useEffect(() => {
    mount();

    return unmount();
  }, []);

  return (
    <Page className="auth_page_wrapper">
      <PageHeader
        title='OTHER OPCA MEMBERS'
      />
      <List className='academy__container-members'>
        {members.map(member => (
          <Item key={`member_${member.id}`} item={member} />
        ))}
      </List>
      {meta.total_pages > meta.page ? (
        <div className='container-button'>
          <ButtonRound
            className='load-more'
            onClick={loadMore}
          >
            Load more
          </ButtonRound>
        </div>
       ) : null
      }
    </Page>
  )
});
