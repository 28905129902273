import React from 'react'
import {InputTextField} from 'components/form';
import {changeField,} from '../../actions/add';
import {observer} from 'mobx-react-lite';
import {getErrors} from "utils";
import {addUserStore} from "../../stores";

export const FirstName = observer(() => {
  const {first_name} = addUserStore.user;

  return (
    <InputTextField
      type='round'
      name='first_name'
      label='First Name'
      placeholder=''
      value={first_name}
      error={getErrors('first_name')}
      onChange={changeField}
    />
  )
})