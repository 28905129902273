import React from "react";
import {MemberType} from 'types';
import {getImageUrl, showUserType, showUserTitle} from 'utils';

type Props = {
  item: MemberType;
};

export const Item = (props: Props) => {
  const {item} = props;
  const userType = showUserType(item.user_type);
  const userTitle = showUserTitle(item.title);

  return (
    <div className="academy__block-members">
      <div className="academy__block-content">
        <div className="academy__title-container">
          {!item.avatar_url ? (
            <span className="academy__title-no-avatar">{`${item.first_name.charAt(0)} ${item.last_name.charAt(0)}`}</span>
          ) : (
            <img src={getImageUrl(item.avatar_url)} alt="" />
          )}
          <div className="academy__title-desc">
            <span>{`${userTitle} ${item.first_name} ${item.last_name}`}</span>
            <p>{`${userType}・${item.location}`}</p>
          </div>
        </div>
        {/* <div className="academy__title-close">
          <button className="message">Message</button>
        </div> */}
      </div>
    </div>
  );
};
