import React from "react"
import {ADMIN_ROUTES} from "constants/index";
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {isActiveClass} from "utils";
import {partnersStore} from "../../Partners/stores";

export const LinkPartners = observer(() => {
  const count = partnersStore.count();
  const countText = count === 0 ? '' : `(${count})`;

  return (
    <NavLink to={ADMIN_ROUTES.partners} className={isActiveClass}>
      <button className="all-courses active">Partners {countText}</button>
    </NavLink>
  )
})