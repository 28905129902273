import { ROUTES } from "./routes";

export const AUTH_HEADER_LINKS = [
  {
    title: "Sessions",
    path: ROUTES.webinars,
  },
  // {
  //   title: "Members",
  //   path: ROUTES.members,
  // },
  {
    title: "Course Programme",
    path: ROUTES.courseMaterials,
  },
  // {
  //   title: 'Forum',
  //   path: ROUTES.home,
  // },
  // {
  //   title: 'Notes',
  //   path: ROUTES.home,
  // },
  {
    title: "Cpd Locker",
    path: ROUTES.cpdLocker,
  },
];

export const NOT_AUTH_HEADER_LINKS = [
  // {
  //   title: 'About us',
  //   path: ROUTES.home,
  // },
  // {
  //   title: 'Contact us',
  //   path: ROUTES.home,
  // },
  {
    title: "Terms of service",
    path: ROUTES.terms,
  },
  {
    title: "Privacy policy",
    path: ROUTES.privacyPolicy,
  },
];
