import React from "react";
import { generatePath, Link, useParams, useNavigate } from "react-router-dom";
import { ADMIN_ROUTES } from "constants/routes";
// import {openInviteModal} from "../../Users/actions/modals";
import { setAssignedUsers } from "../../Webinars/actions/sets";
import {
  addWebinarStore,
  assignedUsersModalStore,
  webinarsStore,
} from "../../Webinars/stores";
import { cloneDeep } from "lodash";

export const AddToWebinar = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleBack = () => {
    navigate(-1);
  };
  const handleSave = () => {
    let checks = [] as Array<number>;
    checks = assignedUsersModalStore.assignedUsers.map(({ id }) => id);
    const _webinar = cloneDeep(addWebinarStore.webinar);

    _webinar.assigned_ids = checks;
    _webinar.assigned_users = assignedUsersModalStore.assignedUsers;
    addWebinarStore.webinar = _webinar;
    webinarsStore.clearCheckedIds();
    navigate(-1);
  };

  return (
    <div className="user-buttons-users">
      <button className="add-new-course" onClick={handleBack}>
        Cancel
      </button>

      <button className="add-new-course" onClick={handleSave}>
        Add users
      </button>
    </div>
  );
};
