import {webinarStore} from "../../stores";
import {mapFromQuiz} from '../../utils';
import {WebinarType} from 'types';

export const setQuiz = (webinar: WebinarType) => {
  webinarStore.inProgress = true;
  const _cpdQuiz = mapFromQuiz(webinar);

  webinarStore.webinar = webinar;
  webinarStore.cpdQuiz = _cpdQuiz;
  webinarStore.inProgress = false;
}
