import {makeAutoObservable} from 'mobx';
import {UserType, InputChangeProps} from 'types';
import {cloneDeep} from 'lodash';

export const editUserStore = makeAutoObservable({
  _user: {} as UserType,
  _updatingUser: false,

  set user(user: UserType) {
    this._user = user;
  },

  get user(): UserType {
    return this._user;
  },

  set updatingUser(value: boolean) {
    this._updatingUser = value;
  },

  get updatingUser(): boolean {
    return this._updatingUser;
  },

  changeUserField(data: InputChangeProps) {
    const user = cloneDeep(this._user);
    user[data.name] = data.value;
    this._user = user;
  },

  clear() {
    this._user = {} as UserType;
  },
});