import {makeAutoObservable} from 'mobx'
import {AdminUserType} from "types";

export const usersStore = makeAutoObservable({
  _users: [] as AdminUserType[],

  set users(value: AdminUserType[]) {
    this._users = value;
  },

  get users(): AdminUserType[] {
    return this._users
  },

  count(): number {
    return this._users.length;
  },

  clear(): void {
    this._users = [] as AdminUserType[]
  }
});

