// import {toast} from 'react-toastify';
import {isMobile, isSafari} from 'react-device-detect';
import {defaultErrorsInfo} from "../../../../../utils";
import {webinarsService} from '../../services';
import {webinarsStore} from '../../stores';
import {SubscriptionType} from 'types';

export const subscribeWebinar = async (webinarId: number) => {
  try {
    webinarsStore.inProgress = true;
    const {data} = await webinarsService.subscribeWebinar(webinarId);

    // if (data.message) {
    //   toast.success(data.message);
    // }
    const subscription: SubscriptionType = data.subscription;

    if (isMobile) {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = subscription.join_url;
      // a.href="javascript:void(0)";
      a.click();
      document.body.removeChild(a);
    } else {
      if (isSafari) {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = subscription.join_url;
        a.click();
        document.body.removeChild(a);
      } else {
        window.open(subscription.join_url, '_blank');
      }
    }

    webinarsStore.inProgress = false;
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
    webinarsStore.inProgress = false;
  }
};
