import {defaultErrorsInfo} from '../../../../../utils';
import {confirmEmailServices} from '../../services';
import {commonStore, currentUserStore} from 'stores';

export const confirmEmail = async (token: string, callback: Function) => {
  try {
    const {data} = await confirmEmailServices.confirmEmail(token, currentUserStore.currentUser.id);

    commonStore.token = data.token;
    currentUserStore.currentUser = data.user.data.attributes;

    callback();
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
};
