import {cloneDeep} from 'lodash';
import {showError500} from "utils";
import {adminUsersService} from "services";
import {usersStore} from "../../stores";
import {mapFromUsers} from "modules/admin/SuperHosts/utils";

export const getUsersByQuery = async (query: string) => {
  const _meta = cloneDeep(usersStore.meta);
  _meta.query = query;
  _meta.page = 1;

  const {status, data} = await adminUsersService.getUsersByQuery(_meta);

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  _meta.total_pages = data.total_pages || 1;

  usersStore.meta = _meta;
  usersStore.users = mapFromUsers(data);
}