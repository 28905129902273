import React from "react"
import {useParams} from "react-router-dom";
import {mount, unmount, getWebinars} from "../../actions/gets";
import {observer} from "mobx-react-lite";
import {cloneDeep} from 'lodash';
import {ListHeader, ListItem, ListSearch} from ".";
import {ButtonRound} from 'components';
import {WebinarType} from "types";
import {webinarsStore} from "../../stores";
import {setMeta} from '../../actions/sets';

export const List = observer(() => {
  let {id} = useParams();
  const {webinars, meta} = webinarsStore;
  const loadMore = () => {
    const _meta = cloneDeep(meta);
    _meta.page = meta.page + 1;
    setMeta(_meta);
    getWebinars();
  };
  React.useEffect(
    () => {
      mount(Number(id));

      return unmount();
    },
    []
  );


  return (
    <div className="superhost__container">
      <ListSearch />
      <ListHeader />
      <div className="superhost__content">
        {
          webinars.map((item: WebinarType, index: number) => <ListItem key={'admin-users-key-' + index} webinar={item}/>)
        }
      </div>
      {meta.total_pages > meta.page ? (
        <div className='container-button'>
          <ButtonRound
            className='load-more'
            onClick={loadMore}
          >
            Load more
          </ButtonRound>
        </div>
       ) : null
      }
    </div>
  )
})