import React from "react"
import { useParams } from "react-router-dom"
import { mount, unmount, getStatistics } from "../../actions/gets"
import { observer } from "mobx-react-lite"
import { cloneDeep } from 'lodash'
import { ListHeader, ListItem, ListSearch } from "."
import { ButtonRound } from 'components'
import { StatisticType } from "types"
import { statisticsStore } from "../../stores"
import { setMeta } from '../../actions/sets'

export const List = observer((props: any) => {
  let { id } = useParams()
  const { arr, meta } = statisticsStore
  const loadMore = () => {
    const _meta = cloneDeep(meta)
    _meta.page = meta.page + 1
    setMeta(_meta)
    getStatistics()
  }
  React.useEffect(() => {
    mount(Number(id))
    return unmount()
  }, [id])


  return (
    <div className="superhost__container">
      <ListSearch />
      <ListHeader />
      <div className="superhost__content">
        {
          arr.map((item: StatisticType, index: number) => <ListItem key={'admin-users-key-' + index} statistic={item}/>)
        }
      </div>
      {meta.total_pages > meta.page ? (
        <div className='container-button'>
          <ButtonRound
            className='load-more'
            onClick={loadMore}
          >
            Load more
          </ButtonRound>
        </div>
       ) : null
      }
    </div>
  )
})