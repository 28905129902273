import React from 'react'
import {changeField} from '../../actions/add';
import {observer} from 'mobx-react-lite';
import {getErrors} from "utils";
import {addUserStore} from "../../stores";
import { Checkbox, FormControlLabel } from '@mui/material';

export const EmailConfirmedCB = observer(() => {
  const {email_confirmed} = addUserStore.user;

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={email_confirmed || false}
          name='email_confirmed'
          onChange={() => changeField({
              name: 'email_confirmed', value: !email_confirmed
          })}
        />
      }
      label='Email confirmed'
    />
  )
})