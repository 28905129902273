import React from 'react'
import {InputTextField} from 'components/form';
import {changeField,} from '../../actions/add';
import {observer} from 'mobx-react-lite';
import {getErrors} from "utils";
import {addUserStore} from "../../stores";

export const Password = observer(() => {
  const {password} = addUserStore.user;

  return (
    <InputTextField
      type='round'
      name='password'
      label='Password'
      placeholder=''
      inputType='password'
      value={password}
      error={getErrors('password')}
      onChange={changeField}
      autocomplete='new-password'
    />
  )
})