import React from "react"
import { ADMIN_ROUTES } from "constants/routes"
import { generatePath, NavLink, useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"

export const LinkCourseMaterials = observer(() => {
  let {id} = useParams()

  const getLink = () => generatePath(ADMIN_ROUTES.courseMaterials, {id})
  const getLinkToRequests = () => generatePath(ADMIN_ROUTES.courseMaterialsRequests, {id})

  return <>
    <NavLink to={getLink()} className={window.location.pathname !== ADMIN_ROUTES.courseMaterialsRequests ? 'active' : ''}>
      <button className="all-courses">Course Materials</button>
    </NavLink>
    <NavLink to={getLinkToRequests()} className={window.location.pathname === ADMIN_ROUTES.courseMaterialsRequests ? 'active' : ''}>
      <button className="all-courses">Requests</button>
    </NavLink>
  </>
})