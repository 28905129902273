import {toast} from "react-toastify";

export const defaultErrorsInfo = (status: number, data: any) => {

  let errorText = `Unknown error! Status: ${status}. Response: ${data}`

  if (status === 401 || status === 403 || status === 400) {
    if (data.error) {
      errorText = data.error;
    }

    if (data.error_message) {
      errorText = data.error_message;
    }

    if (data.message) {
      errorText = data.message;
    }

    if (data.error_messages) {
      errorText = '';
      Object.keys(data.error_messages).map((key) => errorText += key + ': ' + data.error_messages[key] + ',');
    }
  }

  if(status === 101) errorText = data

  toast.error(errorText);
}