import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import Autocomplete, {AutocompleteRenderInputParams} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {addWebinarStore} from '../../stores';
import {changeField} from '../../actions/add';
import {sponsorsStore} from '../../../Sponsors/stores';
import {getSponsorsByQuery} from '../../../Sponsors/actions/gets/getSponsorsByQuery';
import {DisabledPropType} from 'types';

export const AddSponsor = observer((props: DisabledPropType) => {
  const {disabled} = props;
  const {webinar} = addWebinarStore;
  const {sponsors} = sponsorsStore;

  useEffect(() => {
    getSponsorsByQuery('');
  }, []);

  return (
    <div className='group date-picker'>
      <Autocomplete
        multiple={true}
        options={sponsors.map(sponsor => ({
          title: sponsor.title,
          id: sponsor.id,
        }))}
        disabled={disabled}
        // defaultValue={[{title: '', id: 0}]}
        id="tags-standard"
        getOptionLabel={(option) => option.title}
        onChange={(event: React.SyntheticEvent<Element, Event>, value) => {
          changeField({name: 'sponsor_ids', value: value.map(option => option.id)});
        }}
        defaultValue={webinar.sponsor_ids || []}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            label="Select sponsor"
            placeholder="Select sponsor"
          />
        )}
      />
    </div>
  );
});
