import React, {useEffect} from "react"
import {getUserDetails} from "./actions/gets";
import {AddUser} from ".";
import {useParams} from "react-router-dom";

export const EditUser = () => {
  const params = useParams();

  useEffect(() => {
    getUserDetails(Number(params.userId))
  }, [params.userId]);

  return (
    <AddUser />
  )
}