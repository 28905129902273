import {USER_TYPE_ENUM} from '../../../../constants';

export const getFields = (user_type: string): string[] => {
  const fields: string[] = [];

  switch(user_type) {
    case USER_TYPE_ENUM.allied_healthcare_professional:
      fields.push('profession');
      return fields;
    case USER_TYPE_ENUM.gp:
    case USER_TYPE_ENUM.gp_trainee:
    case USER_TYPE_ENUM.consultant:
    case USER_TYPE_ENUM.hospital_doctor:
      fields.push('imc');
      return fields;
    case USER_TYPE_ENUM.practice_nurse:
    case USER_TYPE_ENUM.nurse_specialist:
      fields.push('pin_number');
      return fields;
    default:
      return fields;
  }
};