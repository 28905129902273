import React from "react";
import { observer } from "mobx-react-lite";
import { PageHeader } from "components";
import { Page } from "../Page";
import { mount, unmount, getWebinars } from "./actions/gets";
import { setMeta } from "./actions/sets";
import { webinarsStore } from "./stores";
import { coursesStore } from "../Dashboard/stores";
import { getNotifications } from "../Notifications/actions/gets";
import { SCOPE_OPTIONS, SCOPE_VALUES } from "./constants";
import { cloneDeep } from "lodash";
import { List, ButtonRound } from "components";
import { Item } from "./components";

export const Webinars = observer(() => {
  const { meta, webinars, inProgress } = webinarsStore;
  const { course } = coursesStore;
  const handleOptionPress = (scope: string) => {
    const _meta = cloneDeep(meta);
    _meta.scope = scope;
    _meta.page = 1;
    setMeta(_meta);
    getWebinars();
  };
  // const refresh = () => {
  //   const _meta = cloneDeep(meta);
  //   _meta.page = 1;
  //   setMeta(_meta);
  //   getWebinars();
  // };
  const loadMore = () => {
    const _meta = cloneDeep(meta);
    _meta.page = meta.page + 1;
    setMeta(_meta);
    getWebinars();
  };

  React.useEffect(() => {
    mount();
    getNotifications();

    return unmount();
  }, []);

  return (
    <Page className="auth_page_wrapper">
      <PageHeader
        title={course.title || "Obesity Primary Care Academy"}
        selectedOption={meta.scope}
        options={SCOPE_OPTIONS}
        onOptionPress={handleOptionPress}
      />
      {/* {meta.scope === SCOPE_VALUES.current ? (
        <div className='container-button refresh-container'>
          {webinars.length === 0 && (
            <span>There are no webinars currently running. Click below to refresh.</span>
          )}
          <br />
          <ButtonRound
            className='load-more'
            onClick={refresh}
          >
            Refresh
          </ButtonRound>
        </div>
       ) : null
      } */}
      {meta.scope === SCOPE_VALUES.current && webinars.length === 0 ? (
        <div className="empty-current-webinars">
          <span>There are no sessions available to join at the moment.</span>
          <br />
          <span>
            When a session begins,
            <br /> please refresh this page to join.
          </span>
        </div>
      ) : null}
      {meta.scope === SCOPE_VALUES.upcoming ? (
        <div className="empty-current-webinars">
          <span>
            When a session begins, please click "Current" button to join
          </span>
        </div>
      ) : null}
      <div className="webinar-message">
        Obesity Primary Care Academy is a medical educational programme
        organised and funded by Novo Nordisk Limited and is available only to
        General Practitioners and GP Nurses working in primary care in Ireland
      </div>
      <List>
        {webinars.map((webinar) => (
          <Item
            key={`webinar${webinar.id}`}
            item={webinar}
            selectetScope={meta.scope}
            inProgress={inProgress}
          />
        ))}
      </List>
      {meta.total_pages > meta.page ? (
        <div className="container-button">
          <ButtonRound className="load-more" onClick={loadMore}>
            Load more
          </ButtonRound>
        </div>
      ) : null}
    </Page>
  );
});
