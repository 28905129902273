import React from 'react'
import {observer} from 'mobx-react-lite';
import {Link, useNavigate} from "react-router-dom";
import {ROUTES, navigateToWebinar} from '../../../../constants'
import {ButtonRound, FormInput} from "components";
import {setEmail, setPassword, submitLogin} from '../actions/sets';
import {loginStore} from '../stores';
import {commonStore} from 'stores';
import {InputChangeProps} from 'types';

export const LoginForm = observer(() => {
  const navigate = useNavigate();
  const {email, password, inProgress} = loginStore;
  const handleInputChange = (data: InputChangeProps) => {
    if (data.name === 'email') {
      setEmail((data.value || '').toString());
    } else {
      setPassword((data.value || '').toString());
    }
  };
  const onSuccess = () => {
    if (commonStore.webinarId) {
      navigate(navigateToWebinar(Number(commonStore.webinarId)));
      commonStore.clearWebinarId();
    }
  };
  const handleSubmitForm = () => {
    submitLogin(onSuccess);
  };

  return (
    <div className="signin__block">
      <div className="signin__container">
        <h1>MEDCAFE ACADEMY</h1>
        <div className="signin__content">
          <div className="containers">
            <form>
              <FormInput
                name="email"
                label="Email address"
                value={email}
                onChange={handleInputChange}
                type='email'
              />

              <FormInput
                name="password"
                label="Password"
                value={password}
                onChange={handleInputChange}
                type='password'
              />
              <Link className='forgot' to={ROUTES.forgotPassword}>Forgot Password?</Link>
            </form>
          </div>

          <ButtonRound onClick={handleSubmitForm} disabled={inProgress} className="auth_rounded_button">Sign In</ButtonRound>
          <p>If you wish to join, please email <a href="mailto:academysupport@medcafe.ie"><b>academysupport@medcafe.ie</b></a></p>
        </div>
      </div>
    </div>
  );
});