import React from 'react';
import {observer} from 'mobx-react-lite';
import {CPD_POINTS_OPTIONS} from '../../../../../constants';
import {SelectField} from 'components';
import {addWebinarStore} from '../../stores';
import {changeField} from '../../actions/add';
import {getErrors} from "utils";
import {DisabledPropType} from 'types';

export const CpdPointsType = observer((props: DisabledPropType) => {
  const {disabled} = props;
  const {webinar} = addWebinarStore;
  return (
    <SelectField
      name='cpd_points'
      label='Type of CPD Points'
      options={CPD_POINTS_OPTIONS}
      value={webinar.cpd_points}
      onChange={changeField}
      variant='outlined'
      error={getErrors('cpd_points')}
      disabled={disabled}
    />
  );
});
