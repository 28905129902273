import React from "react";
import moment from "moment";
import {CourseMaterialType} from 'types';
import {getImageUrl} from 'utils';
import {DATE_FORMATS} from '../../../../constants';

type Props = {
  item: CourseMaterialType;
};

export const Item = (props: Props) => {
  const {item} = props;
  const url = getImageUrl(item.file_url);

  return (
    <div className="academy__block-course">
      <div className="academy__title-course">
        <span>{moment(item.created_at).format(DATE_FORMATS.webinarDate)}</span>
        <h2>{item.title}</h2>
        <p>{`Created by : ${item.created_by}`}</p>
      </div>
      <div className="academy__title-close">
        <a href={url || ''} target='_blank'><button className="message">View File</button></a>
      </div>
    </div>
  );
};
