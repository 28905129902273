import React from "react"
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Header} from "../Header";
import {Footer} from "../Footer";
import {ChildrenType} from "types";

export const Layout = (props:ChildrenType) => {
  const {children, className} = props;

  const _className = className ? `page ${className}` : 'page'

  return (
    <div className="admin-wrapper-container">
      <div className="wrapper">
        <Header/>
        <main className={_className}>{children}</main>
      </div>
      <Footer/>
      <ToastContainer/>
    </div>
  );
}