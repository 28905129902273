import { makeAutoObservable } from "mobx";
import { AdminUserType } from "types";

export const assignedUsersModalStore = makeAutoObservable({
  _assignedUsers: [] as AdminUserType[],
  _show: false,

  set assignedUsers(value: AdminUserType[]) {
    this._assignedUsers = value;
  },

  get assignedUsers(): AdminUserType[] {
    return this._assignedUsers;
  },

  set show(value: boolean) {
    this._show = value;
  },

  get show(): boolean {
    return this._show;
  },

  clear(): void {
    this._assignedUsers = [] as AdminUserType[];
    this._show = false;
  },
});
