import { ErrorType } from "types";
import { cloneDeep } from "lodash";
import { errorsStore } from "stores";

export const validateBE = (data: any, fields: string[]) => {
  let errors: ErrorType[] = [];

  if (data) {
    const messages = cloneDeep(data);

    fields.forEach((name) => {
      const value = messages[name];

      if (value) {
        errors.push({ name, value });
      }
    });

    if (messages.user) errors.push({ name: "course_id", value: messages.user });
  }

  errorsStore.errors = errors;
};
