import {cloneDeep} from 'lodash';
import {defaultErrorsInfo, mapByPage} from "../../../../../utils";
import {courseMaterialsService} from "../../services";
import {courseMaterialsStore} from "../../stores";
import {mapFromCourseMaterials} from '../../utils';

export const getCourseMaterials = async () => {
  try {
    const {data} = await courseMaterialsService.getCourseMaterials(courseMaterialsStore.meta);
    const courseMaterialsResponse = mapFromCourseMaterials(data);
    const courseMaterials = mapByPage(courseMaterialsStore.meta.page, courseMaterialsStore.courseMaterials, courseMaterialsResponse);

    const _meta = cloneDeep(courseMaterialsStore.meta);
    _meta.total_pages = data.total_pages || 1;

    courseMaterialsStore.meta = _meta;
    courseMaterialsStore.courseMaterials = courseMaterials;
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
}
