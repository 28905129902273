import {makeAutoObservable} from 'mobx'

export const deleteModalStore = makeAutoObservable({
  _deleteId: 0,
  _deleteIds: [] as number[],
  _show: false,

  set deleteId(value: number) {
    this._deleteId = value;
  },

  get deleteId(): number {
    return this._deleteId;
  },

  set deleteIds(value: number[]) {
    this._deleteIds = value;
  },

  get deleteIds(): number[] {
    return this._deleteIds;
  },

  set show(value: boolean) {
    this._show = value;
  },

  get show(): boolean {
    return this._show;
  },

  clear(): void {
    this._deleteId = 0;
    this._deleteIds = [];
    this._show = false;
  },

});

