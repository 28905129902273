import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import Autocomplete, {AutocompleteRenderInputParams} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {addWebinarStore} from '../../stores';
import {changeField} from '../../actions/add';
import {partnersStore} from '../../../Partners/stores';
import {getParntersByQuery} from '../../../Partners/actions/gets/getParntersByQuery';
import {DisabledPropType} from 'types';

export const AddPartner = observer((props: DisabledPropType) => {
  const {disabled} = props;
  const {webinar} = addWebinarStore;
  const {partners} = partnersStore;

  useEffect(() => {
    getParntersByQuery('');
  }, []);

  return (
    <div className='group date-picker'>
      <Autocomplete
        multiple
        filterSelectedOptions
        options={partners.map(partners => ({
          title: partners.title,
          id: partners.id,
        }))}
        disabled={disabled}
        onChange={(event: React.SyntheticEvent<Element, Event>, value) => {
          changeField({name: 'partner_ids', value: value.map(option => option.id)});
        }}
        defaultValue={webinar.partner_ids || []}
        id="tags-standard"
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            label="Select partner"
            placeholder="Select partner"
          />
        )}
      />
    </div>
  );
});
