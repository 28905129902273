import React from 'react'
import {SelectType} from "types";

export const SelectField = (props: SelectType) => {
  const {label, name, value, onChange, options, setDefaultValue, error} = props;

  React.useEffect(
    () => {
      if (setDefaultValue && options && options.length > 0) {
        onChange({name, value: options[0].value});
      }
    },
    []
  );

  const hasErrors = error && error.length > 0;

  return (
    <div className="form__row custom-select">
      <p>{label}</p>
      <select className="form__select"
              name={name}
              value={value ? value : ''}
              onChange={(event) => onChange({name, value:event.currentTarget.value})}
      >
        {
          options.map((item) => <option key={item.key} value={item.value}>{item.text}</option>)
        }
      </select>
      <div className='form-error'>{hasErrors ? error?.join('<br/>') : ''}</div>
    </div>
  )
}