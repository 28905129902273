import {makeAutoObservable} from 'mobx';
import {currentUserStore} from './currentUserStore';

const {REACT_APP_COURSE_ID} = process.env;

export const commonStore = makeAutoObservable({
  _token: window.localStorage.getItem('@medcafe_portal_token'),
  _selectedCurseId: Number(REACT_APP_COURSE_ID) as number | null,
  _webinarId: window.localStorage.getItem('@medcafe_portal_webinar_id') || '' as string,

  set token(value: string) {
    window.localStorage.setItem('@medcafe_portal_token', value);
    this._token = value;
  },

  get token(): string {
    return this._token as string;
  },

  set selectedCurseId(courseId: number | null) {
    this._selectedCurseId = courseId;
  },

  get selectedCurseId(): number | null {
    return this._selectedCurseId;
  },

  set webinarId(id: string) {
    window.localStorage.setItem('@medcafe_portal_webinar_id', id);
    this._webinarId = id;
  },

  get webinarId(): string {
    return this._webinarId;
  },

  clear() {
    this._selectedCurseId = null;
  },

  clearWebinarId() {
    window.localStorage.removeItem('@medcafe_portal_webinar_id');
    this._webinarId = '';
  },

  logout(): void {
    window.localStorage.removeItem('@medcafe_portal_token');
    window.localStorage.removeItem('@medcafe_portal_webinar_id');
    this._token = '';
    this._webinarId = '';
    currentUserStore.forgetUser();
  },

});
