import { makeAutoObservable } from 'mobx'
import { DetailStatMetaType, StatisticsMetaType, DetailStatType } from "../types"
import { arr, STATISTICS_META } from '../constants'
import { StatisticType } from '@/types'

export const statisticsStore = makeAutoObservable({
  _arr: arr as StatisticType[],
  _meta: STATISTICS_META as StatisticsMetaType,
  _open: false as Boolean,
  _detailsArr: [] as DetailStatType[],
  _detailsMeta: null as DetailStatMetaType | null,

  set arr(value: StatisticType[]) {
    this._arr = [...value]
  },

  get arr(): StatisticType[] {
    return this._arr
  },

  set open(value: Boolean) {
    this._open = value
  },

  get open(): Boolean {
    return this._open
  },

  set detailsArr(value: DetailStatType[]) {
    this._detailsArr = value
  },
  
  get detailsArr(): DetailStatType[] {
    return this._detailsArr
  },

  set detailsMeta(value: DetailStatMetaType | null) {
    this._detailsMeta = value
  },

  get detailsMeta(): DetailStatMetaType | null {
    return this._detailsMeta
  },

  set meta(value: StatisticsMetaType) {
    this._meta = value
  },

  get meta(): StatisticsMetaType {
    return this._meta
  },

  toggleModal(): void {
    this._open = !this._open
  },

  clear(): void {
    this._arr = arr as StatisticType[]
    this._meta = STATISTICS_META as StatisticsMetaType
    this._open = false as Boolean
    this._detailsArr = [] as DetailStatType[]
    this._detailsMeta = null as DetailStatMetaType | null
  }
})

