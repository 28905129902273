import { makeAutoObservable } from "mobx";
import { ImageChangeProps } from "types";
import { cloneDeep } from "lodash";

export type SuperhostFormValues = {
  role: string;
  id?: number;
  first_name?: string;
  last_name?: string;
  company_name?: string;
  password?: string;
  email?: string;
  course_id?: number;
};

export const addSuperHostStore = makeAutoObservable({
  _superHost: { role: "superhost" },

  set superHost(value: SuperhostFormValues) {
    this._superHost = value;
  },

  get superHost(): SuperhostFormValues {
    return this._superHost;
  },

  changeField(data: ImageChangeProps) {
    const clone = cloneDeep(this._superHost);
    const key = data.name as keyof SuperhostFormValues;

    // @ts-ignore
    clone[key] = data.value;

    this._superHost = clone;
  },

  clear(): void {
    this._superHost = { role: "superhost" } as SuperhostFormValues;
  },
});
