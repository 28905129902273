import React from "react"
import {Layout} from "../Layout";
import {WebLeftComponent} from "components";
import {NewPasswordForm} from "./components/NewPasswordForm";

export const NewPassword = () => {
  return (
    <Layout>
      <WebLeftComponent/>
      <NewPasswordForm />
    </Layout>
  );
};