import {makeAutoObservable} from 'mobx'
import {WebinarType} from "types";
import {WEBINARS_META} from '../constants';
import {WebinarsMetaType} from '../types';

export const webinarsStore = makeAutoObservable({
  _webinars: [] as WebinarType[],
  _meta: WEBINARS_META as WebinarsMetaType,
  _checkedIds: [] as number[],

  set webinars(value: WebinarType[]) {
    this._webinars = value;
  },

  get webinars(): WebinarType[] {
    return this._webinars
  },

  set meta(value: WebinarsMetaType) {
    this._meta = value;
  },

  get meta(): WebinarsMetaType {
    return this._meta
  },

  get checkedIds(): number[] {
    return this._checkedIds;
  },

  getWebinarById(id: number) : WebinarType{
    return this._webinars.filter(item => item.id === id)[0];
  },

  addCheckedId(value: number) {
    const copy = [...this._checkedIds];
    const index = copy.findIndex(id => id === value)

    index !== -1 ? copy[index] = value : copy.push(value);

    this._checkedIds = copy;
  },

  removeCheckedId(value: number) {
    this._checkedIds = [...this._checkedIds].filter(id => id !== value)
  },

  addMultipleIds(value: number[]) {

    this._checkedIds = value;
  },

  clearCheckedIds(): void {
    this._checkedIds = [] as number[];
  },

  count(): number {
    return this._webinars.length;
  },

  clear(): void {
    this._webinars = [] as WebinarType[];
    this._checkedIds = [] as number[];
    this._meta = WEBINARS_META as WebinarsMetaType;
  }
});

