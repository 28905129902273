import { logoutAdmin, showError500 } from "../../../../../utils";
import { courseService } from "../../services";
import { coursesStore } from "../../stores";
import { mapFromCourses } from "../../utils";

export const getCourses = async () => {
  const { status, data } = await courseService.getCourses();

  if (status === 401) {
    logoutAdmin();
    return;
  }

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  coursesStore.courses = mapFromCourses(data);
};
