import React from "react"
import {List} from "./components/List";
import {UsersNavBar} from "modules/admin/UsersNavBar";
import {DeleteModal, InviteModal, InfoModal} from "./components";
import {PageSideBar} from "modules/admin/PageSideBar";

export const Users = () => {
  return (
    <PageSideBar className='display-block'>
      <UsersNavBar showButton='users' />
      <List />
      <DeleteModal />
      <InviteModal />
      <InfoModal />
    </PageSideBar>
  )
}