export const getLabelIMC = ({
    accreditation,
    user_type
} : any): string => {
    let label = ''
    if(typeof accreditation === 'string' && accreditation === 'false') accreditation = false
    if(typeof accreditation === 'string' && accreditation === 'true') accreditation = true
    switch(accreditation) {
        case true:
            switch(user_type) {
                case 'gp': case 'gp_trainee': case 'consultant': case 'hospital_doctor':
                    label = 'IMC #'
                    break
                case 'nurse_specialist': case 'practice_nurse':
                    label = 'NMBI Pin Code'
                    break
                case 'allied_healthcare_professional':
                    label = 'Accreditation number'
                    break
                default:
                    break
            }
            break
        case false:
            label = 'Accreditation number'
            break
        default:
            break
    }
    console.log(accreditation, user_type, label)
    return label
}