import React from "react";
import { observer } from "mobx-react-lite";
import dChangeIcon from "assets/img/png/d_change.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { currentUserStore } from "stores";

export const Footer = observer(() => {
  const { currentUser } = currentUserStore;

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__content">
          <div className="footer__copyrights">
            {/* <img src={FooterLogo} alt=""/> */}
            <p>
              &copy; MedCafe Academy {new Date().getFullYear()}. All rights
              reserved.
            </p>
          </div>
          <ul className="footer-list">
            <a href="https://www.medcafe.ie/terms_of_service">
              <li>Terms of service</li>
            </a>
            <a href="https://www.medcafe.ie/privacy_policy">
              <li>Privacy Policy</li>
            </a>
            {currentUser.id && (
              <Link to={ROUTES.novoNordiskTerms}>
                <li>Novo Nordisk Terms and Conditions</li>
              </Link>
            )}
          </ul>
          {currentUser.id && (
            <img className="footer-dChangeIcon" src={dChangeIcon} />
          )}
        </div>
        <div className="footer__info">
          <div>IE24OB00031</div>
          <div>
            The Apis bull logo is registered trademark owned by Novo Nordisk
            A/S.
          </div>
          <div>February 2024</div>
        </div>
      </div>
    </footer>
  );
});
