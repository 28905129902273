import React, { useMemo } from 'react'
import {InputTextField} from 'components/form';
import {changeField,} from '../../actions/add';
import {observer} from 'mobx-react-lite';
import {getErrors, getFieldIMC, getLabelIMC} from "utils";
import {addUserStore} from "../../stores";
import { AdminUserType } from '@/types';

export const Imc = observer(() => {
  const {accreditation, user_type} = addUserStore.user;
  const field = useMemo(() => getFieldIMC({accreditation, user_type}), [accreditation, user_type])
  console.log(field, addUserStore.user[field as keyof AdminUserType], addUserStore.user.registration)
  return (
    <InputTextField
      type='round'
      name={field}
      label={getLabelIMC({accreditation, user_type})}
      placeholder=''
      value={addUserStore.user[field as keyof AdminUserType] || ''}
      error={getErrors(field)}
      onChange={changeField}
    />
  )
})