import React from 'react';
import {observer} from 'mobx-react-lite';
import {CPD_ACCREDITATION_OPTIONS} from '../../../../../constants';
import {SelectField} from 'components';
import {addWebinarStore} from '../../stores';
import {changeField} from '../../actions/add';
import {getErrors} from "utils";
import {DisabledPropType} from 'types';

export const CpdAccreditation = observer((props: DisabledPropType) => {
  const {disabled} = props;
  const {webinar} = addWebinarStore;
  return (
    <SelectField
      name='ref_type'
      label='CPD Accreditation'
      options={CPD_ACCREDITATION_OPTIONS}
      value={webinar.ref_type}
      onChange={changeField}
      variant='outlined'
      error={getErrors('ref_type')}
      disabled={disabled}
    />
  );
});
