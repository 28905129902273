import {makeAutoObservable} from 'mobx'
import {SCOPE_VALUES, CPD_LOCKER_META} from '../constants'
import {CpdLockerMetaType} from '../types';
import {WebinarType} from 'types';

export const cpdLockerStore = makeAutoObservable({
  _cpdLocker: [] as WebinarType[],
  _selectedOption: SCOPE_VALUES.live as string,
  _meta: CPD_LOCKER_META as CpdLockerMetaType,
  _inProgress: false,

  set cpdLocker(value: WebinarType[]) {
    this._cpdLocker = value;
  },

  get cpdLocker(): WebinarType[] {
    return this._cpdLocker;
  },

  set meta(meta: CpdLockerMetaType) {
    this._meta = meta;
  },

  get meta(): CpdLockerMetaType {
    return this._meta;
  },

  set inProgress(value: boolean) {
    this._inProgress = value;
  },

  get inProgress(): boolean {
    return this._inProgress;
  },

  clear(): void {
    this._cpdLocker = [] as any;
    this._meta = CPD_LOCKER_META as CpdLockerMetaType;
  }
});

