import {defaultErrorsInfo} from '../../../../../utils';
import {loginStore} from '../../stores';
import {loginServices} from '../../services';
import {commonStore, currentUserStore} from 'stores';

export const submitLogin = async (callback?: Function) => {
  try {
    loginStore.inProgress = true;
    const {email, password} = loginStore;
    const {data} = await loginServices.login(email, password);
    commonStore.token = data.token;
    currentUserStore.currentUser = data.user.data.attributes;

    loginStore.inProgress = false;

    if (callback)
      callback();
  } catch (error: any) {
    loginStore.inProgress = false;

    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
  }
};
