import React from "react"
import HomeImage from "assets/img/png/sidebar/home_logo.png";
import UsersImage from "assets/img/png/sidebar/users.png";
import WebinarsImage from 'assets/img/png/sidebar/webinars.png';
import CourseMaterialImage from 'assets/img/png/sidebar/course_material.png';
import StatisticsImage from 'assets/img/png/sidebar/statistics_icon.png';
import {ADMIN_ROUTES} from "constants/routes";
import {isActiveClass} from "utils";
import {generatePath, NavLink, useParams} from "react-router-dom";

export const SideBar = () => {
  const className = (data:any) => 'sidebar-item ' + isActiveClass(data);

  const params = useParams();

  return (
    <aside>
      <div className="sidebar__container">
        <div className="sidebar__content">
          <div className="sidebar-items">

            <div className="sidebar-item">
              <NavLink to={ADMIN_ROUTES.courses} className={className}><img src={HomeImage} alt="" /></NavLink>
            </div>

            <div className="sidebar-item">
              <NavLink to={generatePath(ADMIN_ROUTES.users, {id: params.id})} className={className}><img src={UsersImage} alt="" /></NavLink>
            </div>

            <div className="sidebar-item">
              <NavLink to={generatePath(ADMIN_ROUTES.webinars, {id: params.id})} className={className}><img src={WebinarsImage} alt="" /></NavLink>
            </div>

            <div className="sidebar-item">
              <NavLink to={generatePath(ADMIN_ROUTES.courseMaterials, {id: params.id})} className={className}><img src={CourseMaterialImage} alt="" /></NavLink>
            </div>

            <div className="sidebar-item">
              <NavLink to={generatePath(ADMIN_ROUTES.statistics, {id: params.id})} className={className}><img src={StatisticsImage} alt="" /></NavLink>
            </div>
            {/*<div className="sidebar-item">*/}
            {/*  <a href="./admin_forum.html"><img src="./img/png/sidebar/chats.png" alt=""></a>*/}
            {/*</div>*/}
            {/*<div className="sidebar-item">*/}
            {/*  <a href="./admin_chat.html"><img src="./img/png/sidebar/chat.png" alt=""></a>*/}
            {/*</div>*/}
            {/*<div className="sidebar-item">*/}
            {/*  <a href="./open_reports.html"><img src="./img/png/sidebar/Report_Icon.png" alt=""></a>*/}
            {/*</div>*/}
            {/*<div className="sidebar-item">*/}
            {/*  <img src="./img/png/sidebar/sponsors_partners.png" alt="">*/}
            {/*</div>*/}
            {/*<div className="sidebar-item">*/}
            {/*  <img src="./img/png/sidebar/mail.png" alt="">*/}
            {/*</div>*/}
            {/*<div className="sidebar-item">*/}
            {/*  <a href="./statistics.html"><img src="./img/png/sidebar/statistics_icon.png" alt=""></a>*/}
            {/*</div>*/}


          </div>
        </div>
      </div>
    </aside>
);
};