import {deleteModalStore, usersStore} from "../../stores";
import {adminUsersService} from "services";
import {showError500} from "utils";
import {getUsers} from "../gets";
import {toast} from "react-toastify";

export const onDeleteUsers = async () => {
  const ids = deleteModalStore.deleteIds;
  const id = deleteModalStore._deleteId;
  const isManyDelete = ids.length > 0;

  const params = {user_ids: [isManyDelete? ids : id]}

  const {status, data} = await adminUsersService.deleteUser(params)

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  toast.success('Users was delete successfully.');

  deleteModalStore.clear();
  usersStore.clear();
  getUsers();
}