import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {observer} from 'mobx-react-lite';
import {SelectField, FormInput, ButtonRound, FormCheckbox, RadioButtonsGroup} from 'components';
import {InputChangeProps, CheckboxChangeProps} from 'types';
import {
  ROUTES,
  USER_TYPES,
  ACCOUNT_LABELS,
  ACCREDITATION_TYPES,
} from '../../../../constants';
import {getFields} from '../utils';
import {unmount, changeUserField, submitUser} from '../actions/sets';
import {createAccountStore} from '../stores';

export const CreateAccountForm = observer(() => {
  const {user, inProgress} = createAccountStore;
  const [checked, setChecked]= useState(false);
  const onFieldChange = (data: InputChangeProps) => {
    changeUserField(data);
  };
  const handleSubmitForm = () => {
    submitUser();
  };
  const handleCheckbox = (data: CheckboxChangeProps) => {
    setChecked(data.value);
  };
  const isAccredited = (): boolean => {
    const isBoolean = typeof user.accreditation === 'boolean';
    return isBoolean ? !!user.accreditation : user.accreditation === 'true';
  };
  const fields = getFields(user.user_type);

  useEffect(() => {
    return () => unmount();
  }, []);

  return (
    <div className="signin__block">
      <div className="signin__container signup__container">
        <h1>CREATE AN ACCOUNT</h1>
        <div className="signin__content">
          <div className="containers">
            <form>
              <div className="row">
                <FormInput
                  name='first_name'
                  label={ACCOUNT_LABELS['first_name']}
                  value={user.first_name}
                  onChange={onFieldChange}
                />
                <FormInput
                  name='last_name'
                  label={ACCOUNT_LABELS['last_name']}
                  value={user.last_name}
                  onChange={onFieldChange}
                />
              </div>
              <SelectField
                label={ACCOUNT_LABELS['user_type']}
                name='user_type'
                value={user.user_type}
                options={USER_TYPES}
                onChange={onFieldChange}
              />
              <RadioButtonsGroup
                name='accreditation'
                label={ACCOUNT_LABELS['accreditation']}
                value={user.accreditation || ''}
                onChange={onFieldChange}
                options={ACCREDITATION_TYPES}
              />
              {isAccredited() && fields.map(field => (
                <FormInput
                  key={`account_${field}`}
                  name={field}
                  label={ACCOUNT_LABELS[field]}
                  value={user[field] || ''}
                  onChange={onFieldChange}
                />
              ))}
              <FormInput
                name="email"
                label={ACCOUNT_LABELS['email']}
                value={user.email}
                onChange={onFieldChange}
                type='email'
              />
              <FormInput
                name="confirm_email"
                label={ACCOUNT_LABELS['confirm_email']}
                value={user.confirm_email || ''}
                onChange={onFieldChange}
                type='email'
              />
              <FormInput
                name="password"
                label={ACCOUNT_LABELS['password']}
                value={user.password}
                onChange={onFieldChange}
                type='password'
              />
              <FormCheckbox
                name="termsAgree"
                label="I accept the Terms of Service"
                value={checked}
                onChange={handleCheckbox}
              />
            </form>
          </div>
          <ButtonRound
            onClick={handleSubmitForm}
            disabled={!checked || inProgress}
            className="auth_rounded_button">Sign Up
          </ButtonRound>
          <p>Already have an account? <Link to={ROUTES.login}><b>Sign In</b></Link></p>
        </div>
      </div>
    </div>
  );
});
