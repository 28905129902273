import {AdminSponsorType} from "types";
import {cloneDeep} from "lodash";

export const mapFromSponsor = (data: any): AdminSponsorType[] => {
  const clone = cloneDeep(data);
  const sponsors = clone.sponsors && clone.sponsors.data;
  const hasData = sponsors && sponsors.length > 0;

  return hasData ? sponsors.map((item: any) => {
    const {id, title, namespace, logo_url} = item.attributes;

    return {
      id,
      title,
      namespace,
      logo_url,
    }
  }) : [];
}

