export enum CpdStatus {
  not_applicable = 'not_applicable',
  applied_for = 'applied_for',
  granted = 'granted',
};

export const CPD_STATUSES = [{
  label: 'Not applicable',
  value: CpdStatus.not_applicable,
}, {
  label: 'Applied For',
  value: CpdStatus.applied_for,
}, {
  label: 'Granted',
  value: CpdStatus.granted,
}];
