import {paramsToFormData, showError500} from "utils";
import {adminInviteService} from "services";
import {STATUS_REJECTED} from "constants/inviteStatuses";
import {getInvites} from "../gets";

export const getRejectReasons = async (inviteId:number, reject_reason:number) => {
  const formData = paramsToFormData({status: STATUS_REJECTED, reject_reason}, 'invite');

  const {status, data} = await adminInviteService.updateInvite(formData, inviteId);

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  getInvites()
}