import {makeAutoObservable} from 'mobx'
import {CourseType} from "../../../..//types";

export const infoModalStore = makeAutoObservable({
  _course: {} as CourseType,
  _show: false,

  set course(value: CourseType) {
    this._course = value;
  },

  get course(): CourseType {
    return this._course;
  },

  set show(value: boolean) {
    this._show = value;
  },

  get show(): boolean {
    return this._show;
  },

  clear():void {
    this._course = {} as CourseType
    this._show = false;
  },

});

