import { cloneDeep } from "lodash";
import { addWebinarStore } from "../../stores";
import { Speaker } from "../../types";

export const removeSpeaker = (id: number) => {
  const _webinar = cloneDeep(addWebinarStore.webinar);

  const isSpeakerExists = Boolean(
    _webinar.speakers &&
      _webinar.speakers.data.find((speaker) => speaker.id === id.toString())
  );

  if (isSpeakerExists) {
    const idx = _webinar.speakers_attributes.findIndex(
      (speaker: any) => speaker.id === id
    );
    _webinar.speakers_attributes[idx] = {
      ..._webinar.speakers_attributes[idx],
      destroy: true,
    };
  } else {
    _webinar.speakers_attributes = _webinar.speakers_attributes.filter(
      (speaker: Speaker) => speaker.id !== id
    );
  }

  addWebinarStore.webinar = _webinar;
};
