export enum RefType {
  icgp = "icgp",
  rcpi = "rcpi",
  ipna = "ipna",
}

export type WebinarType = {
  id: number;
  course_id: number;
  webinar_type: string;
  landing_show: boolean;
  qrcode: string;
  generate_qrcode: boolean;
  assigned_ids: number[];
  assigned_users: any[];
  webinar_title: string;
  zoom_uid: string;
  join_url: string;
  vimeo_url: string;
  cpd_points: number | string;
  int_cpd_points: number;
  ext_cpd_points: number;
  icgp_ref: string;
  ipna_ref: string;
  cpd_status: number | string;
  ref_type: RefType;
  rcpi_ref: string;
  start_date: string | Date;
  end_date: string | Date;
  published_at: string;
  is_allow_to_subscribe: boolean;
  for_event: boolean;
  agenda: any;
  evaluation_feedback_summary: any;
  status: string;
  cpd_quiz: string;
  created_at: string;
  updated_at: string;
  api_key: string;
  viewed_count: number;
  downloaded_count: number;
  completed_count: number;
  attended_count: number;
  in_person_count: number;
  sponsors: any[];
  speakers: { data: any[] };
  partners: any[];
  is_register_interes: boolean;
  is_allow_to_get_sertificate: boolean;
  is_quiz_completed: boolean;
  speakers_names?: string;
  gms_study_days: string;
  [key: string]: any;
};
