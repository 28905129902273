import {toast} from 'react-toastify';
import {defaultErrorsInfo} from "utils";
import {webinarStore, quizModalStore} from '../../stores';
import {webinarService} from '../../services';

export const submitAnswers = async (callback?: Function) => {
  try {
    quizModalStore.inProgress = true;
    const {webinar} = webinarStore;
    const {cpdQuizAnswers} = quizModalStore;
    const {data} = await webinarService.submitAnswers(webinar.id, cpdQuizAnswers);

    quizModalStore.clear();

    if (data.message) {
      toast.success(data.message);
    }

    if (callback) {
      setTimeout(() => {
        callback();
      }, 1500);
    }
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
    quizModalStore.clear();
  }
};
