import {showError500} from "utils";
import {sponsorsService} from "../../services";
import {sponsorsStore} from "../../stores";
import {mapFromSponsor} from "../../utils";

export const getSponsorsByQuery = async (query:String) => {
  const params = {
    query,
    page: 1,
    per_page: 10,
  };

  const {status, data} = await sponsorsService.getSponsorsByQuery(params);

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  sponsorsStore.sponsors = mapFromSponsor(data);
}