import {AdminUserType} from "types";
import {cloneDeep} from "lodash";
import {geDateFormat} from "utils";
import {mapFromOneCourse} from "modules/admin/Courses/utils";

export const mapFromOneUser = (data: any): AdminUserType => {
  const clone = cloneDeep(data);

  const {
    id, first_name, last_name, email, password, role, company_name,
    country, created_at, last_login,
    title, phone, discipline, imc, accreditation, country_id, location, user_type,
    avatar_url, email_confirmed, county, registration, pin_number, county_id
  } = clone;

  // console.log(clone)

  return {
    id,
    first_name,
    last_name,
    email,
    password,
    role,
    company_name,
    pin_number,
    country,
    registration,
    county,
    county_id,
    created_at: geDateFormat(created_at),
    last_login: geDateFormat(last_login),
    course: mapFromOneCourse(clone.course),
    title,
    phone,
    discipline,
    imc,
    accreditation,
    country_id,
    location,
    user_type,
    avatar_url,
    email_confirmed
  }
}

