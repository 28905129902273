import { showError500 } from "utils" 
import { adminStatisticsService } from "services" 
import { statisticsStore } from "../../stores"
import moment from "moment"

export const getDetailStatsExport = async () => {
  const { detailsMeta } = statisticsStore
  if(!detailsMeta?.id && !detailsMeta?.statistic_type) return
  const { status, data } = await adminStatisticsService.getDetailStatsExport(detailsMeta.id!, detailsMeta.statistic_type!)
  const csvContent = 'data:text/csv;charset=utf-8,' + data
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", `${detailsMeta?.title.split(' ').join('_')}_${detailsMeta?.statistic_type}_${moment().format('DD_MM_YYYY')}.csv`)
  document.body.appendChild(link)

  link.click()


  if (status !== 200) {
    showError500(status, data)
    return
  }
  

}