export const WebinarErrorKeys = [
  "zoom_uid",
  "status",
  "vimeo_url",
  "cpd_points",
  "webinar_title",
  "webinar_type",
  "gms_study_days",
  "speakers.name",
  "start_date",
  "end_date",
];
