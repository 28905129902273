import React from "react"
import {observer} from "mobx-react-lite";
import {AddNewButtons} from ".";
import {LinkCourses, LinkSponsors, LinkSuperHost, LinkPartners} from "./components/index";

export type Props = {
  showButton: 'course' | 'superhost' | 'sponsors' | 'partners';
}

export const TopNavBar = observer((props: Props) => {
  return (
    <div className="courses__header">
      <div className="buttons-container">
        <LinkCourses/>
        <LinkSuperHost/>
        <LinkSponsors/>
        <LinkPartners/>
      </div>

      <AddNewButtons showButton={props.showButton} />
    </div>
  )
})