import React from 'react';
import {observer} from 'mobx-react-lite';
import {InputChangeProps} from 'types';
import {ButtonRound, RadioButtonsGroup} from 'components';
import {webinarStore, quizModalStore} from '../stores';
import {startQuiz, nextQuestion, setAnswer, submitAnswers, closeQuizModal} from '../actions/sets';

type Props = {
  submitCallback?: () => void;
};

const QuizAnswerOption = [
  {value: true, label: 'True'},
  {value: false, label: 'False'},
];

export const QuizModal = observer((props: Props) => {
  const {submitCallback} = props;
  const {cpdQuiz} = webinarStore;
  const {isOpen, cpdQuizAnswers, quizStarted, selectedQuestionIndex, inProgress} = quizModalStore;

  const isLastQuestion = (): boolean => {
    return selectedQuestionIndex === cpdQuiz.length - 1;
  };
  const handleAnswerChange = (data: InputChangeProps) => {
    setAnswer(data);
  };
  const handleSuccess = () => {
    if (submitCallback)
      submitCallback();
  };
  const handleButtonClick = () => {
    if (!isLastQuestion())
      nextQuestion();
    else
      submitAnswers(handleSuccess);
  };

  const getName = () => cpdQuiz[selectedQuestionIndex].id.toString();
  const getValue = () => cpdQuizAnswers[cpdQuiz[selectedQuestionIndex].id]?.toString();
  
  return isOpen ? (
    <div className='quiz__modal-container'>
      <div className='quiz__modal-content'>
        <span className='quiz__modal-close' onClick={closeQuizModal}>&times;</span>
        {!quizStarted ? (
          <div className='quiz__modal-wrapper'>
            <span>You need to answer 8 out 10 questions to get your CPD points for this webinar.</span>
            <ButtonRound className='quiz__modal-button' onClick={startQuiz}>Start The Quiz</ButtonRound>
          </div>
        ) : (
          <div className='quiz__modal-wrapper'>
            <span>{cpdQuiz[selectedQuestionIndex].question}</span>
            <RadioButtonsGroup
              label=''
              row={false}
              options={QuizAnswerOption}
              name={getName()}
              value={getValue() || ''}
              onChange={handleAnswerChange}
            />
            <ButtonRound
              className='quiz__modal-button'
              disabled={!getValue() || inProgress}
              onClick={handleButtonClick}
            >
              {isLastQuestion() ? 'Submit' : 'Next'}
            </ButtonRound>
          </div>
        )}
      </div>
    </div>
  ) : null;
});
