import {showError500} from "utils";
import {sponsorsService} from "../../services";
import {partnersStore} from "../../stores";
import {mapFromPartners} from "../../utils";

export const getParntersByQuery = async (query:String) => {
  const params = {
    query,
    page: 1,
    per_page: 10,
  };

  const {status, data} = await sponsorsService.getPartnersByQuery(params);

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  partnersStore.partners = mapFromPartners(data);
}