import {addUserStore} from "../../stores";
import {ImageChangeProps} from "types";

export const addAvatarPreview = (value:ImageChangeProps) => {
  addUserStore.changeField(value)

  let reader = new FileReader();
  reader.readAsDataURL(value.value);

  reader.onload = (event) => {
    addUserStore.changeField({name: value.name, value: event.target?.result});
  }
}