import React from "react"
import {Page} from "../Page";
import {TopNavBar} from "modules/admin/TopNavBar";
import {List} from "./components/List";
import {AddModal, DeleteModal} from "./components";

export const Sponsors = () => {
  return (
    <Page>
      <TopNavBar showButton='sponsors' />
      <List />
      <AddModal />
      <DeleteModal />
    </Page>
  )
}