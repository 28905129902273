import React from "react"
import {observer} from "mobx-react-lite";
import {usersStore} from "../../stores";
import {openDeleteSelectedModal} from "../../actions/modals";
import {debounce} from "lodash";
import {getUsersByQuery} from "../../actions/gets";

export const ListSearch = observer(() => {
  const showDeleteButton = usersStore.checkedIds.length > 0;
  const display = showDeleteButton ? 'block' : 'none';

  const onSearch = debounce((query:string) => getUsersByQuery(query), 3000);

  return (
    <div className="search__container">
      <form className="chat">
        <button type="submit">Search</button>
        <input type="search" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} />
      </form>
      <div className="search__content">
        <p style={{display: display}} onClick={openDeleteSelectedModal}>Delete</p>
      </div>
    </div>
  )
})