import React from "react"
import {ADMIN_ROUTES} from "constants/index";
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {isActiveClass} from "utils";
import {sponsorsStore} from "../../Sponsors/stores";

export const LinkSponsors = observer(() => {
  const count = sponsorsStore.count();
  const countText = count === 0 ? '' : `(${count})`;

  return (
    <NavLink to={ADMIN_ROUTES.sponsors} className={isActiveClass}>
      <button className="all-courses active">Sponsors {countText}</button>
    </NavLink>
  )
})