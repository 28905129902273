import {showError500} from "utils";
import {adminUsersService} from "services";
import {usersStore} from "../../stores";
import {mapFromUsers} from "../../utils";

export const getSuperHosts = async () => {
  const params = {role: 'superhost', page: 1, per_page: 25}
  const {status, data} = await adminUsersService.getSuperHosts(params);

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  usersStore.users = mapFromUsers(data);
}