import axios from 'axios'
import {generatePath} from 'react-router-dom'
import {addAuthTokens} from '.';

export const postRequestAuth = async (url:string, params:object) => {
    return await axios
        .create({baseURL: process.env.REACT_APP_API_URL})
        .post(generatePath(url), {...params}, addAuthTokens())
        .then(response => response)
        .catch(error => error.response)
};
