export const WEBINAR_TYPES_OPTIONS = [{
  label: 'Live webinar',
  value: 'online',
}, {
  label: 'Hybrid meeting',
  value: 'hybrid',
}, {
  label: 'Recorded only',
  value: 'recorded',
}];
