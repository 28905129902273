import {cloneDeep} from 'lodash';
import {deleteModalStore, webinarsStore} from "../../stores";
import {adminWebinarsService} from "services";
import {showError500} from "utils";
import {getWebinars} from "../gets";
import {toast} from "react-toastify";

export const onDeleteWebinars = async () => {
  const _meta = cloneDeep(webinarsStore.meta);
  const ids = deleteModalStore.deleteIds;
  const id = deleteModalStore._deleteId;
  const isManyDelete = ids.length > 0;

  const params = {webinar_ids: [isManyDelete? ids : id]}

  const {status, data} = await adminWebinarsService.deleteWebinar(params)

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  toast.success(`${ids.length > 0 ? 'Webinars' : 'Webinar'} was delete successfully.`);

  _meta.page = 1;
  _meta.query = '';

  deleteModalStore.clear();
  webinarsStore.clear();
  webinarsStore.meta = _meta;

  getWebinars();
}