import {CpdLockerMetaType} from '../types';

export enum SCOPE_VALUES {
  live = 'cpd_live',
  recorded = 'cpd_recorded'
};

export const CPD_LOCKER_META: CpdLockerMetaType = {
  scope: SCOPE_VALUES.live,
  courseId: 0,
  page: 1,
  perPage: 4,
  total_pages: 1,
};