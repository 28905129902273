import {PageSectionButtonsType} from 'types';
import classnames from 'classnames';
import {ButtonRound} from './ButtonRound';

export const PageSectionButtons = (props: PageSectionButtonsType) => {
  const {options, selectedOption, onOptionPress} = props;
  return (
    <div className="academy__buttons">
      {options.map(option => (
        <a key={option.value}>
          <ButtonRound
            className={classnames('my-academy', {
              active: option.value === selectedOption,
            })}
            onClick={() => onOptionPress(option.value)}
          >
            {option.label}
          </ButtonRound>
        </a>
      ))}
    </div>
  );
};