import React from 'react';
import {observer} from 'mobx-react-lite';
import {InputTextField} from 'components/form';
import {addWebinarStore} from '../../stores';
import {changeField} from '../../actions/add';
import {getErrors} from "utils";
import {DisabledPropType} from 'types';

export const ZoomField = observer((props: DisabledPropType) => {
  const {disabled} = props;
  const {webinar} = addWebinarStore;
  return (
    <InputTextField
      name='zoom_uid'
      label='Zoom Webinar/Meeting ID'
      value={webinar.zoom_uid}
      onChange={changeField}
      type='round'
      error={getErrors('zoom_uid')}
      disabled={disabled}
    />
  );
});
