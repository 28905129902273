import {WebinarType} from "types";
import {cloneDeep} from "lodash";

export const mapFromWebinars = (data: any): WebinarType[] => {
  const clone = cloneDeep(data);
  const _webinars = clone.webinars.data;
  const hasData = _webinars && _webinars.length > 0;

  return hasData ? _webinars.map((item: any) => ({...item.attributes})) : [];
}
