import React, { useEffect } from "react";
import { useNavigate, generatePath, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ADMIN_ROUTES } from "../../../../../constants";
import { WebinarType } from "../Add/WebinarType";
import { WebinarTitle } from "../Add/WebinarTitle";
import { DateInput } from "../Add/DateInput";
import { TimeInput } from "../Add/TimeInput";
import { ZoomField } from "../Add/ZoomField";
import { VimeoField } from "../Add/VimeoField";
import { CpdStatus } from "../Add/CpdStatus";
import { AddSponsor } from "../Add/AddSponsor";
import { AddPartner } from "../Add/AddPartner";
import { QuestionInput } from "../Add/QuestionInput";
import { SpeakerInput } from "../Add/SpeakerInput";
import { Agenda } from "../Add/Agenda";
import { EvaluationFeedbackSummary } from "../Add/EvaluationFeedbackSummary";
import { CpdAccreditation } from "../Add/CpdAccreditation";
import { ReferenceNumber } from "../Add/ReferenceNumber";
import { CpdPointsType } from "../Add/CpdPointsType";
import { ExtCpdPoints } from "../Add/ExtCpdPoints";
import { IntCpdPoints } from "../Add/IntCpdPoints";
import { GmsStudyDays } from "../Add/GmsStudyDays";
import { toggleModal } from "../../actions/modals";
import { addMultypleCheckedIds } from "../../actions";
import { addQuestion, addSpeaker } from "../../actions/add";
import { addWebinarStore, assignedUsersModalStore } from "../../stores";
import { QuizQuestion, Speaker } from "../../types";
import { usersStore } from "../../../Users/stores";
import { setMeta } from "../../../Users/actions/sets";
import { getUsers } from "../../../Users/actions/gets";
import { RefType } from "types";
import { cloneDeep } from "lodash";
import { setAssignedUsers } from "../../actions/sets";

const ReferenceNumberType = {
  icgp: {
    name: "icgp_ref",
    label: "ICGP",
  },
  rcpi: {
    name: "rcpi_ref",
    label: "RCPI",
  },
  ipna: {
    name: "ipna_ref",
    label: "IPNA",
  },
};

export const DetailsForm = observer(() => {
  const { webinar } = addWebinarStore;
  const { users, meta } = usersStore;
  const { assignedUsers } = assignedUsersModalStore;
  const { id } = useParams();
  const navigate = useNavigate();

  const navigateToAddUsers = () => {
    const path = generatePath(ADMIN_ROUTES.addUsersWebinar, { id });

    if (webinar.assigned_ids && webinar.assigned_ids.length > 0) {
      addMultypleCheckedIds(webinar.assigned_ids);
    }

    navigate(path);
  };
  const handleAddQuestion = () => {
    addQuestion();
  };
  const handleAddSpeaker = () => {
    addSpeaker();
  };

  useEffect(() => {
    if (webinar && webinar.assigned_ids.length) {
      setAssignedUsers(webinar.assigned_users);
    }
  }, [webinar]);

  return (
    <div className="user-container">
      <div className="personal-info">
        <div className="user-block">
          <span>WEBINAR INFO:</span>

          <div className="user-fields">
            <WebinarType disabled />
            <WebinarTitle disabled />
            {webinar.assigned_ids.length > 0 && (
              <div className="group">
                <button className="add-new-course" onClick={toggleModal}>
                  Assigned Users
                </button>
              </div>
            )}
            <div className="row">
              <DateInput label="Start date" name="start_date" disabled />
              <TimeInput label="Start time" name="start_date" disabled />
            </div>
            <div className="row">
              <DateInput label="End date" name="end_date" disabled />
              <TimeInput label="End time" name="end_date" disabled />
            </div>
            {!!webinar.zoom_uid ? <ZoomField disabled /> : null}
            {!!webinar.vimeo_url ? <VimeoField disabled /> : null}
            {/* <SponsorToNewsletter /> */}
          </div>
        </div>
      </div>

      <div className="additional-info">
        <div className="user-block">
          <span>CPD INFO:</span>
          <div className="user-fields" style={{ marginBottom: "40px" }}>
            <CpdAccreditation disabled />
            {ReferenceNumberType[webinar.ref_type] && (
              <ReferenceNumber
                name={ReferenceNumberType[webinar.ref_type].name}
                label={ReferenceNumberType[webinar.ref_type].label}
                disabled
              />
            )}
            <CpdStatus disabled />
            {webinar.ref_type === RefType.icgp &&
              webinar.cpd_status === "granted" && <GmsStudyDays disabled />}
            <CpdPointsType disabled />
            {(webinar.cpd_points === "external" ||
              webinar.cpd_points === "both") && <ExtCpdPoints disabled />}
            {(webinar.cpd_points === "internal" ||
              webinar.cpd_points === "both") && <IntCpdPoints disabled />}
          </div>

          <span>CPD QUESTIONS:</span>
          <div className="user-fields">
            {(webinar.cpd_quizzes_attributes || []).map(
              (cpdQuiz: QuizQuestion) => (
                <QuestionInput index={cpdQuiz.id} item={cpdQuiz} disabled />
              )
            )}
          </div>
        </div>
      </div>

      <div className="account-info">
        <div className="user-block">
          <span>SPEAKER INFO:</span>
          <div className="user-fields">
            {(webinar.speakers_attributes || []).map((speaker: Speaker) => (
              <SpeakerInput index={speaker.id} item={speaker} disabled />
            ))}
          </div>

          <span>Sponsors and partnership info:</span>
          <div className="user-fields">
            {webinar.sponsors.length > 0 && <AddSponsor disabled />}
            {webinar.partners.length > 0 && <AddPartner disabled />}
          </div>
          {!!webinar.agenda_url && <Agenda disabled />}
          {!!webinar.evaluation_feedback_summary_url && (
            <EvaluationFeedbackSummary disabled />
          )}
        </div>
      </div>
    </div>
  );
});
