import React from "react";
import { observer } from "mobx-react-lite";
import CloseIcon from "assets/img/png/ic-close.png";
import { addModalStore, addSuperHostStore } from "../stores";
import { InputTextField, SelectField } from "components/form";
import {
  changeField,
  mount,
  onSubmitAddSuperHost,
  unmount,
} from "../actions/add";
import { closeCreateSuperHostModal } from "../actions/modals";
import { coursesStore } from "../../Courses/stores";
import { onSubmitEditSuperHost } from "../actions/edit";
import { errorsStore } from "stores";

export const AddModal = observer(() => {
  const { show } = addModalStore;
  const {
    first_name,
    last_name,
    company_name,
    email,
    password,
    id,
    course_id,
  } = addSuperHostStore.superHost;

  const coursesOptions = coursesStore.options();

  const isEdit = Boolean(id);

  const headerText = isEdit ? "EDIT SUPERHOST" : "CREATE SUPERHOST";
  const buttonText = isEdit ? "Save Superhost" : "Create Superhost";

  const getError = (name: string) => errorsStore.getError(name);

  React.useEffect(() => {
    mount();

    return unmount();
  }, []);

  const onSubmit = () =>
    isEdit ? onSubmitEditSuperHost() : onSubmitAddSuperHost();

  if (!show) {
    return <div />;
  }

  return (
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-container-superhost">
        <span className="close">×</span>
        <div className="modal-content-create__superhost">
          <div className="modal-content-create__headline">
            <img
              src={CloseIcon}
              alt="close modal button"
              onClick={closeCreateSuperHostModal}
            />
          </div>
          <h3>{headerText}</h3>
          <div className="superhost-create__desc">
            <div className="superhost-inputs">
              <InputTextField
                type="modal"
                name="first_name"
                label="First Name"
                placeholder=""
                value={first_name || ""}
                error={getError("first_name")}
                onChange={changeField}
              />

              <InputTextField
                type="modal"
                name="last_name"
                label="Last Name"
                placeholder=""
                value={last_name || ""}
                error={getError("last_name")}
                onChange={changeField}
              />

              <InputTextField
                type="modal"
                name="company_name"
                label="Company Name"
                value={company_name || ""}
                error={getError("company_name")}
                onChange={changeField}
              />

              <InputTextField
                type="modal"
                name="password"
                label="Password"
                value={password || ""}
                error={getError("password")}
                onChange={changeField}
              />

              <InputTextField
                type="modal"
                name="email"
                label="Email"
                value={email || ""}
                error={getError("email")}
                onChange={changeField}
              />

              <SelectField
                name="course_id"
                label="Course"
                // setDefaultValue={true}
                options={coursesOptions}
                value={course_id || 0}
                error={getError("course_id")}
                onChange={changeField}
              />
            </div>

            <div className="superhost-btns-create">
              <button
                className="cancel-btn-modal"
                onClick={closeCreateSuperHostModal}
              >
                Cancel
              </button>
              <button className="create-btn" onClick={onSubmit}>
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
