import {API_ADMIN_PARTNER_DELETE, API_ADMIN_PARTNER_ID, API_ADMIN_PARTNERS} from "constants/api";
import {getRequestAuth, postRequestAuth, postRequestFile, putRequestFile} from "utils/requests";
import {generatePath} from "react-router-dom";

const getPartners = async () => await getRequestAuth(API_ADMIN_PARTNERS);

const getPartnersByQuery = async (params:object) => await getRequestAuth(API_ADMIN_PARTNERS, params);

const addPartner = async (params: FormData) => await postRequestFile(API_ADMIN_PARTNERS, params);

const editPartner = async (params:FormData, id:number) => await putRequestFile(courseUrl(id), params);

const deletePartner = async (params: object) => await postRequestAuth(API_ADMIN_PARTNER_DELETE, params);

export const sponsorsService = {
  getPartners,
  getPartnersByQuery,
  addPartner,
  editPartner,
  deletePartner,
}

const courseUrl = (id:number) => generatePath(API_ADMIN_PARTNER_ID, {id});