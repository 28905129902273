import {makeAutoObservable} from 'mobx'
import {CourseFormType, ImageChangeProps} from "types";
import {cloneDeep} from "lodash";

export const addCourseStore = makeAutoObservable({
  _course: {} as CourseFormType,

  set course(value: CourseFormType) {
    this._course = value;
  },

  get course():CourseFormType {
    return this._course;
  },

  changeField(data: ImageChangeProps) {
    const clone = cloneDeep(this._course);
    const key = data.name as keyof CourseFormType;

    // @ts-ignore
    clone[key] = data.value;
    this._course = clone;
  },

  clear(): void {
    this._course = {} as CourseFormType
  },
});

