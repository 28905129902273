import React from "react";
import { Layout } from "../Layout";
import { TopComponent } from "components";
import landingImage from "assets/img/png/medcafe_logo.png";
import { SignInForm, List } from "./components";

const Landing = () => {
  return (
    <Layout hideHeader className="landing not_auth_page__container">
      <div className="top__container">
        <div className="top__logo">
          {/* <img src={landingImage} alt="" /> */}
          <div className="top__content">
            {/* <TopComponent /> */}
            <SignInForm />
            {/* <div className="bottom__container"> */}
            {/* <h1>Available Courses:</h1> */}
            {/* <List /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* <div className="bottom__container">
        <h1>Available Courses:</h1>
        <List />
      </div> */}
    </Layout>
  );
};

export { Landing };
