import {cloneDeep} from 'lodash';
import {CpdQuizType, CpdQuizAnswersType} from 'types';
import {quizModalStore, webinarStore} from '../../stores';

export const startQuiz = () => {
  const _cpdQuiz = cloneDeep(webinarStore.cpdQuiz);
  const _cpdQuizAnswers = _cpdQuiz.reduce((cpdQuizAnswers: CpdQuizAnswersType, item: CpdQuizType) => {
    const cpdQuizAnswerss = cpdQuizAnswers;
    cpdQuizAnswerss[item.id] = '';
    return cpdQuizAnswerss;
  },
  {});

  quizModalStore.cpdQuizAnswers = _cpdQuizAnswers;
  quizModalStore.quizStarted = true;
};
