import {cloneDeep} from 'lodash';
import {InputChangeProps} from 'types';
import {quizModalStore} from '../../stores';

export const setAnswer = (data: InputChangeProps) => {
  const _cpdQuizAnswers = cloneDeep(quizModalStore.cpdQuizAnswers);
  _cpdQuizAnswers[data.name] = data.value === 'true';

  quizModalStore.cpdQuizAnswers = _cpdQuizAnswers;
};
