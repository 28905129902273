import React from "react"
import { observer } from "mobx-react-lite"

export const CourseMaterialRequestsBtn = observer((props: any) => {
  return (
    <div className="request-buttons">
      <button className={`request-btn ${props.scope === 'pending' ? 'active' : ''}`} id='pending' onClick={props.setScope}>PENDING</button>
      <button className={`request-btn ${props.scope === 'approved' ? 'active' : ''}`} id='approved' onClick={props.setScope}>APPROVED</button>
      <button className={`request-btn ${props.scope === 'rejected' ? 'active' : ''}`} id='rejected' onClick={props.setScope}>REJECTED</button>
    </div>
  )
})