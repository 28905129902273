import {WebinarSpeakerType} from "types";
import {cloneDeep} from "lodash";

export const mapFromSpeakers = (data: any): WebinarSpeakerType[] => {
  const clone = cloneDeep(data);
  const _data = clone.data;
  const hasData = _data && _data.length > 0;

  return hasData ? _data.map((item: any) => ({...item.attributes})) : [];
}
