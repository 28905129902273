// import {cloneDeep} from 'lodash';
import {defaultErrorsInfo} from "../../../../../utils";
import {webinarService} from "../../services";
import {webinarStore} from "../../stores";
import {mapFromWebinar, mapFromQuiz} from '../../utils';

export const getWebinar = async (webinarId: string) => {
  try {
    webinarStore.inProgress = true;
    const {data} = await webinarService.getWebinar(webinarId);
    const _webinar = mapFromWebinar(data);
    const _cpdQuiz = mapFromQuiz(_webinar);

    webinarStore.webinar = _webinar;
    webinarStore.cpdQuiz = _cpdQuiz;
    webinarStore.inProgress = false;
  } catch (error: any) {
    const {response} = error;
    defaultErrorsInfo(response.status, response.data);
    webinarStore.inProgress = false;
  }
}
