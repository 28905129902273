import React, {useState, useCallback} from 'react';
import {debounce} from 'lodash';
import searchIcon from 'assets/img/svg/search.svg';

type SearchInputType = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

export const SearchInput = (props: SearchInputType) => {
  const {value, placeholder, onChange} = props;
  const debouncedSave = useCallback(
    debounce(nextValue => onChange(nextValue), 1000),
    [],
  );
  const [query, setQuery] = useState(value || '');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setQuery(text);
    debouncedSave(text);
  };

  return (
    <div className='page__title'>
      <div className='search_input-container'>
        <input className='search_input' value={query} placeholder={placeholder || 'Search...'} onChange={handleChange} />
        <img src={searchIcon} className='search_icon' />
      </div>
    </div>
  );
};
