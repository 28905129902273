import React from "react";
import { ButtonRound, FormInput } from "components";
import { observer } from "mobx-react-lite";
import { adminAuthStore } from "stores";
import { changeField, onLogin } from "../actions";

export const LoginForm = observer(() => {
  const { email, password } = adminAuthStore.user;

  return (
    <div className="signin__block">
      <div className="signin__container">
        <h1>MEDCAFE ACADEMY</h1>
        <div className="signin__content">
          <div className="containers">
            <form>
              <div className="group">
                <FormInput
                  name="email"
                  label="Email address"
                  value={email}
                  onChange={changeField}
                  type="email"
                />

                <FormInput
                  name="password"
                  label="Password"
                  value={password}
                  onChange={changeField}
                  type="password"
                />
              </div>
            </form>
          </div>

          <ButtonRound onClick={onLogin}>Sign In</ButtonRound>
        </div>
      </div>
    </div>
  );
});
