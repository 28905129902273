import React from "react"
import EditIcon from "assets/img/png/edit_icon.png";
import DeleteIcon from "assets/img/png/trashcan.png";
import {CourseType} from "types";
import {openDeleteModal, openInfoModal} from "../actions/modals";
import {generatePath, useNavigate} from "react-router-dom";
import {setEditCourseData} from "../actions/edit";
import {ADMIN_ROUTES} from "constants/routes";

type Props = {
  course: CourseType;
}

export const Course = (props:Props) => {
  const {id, title} = props.course;
  let navigate = useNavigate();

  const onEdit = () => {
    setEditCourseData(props.course);

    navigate(ADMIN_ROUTES.editCourse);
  }

  const onOpenCourse = () => {
    const url = generatePath(ADMIN_ROUTES.users, {id})
    navigate(url);
  }

  return (
    <div className="courses-list">
      <div className="courses-item">
        <p className='cursor-pointer' onClick={() => openInfoModal(id)}>{title}</p>
        <div className="content-options courses">
          <div className="edit">
            <img src={EditIcon} alt="" onClick={onEdit} />
          </div>
          <div className="delete">
            <img src={DeleteIcon} alt="" onClick={() => openDeleteModal(id)} />
          </div>
        </div>
      </div>

      <button className="open-course" onClick={onOpenCourse}>Open Course</button>
    </div>
  )
}