import React from "react"

export const ListHeader = () => {
  return (
    <div className="superhost__header">
      <div className="header-categories-sponsors">
        <div className="name">
          <p>User Name</p>
        </div>
        <div className="name">
          <p>User Email</p>
        </div>
        <div className="name">
          <p>Coutry</p>
        </div>
        <div className="name">
          <p>Date Registered</p>
        </div>
        <div className="name">
          <p>Last log in</p>
        </div>
      </div>
    </div>
  )
}