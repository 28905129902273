import React from "react"
import {Page} from "../Page";
import {TopNavBar} from "modules/admin/TopNavBar";
import {List} from "./components/List";
import {AddModal, DeleteModal} from "./components";

export const Partners = () => {
  return (
    <Page>
      <TopNavBar showButton='partners' />
      <List />
      <AddModal />
      <DeleteModal />
    </Page>
  )
}