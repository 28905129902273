import {makeAutoObservable} from 'mobx'

export const confirmModalStore = makeAutoObservable({
  _courseId: 0,
  _show: false,

  set courseId(value: number) {
    this._courseId = value;
  },

  get courseId(): number {
    return this._courseId;
  },

  set show(value: boolean) {
    this._show = value;
  },

  get show(): boolean {
    return this._show;
  },

  clear(): void {
    this._courseId = 0;
    this._show = false;
  },

});

