import React from "react"
import {ADMIN_ROUTES} from "constants/routes";
import {generatePath, Link, useParams} from "react-router-dom";

export const AddNewWebinar = () => {
  const {id} = useParams();

  const getLinkAddWebinar = () => generatePath(ADMIN_ROUTES.addWebinar, {id});

  return (
    <div className='user-buttons-users'>
      <Link to={getLinkAddWebinar()}>
        <button className="add-new-course">Add webinar</button>
      </Link>
    </div>
  )
}