import React from "react"
import {Layout} from "../Layout";
import {WebLeftComponent} from "components";
import {ForgotPasswordForm} from "./components";

export const ForgotPassword = () => {
  return (
    <Layout>
      <WebLeftComponent />
      <ForgotPasswordForm />
    </Layout>
  );
};
