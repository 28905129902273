import {API_ADMIN_LOGIN, API_ADMIN_LOGOUT} from "../constants";
import {postRequest, putRequestAuth} from '../utils/requests';

/**
 * @param {Object} params
 * @param {String} params.email
 * @param {String} params.password
 */
const adminLogin = async (params:object) => await postRequest(API_ADMIN_LOGIN, params);

const adminLogout = async () => await putRequestAuth(API_ADMIN_LOGOUT, {});

export const authService = {
    adminLogin,
    adminLogout,
}