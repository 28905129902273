import { makeAutoObservable } from "mobx";
import { AdminUserType } from "types";
import { USERS_META } from "../constants";
import { UsersMetaType } from "../types";

export const usersStore = makeAutoObservable({
  _users: [] as AdminUserType[],
  _meta: USERS_META as UsersMetaType,
  _checkedIds: [] as number[],

  set users(value: AdminUserType[]) {
    this._users = value;
  },

  get users(): AdminUserType[] {
    return this._users;
  },

  set meta(value: UsersMetaType) {
    this._meta = value;
  },

  get meta(): UsersMetaType {
    return this._meta;
  },

  get checkedIds(): number[] {
    return this._checkedIds;
  },

  getUserById(id: number): AdminUserType {
    return this._users.filter((item) => item.id === id)[0];
  },

  addCheckedId(value: number) {
    const copy = [...this._checkedIds];
    const index = copy.findIndex((id) => id === value);

    index !== -1 ? (copy[index] = value) : copy.push(value);

    this._checkedIds = copy;
  },

  removeCheckedId(value: number) {
    this._checkedIds = [...this._checkedIds].filter((id) => id !== value);
  },

  count(): number {
    return this._users.length;
  },

  clear(): void {
    this._users = [] as AdminUserType[];
    this._checkedIds = [] as number[];
    this._meta = {
      ...USERS_META,
      course_id: this._meta.course_id,
    } as UsersMetaType;
  },
});
