import React from 'react';
import {observer} from 'mobx-react-lite';
import {PageHeader} from 'components';
import {Page} from '../Page';
import {QuizModal} from '../Webinar/components';
import {mount, unmount, getCpdLocker} from './actions/gets';
import {setMeta} from './actions/sets';
import {cpdLockerStore} from './stores';
import {coursesStore} from '../Dashboard/stores';
import {SCOPE_OPTIONS, SCOPE_VALUES} from './constants';
import {cloneDeep} from 'lodash'
import {List, ButtonRound} from 'components';
import {Item} from './components';

export const CpdLocker = observer(() => {
  const {meta, cpdLocker, inProgress} = cpdLockerStore;
  const {course} = coursesStore;
  const handleOptionPress = (scope: string) => {
    const _meta = cloneDeep(meta);
    _meta.scope = scope;
    setMeta(_meta);
    getCpdLocker();
  };
  const loadMore = () => {
    const _meta = cloneDeep(meta);
    _meta.page = meta.page + 1;
    setMeta(_meta);
    getCpdLocker();
  };
  const handleSuccess = () => {
    const _meta = cloneDeep(meta);
    _meta.page = 1;
    setMeta(_meta);
    getCpdLocker();
  };

  React.useEffect(() => {
    if (!course.id) {
      mount();
    } else {
      const _meta = cloneDeep(meta);
      _meta.courseId = course.id;
      setMeta(_meta);
      mount();
    }

    return unmount();
  }, []);

  return (
    <Page className="auth_page_wrapper">
      <PageHeader
        title={course.title || 'Obesity Primary Care Academy'}
        selectedOption={meta.scope}
        options={SCOPE_OPTIONS}
        onOptionPress={handleOptionPress}
      />
      <List>
        {cpdLocker.map(cpd => (
          <Item
            key={`cpdLocker_${cpd.id}`}
            item={cpd}
            selectetScope={meta.scope}
            inProgress={inProgress}
            disabled={meta.scope === SCOPE_VALUES.live}
            hideQuizButton={meta.scope === SCOPE_VALUES.live}
          />
        ))}
      </List>
      {meta.total_pages > meta.page ? (
        <div className='container-button'>
          <ButtonRound
            className='load-more'
            onClick={loadMore}
          >
            Load more
          </ButtonRound>
        </div>
       ) : null
      }
      <QuizModal submitCallback={handleSuccess} />
    </Page>
  )
});
