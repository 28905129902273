import React from 'react';
import {observer} from 'mobx-react-lite';
import {PageHeader} from 'components';
import {Page} from '../Page';
import {CreateCourseMaterialForm, ConfirmModal} from './components';

export const CreateCourseMaterial = observer(() => {
  return (
    <Page className="auth_page_wrapper">
      <PageHeader
        title='ADD NEW COURSE MATERIAL'
      />
      <CreateCourseMaterialForm />
      <ConfirmModal />
    </Page>
  )
});
