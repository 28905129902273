import React from "react";
import { useNavigate, generatePath, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ADMIN_ROUTES } from "../../../../../constants";
import { WebinarType } from "./WebinarType";
import { WebinarTitle } from "./WebinarTitle";
import { DateInput } from "./DateInput";
import { TimeInput } from "./TimeInput";
import { ZoomField } from "./ZoomField";
import { VimeoField } from "./VimeoField";
import { SponsorToNewsletter } from "./SponsorToNewsletter";
import { CpdStatus } from "./CpdStatus";
import { AddSponsor } from "./AddSponsor";
import { AddPartner } from "./AddPartner";
import { QuestionInput } from "./QuestionInput";
import { SpeakerInput } from "./SpeakerInput";
import { Agenda } from "./Agenda";
import { EvaluationFeedbackSummary } from "./EvaluationFeedbackSummary";
import { CpdAccreditation } from "./CpdAccreditation";
import { ReferenceNumber } from "./ReferenceNumber";
import { CpdPointsType } from "./CpdPointsType";
import { ExtCpdPoints } from "./ExtCpdPoints";
import { IntCpdPoints } from "./IntCpdPoints";
import { GmsStudyDays } from "./GmsStudyDays";
import { toggleModal } from "../../actions/modals";
import { addMultypleCheckedIds } from "../../actions";
import { addQuestion, addSpeaker } from "../../actions/add";
import { addWebinarStore, assignedUsersModalStore } from "../../stores";
import { QuizQuestion, Speaker } from "../../types";
import { RefType } from "types";
import { CustomSwitch } from "./CustomSwitch";
import { setAssignedUsers } from "../../actions/sets";
import { getErrors } from "utils";

const ReferenceNumberType = {
  icgp: {
    name: "icgp_ref",
    label: "ICGP",
  },
  rcpi: {
    name: "rcpi_ref",
    label: "RCPI",
  },
  ipna: {
    name: "ipna_ref",
    label: "IPNA",
  },
};

export const AddWebinarForm = observer(() => {
  const { webinar } = addWebinarStore;

  const { id } = useParams();
  const navigate = useNavigate();

  const navigateToAddUsers = () => {
    const path = generatePath(ADMIN_ROUTES.addUsersWebinar, { id });

    if (webinar.assigned_ids && webinar.assigned_ids.length > 0) {
      addMultypleCheckedIds(webinar.assigned_ids);
    }

    navigate(path);
  };
  const handleAddQuestion = () => {
    addQuestion();
  };
  const handleAddSpeaker = () => {
    addSpeaker();
  };

  return (
    <div className="user-container">
      <div className="personal-info">
        <div className="user-block">
          <span>WEBINAR INFO:</span>

          <div className="user-fields">
            <CustomSwitch />
            <WebinarType />
            <WebinarTitle />
            <div className="group">
              <button className="add-new-course" onClick={navigateToAddUsers}>
                Add User
              </button>
            </div>
            <div className="group">
              <button className="add-new-course" onClick={toggleModal}>
                Assigned Users
              </button>
            </div>
            <div className="row">
              <DateInput label="Start date" name="start_date" />
              <TimeInput label="Start time" name="start_date" />
            </div>
            <div className="row">
              <DateInput label="End date" name="end_date" />
              <TimeInput label="End time" name="end_date" />
            </div>
            <ZoomField />
            <VimeoField />
            {/* <SponsorToNewsletter /> */}
          </div>
        </div>
      </div>

      <div className="additional-info">
        <div className="user-block">
          <span>CPD INFO:</span>
          <div className="user-fields" style={{ marginBottom: "40px" }}>
            <CpdAccreditation />
            {ReferenceNumberType[webinar.ref_type] && (
              <ReferenceNumber
                name={ReferenceNumberType[webinar.ref_type].name}
                label={ReferenceNumberType[webinar.ref_type].label}
              />
            )}
            <CpdStatus />
            {webinar.ref_type === RefType.icgp &&
              webinar.cpd_status === "granted" && <GmsStudyDays />}
            <CpdPointsType />
            {(webinar.cpd_points === "external" ||
              webinar.cpd_points === "both") && <ExtCpdPoints />}
            {(webinar.cpd_points === "internal" ||
              webinar.cpd_points === "both") && <IntCpdPoints />}
          </div>

          <span>CPD QUESTIONS:</span>
          <div className="user-fields">
            {(webinar.cpd_quizzes_attributes || []).map(
              (cpdQuiz: QuizQuestion) => (
                <QuestionInput index={cpdQuiz.id} item={cpdQuiz} />
              )
            )}
            <div className="group">
              <button className="add-new-course" onClick={handleAddQuestion}>
                Add Question
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="account-info">
        <div className="user-block">
          <span>SPEAKER INFO:</span>
          <div className="user-fields">
            {(webinar.speakers_attributes || [])
              .filter((s: Speaker) => !s.destroy)
              .map((speaker: Speaker, idx: number) => (
                <SpeakerInput index={idx} item={speaker} />
              ))}
            <div className="group">
              <button className="add-new-course" onClick={handleAddSpeaker}>
                Add Speaker
              </button>
            </div>
          </div>

          <span>Sponsors and partnership info:</span>
          <div className="user-fields">
            <AddSponsor />
            <AddPartner />
          </div>
          <Agenda />
          <EvaluationFeedbackSummary />
        </div>
      </div>
    </div>
  );
});
