export const COUNTIES = [{
  label: 'Antrim',
  value: 0,
}, {
  label: 'Armagh',
  value: 1,
}, {
  label: 'Carlow',
  value: 2,
}, {
  label: 'Cavan',
  value: 3,
}, {
  label: 'Clare',
  value: 4,
}, {
  label: 'Cork',
  value: 5,
}, {
  label: 'Derry',
  value: 6,
}, {
  label: 'Donegal',
  value: 7,
}, {
  label: 'Down',
  value: 8,
}, {
  label: 'Dublin North',
  value: 9,
}, {
  label: 'Dublin South',
  value: 33,
}, {
  label: 'Fermanagh',
  value: 10,
}, {
  label: 'Galway',
  value: 11,
}, {
  label: 'Kerry',
  value: 12,
}, {
  label: 'Kildare',
  value: 13,
}, {
  label: 'Kilkenny',
  value: 14,
}, {
  label: 'Laois',
  value: 15,
}, {
  label: 'Leitrim',
  value: 16,
}, {
  label: 'Limerick',
  value: 17,
}, {
  label: 'Longford',
  value: 18,
}, {
  label: 'Louth',
  value: 19,
}, {
  label: 'Mayo',
  value: 20,
}, {
  label: 'Meath',
  value: 21,
}, {
  label: 'Monaghan',
  value: 22,
}, {
  label: 'Offaly',
  value: 23,
}, {
  label: 'Roscommon',
  value: 24,
}, {
  label: 'Sligo',
  value: 25,
}, {
  label: 'Tipperary',
  value: 26,
}, {
  label: 'Tyrone',
  value: 27,
}, {
  label: 'Waterford',
  value: 28,
}, {
  label: 'Westmeath',
  value: 29,
}, {
  label: 'Wexford',
  value: 30,
}, {
  label: 'Wicklow',
  value: 31,
}];