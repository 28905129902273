import React, {useEffect} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {observer} from "mobx-react-lite";
import {ROUTES} from '../../constants';
import {commonStore, currentUserStore} from 'stores'
import {getNotifications} from '../../modules/web/Notifications/actions/gets';
import {RouteProps} from 'types';

export const PrivateRoute = observer((props: RouteProps) => {
  const {children} = props;
  const {currentUser} = currentUserStore;
  const params = useParams();

  if (!commonStore.token && params.webinarId) {
    commonStore.webinarId = params.webinarId;
  }

  useEffect(() => {
    getNotifications();
  }, []);

  if (!commonStore.token)
    return <Navigate to={ROUTES.main} />
  else if(currentUser.id && !currentUser.email_confirmed)
    return <Navigate to={ROUTES.confirmEmail} />
  else if(currentUser.id && !currentUser.location && !window.location.pathname.match(ROUTES.settings))
    return <Navigate to={ROUTES.settings} />
  else if(!commonStore.selectedCurseId && !window.location.pathname.match(ROUTES.webinars) && !window.location.pathname.match(ROUTES.settings))
    return <Navigate to={ROUTES.webinars} />

  return children;
});
