import React from "react"
import {observer} from "mobx-react-lite";
import {deleteModalStore} from "../stores";
import {closeDeleteModal, onDeleteSuperHost} from "../actions/modals";

export const DeleteModal = observer(() => {
  const {show} = deleteModalStore;

  if (!show) {
    return <div/>
  }

  return (
    <div className="modal" style={{display: 'block'}}>
      <div className="modal-container">
        <div className="modal-content-superhost">
          <span className="close">×</span>
          <h3>DELETE SUPERHOST</h3>
          <div className="superhost-desc">
            <p>Are you sure you want to delete this superhost?</p>
            <div className="superhost-btns">
              <button className="cancel-btn-modal" onClick={closeDeleteModal}>Cancel</button>
              <button className="delete-superhost-btn" onClick={onDeleteSuperHost}>Delete Superhost</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})