import React from 'react';
import {observer} from 'mobx-react-lite';
import {ImageField} from 'components/form';
import {changeField} from '../../actions/add';
import {addWebinarStore} from '../../stores';
import {DisabledPropType} from 'types';

export const EvaluationFeedbackSummary = observer((props: DisabledPropType) => {
  const {disabled} = props;
  const {webinar} = addWebinarStore;

  return (
    <div className='group date-picker'>
      <ImageField
        maxFileSize={1024}
        accept='.jpg, .jpeg, .png, .pdf'
        acceptInfo='(JPG/JPEG/PNG/PDF)'
        label='Evaluation Feedback Summary'
        name='evaluation_feedback_summary'
        onChange={changeField}
        value={webinar.evaluation_feedback_summary?.name}
        disabled={disabled}
      />
    </div>
  );
});
