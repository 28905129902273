import React from "react"
import {AdminUserType} from "types";
import EditIcon from "assets/img/png/edit_icon.png";
import DeleteIcon from "assets/img/png/trashcan.png";
import {openDeleteModal, openInfoModal} from "../../actions/modals";
import {addCheckedId, removeCheckedId} from "../../actions";
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {ADMIN_ROUTES} from "constants/routes";

type Props = {
  user: AdminUserType;
}

export const ListItem = (props: Props) => {
  let navigate = useNavigate();
  let params = useParams();

  const {
    id, first_name, last_name, email, country, created_at, last_login, accreditation
  } = props.user;

  const onClickCheckBox = (event: any) => {
    const checked = Boolean(event.target.checked);
    checked ? addCheckedId(id) : removeCheckedId(id);
  }

  const redirectEditUserPage = () => navigate(generatePath(ADMIN_ROUTES.editUser, {id: params.id, userId: id}))

  return (
    <div className="content-line-users">
      <div className="content-desc">
        <label className="container">
          <input type="checkbox" className="content-desc__checkbox" onClick={onClickCheckBox}/>
          <span className="checkmark"></span>
        </label>
        <div className="user-name webinars cursor-pointer" onClick={() => openInfoModal(id)}>
          <p>{first_name + ' ' + last_name}</p>
        </div>
        <div className="user-name webinars">
          <p>{email}</p>
        </div>
        <div className="user-name webinars">
          <p>{accreditation ? 'Ireland' : country}</p>
        </div>
        <div className="user-name webinars">
          <p>{created_at}</p>
        </div>
        <div className="user-name webinars">
          <p>{last_login}</p>
        </div>
      </div>

      <div className="content-options">
        <div className="edit">
          <img src={EditIcon} alt="" onClick={redirectEditUserPage}/>
        </div>

        <div className="delete">
          <img src={DeleteIcon} alt="" onClick={() => openDeleteModal(id)}/>
        </div>
      </div>
    </div>
  )
}