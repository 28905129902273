import axios from 'axios'
import {generatePath} from 'react-router-dom'
import {addAuthTokens} from '.';

export const getRequestAuth = async (url:string, params?:object) => {
    return await axios
        .create({baseURL: process.env.REACT_APP_API_URL})
        .get(generatePath(url), addAuthTokens(params))
        .then(response => response)
        .catch(error => error.response)
};
