import moment from "moment";

export const WebinarConstraints = {
  status: {
    presence: true,
  },
  webinar_type: {
    presence: true,
  },
  webinar_title: {
    presence: true,
  },
  zoom_uid: {
    validateZoom: {
      message: "can't be blanc",
    },
  },
  vimeo_url: {
    validateVimeo: true,
  },
  ref_type: {
    validateCPD: true,
  },
  icgp_ref: {
    validateCPD: true,
  },
  rcpi_ref: {
    validateRcpiRef: {
      message: "number is required",
    },
  },
  ipna_ref: {
    validateIpnaRef: {
      message: "number is required",
    },
  },
  cpd_status: {
    presence: true,
  },
  gms_study_days: {
    validateGmsStudyDays: true,
  },
  cpd_points: {
    validateCPD: true,
  },
  ext_cpd_points: {
    validateCPD: true,
  },
  int_cpd_points: {
    validateIntPoints: {
      message: "number is required",
    },
  },
  cpd_quizzes_attributes: {
    validateQuiz: true,
  },
  speakers_attributes: {
    validateSpeaker: true,
  },
  start_date: {
    validateStartDate: true,
  },
  end_date: {
    validateEndDate: true,
  },
};
