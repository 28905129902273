import {paramsToFormData, showError500} from "utils";
import {adminInviteService} from "services";
import {STATUS_APPROVED} from "constants/inviteStatuses";
import {getInvites} from ".";

export const approveInvite = async (courseId:number, inviteId:number) => {
  const formData = paramsToFormData({status: STATUS_APPROVED}, 'invite');
  const {status, data} = await adminInviteService.updateInvite(formData, inviteId);

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  getInvites()
}