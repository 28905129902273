import {makeAutoObservable} from 'mobx';
import {UserType} from 'types';

export const currentUserStore = makeAutoObservable({
  _currentUser: {} as UserType,

  set currentUser(user: UserType) {
    window.localStorage.setItem('@medcafe_portal_user', JSON.stringify({user}));
    this._currentUser = user;
  },

  get currentUser(): UserType {
    return this._currentUser;
  },

  mountUser(): void {
    if (!this._currentUser.id) {
      const jsonUser = window.localStorage.getItem('@medcafe_portal_user');
      if (jsonUser) {
        const {user} = JSON.parse(jsonUser);
        this._currentUser = user;
      }
    }
  },

  forgetUser(): void {
    window.localStorage.removeItem('@medcafe_portal_user');
    this._currentUser = {} as UserType;
  }
});
