import { cloneDeep } from "lodash";
import { WebinarType } from "types";

export const mapWebinarToFormData = (
  webinar: WebinarType,
  courseId: number
): FormData => {
  const storeData = cloneDeep({
    ...webinar,
    course_id: courseId,
  }) as any;
  const formData: FormData = new FormData();
  const keys = Object.keys(storeData);

  keys.forEach((key) => {
    if (key === "cpd_quizzes_attributes") {
      storeData[key].forEach((quiz: any) => {
        if (webinar.id && quiz.id > 10) {
          formData.append(`webinar[${key}[]id]`, quiz.id);
        }
        formData.append(`webinar[${key}[]question]`, quiz.question);
        formData.append(`webinar[${key}[]correct]`, quiz.correct);
      });
    } else if (key === "speakers_attributes") {
      storeData[key].forEach((speaker: any) => {
        if (
          webinar.id &&
          webinar.speakers &&
          webinar.speakers.data.find((s: any) => s.id === speaker.id.toString())
        ) {
          formData.append(`webinar[${key}[]id]`, speaker.id);
        }
        formData.append(`webinar[${key}[]name]`, speaker.name);
        formData.append(`webinar[${key}[]location]`, speaker.location);
        formData.append(`webinar[${key}[]speaker_type]`, speaker.speaker_type);

        if (speaker.avatar?.type)
          formData.append(`webinar[${key}[]avatar]`, speaker.avatar, "file");

        if (speaker.destroy) {
          formData.append(`webinar[${key}[]_destroy]`, speaker.destroy);
        }
      });
    } else if (
      key === "partner_ids" ||
      key === "sponsor_ids" ||
      key === "assigned_ids"
    ) {
      storeData[key].forEach((id: any) => {
        formData.append(`webinar[${key}][]`, id?.id || id);
      });
    } else if (key === "evaluation_feedback_summary" || key === "agenda") {
      if (storeData[key]?.type) {
        formData.append(
          `webinar[${key}]`,
          storeData[key],
          `file${Date.now()}.${storeData[key].name.split(".").pop()}`
        );
      } else if (storeData[key] === null)
        formData.append(`webinar[${key}]`, "null");
    } else if (storeData[key] || storeData[key] === 0) {
      formData.append(`webinar[${key}]`, storeData[key]);
    } else if (key === "vimeo_url" && storeData[key])
      formData.append("webinar[vimeo_url]", storeData[key]);
  });

  formData.delete("speakers");

  return formData;
};
