import {CourseType} from "types";
import {cloneDeep} from "lodash";
import {mapFromOneCourse} from ".";

export const mapFromCourses = (data: any): CourseType[] => {
  const clone = cloneDeep(data);
  const _courses = clone.courses.data;
  const hasData = _courses && _courses.length > 0;

  return hasData ? _courses.map((item: any) => (mapFromOneCourse(item.attributes))) : [];
}