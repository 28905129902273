import { observer } from "mobx-react-lite"
import { getDetailStatsExport } from "../actions/gets"
import { toggleModal } from "../actions/toggleModal"
import { statisticsStore } from "../stores"
import { DetailStatType } from "../types/DetailStatType"


export const DetailStatModal = observer(() => {
    const { open, detailsArr, detailsMeta } = statisticsStore

    const handleDownloadCSV = () => {
        getDetailStatsExport()
    }

    const handleToggleModal = () => {
        toggleModal(detailsMeta?.id)
    }

    return <div className={`detailStat ${open ? 'open' : ''}`} onClick={handleToggleModal}>
        <div className='detailStat__card' onClick={e => e.stopPropagation()}>
            <div className='detailStat__close' onClick={handleToggleModal}>&times;</div>
            <div className='detailStat__download'>
                <button onClick={handleDownloadCSV}>Download CSV File</button>
            </div>
            <div className='detailStat__title'>{detailsMeta?.title}</div>
            <div className="superhost__header">
                <div className="header-categories-sponsors">
                    <div className="name">
                        <p>#</p>
                    </div>
                    <div className="name">
                        <p>First Name</p>
                    </div>
                    <div className="name">
                        <p>Last Name</p>
                    </div>
                    <div className="name">
                        <p>E-Mail</p>
                    </div>
                </div>
            </div>
            <div className='detailStat__table'>
                {detailsArr.map((item: DetailStatType, index: number) => <div className="content-line-users">
                    <div className="content-desc">
                        <div className="user-name webinars">
                            <p>{index + 1}</p>
                        </div>
                        <div className="user-name webinars">
                            <p>{item.first_name}</p>
                        </div>
                        <div className="user-name webinars">
                            <p>{item.last_name}</p>
                        </div>
                        <div className="user-name webinars">
                            <p>{item.email}</p>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    </div>
})