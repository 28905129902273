import React from "react"
import { PageSideBar } from "modules/admin/PageSideBar"
import { List } from './components/List'
import { StatisticsNavBar } from './components/StatisticsNavBar'
import { DetailStatModal } from "./modals/DetailStatModal"

export const Statistics = () => {
  return (
    <PageSideBar className='display-block'>
      <StatisticsNavBar />
      <DetailStatModal />
      <List />
    </PageSideBar>
  )
}