import React from "react"
import {observer} from "mobx-react-lite";
import {AddNewUsers} from "./components";
import {Props} from ".";

export const AddNewButtons = observer((props: Props) => {
  const {showButton} = props;

  if (showButton === 'users') return <AddNewUsers/>
  if (showButton === 'requests') return <div />

  return <div/>
})