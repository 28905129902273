import React from "react"
import {debounce} from "lodash";
import {getSuperHostsByQuery} from "../../actions/gets";

export const ListSearch = () => {
  const onSearch = debounce((query:string) => getSuperHostsByQuery(query), 3000);

  return (
    <form className="chat">
      <button type="submit">Search</button>
      <input type="search" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} />
    </form>
  )
}