import { getStatistics } from "./getStatictics"
import { statisticsStore } from '../../stores'
import { setMeta } from '../sets'

export const mount = (id: number) => {
  setMeta({
    ...statisticsStore.meta,
    course_id: id,
  })
  getStatistics()
}