import React from "react"
import {ADMIN_ROUTES} from "constants/routes";
import {generatePath, NavLink, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {isActiveClass} from "utils";
import {addWebinarStore} from "../../stores";

export const NewsletterUsers = observer(() => {
  const {webinar} = addWebinarStore;
  const {id, webinarId} = useParams();
  const getLink = () => generatePath(ADMIN_ROUTES.users, {id, webinarId});

  return (
    <NavLink to={getLink()} className={isActiveClass}>
      <button className="all-courses active">{`Newsletter users ${0}`}</button>
    </NavLink>
  )
})