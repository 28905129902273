import {PageHeaderType} from 'types';
import {PageSectionButtons} from './PageSectionButtons';
import oAcademyIcon from 'assets/img/png/o_academy.png';

export const PageHeader = (props: PageHeaderType) => {
  const {title, options, selectedOption, onOptionPress} = props;
  return (
    <div className='page__title-container'>
      <img className='page__title-oAcademyIcon' src={oAcademyIcon} />
      <div className="page__title">
        <h1>{title}</h1>
        {options && onOptionPress && (
          <PageSectionButtons
            options={options}
            selectedOption={selectedOption || options[0].value}
            onOptionPress={onOptionPress}
          />
        )}
      </div>
    </div>
  );
};