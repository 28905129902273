import React from 'react'
import {AdminRoutes, WebRoutes} from '.';
import {Routes as ReactRoutes} from 'react-router-dom';

export const Routes = () => {
  return (
    <ReactRoutes>
      {WebRoutes()}
      {AdminRoutes()}

      {/*<Route key='RouteError404Key' element={<Error404/>}/>*/}
    </ReactRoutes>
  )
}
