export const CPD_POINTS_OPTIONS = [{
  label: 'external',
  value: 'external',
}, {
  label: 'internal',
  value: 'internal',
}, {
  label: 'both',
  value: 'both',
}];
