import {makeAutoObservable} from 'mobx';
import {UserType, InputChangeProps} from 'types';
import {cloneDeep} from 'lodash';

export const createAccountStore = makeAutoObservable({
  _user: {} as UserType,
  _courseId: Number(window.localStorage.removeItem('@medcafe_portal_courseId')),
  _inProgress: false,

  set user(user: UserType) {
    this._user = user;
  },

  get user(): UserType {
    return this._user;
  },

  set courseId(value: number) {
    window.localStorage.setItem('@medcafe_portal_courseId', value.toString());
    this._courseId = value;
  },

  get courseId(): number {
    return this._courseId;
  },

  set inProgress(value: boolean) {
    this._inProgress = value;
  },

  get inProgress(): boolean {
    return this._inProgress;
  },

  changeUserField(data: InputChangeProps) {
    const user = cloneDeep(this._user);
    user[data.name] = data.value;
    this._user = user;
  },

  clearCourseId() {
    window.localStorage.removeItem('@medcafe_portal_courseId');
    this._courseId = 0;
  },

  clear() {
    this._user = {} as UserType;
  },
});