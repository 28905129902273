import {OptionType} from "types";
import {cloneDeep} from "lodash";

export const mapFromRejectReasons = (data: any): OptionType[] => {
  const clone = Object.keys(cloneDeep(data.reject_reasons));

  return clone.map((item: any, index: number) => ({
    text: item,
    value: index,
    key: 'options-reason-key-' + index
  }))
}