import {
  SuperhostFormValues,
  addModalStore,
  addSuperHostStore,
} from "../../stores";
import { AdminUserType } from "types";
import { cloneDeep } from "lodash";

export const openCreateSuperHostModal = ({
  role,
  company_name,
  email,
  first_name,
  id,
  last_name,
  password,
  course,
}: AdminUserType) => {
  const superHost: SuperhostFormValues = {
    role,
    company_name,
    course_id: course.id,
    email,
    first_name,
    id,
    last_name,
    password,
  };

  addSuperHostStore.superHost = cloneDeep(superHost);
  addModalStore.show = true;
};
