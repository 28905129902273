import React from "react"
import { observer } from "mobx-react-lite"
import { debounce, cloneDeep } from 'lodash'
import { courseMaterialsStore } from "../../stores"
import { getCourseMaterials } from '../../actions/gets'
import { setMeta } from "../../actions/sets"

type ListSearchType = {
  scope: string | undefined
}

export const ListSearch = observer((props: ListSearchType) => {
  const {meta} = courseMaterialsStore
  // const showDeleteButton = webinarsStore.checkedIds.length > 0
  // const display = showDeleteButton ? 'block' : 'none'

  const handleGetCourseMaterials = (query: string) => {
    const _meta = cloneDeep(meta)
    _meta.page = 1
    _meta.query = query
    setMeta(_meta)
    getCourseMaterials(props?.scope)
  }
  const onSearch = debounce((query: string) => handleGetCourseMaterials(query), 500)

  // const display = 'none'
  // const openDeleteSelectedModal = () => {}
  return (
    <div className="search__container">
      <form className="chat">
        <button type="submit">Search</button>
        <input type="search" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} />
      </form>
      <div className="search__content">
        {/* <p style={{display: display}} onClick={openDeleteSelectedModal}>Delete</p> */}
      </div>
    </div>
  )
})