import React from "react"
import {observer} from "mobx-react-lite";
import {AddNewCourse, AddNewSponsors, CreateSuperHost, AddNewPartners} from "./components";
import {Props} from ".";

export const AddNewButtons = observer((props: Props) => {
  const {showButton} = props;

  if (showButton === 'course') return <AddNewCourse/>
  if (showButton === 'superhost') return <CreateSuperHost/>
  if (showButton === 'sponsors') return <AddNewSponsors />
  if (showButton === 'partners') return <AddNewPartners />

  return <div/>
})