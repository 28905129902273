import {makeAutoObservable} from 'mobx'
import {CourseMaterialType} from "types";
import {CourseMaterialsMetaType} from '../types';
import {COURSE_MATERIALS_META} from '../constants';

export const courseMaterialsStore = makeAutoObservable({
  _courseMaterials: [] as CourseMaterialType[],
  _meta: COURSE_MATERIALS_META as CourseMaterialsMetaType,

  set courseMaterials(value: CourseMaterialType[]) {
    this._courseMaterials = value;
  },

  get courseMaterials(): CourseMaterialType[] {
    return this._courseMaterials;
  },

  set meta(meta: CourseMaterialsMetaType) {
    this._meta = meta;
  },

  get meta(): CourseMaterialsMetaType {
    return this._meta;
  },

  clear(): void {
    this._courseMaterials = [] as CourseMaterialType[];
  }
});

