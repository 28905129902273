import {deleteModalStore} from "../../stores";
import {sponsorsService} from "../../services";
import {showError500} from "utils";
import {getPartners} from "../gets";
import {toast} from "react-toastify";

export const onDeletePartner = async () => {
  const ids = deleteModalStore.deleteIds;
  const id = deleteModalStore._deleteId;
  const isManyDelete = ids.length > 0;

  const params = {partner_ids: [isManyDelete? ids : id]}

  const {status, data} = await sponsorsService.deletePartner(params)

  if (status !== 200) {
    showError500(status, data);
    return;
  }

  toast.success('Partners was delete successfully.');

  deleteModalStore.clear();
  getPartners();
}