import { showError500 } from "utils" 
import { adminStatisticsService } from "services" 
import { statisticsStore } from "../../stores" 

export const getDetailStats = async (id: number, statistic_type: string) => {
  const { status, data } = await adminStatisticsService.getDetailStats(id, statistic_type)

  const _arr = data.users.data.map((item: any) => item.attributes)

  if (status !== 200) {
    showError500(status, data)
    return
  }
  
  statisticsStore.detailsArr = _arr
}