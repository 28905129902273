import React, { useCallback, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { Page } from "../Page"
import { webinarReviewService } from "./services"

const QUESTIONS_TITLES: any = {
    knowledge_outset: 'Knowledge at outset of activity.',
    knowledge_following: 'Knowledge following the activity.',
    expectations: 'Do you feel that the learning aims/objectives of the session(s) were met?',
    content: 'Content?',
    delivery: 'Delivery?',
    materials: 'Materials?',
    total: 'How would you rate the activity as a whole?'
}


export const WebinarReview = () => {
    const params = useParams()
    const navigate = useNavigate()
    const webinarId = useMemo(() => params.webinarId || '', [params])
    const [isLoading, setIsLoading] = useState(false as boolean)
    const [data, setData] = useState({
        knowledge_outset: null as string | null,
        knowledge_following: null as string | null,
        expectations: null as string | null,
        content: null as string | null,
        delivery: null as string | null,
        materials: null as string | null,
        total: null as string | null
    } as any)
    const [text, setText] = useState('' as string)
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            let _data = []
            for (let i in data) {
                const item = data[i]
                if(item) _data.push({
                    question: QUESTIONS_TITLES[i],
                    answer: item
                })
            }
            _data.push({
                question: 'Would you like to leave any additional feedback, or suggestions for future webinars?',
                answer: text
            })
            if(webinarId) await webinarReviewService.postReview(webinarId, _data)
            await toast.success('Thank you for your feedback!')
            navigate(`/webinars/${webinarId}`)
        } catch(err) {
            console.log(err)
        }
        setIsLoading(false)
    }
    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setData((prev: any) => ({...prev, [name]: value}))
    }, [])
    const handleTextChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target
        setText(value)
    }, [])

    return (
        <Page className="feedbacks">
            <div className="feedback__container">
                <div className="feedback__content">
                    <h1>Evaluation Form</h1>
                    <form onSubmit={handleSubmit} className="feedback__block">
                        <div className="rate__container">
                            <div className="rate__content">
                            <h3>Please rate your level of knowledge on the educational topic(s)/session(s) covered as follows.    
                            </h3>
                            <span>Knowledge at outset of activity.</span>
                            <div className="rating__points">
                                <p>Low</p>
                                <div className="radio-btns rate">
                                <label id="radio" className='rate'>
                                    <input type="radio" name="knowledge_outset" onChange={handleChange} value="1" checked={data.knowledge_outset === '1'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>1</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="knowledge_outset" onChange={handleChange} value="2" checked={data.knowledge_outset === '2'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>2</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="knowledge_outset" onChange={handleChange} value="3" checked={data.knowledge_outset === '3'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>3</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="knowledge_outset" onChange={handleChange} value="4" checked={data.knowledge_outset === '4'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>4</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="knowledge_outset" onChange={handleChange} value="5" checked={data.knowledge_outset === '5'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>5</p>
                                </label>
                                </div>
                                <p>High</p>
                            </div>
                            <span>Knowledge following the activity.</span>
                            <div className="rating__points">
                                <p>Low</p>
                                <div className="radio-btns rate">
                                <label id="radio" className='rate'>
                                    <input type="radio" name="knowledge_following" onChange={handleChange} value="1" checked={data.knowledge_following === '1'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>1</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="knowledge_following" onChange={handleChange} value="2" checked={data.knowledge_following === '2'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>2</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="knowledge_following" onChange={handleChange} value="3" checked={data.knowledge_following === '3'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>3</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="knowledge_following" onChange={handleChange} value="4" checked={data.knowledge_following === '4'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>4</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="knowledge_following" onChange={handleChange} value="5" checked={data.knowledge_following === '5'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>5</p>
                                </label>
                                </div>
                                <p>High</p>
                            </div>
                            <span>Do you feel that the learning aims/objectives of the session(s) were met?</span>
                            <div className="rating__points">
                                <div className="radio-btns rate">
                                <label id="radio" className='rate'>
                                    <input type="radio" name="expectations" onChange={handleChange} value="Not really" checked={data.expectations === 'Not really'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>Not really</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="expectations" onChange={handleChange} value="Partly" checked={data.expectations === 'Partly'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>Partly</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="expectations" onChange={handleChange} value="Mostly" checked={data.expectations === 'Mostly'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>Mostly</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="expectations" onChange={handleChange} value="Completely" checked={data.expectations === 'Completely'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>Completely</p>
                                </label>
                                </div>
                            </div>
                            </div>
                            <div className="rate__content">
                            <h3>On a scale of 1 to 5, how would you rate the content and associated materials, etc. in terms of scientific validity/objectivity, and relevance to the specialty of general practice?  
                            </h3>
                            <span>Content?</span>
                            <div className="rating__points">
                                <p>Irrelevant</p>
                                <div className="radio-btns rate">
                                <label id="radio" className='rate'>
                                    <input type="radio" name="content" onChange={handleChange} value="1" checked={data.content === '1'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>1</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="content" onChange={handleChange} value="2" checked={data.content === '2'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>2</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="content" onChange={handleChange} value="3" checked={data.content === '3'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>3</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="content" onChange={handleChange} value="4" checked={data.content === '4'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>4</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="content" onChange={handleChange} value="5" checked={data.content === '5'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>5</p>
                                </label>
                                </div>
                                <p>Very relevant</p>
                            </div>
                            <span>Delivery?</span>
                            <div className="rating__points">
                                <p>Ineffective</p>
                                <div className="radio-btns rate">
                                <label id="radio" className='rate'>
                                    <input type="radio" name="delivery" onChange={handleChange} value="1" checked={data.delivery === '1'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>1</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="delivery" onChange={handleChange} value="2" checked={data.delivery === '2'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>2</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="delivery" onChange={handleChange} value="3" checked={data.delivery === '3'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>3</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="delivery" onChange={handleChange} value="4" checked={data.delivery === '4'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>4</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="delivery" onChange={handleChange} value="5" checked={data.delivery === '5'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>5</p>
                                </label>
                                </div>
                                <p>Effective</p>
                            </div>
                            <span>Materials?</span>
                            <div className="rating__points">
                                <p>Unhelpful</p>
                                <div className="radio-btns rate">
                                <label id="radio" className='rate'>
                                    <input type="radio" name="materials" onChange={handleChange} value="1" checked={data.materials === '1'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>1</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="materials" onChange={handleChange} value="2" checked={data.materials === '2'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>2</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="materials" onChange={handleChange} value="3" checked={data.materials === '3'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>3</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="materials" onChange={handleChange} value="4" checked={data.materials === '4'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>4</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="materials" onChange={handleChange} value="5" checked={data.materials === '5'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>5</p>
                                </label>
                                </div>
                                <p>Helpful</p>
                            </div>
                            <span>How would you rate the activity as a whole?</span>
                            <div className="rating__points">
                                <p>Poor</p>
                                <div className="radio-btns rate">
                                <label id="radio" className='rate'>
                                    <input type="radio" name="total" onChange={handleChange} value="1" checked={data.total === '1'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>1</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="total" onChange={handleChange} value="2" checked={data.total === '2'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>2</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="total" onChange={handleChange} value="3" checked={data.total === '3'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>3</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="total" onChange={handleChange} value="4" checked={data.total === '4'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>4</p>
                                </label>
                                <label id="radio" className='rate'>
                                    <input type="radio" name="total" onChange={handleChange} value="5" checked={data.total === '5'}></input>
                                    <div className="radio__circle">
                                    <div className="radio__circle-content"></div>
                                    </div>
                                    <p>5</p>
                                </label>
                                </div>
                                <p>Excellent</p>
                            </div>
                            <div className='feedback'>
                                <textarea value={text} onChange={handleTextChange} className="forum" name='text' placeholder="Would you like to leave any additional feedback, or suggestions for future webinars?" />
                            </div>
                            </div>
                        </div>
                    <button disabled={isLoading} className="submit-btn">Submit Feedback</button>
                    </form>
                </div>
            </div>
        </Page>
    )
}