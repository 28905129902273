import React from "react"
import {AddUserForm} from "./components/Add";
import {PageSideBar} from "modules/admin/PageSideBar";

export const AddUser = () => {
  return (
    <PageSideBar className='display-block'>
      <AddUserForm />
    </PageSideBar>
  )
}