import {makeAutoObservable} from 'mobx'
import {CpdQuizAnswersType} from "types";

export const quizModalStore = makeAutoObservable({
  _isOpen: false,
  _cpdQuizAnswers: {} as CpdQuizAnswersType,
  _quizStarted: false,
  _selectedQuestionIndex: 0,
  _inProgress: false,

  set isOpen(value: boolean) {
    this._isOpen = value;
  },

  get isOpen(): boolean {
    return this._isOpen;
  },

  set cpdQuizAnswers(value: CpdQuizAnswersType) {
    this._cpdQuizAnswers = value;
  },

  get cpdQuizAnswers(): CpdQuizAnswersType {
    return this._cpdQuizAnswers;
  },

  set inProgress(value: boolean) {
    this._inProgress = value;
  },

  get inProgress(): boolean {
    return this._inProgress;
  },

  set quizStarted(value: boolean) {
    this._quizStarted = value;
  },

  get quizStarted(): boolean {
    return this._quizStarted;
  },

  set selectedQuestionIndex(value: number) {
    this._selectedQuestionIndex = value;
  },

  get selectedQuestionIndex(): number {
    return this._selectedQuestionIndex;
  },

  clear(): void {
    this._isOpen = false;
    this._inProgress = false;
    this._cpdQuizAnswers = {} as CpdQuizAnswersType;
    this._quizStarted = false;
    this._selectedQuestionIndex = 0;
  }
});

