import React from 'react';
import {observer} from 'mobx-react-lite';
import {InputTextField} from 'components/form';
import {addWebinarStore} from '../../stores';
import {changeField} from '../../actions/add';
import {getErrors} from "utils";
import {DisabledPropType} from 'types';

type Props = {
  name: string;
  label: string;
};

export const ReferenceNumber = observer((props: Props & DisabledPropType) => {
  const {name, label, disabled} = props;
  const {webinar} = addWebinarStore;
  return (
    <InputTextField
      name={name}
      label={`${label} refenence number`}
      value={webinar[name]}
      onChange={changeField}
      error={getErrors(name)}
      type='round'
      disabled={disabled}
    />
  );
});
