import {makeAutoObservable} from 'mobx'
import {AdminInviteType, ImageChangeProps} from "types";
import {cloneDeep} from "lodash";

export const addInviteStore = makeAutoObservable({
  _invite: {} as AdminInviteType,

  set invite(value: AdminInviteType) {
    this._invite = value;
  },

  get invite(): AdminInviteType {
    return this._invite;
  },

  changeField(data: ImageChangeProps) {
    const clone = cloneDeep(this._invite);
    const key = data.name as keyof AdminInviteType;

    // @ts-ignore
    clone[key] = data.value;
    this._invite = clone;
  },

  clear(): void {
    this._invite = {} as AdminInviteType;
  },

});

