import React from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import { Link, useLocation } from "react-router-dom";
import {
  AUTH_HEADER_LINKS,
  NOT_AUTH_HEADER_LINKS,
  ROUTES,
} from "../../../constants";
import { openNotifications } from "../Notifications/actions/sets";
import { currentUserStore, commonStore } from "stores";
import notificationsIcon from "assets/img/svg/notifications_black_24dp.svg";

export const HeaderLinks = observer(() => {
  const { pathname } = useLocation();
  const { currentUser } = currentUserStore;
  const { selectedCurseId } = commonStore;
  const authLinks = selectedCurseId ? AUTH_HEADER_LINKS : [];
  const isActive = (url: string): boolean => !!pathname.match(url);
  const { id, first_name, last_name } = currentUser || {};
  const handleLogout = () => {
    commonStore.logout();
  };
  const handleNotifications = () => {
    openNotifications();
  };
  if (!currentUser.id) {
    return (
      <ul className="menu">
        {NOT_AUTH_HEADER_LINKS.map((link) => (
          <Link to={link.path} key={link.title}>
            <li className={classnames("", { active: isActive(link.path) })}>
              {link.title}
            </li>
          </Link>
        ))}
      </ul>
    );
  } else {
    return (
      <ul className="menu">
        {authLinks.map((link) => (
          <Link to={link.path} key={link.title}>
            <li className={classnames("", { active: isActive(link.path) })}>
              {link.title}
            </li>
          </Link>
        ))}
        {/* <div className='header__login-mob'> */}
        {/* <a className="header__link-mob">
          <li onClick={handleNotifications}>Notifications</li>
        </a> */}
        <Link to={ROUTES.settings} className="user_name header__link-mob">
          <li className={classnames("", { active: isActive(ROUTES.settings) })}>
            {`${first_name} ${last_name}`}
          </li>
        </Link>
        <a className="header__link-mob">
          <li onClick={handleLogout}>Logout</li>
        </a>
        {/* </div> */}
      </ul>
    );
  }
});
