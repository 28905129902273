import React from "react"
import {observer} from "mobx-react-lite";
import CloseIcon from "assets/img/png/ic-close.png";
import {addInviteStore, inviteModalStore} from "../stores";
import {InputTextField, SelectField} from "components/form";
import {changeField, closeInviteModal, onInviteUser, unmoutInviteModal} from "../actions/modals";
import {getErrors} from "utils";
import {getCourses} from "modules/admin/Courses/actions/gets";
import {coursesStore} from "modules/admin/Courses/stores";

export const InviteModal = observer(() => {
  const {show} = inviteModalStore;
  const {user_first_name, user_last_name, user_email, course_id} = addInviteStore.invite;

  const coursesOptions = coursesStore.options();

  React.useEffect(
    () => {
      getCourses();

      return unmoutInviteModal();
    },
    []
  );

  if (!show) {
    return <div/>
  }

  return (
    <div className="modal" style={{display: 'block'}}>
      <div className="modal-container-superhost">
        <span className="close">×</span>
        <div className="modal-content-create__sponsors">
          <div className="modal-content-create__headline">
            <img src={CloseIcon} alt="close modal button" onClick={closeInviteModal}/>
          </div>
          <h3>v</h3>
          <div className="superhost-create__desc">
            <div className="superhost-inputs">

              <InputTextField
                type='modal'
                name='user_first_name'
                label='First Name'
                value={user_first_name}
                error={getErrors('user_first_name')}
                onChange={changeField}
              />

              <InputTextField
                type='modal'
                name='user_last_name'
                label='Surname'
                value={user_last_name}
                error={getErrors('user_last_name')}
                onChange={changeField}
              />

              <InputTextField
                type='modal'
                name='user_email'
                label='Email'
                value={user_email}
                error={getErrors('user_email')}
                onChange={changeField}
              />

              <SelectField
                name='course_id'
                label='Course name'
                setDefaultValue={true}
                options={coursesOptions}
                value={course_id}
                error={getErrors('course_id')}
                onChange={changeField}
              />
            </div>

            <div className="superhost-btns-create">
              <button className="cancel-btn-modal" onClick={closeInviteModal}>Cancel</button>
              <button className="create-btn" onClick={onInviteUser}>Invite User</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})